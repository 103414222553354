import { IApi } from './api.service';
import { ApiEndpoints } from './endpoints';
import {
    ISkill,
    ISkillCreationDto,
    ISkillFilter,
    ISkillProficiency,
    ISkillProficiencyCreationDto,
    ISkillProficiencyUpdateDto,
} from '../interfaces/SkillProficiency.interface';
import { IPaginatedListResponse } from '../interfaces/Pagination.interface';

export const getAllSkills = async (api: IApi, filter: ISkillFilter): Promise<IPaginatedListResponse<ISkill>> => {
    const paginatedSkills: IPaginatedListResponse<ISkill> | null = await api.get<IPaginatedListResponse<ISkill>, ISkillFilter>(
        ApiEndpoints.skill,
        { params: filter },
    );
    if (!paginatedSkills) {
        return {
            count: 0,
            items: [],
            page: 0,
            pageSize: 0,
        };
    }
    return paginatedSkills;
};

export const getOrCreateSkill = async (api: IApi, dto: ISkillCreationDto): Promise<ISkill | null> => {
    return api.post<ISkill, ISkillCreationDto>(ApiEndpoints.skill, dto);
};

export const createSkillProficiency = async (api: IApi, dto: ISkillProficiencyCreationDto): Promise<ISkillProficiency | null> => {
    return api.post<ISkillProficiency, ISkillProficiencyCreationDto>(ApiEndpoints.skillProficiency, dto);
};

export const updateSkillProficiency = async (api: IApi, id: number, dto: ISkillProficiencyUpdateDto): Promise<ISkillProficiency | null> => {
    return api.put<ISkillProficiency, ISkillProficiencyUpdateDto>(ApiEndpoints.skillProficiencyId.replace(':id', String(id)), dto);
};

export const removeSkillProficiency = async (api: IApi, id: number): Promise<void> => {
    return api.delete(ApiEndpoints.skillProficiencyId.replace(':id', String(id)));
};
