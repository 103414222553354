import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';

interface PasswordFieldProps {
    label: string;
    value: string;
    error: boolean;
    onChange: OutlinedInputProps['onChange'];
}

const PasswordField = (props: PasswordFieldProps) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <TextField
            label={props.label}
            type={showPassword ? 'text' : 'password'}
            dir={'ltr'}
            variant="outlined"
            autoComplete="off"
            fullWidth
            margin="normal"
            value={props.value}
            error={props.error}
            onChange={props.onChange}
            required={true}
            slotProps={{
                input: {
                    endAdornment: props.value && (
                        <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                },
                htmlInput: { autoComplete: 'new-password' },
            }}
        />
    );
};

export default PasswordField;
