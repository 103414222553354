import React from 'react';
import { Box, Divider, Grid2, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { IExperienceTheme, IFontTheme, IMarginTheme, ITitleTheme, ThemeValue } from '../../interfaces/Theme.interface';
import dayjs from 'dayjs';
import { LABEL_SIZE } from './ThemeDesigner';
import MarginThemeCustomizer from './MarginTheme';
import FontThemeCustomizer from './FontTheme';
import { TranslationKey } from '../../interfaces/TranslationKey';
import { useTranslation } from 'react-i18next';
import TitleThemeCustomizer from './TitleTheme';

interface ExperienceThemeCustomizerProps {
    theme: IExperienceTheme;
    setTheme: (experienceTheme: (prev: IExperienceTheme) => IExperienceTheme) => void;
    setShouldUpdateThemed: (value: boolean) => void;
}

const ExperienceThemeCustomizer = (props: ExperienceThemeCustomizerProps): React.ReactElement => {
    const { t } = useTranslation();

    const handleHeaderFontThemeChange = (key: keyof IFontTheme, value: string) => {
        handleThemeChange('header', key, value);
    };

    const handleDateFontThemeChange = (key: keyof IFontTheme, value: string) => {
        handleThemeChange('date', key, value);
    };

    const handleItemFontThemeChange = (key: keyof IFontTheme, value: string) => {
        handleThemeChange('item', key, value);
    };

    const handleMarginThemeChange = (key: keyof IMarginTheme, value: number) => {
        handleThemeChange('margin', key, value);
    };

    const handleTitleThemeChange = (key: keyof ITitleTheme, value: ThemeValue) => {
        handleThemeChange('title', key, value);
    };

    const handleThemeChange = <T extends keyof IExperienceTheme, K extends keyof IExperienceTheme[T]>(
        section: T,
        key: K,
        value: ThemeValue,
    ) => {
        props.setTheme((prev: IExperienceTheme) => ({
            ...prev,
            [section]: {
                ...prev[section],
                [key]: value,
            },
        }));
        props.setShouldUpdateThemed(true);
    };

    return (
        <Box>
            <Typography variant="h6">{t(TranslationKey.theme.title)}</Typography>
            <Grid2 container spacing={2} sx={{ mt: '10px' }}>
                <Grid2 size={12}>
                    <TitleThemeCustomizer
                        labelKey={'title-experience'}
                        theme={props.theme.title}
                        handleThemeChange={handleTitleThemeChange}
                    />
                </Grid2>
            </Grid2>
            <Divider sx={{ my: 2 }} />

            <Typography variant="h6">{t(TranslationKey.theme.header)}</Typography>
            <Grid2 container spacing={2}>
                <Grid2 size={12}>
                    <FontThemeCustomizer
                        labelKey={'experience-header'}
                        theme={props.theme.header}
                        handleThemeChange={handleHeaderFontThemeChange}
                        variantItems={[
                            <MenuItem key={'experience-header-variant-h5'} value="h5">
                                {t(TranslationKey.theme.fontSize.L)}
                            </MenuItem>,
                            <MenuItem key={'experience-header-variant-h6'} value="h6">
                                {t(TranslationKey.theme.fontSize.M)}
                            </MenuItem>,
                            <MenuItem key={'experience-header-variant-body1'} value="body1">
                                {t(TranslationKey.theme.fontSize.S)}
                            </MenuItem>,
                        ]}
                    />
                </Grid2>
            </Grid2>

            <Divider sx={{ my: 2 }} />
            <Typography variant="h6">{t(TranslationKey.date._name)}</Typography>
            <Grid2 container spacing={2} sx={{ mb: '5px' }}>
                <Grid2 size={LABEL_SIZE} alignContent="center">
                    <InputLabel>{t(TranslationKey.theme.dateFormat)}</InputLabel>
                </Grid2>
                <Grid2 size="grow">
                    <Select
                        value={props.theme.date.format}
                        onChange={(e) => handleThemeChange('date', 'format', e.target.value)}
                        variant={'outlined'}
                        sx={{ width: '100%' }}
                    >
                        <MenuItem key={'experience-date-format-1'} value="YYYY-MM">
                            {dayjs().format('YYYY-MM')}
                        </MenuItem>{' '}
                        {/*EN, DE, SP*/}
                        <MenuItem key={'experience-date-format-2'} value="YYYY/MM">
                            {dayjs().format('YYYY/MM')}
                        </MenuItem>{' '}
                        {/*AR*/}
                        <MenuItem key={'experience-date-format-3'} value="MM/YYYY">
                            {dayjs().format('MM/YYYY')}
                        </MenuItem>{' '}
                        {/*EN, AR, SP*/}
                        <MenuItem key={'experience-date-format-4'} value="MM.YYYY">
                            {dayjs().format('MM.YYYY')}
                        </MenuItem>{' '}
                        {/*DE*/}
                        <MenuItem key={'experience-date-format-5'} value="MMM, YYYY">
                            {dayjs().format('MMM, YYYY')}
                        </MenuItem>{' '}
                        {/*EN*/}
                        <MenuItem key={'experience-date-format-6'} value="MMMM YYYY">
                            {dayjs().format('MMMM YYYY')}
                        </MenuItem>{' '}
                        {/*EN, DE*/}
                        <MenuItem key={'experience-date-format-7'} value="MMMM YYYY">
                            {dayjs().format('YYYY MMMM')}
                        </MenuItem>{' '}
                        {/*Ar*/}
                    </Select>
                </Grid2>
            </Grid2>
            <Grid2 container spacing={2}>
                <Grid2 size={12}>
                    <FontThemeCustomizer
                        labelKey={'experience-date'}
                        theme={props.theme.date}
                        handleThemeChange={handleDateFontThemeChange}
                        variantItems={[
                            <MenuItem key={'experience-date-variant-h6'} value="h6">
                                {t(TranslationKey.theme.fontSize.L)}
                            </MenuItem>,
                            <MenuItem key={'experience-date-variant-body1'} value="body1">
                                {t(TranslationKey.theme.fontSize.M)}
                            </MenuItem>,
                            <MenuItem key={'experience-date-variant-body2'} value="body2">
                                {t(TranslationKey.theme.fontSize.S)}
                            </MenuItem>,
                            <MenuItem key={'experience-date-variant-caption'} value="caption">
                                {t(TranslationKey.theme.fontSize.XS)}
                            </MenuItem>,
                        ]}
                    />
                </Grid2>
            </Grid2>

            <Divider sx={{ my: 2 }} />
            <Typography variant="h6">{t(TranslationKey.experience.item.experienceItems)}</Typography>
            <Grid2 container spacing={2}>
                <Grid2 size={12}>
                    <FontThemeCustomizer
                        labelKey={'experience-item'}
                        theme={props.theme.item}
                        handleThemeChange={handleItemFontThemeChange}
                        variantItems={[
                            <MenuItem key={'experience-item-variant-h5'} value="h6">
                                {t(TranslationKey.theme.fontSize.L)}
                            </MenuItem>,
                            <MenuItem key={'experience-item-variant-body1'} value="body1">
                                {t(TranslationKey.theme.fontSize.M)}
                            </MenuItem>,
                            <MenuItem key={'experience-item-variant-body2'} value="body2">
                                {t(TranslationKey.theme.fontSize.S)}
                            </MenuItem>,
                            <MenuItem key={'experience-item-variant-caption'} value="caption">
                                {t(TranslationKey.theme.fontSize.XS)}
                            </MenuItem>,
                        ]}
                    />
                </Grid2>
            </Grid2>
            <Divider sx={{ my: 2 }} />

            <Grid2 container spacing={2} sx={{ mt: '10px' }}>
                <Grid2 size={12}>
                    <MarginThemeCustomizer theme={props.theme.margin} handleThemeChange={handleMarginThemeChange} />
                </Grid2>
            </Grid2>
        </Box>
    );
};

export default ExperienceThemeCustomizer;
