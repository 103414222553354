import { IReference, IReferenceUpdateDto } from '../../../../interfaces/Reference.interface';
import { IFontTheme } from '../../../../interfaces/Theme.interface';
import React, { useState } from 'react';
import { Box } from '@mui/material';
import { EditableTextField } from '../../../GeneralTextField';
import { TranslationKey } from '../../../../interfaces/TranslationKey';

interface ReferenceProps {
    reference: IReference;
    setReference: (reference: IReference) => void;
    headerTheme: IFontTheme;
    subHeaderTheme: IFontTheme;
    onDoneClicked: (dto: IReferenceUpdateDto) => void;
}

const ReferenceHeaderForm = (props: ReferenceProps): React.ReactElement => {
    const [name, setName] = useState<string>(props.reference.name);
    const [contact, setContact] = useState<string | null>(props.reference.contact);

    const onDoneClickedName = (value: string) => {
        if (!value || value === props.reference.name) return;

        props.onDoneClicked({ name: value, contact: contact || null });
        setName(value);
    };

    const onDoneClickedContact = (value: string) => {
        const cleanValue = value || null;
        if (cleanValue === props.reference.contact) return;

        props.onDoneClicked({ name: name, contact: cleanValue });
        setContact(cleanValue);
    };

    return (
        <Box>
            <EditableTextField
                value={name}
                setValue={setName}
                label={TranslationKey.reference.name}
                minLength={3}
                required={true}
                fontTheme={props.headerTheme}
                onDoneClicked={onDoneClickedName}
            />
            {!!props.reference.id && (
                <EditableTextField
                    value={contact ?? ''}
                    setValue={setContact}
                    label={TranslationKey.reference.contact}
                    required={false}
                    fontTheme={props.subHeaderTheme}
                    onDoneClicked={onDoneClickedContact}
                />
            )}
        </Box>
    );
};

export default ReferenceHeaderForm;
