import { IReference, IReferenceCreationDto, IReferenceUpdateDto } from '../../../../interfaces/Reference.interface';
import { IReferenceTheme } from '../../../../interfaces/Theme.interface';
import React, { useEffect, useState } from 'react';
import { IApi } from '../../../../services/api.service';
import { useAxiosApi } from '../../../../hooks/useAxiosApi';
import { createReference, updateReference } from '../../../../services/reference.service';
import ReferenceHeaderForm from './ReferenceHeaderFrom';
import { LinearProgress } from '@mui/material';

interface ReferenceProps {
    cvId: number;
    reference: IReference;
    references: IReference[];
    setReferences: (reference: IReference[]) => void;
    theme: IReferenceTheme;
}

const ReferenceForm = (props: ReferenceProps): React.ReactElement => {
    const api: IApi = useAxiosApi();
    const [reference, setReference] = useState<IReference>(props.reference);
    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {
        updateState(reference);
    }, [reference]);

    const updateState = (_reference: IReference) => {
        setReference(_reference);
        const updatedList = props.references.map((e: IReference) => (e.id === reference.id ? _reference : e));
        props.setReferences(updatedList);
    };

    const _updateReference = (dto: IReferenceUpdateDto) => {
        updateReference(api, reference.id, dto).then((updatedReference: IReference | null) => {
            if (updatedReference) {
                updateState(updatedReference);
            }
        });
    };

    const onDoneClickedHeader = (dto: IReferenceUpdateDto) => {
        dto = {
            name: dto.name,
            contact: dto.contact || null,
        };

        if (!reference.id) {
            const readyToCreate: boolean = !!dto.name;

            if (readyToCreate) {
                setIsLoading(true);
                const creationDto: IReferenceCreationDto = {
                    ...dto,
                    cv: { id: props.cvId },
                } as IReferenceCreationDto;

                createReference(api, creationDto)
                    .then((newReference: IReference | null) => {
                        if (newReference) {
                            updateState(newReference);
                        }
                    })
                    .finally(() => setIsLoading(false));
            }
        } else {
            _updateReference(dto);
        }
    };

    return (
        <>
            <ReferenceHeaderForm
                reference={reference}
                setReference={setReference}
                headerTheme={props.theme.header}
                subHeaderTheme={props.theme.subHeader}
                onDoneClicked={onDoneClickedHeader}
            />
            {isLoading && <LinearProgress sx={{ my: 3 }} />}
        </>
    );
};

export default ReferenceForm;
