import { useAuth } from '../context/Auth.context';
import { useEffect } from 'react';
import { useRefresh } from './useRefresh';
import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { api, IApi } from '../services/api.service';
import { useTranslation } from 'react-i18next';

export const useAxiosApi = (): IApi => {
    const { i18n } = useTranslation();
    const { accessToken } = useAuth();
    const refresh = useRefresh();
    let isRefreshing = false;
    let refreshSubscribers: ((token: string) => void)[] = [];

    useEffect(() => {
        const requestIntercept: number = api.axiosInstance.interceptors.request.use(
            async (config: InternalAxiosRequestConfig) => {
                config.headers['Accept-Language'] = i18n.language;
                if (accessToken) {
                    config.headers.Authorization = `Bearer ${accessToken}`;
                }
                return config;
            },
            (error) => Promise.reject(error),
        );

        const onRefreshed = (token: string) => {
            refreshSubscribers.forEach((callback) => callback(token));
            refreshSubscribers = [];
        };

        const responseIntercept: number = api.axiosInstance.interceptors.response.use(
            (response: AxiosResponse) => response,
            async (error) => {
                const prevRequest = error.config;
                if (error.response?.status === 401 && !prevRequest?.sent) {
                    if (!isRefreshing) {
                        isRefreshing = true;
                        try {
                            const _accessToken = await refresh();
                            if (_accessToken) {
                                await new Promise((resolve) => setTimeout(resolve, 2)); // Ensure setAccessToken is executed
                                onRefreshed(_accessToken);
                                prevRequest.headers.Authorization = `Bearer ${_accessToken}`;
                                return api.axiosInstance(prevRequest);
                            }
                        } catch {
                            console.error('Token refresh failed!');
                        } finally {
                            isRefreshing = false;
                        }
                    } else {
                        return new Promise((resolve) => {
                            refreshSubscribers.push((token: string) => {
                                prevRequest.headers.Authorization = `Bearer ${token}`;
                                resolve(api.axiosInstance(prevRequest));
                            });
                        });
                    }
                }

                return Promise.reject(error);
            },
        );

        return () => {
            api.axiosInstance.interceptors.response.eject(responseIntercept);
            api.axiosInstance.interceptors.request.eject(requestIntercept);
        };
    }, [accessToken, refresh]);

    return api;
};
