import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { ICV } from '../../interfaces/CV.interface';
import { IPDFStyle } from '../../interfaces/pdfStyle.interface';
import { ISkillProficiency, SkillProficiencyToValue } from '../../interfaces/SkillProficiency.interface';
import PDFProficiency from './Proficiency';
import { Style } from '@react-pdf/types';

interface SectionPDFProps {
    cv: ICV;
    styles: IPDFStyle;
    inline: boolean;
}

const SkillsPDF = (props: SectionPDFProps): React.ReactElement => {
    const eliminateMargin: boolean = props.styles.skillBarStyle.type === 'none';

    const flex: Style = props.inline ? { ...props.styles.rowStyle, justifyContent: 'space-between' } : {};

    return (
        <View style={props.styles.skillView}>
            {props.cv.skillProficiencies.map((sp: ISkillProficiency, index: number) => {
                return (
                    <View
                        key={`PDF-skills-${sp.id}`}
                        style={{
                            ...flex,
                            marginBottom: index === props.cv.skillProficiencies.length - 1 || eliminateMargin || props.inline ? 0 : '5px',
                        }}
                    >
                        <Text style={{ ...props.styles.skillFont, marginBottom: eliminateMargin ? 0 : '3px' }}>{sp.skill.name}</Text>
                        <View style={props.inline ? { display: 'flex', width: '50%', alignItems: 'flex-end' } : {}}>
                            <PDFProficiency
                                rowStyle={props.styles.rowStyle}
                                theme={props.styles.skillBarStyle}
                                value={SkillProficiencyToValue[sp.proficiency]}
                                labelKey={'Skills'}
                            />
                        </View>
                    </View>
                );
            })}
        </View>
    );
};

export default SkillsPDF;
