import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { SketchPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../interfaces/TranslationKey';

interface ColorPickerDialogProps {
    label: string;
    color: string;
    onColorChange: (color: string) => void;
}

const ColorPickerDialog = (props: ColorPickerDialogProps) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleColorChangeComplete = (newColor: { hex: any }) => {
        props.onColorChange(newColor.hex);
    };

    const colorSquare = (): React.ReactElement => {
        return <Box width={25} height={25} bgcolor={props.color}></Box>;
    };

    return (
        <>
            <Button variant="outlined" color="primary" onClick={handleOpen} sx={{ mt: 1, px: 2, py: 1 }} endIcon={colorSquare()}>
                {t(props.label)}
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{t(props.label)}</DialogTitle>
                <DialogContent>
                    <SketchPicker color={props.color} onChangeComplete={handleColorChangeComplete} />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={handleClose}>
                        {t(TranslationKey.buttons.close)}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ColorPickerDialog;
