import React from 'react';
import { Box, Divider, Grid2, MenuItem, Typography } from '@mui/material';
import { IFontTheme, IMarginTheme, IReferenceTheme, ITitleTheme, ThemeValue } from '../../interfaces/Theme.interface';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../interfaces/TranslationKey';
import MarginThemeCustomizer from './MarginTheme';
import FontThemeCustomizer from './FontTheme';
import TitleThemeCustomizer from './TitleTheme';

interface ReferenceThemeCustomizerProps {
    theme: IReferenceTheme;
    setTheme: (referenceTheme: (prev: IReferenceTheme) => IReferenceTheme) => void;
    setShouldUpdateThemed: (value: boolean) => void;
}

const ReferenceThemeCustomizer = (props: ReferenceThemeCustomizerProps): React.ReactElement => {
    const { t } = useTranslation();

    const handleHeaderFontThemeChange = (key: keyof IFontTheme, value: string) => {
        handleThemeChange('header', key, value);
    };

    const handleSubHeaderFontThemeChange = (key: keyof IFontTheme, value: string) => {
        handleThemeChange('subHeader', key, value);
    };

    const handleMarginThemeChange = (key: keyof IMarginTheme, value: number) => {
        handleThemeChange('margin', key, value);
    };

    const handleTitleThemeChange = (key: keyof ITitleTheme, value: ThemeValue) => {
        handleThemeChange('title', key, value);
    };

    const handleThemeChange = <T extends keyof IReferenceTheme, K extends keyof IReferenceTheme[T]>(
        section: T,
        key: K,
        value: ThemeValue,
    ) => {
        props.setTheme((prev: IReferenceTheme) => ({
            ...prev,
            [section]: {
                ...prev[section],
                [key]: value,
            },
        }));
        props.setShouldUpdateThemed(true);
    };

    return (
        <Box>
            <Typography variant="h6">{t(TranslationKey.theme.title)}</Typography>
            <Grid2 container spacing={2} sx={{ mt: '10px' }}>
                <Grid2 size={12}>
                    <TitleThemeCustomizer
                        labelKey={'title-reference'}
                        theme={props.theme.title}
                        handleThemeChange={handleTitleThemeChange}
                    />
                </Grid2>
            </Grid2>
            <Divider sx={{ my: 2 }} />

            <Typography variant="h6">{t(TranslationKey.theme.header)}</Typography>
            <Grid2 container spacing={2}>
                <Grid2 size={12}>
                    <FontThemeCustomizer
                        labelKey={'reference-header'}
                        theme={props.theme.header}
                        handleThemeChange={handleHeaderFontThemeChange}
                        variantItems={[
                            <MenuItem key={'reference-header-variant-h5'} value="h5">
                                {t(TranslationKey.theme.fontSize.L)}
                            </MenuItem>,
                            <MenuItem key={'reference-header-variant-h6'} value="h6">
                                {t(TranslationKey.theme.fontSize.M)}
                            </MenuItem>,
                            <MenuItem key={'reference-header-variant-body1'} value="body1">
                                {t(TranslationKey.theme.fontSize.S)}
                            </MenuItem>,
                        ]}
                    />
                </Grid2>
            </Grid2>
            <Divider sx={{ my: 2 }} />

            <Typography variant="h6">{t(TranslationKey.theme.subHeader)}</Typography>
            <Grid2 container spacing={2}>
                <Grid2 size={12}>
                    <FontThemeCustomizer
                        labelKey={'reference-subheader'}
                        theme={props.theme.subHeader}
                        handleThemeChange={handleSubHeaderFontThemeChange}
                        variantItems={[
                            <MenuItem key={'reference-subHeader-variant-h6'} value="h6">
                                {t(TranslationKey.theme.fontSize.L)}
                            </MenuItem>,
                            <MenuItem key={'reference-subHeader-variant-body1'} value="body1">
                                {t(TranslationKey.theme.fontSize.M)}
                            </MenuItem>,
                            <MenuItem key={'reference-subHeader-variant-body2'} value="body2">
                                {t(TranslationKey.theme.fontSize.S)}
                            </MenuItem>,
                            <MenuItem key={'reference-subHeader-variant-caption'} value="caption">
                                {t(TranslationKey.theme.fontSize.XS)}
                            </MenuItem>,
                        ]}
                    />
                </Grid2>
            </Grid2>

            <Divider sx={{ my: 2 }} />
            <Grid2 container spacing={2} sx={{ mt: '10px' }}>
                <Grid2 size={12}>
                    <MarginThemeCustomizer theme={props.theme.margin} handleThemeChange={handleMarginThemeChange} />
                </Grid2>
            </Grid2>
        </Box>
    );
};

export default ReferenceThemeCustomizer;
