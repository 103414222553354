import { IReference } from '../../../interfaces/Reference.interface';
import { IReferenceTheme } from '../../../interfaces/Theme.interface';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { IApi } from '../../../services/api.service';
import { useAxiosApi } from '../../../hooks/useAxiosApi';
import { useCV } from '../../../context/CV.context';
import { reorderItems } from '../../../services/common.services';
import { ApiEndpoints } from '../../../services/endpoints';
import { removeReference } from '../../../services/reference.service';
import OrderableList from '../../OrderableList';
import { IOrderableEntity } from '../../../interfaces/Common.interface';
import AddSectionButton from '../../AddSectionButton';
import ReferenceForm from './reference/ReferenceForm';

interface ReferencesProps {
    cvId: number;
    references: IReference[];
    theme: IReferenceTheme;
}

const ReferencesForm = (props: ReferencesProps): React.ReactElement => {
    const api: IApi = useAxiosApi();
    const { dispatch } = useCV();
    const [references, setReferences] = React.useState<IReference[]>(props.references);

    useEffect(() => {
        dispatch({ type: 'UPDATE_FIELD', field: 'references', value: references });
    }, [references]);

    const referenceAddCallback = () => {
        if (references.map((entity: IReference) => entity.id).includes(0)) {
            return;
        }
        const newEntity: IReference = {
            name: '',
            contact: '',
            id: 0,
            weight: 100,
        };
        setReferences([...references, newEntity]);
    };

    const _reorderItems = async (movedItemId: number, newIndex: number) => {
        if (movedItemId) {
            reorderItems(ApiEndpoints.referenceIdReorder, api, movedItemId, newIndex).then();
        }
    };

    const handleRemoveReference = (id: number): void => {
        if (id) {
            removeReference(api, id).then();
        }
        const filteredList: IReference[] = references.filter((_reference: IReference) => _reference.id !== id);
        setReferences(filteredList);
    };

    return (
        <>
            <OrderableList
                labelKey={'reference-'}
                items={references}
                setItems={setReferences as Dispatch<SetStateAction<IOrderableEntity[]>>}
                onMoveEnd={_reorderItems}
                onRemoveCallback={handleRemoveReference}
                child={(item) => (
                    <ReferenceForm
                        cvId={props.cvId}
                        reference={item as IReference}
                        references={references}
                        setReferences={setReferences}
                        theme={props.theme}
                    />
                )}
            ></OrderableList>
            <AddSectionButton disabled={references.map((entity: IReference) => entity.id).includes(0)} onClick={referenceAddCallback} />
        </>
    );
};

export default ReferencesForm;
