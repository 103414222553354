import React from 'react';
import { Box, Checkbox, Grid2, InputLabel, Slider } from '@mui/material';
import { IImageTheme } from '../../interfaces/Theme.interface';
import { LABEL_SIZE } from './ThemeDesigner';
import { TranslationKey } from '../../interfaces/TranslationKey';
import { useTranslation } from 'react-i18next';
import ColorPickerDialog from './ColorPickerDialog';

interface ImageThemeCustomizerProps {
    theme: IImageTheme;
    setTheme: (imageTheme: (prev: IImageTheme) => IImageTheme) => void;
    setShouldUpdateThemed: (value: boolean) => void;
}

const ImageThemeCustomizer = (props: ImageThemeCustomizerProps): React.ReactElement => {
    const { t } = useTranslation();

    const handleThemeChange = (key: keyof IImageTheme, value: any) => {
        props.setTheme((prev: IImageTheme) => ({
            ...prev,
            [key]: value,
        }));
        props.setShouldUpdateThemed(true);
    };

    const handleToggleBorder = () => {
        const nextValue: boolean = !props.theme.hasBorder;
        handleThemeChange('hasBorder', nextValue);
        handleThemeChange('borderColor', nextValue ? '#fff' : null);
        handleThemeChange('borderWidth', nextValue ? 1 : null);
    };

    return (
        <Box>
            <Grid2 container spacing={2}>
                <Grid2 size={LABEL_SIZE}>
                    <InputLabel>{t(TranslationKey.theme.imageHeight)}</InputLabel>
                </Grid2>
                <Grid2 size="grow">
                    <Slider
                        value={props.theme.height}
                        onChange={(_, newValue: number | number[]) => handleThemeChange('height', newValue as number)}
                        valueLabelDisplay={'auto'}
                        valueLabelFormat={String(props.theme.height)}
                        step={1}
                        min={50}
                        max={300}
                        marks={[
                            { value: 50, label: 50 },
                            { value: 300, label: 300 },
                        ]}
                    />
                </Grid2>
            </Grid2>
            <Grid2 container spacing={2}>
                <Grid2 size={LABEL_SIZE}>
                    <InputLabel>{t(TranslationKey.theme.imageWidth)}</InputLabel>
                </Grid2>
                <Grid2 size="grow">
                    <Slider
                        value={props.theme.width}
                        onChange={(_, newValue: number | number[]) => handleThemeChange('width', newValue as number)}
                        valueLabelDisplay={'auto'}
                        valueLabelFormat={String(props.theme.width)}
                        step={1}
                        min={50}
                        max={300}
                        marks={[
                            { value: 50, label: 50 },
                            { value: 300, label: 300 },
                        ]}
                    />
                </Grid2>
            </Grid2>
            <Grid2 container spacing={2}>
                <Grid2 size={4} alignContent="center">
                    <InputLabel>{t(TranslationKey.theme.circularImage)}</InputLabel>
                </Grid2>
                <Grid2>
                    <Checkbox
                        checked={props.theme.circular}
                        onChange={() => handleThemeChange('circular', !props.theme.circular)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Grid2>
            </Grid2>
            <Grid2 container spacing={2}>
                <Grid2 size={4} alignContent="center">
                    <InputLabel>{t(TranslationKey.theme.imageHasBorder)}</InputLabel>
                </Grid2>
                <Grid2>
                    <Checkbox checked={props.theme.hasBorder} onChange={handleToggleBorder} inputProps={{ 'aria-label': 'controlled' }} />
                </Grid2>
            </Grid2>
            {props.theme.hasBorder && (
                <>
                    <Grid2 container spacing={2}>
                        <Grid2 size={LABEL_SIZE}>
                            <InputLabel>{t(TranslationKey.theme.imageBorderWidth)}</InputLabel>
                        </Grid2>
                        <Grid2 size="grow">
                            <Slider
                                value={props.theme.borderWidth ?? 1}
                                onChange={(_, newValue: number | number[]) => handleThemeChange('borderWidth', newValue as number)}
                                valueLabelDisplay={'auto'}
                                valueLabelFormat={String(props.theme.borderWidth)}
                                step={1}
                                min={1}
                                max={10}
                                marks={[
                                    { value: 1, label: 1 },
                                    { value: 10, label: 10 },
                                ]}
                            />
                        </Grid2>
                    </Grid2>
                    <ColorPickerDialog
                        label={t(TranslationKey.theme.color.imageBorder)}
                        color={props.theme.borderColor ?? '#fff'}
                        onColorChange={(color: string) => handleThemeChange('borderColor', color)}
                    />
                </>
            )}
        </Box>
    );
};

export default ImageThemeCustomizer;
