import { ApiEndpoints } from './endpoints';
import { IEducation, IEducationCreationDto, IEducationUpdateDto } from '../interfaces/Education.interface';
import { IApi } from './api.service';

export const createEducation = async (api: IApi, dto: IEducationCreationDto): Promise<IEducation | null> => {
    return api.post<IEducation, IEducationCreationDto>(ApiEndpoints.education, dto);
};

export const updateEducation = async (api: IApi, id: number, dto: IEducationUpdateDto): Promise<IEducation | null> => {
    return api.put<IEducation, IEducationUpdateDto>(ApiEndpoints.educationId.replace(':id', String(id)), dto);
};

export const removeEducation = async (api: IApi, id: number): Promise<void> => {
    return api.delete(ApiEndpoints.educationId.replace(':id', String(id)));
};
