import { ISocialLink, SocialPlatform } from '../../../interfaces/SocialLink.interface';
import { ISocialLinkTheme } from '../../../interfaces/Theme.interface';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { IApi } from '../../../services/api.service';
import { useAxiosApi } from '../../../hooks/useAxiosApi';
import { useCV } from '../../../context/CV.context';
import { reorderItems } from '../../../services/common.services';
import { ApiEndpoints } from '../../../services/endpoints';
import { removeSocialLink } from '../../../services/socialLink.service';
import OrderableList from '../../OrderableList';
import { IOrderableEntity } from '../../../interfaces/Common.interface';
import AddSectionButton from '../../AddSectionButton';
import SocialLinkForm from './link/SocialLinkForm';

interface SocialLinksProps {
    cvId: number;
    socialLinks: ISocialLink[];
    theme: ISocialLinkTheme;
}

const SocialLinksForm = (props: SocialLinksProps): React.ReactElement => {
    const api: IApi = useAxiosApi();
    const { dispatch } = useCV();
    const [socialLinks, setSocialLinks] = React.useState<ISocialLink[]>(props.socialLinks);

    useEffect(() => {
        dispatch({ type: 'UPDATE_FIELD', field: 'socialLinks', value: socialLinks });
    }, [socialLinks]);

    const addCallback = () => {
        if (socialLinks.map((entity: ISocialLink) => entity.id).includes(0)) {
            return;
        }
        const newEntity: ISocialLink = {
            url: '',
            platform: SocialPlatform.OTHER,
            id: 0,
            weight: 100,
        };
        setSocialLinks([...socialLinks, newEntity]);
    };

    const _reorderItems = async (movedItemId: number, newIndex: number) => {
        if (movedItemId) {
            reorderItems(ApiEndpoints.socialLinkIdReorder, api, movedItemId, newIndex).then();
        }
    };

    const handleRemove = (id: number): void => {
        if (id) {
            removeSocialLink(api, id).then();
        }
        const filteredList: ISocialLink[] = socialLinks.filter((_socialLink: ISocialLink) => _socialLink.id !== id);
        setSocialLinks(filteredList);
    };

    return (
        <>
            <OrderableList
                labelKey={'socialLink-'}
                items={socialLinks}
                setItems={setSocialLinks as Dispatch<SetStateAction<IOrderableEntity[]>>}
                onMoveEnd={_reorderItems}
                onRemoveCallback={handleRemove}
                child={(item) => (
                    <SocialLinkForm
                        cvId={props.cvId}
                        socialLink={item as ISocialLink}
                        socialLinks={socialLinks}
                        setSocialLinks={setSocialLinks}
                        theme={props.theme}
                    />
                )}
            ></OrderableList>
            <AddSectionButton disabled={socialLinks.map((entity: ISocialLink) => entity.id).includes(0)} onClick={addCallback} />
        </>
    );
};

export default SocialLinksForm;
