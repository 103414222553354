import React from 'react';
import { Box, Grid2 } from '@mui/material';
import { IProficiencyTheme } from '../../interfaces/Theme.interface';

interface ProficiencyProps {
    value: number;
    theme: IProficiencyTheme;
    labelKey: string;
}

const DashedProgress = (props: ProficiencyProps) => {
    const levels = [0, 20, 40, 60, 80, 100];
    const dashCount = 5;

    const activeDashes = levels.indexOf(props.value);

    return (
        <Grid2 display={'flex'} gap={props.theme.gap}>
            {[...Array(dashCount)].map((_, index: number) => (
                <Box
                    key={`${props.labelKey}-dashed-${index}`}
                    width={'100%'}
                    sx={{
                        height: props.theme.thickness,
                        backgroundColor: index < activeDashes ? props.theme.activeColor : props.theme.inactiveColor,
                        borderRadius: props.theme.borderRadius,
                    }}
                />
            ))}
        </Grid2>
    );
};

export default DashedProgress;
