import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ModeProvider } from './context/Mode.context';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import './i18n';
import { cacheLtr, cacheRtl, createAppTheme } from './theme';
import { ThemeProvider } from '@mui/material';
import { CacheProvider, Global } from '@emotion/react';
import { AuthProvider } from './context/Auth.context';
import { useTranslation } from 'react-i18next';
import { globalStyles } from './components/GlobalStyles';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { TranslationKey } from './interfaces/TranslationKey';
import { STORAGE_LANGUAGE_KEY } from './common/constants';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './routes/Routes';

const Root = (): React.ReactElement => {
    const { t, i18n } = useTranslation();
    const [direction, setDirection] = useState<'ltr' | 'rtl'>(i18n.dir());

    useEffect(() => {
        const browserLang = navigator.language.split('-')[0];
        const storedLang = localStorage.getItem(STORAGE_LANGUAGE_KEY)?.split('-')[0] || browserLang || 'en';
        if (i18n.language !== storedLang) {
            i18n.changeLanguage(storedLang).then();
        }

        document.body.dir = i18n.dir(storedLang);
        setDirection(i18n.dir(storedLang));
    }, []);

    return (
        <CacheProvider value={direction === 'rtl' ? cacheRtl : cacheLtr}>
            <ThemeProvider theme={createAppTheme(direction)}>
                <Global styles={globalStyles} />
                <AuthProvider>
                    <ModeProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <HelmetProvider>
                                <Helmet>
                                    <html lang={i18n.language} dir={i18n.dir()} />
                                    <title>{t(TranslationKey.helmet.title.homePage)}</title>
                                    <link rel="canonical" href={window.location.href} />
                                    <meta name="description" content={t(TranslationKey.helmet.meta.description)} />
                                    <meta name="keywords" content={t(TranslationKey.helmet.meta.keywords)} />
                                    <meta property="og:title" content={t(TranslationKey.helmet.meta.og_title)} />
                                    <meta property="og:description" content={t(TranslationKey.helmet.meta.og_description)} />
                                    <meta name="twitter:title" content={t(TranslationKey.helmet.meta.twitter_title)} />
                                    <meta name="twitter:description" content={t(TranslationKey.helmet.meta.twitter_description)} />
                                </Helmet>
                                <Router>
                                    <AppRoutes setDirection={setDirection} />
                                </Router>
                            </HelmetProvider>
                        </LocalizationProvider>
                    </ModeProvider>
                </AuthProvider>
            </ThemeProvider>
        </CacheProvider>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <Root />
    </React.StrictMode>,
);
