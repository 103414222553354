import React, { useState } from 'react';
import { LinearProgress } from '@mui/material';
import {
    ISkill,
    ISkillFilter,
    ISkillProficiency,
    ISkillProficiencyCreationDto,
    ISkillProficiencyUpdateDto,
    SkillProficiencyLevel,
    SkillProficiencyToValue,
    ValueToSkillProficiency,
} from '../../../../interfaces/SkillProficiency.interface';
import { EditableSearchField, ISearchFieldError } from '../../../SearchField';
import { EditableProficiency } from '../../../proficiency/Proficiency';
import { TranslationKey } from '../../../../interfaces/TranslationKey';
import { IPaginatedListResponse } from '../../../../interfaces/Pagination.interface';
import { createSkillProficiency, getAllSkills, getOrCreateSkill, updateSkillProficiency } from '../../../../services/skill.service';
import { ISkillTheme } from '../../../../interfaces/Theme.interface';
import { IApi } from '../../../../services/api.service';
import { useAxiosApi } from '../../../../hooks/useAxiosApi';
import { useCV } from '../../../../context/CV.context';

interface SkillProficiencyProps {
    cvId: number;
    skillProficiency: ISkillProficiency;
    skillProficiencies: ISkillProficiency[];
    setSkillProficiencies: (skillProficiencies: ISkillProficiency[]) => void;
    theme: ISkillTheme;
}

const SkillProficiencyForm = (props: SkillProficiencyProps): React.ReactElement => {
    const api: IApi = useAxiosApi();
    const { cv } = useCV();
    const [skillProficiency, setSkillProficiency] = React.useState<ISkillProficiency>(props.skillProficiency);
    const [skill, setSkill] = React.useState<ISkill>(skillProficiency.skill);
    const [proficiency, setProficiency] = React.useState<SkillProficiencyLevel>(skillProficiency.proficiency);
    const [searchFieldError, setSearchFieldError] = useState<ISearchFieldError>({ type: null, message: '' });
    const [isLoading, setIsLoading] = React.useState(false);

    const fetchAllSkills = async (query?: string): Promise<ISkill[]> => {
        const filter: ISkillFilter = {
            page: 1,
            size: 50,
        } as ISkillFilter;

        if (query) {
            filter.name = query;
        }

        const paginatedSkills: IPaginatedListResponse<ISkill> = await getAllSkills(api, filter);
        return paginatedSkills.items;
    };

    const displaySkill = (skill: ISkill): string => skill.name;

    const _createSkillProficiency = (dto: ISkillProficiencyCreationDto) => {
        setIsLoading(true);
        const oldId: number = skillProficiency.id;
        createSkillProficiency(api, dto)
            .then(async (newSkillProficiency: ISkillProficiency | null) => {
                if (newSkillProficiency) {
                    setSkillProficiency(newSkillProficiency);
                    const updatedList = props.skillProficiencies.map((s: ISkillProficiency) => (oldId === s.id ? newSkillProficiency : s));
                    props.setSkillProficiencies(updatedList);
                } else {
                    setSkillProficiency(props.skillProficiency);
                    setSkill(props.skillProficiency.skill);
                }
            })
            .catch(() => {
                setSkillProficiency(props.skillProficiency);
                setSkill(props.skillProficiency.skill);
            })
            .finally(() => setIsLoading(false));
    };

    const _updateSkillProficiency = (dto: ISkillProficiencyUpdateDto) => {
        updateSkillProficiency(api, skillProficiency.id, dto).then(async (updatedSkillProficiency: ISkillProficiency | null) => {
            if (updatedSkillProficiency) {
                setSkillProficiency(updatedSkillProficiency);
                const updatedList = props.skillProficiencies.map((s: ISkillProficiency) =>
                    skillProficiency.id === s.id ? updatedSkillProficiency : s,
                );
                props.setSkillProficiencies(updatedList);
            }
        });
    };

    const onDoneClicked = async (name: string): Promise<ISkill | null> => {
        if (!name) {
            return new Promise((resolve) => {
                resolve({ name: name, id: 0 });
            });
        }

        const cleanName: string = name.toLowerCase();

        // No changes
        if (cleanName === cv?.skillProficiencies.find((sp: ISkillProficiency) => sp.id === skillProficiency.id)?.skill.name) {
            return skill;
        }

        if (props.skillProficiencies.some((sp: ISkillProficiency) => sp.skill.name?.toLowerCase() === cleanName)) {
            setSearchFieldError({ type: 'DUPLICATE', message: TranslationKey.error.duplicatedValue });
            return null;
        }

        const newSkill: ISkill | null = await getOrCreateSkill(api, { name: cleanName });

        if (newSkill) {
            if (skillProficiency.id) {
                _updateSkillProficiency({ skill: { id: newSkill.id }, proficiency: proficiency });
            } else {
                _createSkillProficiency({ skill: { id: newSkill.id }, proficiency: proficiency, cv: { id: props.cvId } });
            }
            return newSkill;
        }

        return new Promise((resolve) => {
            resolve({ name: cleanName, id: 0 });
        });
    };

    const onSelectSkill = (newSkill: ISkill): ISkill | null => {
        // No changes
        if (newSkill.name === cv?.skillProficiencies.find((sp: ISkillProficiency) => sp.id === skillProficiency.id)?.skill.name) {
            return newSkill;
        }

        if (props.skillProficiencies.some((sp: ISkillProficiency) => sp.skill.id === newSkill.id)) {
            setSearchFieldError({ type: 'DUPLICATE', message: TranslationKey.error.duplicatedValue });
            return null;
        }

        if (skillProficiency.id) {
            _updateSkillProficiency({ skill: { id: newSkill.id }, proficiency: proficiency });
        } else {
            _createSkillProficiency({ skill: { id: newSkill.id }, proficiency: proficiency, cv: { id: props.cvId } });
        }
        return newSkill;
    };

    const onChangeProficiency = (newProficiency: SkillProficiencyLevel): void => {
        if (newProficiency === skillProficiency.proficiency || !skill.id) return;
        if (!skillProficiency.id) {
            _createSkillProficiency({ skill: { id: skill.id }, proficiency: proficiency, cv: { id: props.cvId } });
        } else {
            _updateSkillProficiency({ skill: { id: skill.id }, proficiency: newProficiency });
        }
    };

    return (
        <>
            <EditableSearchField<ISkill>
                value={skill}
                setValue={setSkill}
                fetchResults={fetchAllSkills}
                label={TranslationKey.skill._name}
                getDisplayValue={displaySkill}
                onDoneClicked={onDoneClicked}
                onSelect={onSelectSkill}
                error={searchFieldError}
                setError={setSearchFieldError}
                fontTheme={props.theme.font}
                typographyComponent={'p'}
            />

            {isLoading ? (
                <LinearProgress />
            ) : (
                !!props.skillProficiency.skill.id && (
                    <EditableProficiency<SkillProficiencyLevel>
                        theme={props.theme.proficiency}
                        value={proficiency}
                        setValue={setProficiency}
                        proficiencyToValue={SkillProficiencyToValue}
                        valueToProficiency={ValueToSkillProficiency}
                        onChange={onChangeProficiency}
                        labelKey={'Skills'}
                    />
                )
            )}
        </>
    );
};

export default SkillProficiencyForm;
