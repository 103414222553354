import React from 'react';
import { Box, Divider, Grid2, MenuItem, Typography } from '@mui/material';
import {
    IFontTheme,
    ILanguageTheme,
    IMarginTheme,
    IProficiencyTheme,
    ISkillTheme,
    ITitleTheme,
    ThemeValue,
} from '../../interfaces/Theme.interface';
import FontThemeCustomizer from './FontTheme';
import MarginThemeCustomizer from './MarginTheme';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../interfaces/TranslationKey';
import TitleThemeCustomizer from './TitleTheme';
import ProficiencyThemeCustomizer from './ProficiencyTheme';

type GenericTheme = ISkillTheme | ILanguageTheme;

interface ThemeCustomizerProps {
    themeLabelKey: string;
    theme: GenericTheme;
    setTheme: (theme: (prev: GenericTheme) => GenericTheme) => void;
    setShouldUpdateThemed: (value: boolean) => void;
}

const SkillLanguageThemeCustomizer = (props: ThemeCustomizerProps): React.ReactElement => {
    const { t } = useTranslation();

    const handleFontThemeChange = (key: keyof IFontTheme, value: string) => {
        handleThemeChange('font', key, value);
    };

    const handleMarginThemeChange = (key: keyof IMarginTheme, value: number) => {
        handleThemeChange('margin', key, value);
    };

    const handleProficiencyThemeChange = (key: keyof IProficiencyTheme, value: ThemeValue) => {
        handleThemeChange('proficiency', key, value);
    };

    const handleTitleThemeChange = (key: keyof ITitleTheme, value: ThemeValue) => {
        handleThemeChange('title', key, value);
    };

    const handleThemeChange = <T extends keyof GenericTheme, K extends keyof GenericTheme[T]>(section: T, key: K, value: ThemeValue) => {
        props.setTheme((prev: GenericTheme) => ({
            ...prev,
            [section]: {
                ...prev[section],
                [key]: value,
            },
        }));
        props.setShouldUpdateThemed(true);
    };

    return (
        <Box>
            <Typography variant="h6">{t(TranslationKey.theme.title)}</Typography>
            <Grid2 container spacing={2} sx={{ my: '10px' }}>
                <Grid2 size={12}>
                    <TitleThemeCustomizer
                        labelKey={'title-skill-language'}
                        theme={props.theme.title}
                        handleThemeChange={handleTitleThemeChange}
                    />
                </Grid2>
            </Grid2>
            <Divider sx={{ my: 2 }} />

            <Typography variant="h6">{t(props.themeLabelKey)}</Typography>
            <Grid2 container spacing={2}>
                <Grid2 size={12}>
                    <FontThemeCustomizer
                        labelKey={'skills'}
                        theme={props.theme.font}
                        handleThemeChange={handleFontThemeChange}
                        variantItems={[
                            <MenuItem key={`${props.themeLabelKey}-font-variant-h5`} value="h5">
                                {t(TranslationKey.theme.fontSize.XL)}
                            </MenuItem>,
                            <MenuItem key={`${props.themeLabelKey}-font-variant-h6`} value="h6">
                                {t(TranslationKey.theme.fontSize.L)}
                            </MenuItem>,
                            <MenuItem key={`${props.themeLabelKey}-font-variant-body1`} value="body1">
                                {t(TranslationKey.theme.fontSize.M)}
                            </MenuItem>,
                            <MenuItem key={`${props.themeLabelKey}-font-variant-body2`} value="body2">
                                {t(TranslationKey.theme.fontSize.S)}
                            </MenuItem>,
                            <MenuItem key={`${props.themeLabelKey}-font-variant-caption`} value="caption">
                                {t(TranslationKey.theme.fontSize.XS)}
                            </MenuItem>,
                        ]}
                    />
                </Grid2>
            </Grid2>
            <Divider sx={{ my: 2 }} />

            <Grid2 container spacing={2}>
                <Grid2 size={12}>
                    <ProficiencyThemeCustomizer
                        themeLabelKey={props.themeLabelKey}
                        theme={props.theme.proficiency}
                        handleThemeChange={handleProficiencyThemeChange}
                    />
                </Grid2>
            </Grid2>
            <Divider sx={{ my: 2 }} />

            <Grid2 container spacing={2}>
                <Grid2 size={12}>
                    <MarginThemeCustomizer theme={props.theme.margin} handleThemeChange={handleMarginThemeChange} />
                </Grid2>
            </Grid2>
        </Box>
    );
};

export default SkillLanguageThemeCustomizer;
