import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Grid2, MenuItem, Select } from '@mui/material';
import { ICV } from '../interfaces/CV.interface';
import { useMode } from '../context/Mode.context';
import { Mode } from '../interfaces/Mode.interface';
import { ITheme } from '../interfaces/Theme.interface';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { ITemplate, ITemplateCreationDto, TemplateMap } from '../interfaces/Template.interface';
import { TranslationKey } from '../interfaces/TranslationKey';
import EditOffIcon from '@mui/icons-material/EditOff';
import EditIcon from '@mui/icons-material/Edit';
import PrintIcon from '@mui/icons-material/Print';
import PDFDownloadableButton, { getPDFTemplate } from '../components/PDFDownloadableButton';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import { IApi } from '../services/api.service';
import { useAxiosApi } from '../hooks/useAxiosApi';
import { defaultThemeTemplateName } from '../utils/theme.util';
import { TemplateName } from '../common/enum';
import { IProfession } from '../interfaces/Profession.interface';
import { getImage, updateCV } from '../services/cv.service';
import { getLocalizedPath, removeIdKey } from '../utils/common.util';
import { GenericAbortSignal } from 'axios';
import { getOrCreateTemplateForCV } from '../services/template.service';
import { useNavigate, useParams } from 'react-router-dom';
import ThemeDesigner from '../components/theme/ThemeDesigner';
import Loading from '../components/Loading';
import MeccaTemplate from './mecca/MeccaTemplate';
import PDFIcon from '@mui/icons-material/PictureAsPdf';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { PDFViewer } from '@react-pdf/renderer';
import MadinaTemplate from './madina/MadinaTemplate';
import DohaTemplate from './doha/DohaTemplate';
import AlqudsTemplate from './alquds/AlqudsTemplate';
import HamaTemplate from './hama/HamaTemplate';
import CairoTemplate from './cairo/CairoTemplate';
import IdlibTemplate from './idlib/IdlibTemplate';
import { useCV } from '../context/CV.context';
import DamascusTemplate from './damascus/DamascusTemplate';
import HomsTemplate from './homs/HomsTemplate';

export const A4_HEIGHT: number = 1123;
export const A4_WIDTH: number = 794;

const BaseTemplate = (): React.ReactElement => {
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { mode, setMode } = useMode();
    const { cv, dispatch } = useCV();
    const { templateName } = useParams();
    const contentRef = useRef<HTMLDivElement>(null);
    const api: IApi = useAxiosApi();
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [profession, setProfession] = useState<IProfession | null>(null);
    const [selectedTemplate, setSelectedTemplate] = useState<TemplateName>(TemplateName.DAMASCUS);
    const [theme, setTheme] = React.useState<ITheme | null>(defaultThemeTemplateName[selectedTemplate]());
    const [themeId, setThemeId] = React.useState<number>(0);
    const [template, setTemplate] = React.useState<ITemplate | null>(null);
    const [templateLoading, setTemplateLoading] = React.useState(false);
    const [themeLoading, setThemeLoading] = React.useState(false);
    const [isPDFPreview, setIsPDFPreview] = React.useState(false);

    const reactToPrintFn = useReactToPrint({ contentRef });
    const isMounted = useRef(true);

    useEffect(() => {
        setSelectedTemplate((templateName as TemplateName) ?? TemplateName.DAMASCUS);
    }, [templateName]);

    useEffect(() => {
        if (!!cv?.profileImage) {
            getImage(api, cv.id).then((image) => {
                if (image) {
                    setImageSrc(image);
                }
            });
        } else {
            setImageSrc(null);
        }
    }, [cv?.profileImage]);

    useEffect(() => {
        if (!cv?.id) return;

        isMounted.current = true;
        const controller = new AbortController();
        const signal = controller.signal;

        const fetchTemplate = setTimeout(() => {
            getTemplate(signal);
        }, 300);

        return () => {
            clearTimeout(fetchTemplate);
            isMounted.current = false;
            controller.abort();
        };
    }, [selectedTemplate]);

    const getTemplate = (signal: GenericAbortSignal): void => {
        if (!cv?.id) return;

        setTemplateLoading(true);
        setThemeLoading(true);
        const defaultTheme: ITheme = defaultThemeTemplateName[selectedTemplate]();

        const defaultTemplate: ITemplate = {
            id: 0,
            templateName: selectedTemplate,
            theme: { id: 0, ...defaultTheme },
            isCurrent: true,
        };

        const dto: ITemplateCreationDto = {
            templateName: selectedTemplate,
            cv: { id: cv.id },
            theme: defaultTheme,
        };

        getOrCreateTemplateForCV(api, selectedTemplate, cv.id, dto, { signal: signal })
            .then((_template: ITemplate | null) => {
                if (_template) {
                    if (isMounted.current) {
                        setTemplate(_template);
                        setTheme((prev) => {
                            return { ...prev, ...removeIdKey(_template.theme) };
                        });
                        setThemeId(_template.theme.id);
                    }
                } else {
                    console.log('Default theme is used!');
                    if (isMounted.current) {
                        setTemplate(defaultTemplate);
                        setTheme((prev) => {
                            return { ...prev, ...defaultTheme };
                        });
                    }
                }
            })
            .catch((err) => {
                console.error(`Could not grep template due to ${err}`);
                if (isMounted.current) {
                    setTemplate(defaultTemplate);
                    setTheme((prev) => {
                        return { ...prev, ...defaultTheme };
                    });
                }
                console.log('Default theme is used!');
            })
            .finally(() => {
                setTemplateLoading(false);
                setThemeLoading(false);
            });
    };

    const onProfessionSuccess = (_profession: IProfession): void => {
        setProfession(_profession);
        if (cv?.id) {
            updateCV(api, cv.id, { profession: { id: _profession.id } }).then();
            dispatch({ type: 'UPDATE_FIELD', field: 'profession', value: _profession });
        }
    };

    const isEditing: boolean = mode === Mode.EDIT;

    const toggleMode = useCallback(() => {
        setMode(isEditing ? Mode.PRINT : Mode.EDIT);
    }, [isEditing, setMode]);

    const pageStyle: SxProps<Theme> = isEditing
        ? {}
        : (theme) => ({
              border: '2px dashed red',
              width: '100%',
              // For desktop - A4 dimensions
              [theme.breakpoints.up('md')]: {
                  maxWidth: `${A4_WIDTH}px`,
                  minHeight: `${A4_HEIGHT}px`,
                  maxHeight: `${A4_HEIGHT}px`,
              },
              // For mobile - scaled down but maintaining aspect ratio
              [theme.breakpoints.down('md')]: {
                  maxWidth: '100%',
                  minHeight: `calc(100vw * ${A4_HEIGHT / A4_WIDTH})`,
                  aspectRatio: `${A4_WIDTH}/${A4_HEIGHT}`,
              },
              overflowY: 'auto',
              overflowX: 'hidden',
          });

    const calculateScale = () => {
        if (typeof window === 'undefined') return 1;

        const mobileBreakpoint = 768; // md breakpoint
        if (window.innerWidth >= mobileBreakpoint) return 1;

        return window.innerWidth / (A4_WIDTH + 100);
    };

    const scale = calculateScale();

    const renderTemplate = useMemo(() => {
        const templates: TemplateMap = {
            DAMASCUS: DamascusTemplate,
            HOMS: HomsTemplate,
            MECCA: MeccaTemplate,
            MADINA: MadinaTemplate,
            DOHA: DohaTemplate,
            ALQUDS: AlqudsTemplate,
            HAMA: HamaTemplate,
            CAIRO: CairoTemplate,
            IDLIB: IdlibTemplate,
        } as TemplateMap;
        const TemplateComponent = templates[selectedTemplate] || DamascusTemplate;
        return (
            <TemplateComponent
                cv={cv as ICV}
                template={template as ITemplate}
                theme={theme as ITheme}
                profession={profession ?? (cv?.profession as IProfession)}
                setProfession={setProfession}
                onProfessionSuccess={onProfessionSuccess}
            />
        );
    }, [selectedTemplate, cv, template, theme]);

    const handleSelectedTemplate = useCallback(
        (_templateName: TemplateName) => {
            setSelectedTemplate(_templateName);
            navigate(getLocalizedPath(`/cv/${cv?.uuid}/template/${_templateName}`));
        },
        [navigate, cv?.uuid],
    );

    const handlePDFViewer = () => {
        setIsPDFPreview(!isPDFPreview);
    };

    return !isPDFPreview ? (
        <Grid2 container>
            <Grid2 direction={'row-reverse'} size={12} container={true} spacing={2} sx={{ px: { xs: 2, md: 4 }, my: 2 }}>
                <Select
                    value={selectedTemplate}
                    onChange={(e) => handleSelectedTemplate(e.target.value as TemplateName)}
                    displayEmpty
                    variant="outlined"
                    sx={{ width: { xs: '100%', md: '150px' } }}
                >
                    <MenuItem value={TemplateName.DAMASCUS}>{t(TranslationKey.template.damascus)}</MenuItem>
                    <MenuItem value={TemplateName.HOMS}>{t(TranslationKey.template.homs)}</MenuItem>
                    <MenuItem value={TemplateName.MECCA}>{t(TranslationKey.template.mecca)}</MenuItem>
                    <MenuItem value={TemplateName.MADINA}>{t(TranslationKey.template.madina)}</MenuItem>
                    <MenuItem value={TemplateName.DOHA}>{t(TranslationKey.template.doha)}</MenuItem>
                    <MenuItem value={TemplateName.ALQUDS}>{t(TranslationKey.template.alQuds)}</MenuItem>
                    <MenuItem value={TemplateName.HAMA}>{t(TranslationKey.template.hama)}</MenuItem>
                    <MenuItem value={TemplateName.CAIRO}>{t(TranslationKey.template.cairo)}</MenuItem>
                    <MenuItem value={TemplateName.IDLIB}>{t(TranslationKey.template.idlib)}</MenuItem>
                </Select>
                <Button
                    variant="outlined"
                    onClick={toggleMode}
                    endIcon={isEditing ? <EditOffIcon /> : <EditIcon />}
                    sx={{ width: { xs: '100%', md: '150px' } }}
                >
                    {t(isEditing ? TranslationKey.buttons.printMode : TranslationKey.buttons.editMode)}
                </Button>
                {!isEditing && !!cv && !!template && !!theme && (
                    <Button
                        variant="outlined"
                        onClick={() => reactToPrintFn()}
                        endIcon={<PrintIcon />}
                        sx={{ width: { xs: '100%', md: '150px' } }}
                    >
                        {t(TranslationKey.buttons.print)}
                    </Button>
                )}
                {!isEditing && !!cv && !!template && !!theme && (
                    <Button
                        variant={'outlined'}
                        endIcon={<PDFIcon />}
                        onClick={handlePDFViewer}
                        sx={{ width: { xs: '100%', md: '150px' } }}
                    >
                        {t(TranslationKey.buttons.preview)}
                    </Button>
                )}
                {!isEditing && !!cv && !!template && !!theme && (
                    <PDFDownloadableButton cv={cv} theme={theme} templateName={template.templateName} imageSrc={imageSrc} />
                )}
            </Grid2>
            <Grid2
                size={{ xs: 12, lg: isEditing ? 12 : 7 }}
                sx={isEditing ? { px: { xs: 2, md: 4 }, pb: { mx: 2, md: 6 } } : { py: { mx: 2, md: 6 }, px: { xs: 2, md: 4 } }}
                justifyItems={isEditing ? undefined : 'center'}
            >
                <Box sx={pageStyle}>
                    <Box
                        ref={contentRef}
                        sx={{
                            padding: `${theme?.layoutTheme.pageHeightMargin ?? 0}px ${theme?.layoutTheme.pageWidthMargin ?? 0}px`,
                            transform: `scale(${scale})`,
                            transformOrigin: 'top left',
                            width: `${A4_WIDTH}px`,
                            height: `${A4_HEIGHT}px`,
                        }}
                        dir={i18n.dir()}
                    >
                        {!!cv && !!template && !!theme && !templateLoading ? renderTemplate : <Loading />}
                    </Box>
                </Box>
            </Grid2>

            {!isEditing && (
                <Grid2 size={{ xs: 12, lg: 5 }} sx={{ px: { xs: 2, md: 4 }, mt: 4 }}>
                    {!!template && !!theme && !themeLoading ? (
                        <ThemeDesigner
                            key={`ThemeDesigner-${themeId}`}
                            templateName={template.templateName}
                            theme={theme}
                            setTheme={setTheme as (theme: (prev: ITheme) => ITheme) => void}
                            templateId={template.id}
                        />
                    ) : (
                        <Loading />
                    )}
                </Grid2>
            )}
        </Grid2>
    ) : (
        <Box width="100%" height="100vh">
            <Grid2 display={'flex'} size={12} container={true} spacing={2} sx={{ px: { xs: 2, md: 4 }, my: 2 }}>
                {!isEditing && !!cv && !!template && !!theme && (
                    <Button
                        variant={'outlined'}
                        startIcon={<ArrowBackIosIcon sx={{ transform: i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'none' }} />}
                        onClick={handlePDFViewer}
                        sx={{ height: '55px' }}
                    >
                        {t(TranslationKey.buttons.back)}
                    </Button>
                )}
            </Grid2>
            {!!cv && !!theme && (
                <PDFViewer height="100%" width="100%">
                    {getPDFTemplate({ cv: cv, templateName: selectedTemplate, theme: theme, imageSrc: imageSrc })}
                </PDFViewer>
            )}
        </Box>
    );
};

export default BaseTemplate;
