export enum ApiEndpoints {
    login = '/token',
    refresh = '/refresh',
    signup = '/signup',
    activate = '/activate',
    requestActivation = '/activation-request',
    passwordReset = '/password-reset',
    requestPasswordReset = '/password-reset-request',
    logout = '/logout',
    user = '/user',
    contactUs = '/contact-request',
    cv = '/cv',
    cvId = '/cv/:id',
    cvIdImage = '/cv/:id/profile-image',
    cvIdProfileIdAI = '/cv/:id/ai-generation/profile',
    profession = '/profession',
    address = '/address',
    addressId = '/address/:id',
    skill = '/skill',
    skillProficiency = '/skill-proficiency',
    skillProficiencyId = '/skill-proficiency/:id',
    skillProficiencyIdReorder = '/skill-proficiency/:id/reorder',
    language = '/language',
    languageProficiency = '/language-proficiency',
    languageProficiencyId = '/language-proficiency/:id',
    languageProficiencyIdReorder = '/language-proficiency/:id/reorder',
    experience = '/experience',
    experienceId = '/experience/:id',
    experienceIdReorder = '/experience/:id/reorder',
    experienceIdCompany = '/experience/:id/company',
    experienceIdAI = '/experience/:id/ai-generation',
    experienceItem = '/experience-item',
    experienceItemId = '/experience-item/:id',
    experienceItemIdReorder = '/experience-item/:id/reorder',
    education = '/education',
    educationId = '/education/:id',
    educationIdReorder = '/education/:id/reorder',
    certification = '/certification',
    certificationId = '/certification/:id',
    certificationIdReorder = '/certification/:id/reorder',
    reference = '/reference',
    referenceId = '/reference/:id',
    referenceIdReorder = '/reference/:id/reorder',
    socialLink = '/social-link',
    socialLinkId = '/social-link/:id',
    socialLinkIdReorder = '/social-link/:id/reorder',
    template = '/template',
    templateNameCvId = '/template/:name/cv/:id',
    templateId = '/template/:id',
}
