import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { styled } from '@mui/material/zero-styled';

interface AIButtonProps {
    apiCall: () => Promise<void>;
}

const GradientIcon = styled(AutoAwesomeIcon)({
    fontSize: 25,
    fill: 'url(#icon-gradient)',
});

const AIButton = (props: AIButtonProps): React.ReactElement => {
    const [isGenerating, setIsGenerating] = React.useState(false);

    const generate = async () => {
        setIsGenerating(true);
        await props.apiCall();
        setIsGenerating(false);
    };

    return (
        <Button onClick={generate} disabled={isGenerating}>
            {isGenerating ? (
                <CircularProgress size={'20px'} />
            ) : (
                <>
                    <svg width="0" height="0">
                        <defs>
                            <linearGradient id="icon-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                                <stop offset="0%" stopColor="#ffffff" />
                                <stop offset="90%" stopColor="#ff33ff" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <GradientIcon />
                </>
            )}
        </Button>
    );
};

export default AIButton;
