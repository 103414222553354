import { IProfileTheme } from '../../../interfaces/Theme.interface';
import React, { useState } from 'react';
import { IApi } from '../../../services/api.service';
import { useAxiosApi } from '../../../hooks/useAxiosApi';
import { useCV } from '../../../context/CV.context';
import { updateCV, updateProfileViaAI } from '../../../services/cv.service';
import { ICV } from '../../../interfaces/CV.interface';
import { Grid2 } from '@mui/material';
import AIButton from '../../AIButton';
import { EditableTextField } from '../../GeneralTextField';
import { TranslationKey } from '../../../interfaces/TranslationKey';

interface ProfileProps {
    cvId: number;
    profile: string | null;
    theme: IProfileTheme;
}

const ProfileForm = (props: ProfileProps): React.ReactElement => {
    const api: IApi = useAxiosApi();
    const { cv, dispatch } = useCV();
    const [profile, setProfile] = useState<string | null>(props.profile);

    const onDoneClicked = (value: string) => {
        const cleanValue = value === '' ? null : value;
        if (cleanValue === cv?.profile) return;

        updateCV(api, props.cvId, { profile: cleanValue }).then();
        dispatch({ type: 'UPDATE_FIELD', field: 'profile', value: cleanValue });
        setProfile(cleanValue);
    };

    const generateAI = async () => {
        const cv: ICV | null = await updateProfileViaAI(api, props.cvId);
        if (cv) {
            setProfile(cv.profile);
            dispatch({ type: 'UPDATE_FIELD', field: 'profile', value: cv.profile });
        }
    };

    return (
        <Grid2 container size={12} alignItems={'center'}>
            <Grid2 size={'auto'}>
                <AIButton apiCall={generateAI} />
            </Grid2>
            <Grid2 size={'grow'}>
                <EditableTextField
                    multiline={true}
                    value={profile ?? ''}
                    setValue={setProfile}
                    label={TranslationKey.cvProfile}
                    required={false}
                    onDoneClicked={onDoneClicked}
                    typographyComponent={'p'}
                    fontTheme={props.theme.font}
                />
            </Grid2>
        </Grid2>
    );
};

export default ProfileForm;
