import React from 'react';
import { Box, Grid2, InputLabel, MenuItem, Select, Slider, Typography } from '@mui/material';
import { IProficiencyTheme, ThemeValue } from '../../interfaces/Theme.interface';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../interfaces/TranslationKey';
import { LABEL_SIZE } from './ThemeDesigner';
import ColorPickerDialog from './ColorPickerDialog';

interface ThemeCustomizerProps {
    themeLabelKey: string;
    theme: IProficiencyTheme;
    handleThemeChange: (key: keyof IProficiencyTheme, value: ThemeValue) => void;
}

const ProficiencyThemeCustomizer = (props: ThemeCustomizerProps): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <Box>
            <Typography variant="h5">{t(TranslationKey.theme.proficiency._name)}</Typography>
            <Grid2 container spacing={2} sx={{ mb: '10px' }}>
                <Grid2 size={LABEL_SIZE} alignContent="center">
                    <InputLabel>{t(TranslationKey.theme.type)}</InputLabel>
                </Grid2>
                <Grid2 size="grow">
                    <Select
                        value={props.theme.type}
                        onChange={(e) => props.handleThemeChange('type', e.target.value)}
                        variant={'outlined'}
                        sx={{ width: '100%' }}
                    >
                        <MenuItem key={`${props.themeLabelKey}-proficiency-none`} value="none">
                            ---
                        </MenuItem>
                        <MenuItem key={`${props.themeLabelKey}-proficiency-linear`} value="linear">
                            {t(TranslationKey.theme.proficiency.linear)}
                        </MenuItem>
                        <MenuItem key={`${props.themeLabelKey}-proficiency-dashed`} value="dashed">
                            {t(TranslationKey.theme.proficiency.dashed)}
                        </MenuItem>
                        <MenuItem key={`${props.themeLabelKey}-proficiency-dotted`} value="dotted">
                            {t(TranslationKey.theme.proficiency.dotted)}
                        </MenuItem>
                    </Select>
                </Grid2>
            </Grid2>
            <Grid2 container spacing={2} sx={{ mb: '10px' }}>
                <Grid2 size={LABEL_SIZE} alignContent="center">
                    <InputLabel>{t(TranslationKey.theme.proficiency.thickness)}</InputLabel>
                </Grid2>
                <Grid2 size="grow">
                    <Slider
                        value={props.theme.thickness}
                        onChange={(_, newValue: number | number[]) => props.handleThemeChange('thickness', newValue as number)}
                        valueLabelDisplay={'auto'}
                        valueLabelFormat={String(props.theme.thickness)}
                        step={1}
                        min={5}
                        max={15}
                        marks={[
                            { value: 5, label: 5 },
                            { value: 15, label: 15 },
                        ]}
                    />
                </Grid2>
            </Grid2>
            <Grid2 container spacing={2} sx={{ mb: '10px' }}>
                <Grid2 size={LABEL_SIZE} alignContent="center">
                    <InputLabel>{t(TranslationKey.theme.proficiency.gap)}</InputLabel>
                </Grid2>
                <Grid2 size="grow">
                    <Slider
                        value={props.theme.gap}
                        onChange={(_, newValue: number | number[]) => props.handleThemeChange('gap', newValue as number)}
                        valueLabelDisplay={'auto'}
                        valueLabelFormat={String(props.theme.gap)}
                        step={0.1}
                        min={0}
                        max={2}
                        marks={[
                            { value: 0, label: 0 },
                            { value: 2, label: 2 },
                        ]}
                    />
                </Grid2>
            </Grid2>
            <Grid2 container spacing={2} sx={{ mb: '10px' }}>
                <Grid2 size={LABEL_SIZE} alignContent="center">
                    <InputLabel>{t(TranslationKey.theme.proficiency.borderRadius)}</InputLabel>
                </Grid2>
                <Grid2 size="grow">
                    <Slider
                        value={props.theme.borderRadius}
                        onChange={(_, newValue: number | number[]) => props.handleThemeChange('borderRadius', newValue as number)}
                        valueLabelDisplay={'auto'}
                        valueLabelFormat={String(props.theme.borderRadius)}
                        step={1}
                        min={0}
                        max={20}
                        marks={[
                            { value: 0, label: 0 },
                            { value: 20, label: 20 },
                        ]}
                    />
                </Grid2>
            </Grid2>
            <Grid2 container spacing={2} sx={{ mb: '10px' }}>
                <ColorPickerDialog
                    label={t(TranslationKey.theme.proficiency.activeColor)}
                    color={props.theme.activeColor}
                    onColorChange={(color: string) => props.handleThemeChange('activeColor', color)}
                />
                <ColorPickerDialog
                    label={t(TranslationKey.theme.proficiency.inactiveColor)}
                    color={props.theme.inactiveColor}
                    onColorChange={(color: string) => props.handleThemeChange('inactiveColor', color)}
                />
            </Grid2>
        </Box>
    );
};

export default ProficiencyThemeCustomizer;
