import { ILayout, ILayoutTheme, SectionName } from '../interfaces/Theme.interface';
import NamePDF from '../components/pdf/Name';
import ProfessionPDF from '../components/pdf/Profession';
import ImagePDF from '../components/pdf/ProfileImage';
import { Text, View } from '@react-pdf/renderer';
import ContactPDF from '../components/pdf/Contact';
import ProfilePDF from '../components/pdf/profile';
import ExperiencePDF from '../components/pdf/Experiences';
import EducationPDF from '../components/pdf/Educations';
import SkillsPDF from '../components/pdf/Skills';
import LanguagesPDF from '../components/pdf/Languages';
import ReferencePDF from '../components/pdf/References';
import InterestsPDF from '../components/pdf/Interest';
import SocialLinksPDF from '../components/pdf/SocialLinks';
import React from 'react';
import { IPDFProps } from '../interfaces/Template.interface';
import { IPDFStyle } from '../interfaces/pdfStyle.interface';
import { Style } from '@react-pdf/types';
import { defaultTemplateSectionPosition } from '../utils/theme.util';
import { TemplateName } from '../common/enum';

export const TITLE_BOTTOM_MARGIN: string = '7px';

export const getSections = (layoutTheme: ILayoutTheme, templateName: TemplateName): ILayout => {
    const defaultPosition: ILayout = defaultTemplateSectionPosition[templateName];
    return layoutTheme.layout ?? defaultPosition;
};

export interface IPDFSectionElementsProps {
    templateName: TemplateName;
    sections: SectionName[];
    pdfProps: IPDFProps;
    styles: IPDFStyle;
    extraSectionStyle?: Style;
    sectionHeaderStyle?: Style;
    sectionHeaderWidth?: number;
    inlineSkills?: boolean;
    inlineLanguages?: boolean;
    inlineContact?: boolean;
}

export const getPDFSectionElements = (props: IPDFSectionElementsProps) => {
    const extraSectionStyle = props.extraSectionStyle || {};

    const headerWidth = props.sectionHeaderWidth ? `${props.sectionHeaderWidth}%` : undefined;
    const bodyWidth = props.sectionHeaderWidth ? `${100 - props.sectionHeaderWidth}%` : undefined;

    return props.sections.map((section) => {
        let style: Style = {
            backgroundColor: undefined,
            padding: '7px 11px',
            borderRadius: '11px',
            marginBottom: '7px',
        };

        switch (section) {
            case 'name':
                return <NamePDF key={`PDF-${props.templateName}-${section}`} cv={props.pdfProps.cv} styles={props.styles} />;
            case 'profession':
                return <ProfessionPDF key={`PDF-${props.templateName}-${section}`} cv={props.pdfProps.cv} styles={props.styles} />;
            case 'image':
                return !!props.pdfProps.imageSrc ? (
                    <ImagePDF key={`PDF-${props.templateName}-${section}`} imageSrc={props.pdfProps.imageSrc} styles={props.styles} />
                ) : null;
            case 'contact':
                style = props.styles.backgroundColor.contactSection
                    ? {
                          ...extraSectionStyle,
                          ...style,
                          backgroundColor: props.styles.backgroundColor.contactSection,
                      }
                    : { ...extraSectionStyle };
                return (
                    <View key={`PDF-${props.templateName}-${section}`} style={style}>
                        {props.styles.contactView.showTitle && (
                            <View style={{ ...props.sectionHeaderStyle, marginBottom: TITLE_BOTTOM_MARGIN, width: headerWidth }}>
                                <Text style={{ ...props.styles.contactTitleFont }}>{props.pdfProps.cv.metadata.contact.title}</Text>
                            </View>
                        )}
                        <View style={{ width: bodyWidth }}>
                            <ContactPDF cv={props.pdfProps.cv} styles={props.styles} inline={props.inlineContact || false} />
                        </View>
                    </View>
                );
            case 'profile':
                style = props.styles.backgroundColor.profileSection
                    ? {
                          ...extraSectionStyle,
                          ...style,
                          backgroundColor: props.styles.backgroundColor.profileSection,
                      }
                    : { ...extraSectionStyle };
                return !!props.pdfProps.cv.profile ? (
                    <View key={`PDF-${props.templateName}-${section}`} style={style}>
                        {props.styles.profileView.showTitle && (
                            <View style={{ ...props.sectionHeaderStyle, marginBottom: TITLE_BOTTOM_MARGIN, width: headerWidth }}>
                                <Text style={{ ...props.styles.profileTitleFont }}>{props.pdfProps.cv.metadata.profile.title}</Text>
                            </View>
                        )}
                        <View style={{ width: bodyWidth }}>
                            <ProfilePDF cv={props.pdfProps.cv} styles={props.styles} />
                        </View>
                    </View>
                ) : null;
            case 'experiences':
                style = props.styles.backgroundColor.experienceSection
                    ? {
                          ...extraSectionStyle,
                          ...style,
                          backgroundColor: props.styles.backgroundColor.experienceSection,
                      }
                    : { ...extraSectionStyle };
                return !!props.pdfProps.cv.experiences.length ? (
                    <View key={`PDF-${props.templateName}-${section}`} style={style}>
                        {props.styles.experienceView.showTitle && (
                            <View
                                style={{
                                    ...props.sectionHeaderStyle,
                                    marginBottom: TITLE_BOTTOM_MARGIN,
                                    width: headerWidth,
                                }}
                            >
                                <Text style={{ ...props.styles.experienceTitleFont }}>{props.pdfProps.cv.metadata.experiences.title}</Text>
                            </View>
                        )}
                        <View style={{ width: bodyWidth }}>
                            <ExperiencePDF cv={props.pdfProps.cv} styles={props.styles} />
                        </View>
                    </View>
                ) : null;
            case 'educations':
                style = props.styles.backgroundColor.educationSection
                    ? {
                          ...extraSectionStyle,
                          ...style,
                          backgroundColor: props.styles.backgroundColor.educationSection,
                      }
                    : { ...extraSectionStyle };
                return !!props.pdfProps.cv.educations.length || !!props.pdfProps.cv.certifications.length ? (
                    <View key={`PDF-${props.templateName}-${section}`} style={style}>
                        {props.styles.educationView.showTitle && (
                            <View style={{ ...props.sectionHeaderStyle, marginBottom: TITLE_BOTTOM_MARGIN, width: headerWidth }}>
                                <Text style={{ ...props.styles.educationTitleFont }}>
                                    {props.pdfProps.cv.metadata.educationsAndCertifications.title}
                                </Text>
                            </View>
                        )}
                        <View style={{ width: bodyWidth }}>
                            <EducationPDF cv={props.pdfProps.cv} styles={props.styles} />
                        </View>
                    </View>
                ) : null;
            case 'skills':
                style = props.styles.backgroundColor.skillSection
                    ? {
                          ...extraSectionStyle,
                          ...style,
                          backgroundColor: props.styles.backgroundColor.skillSection,
                      }
                    : { ...extraSectionStyle };
                return !!props.pdfProps.cv.skillProficiencies.length ? (
                    <View key={`PDF-${props.templateName}-${section}`} style={style}>
                        {props.styles.skillView.showTitle && (
                            <View style={{ ...props.sectionHeaderStyle, marginBottom: TITLE_BOTTOM_MARGIN, width: headerWidth }}>
                                <Text style={{ ...props.styles.skillTitleFont }}>{props.pdfProps.cv.metadata.skills.title}</Text>
                            </View>
                        )}
                        <View style={{ width: bodyWidth }}>
                            <SkillsPDF cv={props.pdfProps.cv} styles={props.styles} inline={props.inlineSkills || false} />
                        </View>
                    </View>
                ) : null;
            case 'languages':
                style = props.styles.backgroundColor.languageSection
                    ? {
                          ...extraSectionStyle,
                          ...style,
                          backgroundColor: props.styles.backgroundColor.languageSection,
                      }
                    : { ...extraSectionStyle };
                return !!props.pdfProps.cv.languageProficiencies.length ? (
                    <View key={`PDF-${props.templateName}-${section}`} style={style}>
                        {props.styles.languageView.showTitle && (
                            <View style={{ ...props.sectionHeaderStyle, marginBottom: TITLE_BOTTOM_MARGIN, width: headerWidth }}>
                                <Text style={{ ...props.styles.languageTitleFont }}>{props.pdfProps.cv.metadata.languages.title}</Text>
                            </View>
                        )}
                        <View style={{ width: bodyWidth }}>
                            <LanguagesPDF cv={props.pdfProps.cv} styles={props.styles} inline={props.inlineLanguages || false} />
                        </View>
                    </View>
                ) : null;
            case 'references':
                style = props.styles.backgroundColor.referenceSection
                    ? {
                          ...extraSectionStyle,
                          ...style,
                          backgroundColor: props.styles.backgroundColor.referenceSection,
                      }
                    : { ...extraSectionStyle };
                return !!props.pdfProps.cv.references.length ? (
                    <View key={`PDF-${props.templateName}-${section}`} style={style}>
                        {props.styles.referenceView.showTitle && (
                            <View style={{ ...props.sectionHeaderStyle, marginBottom: TITLE_BOTTOM_MARGIN, width: headerWidth }}>
                                <Text style={{ ...props.styles.referenceTitleFont }}>{props.pdfProps.cv.metadata.references.title}</Text>
                            </View>
                        )}
                        <View style={{ width: bodyWidth }}>
                            <ReferencePDF cv={props.pdfProps.cv} styles={props.styles} />
                        </View>
                    </View>
                ) : null;
            case 'interests':
                style = props.styles.backgroundColor.interestSection
                    ? {
                          ...extraSectionStyle,
                          ...style,
                          backgroundColor: props.styles.backgroundColor.interestSection,
                      }
                    : { ...extraSectionStyle };
                return !!props.pdfProps.cv.interests ? (
                    <View key={`PDF-${props.templateName}-${section}`} style={style}>
                        {props.styles.interestsView.showTitle && (
                            <View style={{ ...props.sectionHeaderStyle, marginBottom: TITLE_BOTTOM_MARGIN, width: headerWidth }}>
                                <Text style={{ ...props.styles.interestsTitleFont }}>{props.pdfProps.cv.metadata.interest.title}</Text>
                            </View>
                        )}
                        <View style={{ width: bodyWidth }}>
                            <InterestsPDF cv={props.pdfProps.cv} styles={props.styles} />
                        </View>
                    </View>
                ) : null;
            case 'links':
                style = props.styles.backgroundColor.socialLinkSection
                    ? {
                          ...extraSectionStyle,
                          ...style,
                          backgroundColor: props.styles.backgroundColor.socialLinkSection,
                      }
                    : { ...extraSectionStyle };
                return !!props.pdfProps.cv.socialLinks.length ? (
                    <View key={`PDF-${props.templateName}-${section}`} style={style}>
                        {props.styles.socialLinkView.showTitle && (
                            <View style={{ ...props.sectionHeaderStyle, marginBottom: TITLE_BOTTOM_MARGIN, width: headerWidth }}>
                                <Text style={{ ...props.styles.socialLinkTitleFont }}>{props.pdfProps.cv.metadata.socialLinks.title}</Text>
                            </View>
                        )}
                        <View style={{ width: bodyWidth }}>
                            <SocialLinksPDF cv={props.pdfProps.cv} styles={props.styles} />
                        </View>
                    </View>
                ) : null;

            default:
                return null;
        }
    });
};
