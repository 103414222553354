import { Variant } from '@mui/material/styles/createTypography';

export type ThemeValue = boolean | number | string | Variant | 'LEFT' | 'RIGHT' | 'dotted' | 'dashed' | 'linear';

export type Position =
    | 'top'
    | 'topLeft'
    | 'topRight'
    | 'middle'
    | 'middleLeft'
    | 'middleRight'
    | 'bottom'
    | 'bottomLeft'
    | 'bottomRight'
    | 'excludedSections';

export type SectionName =
    | 'name'
    | 'image'
    | 'profession'
    | 'contact'
    | 'profile'
    | 'skills'
    | 'languages'
    | 'experiences'
    | 'educations'
    | 'links'
    | 'references'
    | 'interests';

export const allSections: SectionName[] = [
    'name',
    'profession',
    'image',
    'contact',
    'skills',
    'languages',
    'references',
    'interests',
    'profile',
    'experiences',
    'educations',
    'links',
];

export interface ISectionPosition {
    immutable: boolean;
    sections: SectionName[];
}

export interface ILayout {
    top: ISectionPosition;
    topLeft: ISectionPosition;
    topRight: ISectionPosition;
    middle: ISectionPosition;
    middleLeft: ISectionPosition;
    middleRight: ISectionPosition;
    bottom: ISectionPosition;
    bottomLeft: ISectionPosition;
    bottomRight: ISectionPosition;
    excludedSections: ISectionPosition;
}

export interface ISectionWidth {
    topLeftWidth: number;
    middleLeftWidth: number;
    bottomLeftWidth: number;
}

export interface IColorScheme {
    primary: string;
    secondary: string;
    base: string;
}

export interface IImageTheme {
    width: number;
    height: number;
    circular: boolean;
    hasBorder: boolean;
    borderColor: string | null;
    borderWidth: number | null;
}

export interface IFontTheme {
    variant: Variant;
    color: string;
    weight: 'bold' | 'normal' | 'light';
    style: 'normal' | 'italic' | 'underline';
}

export interface IMarginTheme {
    bottom: number;
}

export interface ITitleTheme extends IFontTheme {
    show: boolean;
}

export interface INameTheme {
    first: IFontTheme;
    last: IFontTheme;
    margin: { gap: number };
}

export interface IFontMarginTheme {
    font: IFontTheme;
    margin: IMarginTheme;
}

export interface IFontMarginTitleTheme extends IFontMarginTheme {
    title: ITitleTheme;
}

export interface IProficiencyTheme {
    type: 'none' | 'dotted' | 'dashed' | 'linear';
    activeColor: string;
    inactiveColor: string;
    borderRadius: number;
    gap: number;
    thickness: number;
}

export interface IDateTheme {
    format: string;
}

export interface IBackgroundColorTheme {
    page?: string;
    leftSection?: string;
    rightSection?: string;
    nameSection?: string;
    skillSection?: string;
    languageSection?: string;
    contactSection?: string;
    profileSection?: string;
    interestSection?: string;
    experienceSection?: string;
    educationSection?: string;
    referenceSection?: string;
    socialLinkSection?: string;
}

export interface ISubSectionTheme {
    backgroundColor: IBackgroundColorTheme;
}

export type IProfessionTheme = IFontMarginTheme;
export type IContactTheme = IFontMarginTitleTheme;
export type IProfileTheme = IFontMarginTitleTheme;
export type IInterestsTheme = IFontMarginTitleTheme;

export type ISkillTheme = IFontMarginTitleTheme & { proficiency: IProficiencyTheme };

export type ILanguageTheme = ISkillTheme;

export interface IExperienceTheme {
    header: IFontTheme;
    date: IFontTheme & IDateTheme;
    item: IFontTheme;
    margin: IMarginTheme;
    title: ITitleTheme;
}

export interface IEducationTheme {
    header: IFontTheme;
    date: IFontTheme & IDateTheme;
    margin: IMarginTheme;
    title: ITitleTheme;
}

export interface IReferenceTheme {
    header: IFontTheme;
    subHeader: IFontTheme;
    margin: IMarginTheme;
    title: ITitleTheme;
}

export interface ISocialLinkTheme {
    header: IFontTheme;
    margin: IMarginTheme;
    title: ITitleTheme;
}

export interface ILayoutTheme {
    main: 'LEFT' | 'RIGHT';
    layout: ILayout | null;
    sectionWidth: ISectionWidth;
    borderColor: string | null;
    pageWidthMargin: number;
    pageHeightMargin: number;
}

export interface ITheme {
    layoutTheme: ILayoutTheme;
    imageTheme: IImageTheme;
    nameTheme: INameTheme;
    professionTheme: IProfessionTheme;
    subSectionTheme: ISubSectionTheme;
    contactTheme: IContactTheme;
    skillTheme: ISkillTheme;
    languageTheme: ILanguageTheme;
    interestsTheme: IInterestsTheme;
    profileTheme: IProfileTheme;
    experienceTheme: IExperienceTheme;
    educationTheme: IEducationTheme;
    referenceTheme: IReferenceTheme;
    socialLinkTheme: ISocialLinkTheme;
}
