import React from 'react';
import { Grid2, InputLabel, MenuItem, Select } from '@mui/material';
import { IFontTheme } from '../../interfaces/Theme.interface';
import { LABEL_SIZE } from './ThemeDesigner';
import { TranslationKey } from '../../interfaces/TranslationKey';
import { useTranslation } from 'react-i18next';
import ColorPickerDialog from './ColorPickerDialog';

interface ThemeCustomizerProps {
    theme: IFontTheme;
    handleThemeChange: (key: keyof IFontTheme, value: string) => void;
    variantItems: React.ReactElement[];
    labelKey: string;
}

const FontThemeCustomizer = (props: ThemeCustomizerProps): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <Grid2>
            <Grid2 container spacing={2} sx={{ mb: '10px' }}>
                <Grid2 size={LABEL_SIZE} alignContent="center">
                    <InputLabel>{t(TranslationKey.theme.fontSize._name)}</InputLabel>
                </Grid2>
                <Grid2 size="grow">
                    <Select
                        value={props.theme.variant}
                        onChange={(e) => props.handleThemeChange('variant', e.target.value)}
                        variant={'outlined'}
                        sx={{ width: '100%' }}
                    >
                        {props.variantItems}
                    </Select>
                </Grid2>
            </Grid2>
            <Grid2 container spacing={2} sx={{ mb: '10px' }}>
                <Grid2 size={LABEL_SIZE} alignContent="center">
                    <InputLabel>{t(TranslationKey.theme.fontWeight._name)}</InputLabel>
                </Grid2>
                <Grid2 size="grow">
                    <Select
                        value={props.theme.weight}
                        onChange={(e) => props.handleThemeChange('weight', e.target.value)}
                        variant={'outlined'}
                        sx={{ width: '100%' }}
                    >
                        <MenuItem key={`${props.labelKey}-weight-light`} value="light">
                            {t(TranslationKey.theme.fontWeight.light)}
                        </MenuItem>
                        <MenuItem key={`${props.labelKey}-weight-normal`} value="normal">
                            {t(TranslationKey.theme.fontWeight.normal)}
                        </MenuItem>
                        <MenuItem key={`${props.labelKey}-weight-bold`} value="bold">
                            {t(TranslationKey.theme.fontWeight.bold)}
                        </MenuItem>
                    </Select>
                </Grid2>
            </Grid2>
            <Grid2 container spacing={2} sx={{ mb: '10px' }}>
                <Grid2 size={LABEL_SIZE} alignContent="center">
                    <InputLabel>{t(TranslationKey.theme.fontStyle._name)}</InputLabel>
                </Grid2>
                <Grid2 size="grow">
                    <Select
                        value={props.theme.style}
                        onChange={(e) => props.handleThemeChange('style', e.target.value)}
                        variant={'outlined'}
                        sx={{ width: '100%' }}
                    >
                        <MenuItem key={`${props.labelKey}-style-normal`} value="normal">
                            {t(TranslationKey.theme.fontStyle.normal)}
                        </MenuItem>
                        <MenuItem key={`${props.labelKey}-style-italic`} value="italic">
                            {t(TranslationKey.theme.fontStyle.italic)}
                        </MenuItem>
                    </Select>
                </Grid2>
            </Grid2>
            <Grid2 sx={{ mb: '10px' }}>
                <ColorPickerDialog
                    label={t(TranslationKey.theme.color._name)}
                    color={props.theme.color}
                    onColorChange={(color: string) => props.handleThemeChange('color', color)}
                />
            </Grid2>
        </Grid2>
    );
};

export default FontThemeCustomizer;
