import { ApiEndpoints } from './endpoints';
import { IReference, IReferenceCreationDto, IReferenceUpdateDto } from '../interfaces/Reference.interface';
import { IApi } from './api.service';

export const createReference = async (api: IApi, dto: IReferenceCreationDto): Promise<IReference | null> => {
    return api.post<IReference, IReferenceCreationDto>(ApiEndpoints.reference, dto);
};

export const updateReference = async (api: IApi, id: number, dto: IReferenceUpdateDto): Promise<IReference | null> => {
    return api.put<IReference, IReferenceUpdateDto>(ApiEndpoints.referenceId.replace(':id', String(id)), dto);
};

export const removeReference = async (api: IApi, id: number): Promise<void> => {
    return api.delete(ApiEndpoints.referenceId.replace(':id', String(id)));
};
