import React from 'react';
import { Box, Divider, Grid2, InputLabel, Slider } from '@mui/material';
import { IBackgroundColorTheme, ILayoutTheme, ISubSectionTheme, ThemeValue } from '../../interfaces/Theme.interface';
import ColorPickerDialog from './ColorPickerDialog';
import { LABEL_SIZE } from './ThemeDesigner';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../interfaces/TranslationKey';
import SectionPositionCustomizer from './SectionPositionTheme';
import { TemplateName } from '../../common/enum';
import SectionWidthCustomizer from './SectionWidthTheme';
import BackgroundThemeCustomizer from './BackgroundColorTheme';

interface BaseThemeCustomizerProps {
    templateName: TemplateName;
    theme: ILayoutTheme;
    setTheme: (layoutTheme: (prev: ILayoutTheme) => ILayoutTheme) => void;
    sectionTheme: ISubSectionTheme;
    setSectionTheme: (subSectionTheme: (prev: ISubSectionTheme) => ISubSectionTheme) => void;
    setShouldUpdateThemed: (value: boolean) => void;
}

const LayoutThemeCustomizer = (props: BaseThemeCustomizerProps): React.ReactElement => {
    const { t } = useTranslation();

    const handleThemeChange = (key: keyof ILayoutTheme, value: ThemeValue) => {
        props.setTheme((prev: ILayoutTheme) => ({
            ...prev,
            [key]: value,
        }));
        props.setShouldUpdateThemed(true);
    };

    const handleBackgroundColorThemeChange = (key: keyof IBackgroundColorTheme, value: string) => {
        handleSectionThemeChange('backgroundColor', key, value);
    };

    const handleSectionThemeChange = <T extends keyof ISubSectionTheme, K extends keyof ISubSectionTheme[T]>(
        section: T,
        key: K,
        value: any,
    ) => {
        props.setSectionTheme((prev: ISubSectionTheme) => ({
            ...prev,
            [section]: {
                ...prev[section],
                [key]: value,
            },
        }));
        props.setShouldUpdateThemed(true);
    };

    return (
        <Box>
            <Grid2 container spacing={2}>
                <Grid2 size={LABEL_SIZE}>
                    <InputLabel>{t(TranslationKey.theme.pageWidthMargin)}</InputLabel>
                </Grid2>
                <Grid2 size="grow">
                    <Slider
                        value={props.theme.pageWidthMargin}
                        onChange={(_, newValue: number | number[]) => handleThemeChange('pageWidthMargin', newValue as number)}
                        valueLabelDisplay={'auto'}
                        valueLabelFormat={String(props.theme.pageWidthMargin)}
                        step={1}
                        min={0}
                        max={100}
                        marks={[
                            { value: 0, label: 0 },
                            { value: 100, label: 100 },
                        ]}
                    />
                </Grid2>
            </Grid2>

            <Grid2 container spacing={2}>
                <Grid2 size={LABEL_SIZE}>
                    <InputLabel>{t(TranslationKey.theme.pageHeightMargin)}</InputLabel>
                </Grid2>
                <Grid2 size="grow">
                    <Slider
                        value={props.theme.pageHeightMargin}
                        onChange={(_, newValue: number | number[]) => handleThemeChange('pageHeightMargin', newValue as number)}
                        valueLabelDisplay={'auto'}
                        valueLabelFormat={String(props.theme.pageHeightMargin)}
                        step={1}
                        min={0}
                        max={100}
                        marks={[
                            { value: 0, label: 0 },
                            { value: 100, label: 100 },
                        ]}
                    />
                </Grid2>
            </Grid2>
            <Divider sx={{ my: 2 }} />

            <Grid2 container spacing={2} sx={{ mb: '10px' }}>
                {props.theme.borderColor && (
                    <Grid2>
                        <ColorPickerDialog
                            label={TranslationKey.theme.color.primary}
                            color={props.theme.borderColor}
                            onColorChange={(color: string) => handleThemeChange('borderColor', color)}
                        />
                    </Grid2>
                )}
            </Grid2>

            {(!!props.sectionTheme.backgroundColor.page ||
                !!props.sectionTheme.backgroundColor.leftSection ||
                !!props.sectionTheme.backgroundColor.rightSection ||
                !!props.sectionTheme.backgroundColor.nameSection ||
                !!props.sectionTheme.backgroundColor.skillSection ||
                !!props.sectionTheme.backgroundColor.languageSection ||
                !!props.sectionTheme.backgroundColor.contactSection ||
                !!props.sectionTheme.backgroundColor.interestSection ||
                !!props.sectionTheme.backgroundColor.profileSection ||
                !!props.sectionTheme.backgroundColor.experienceSection ||
                !!props.sectionTheme.backgroundColor.educationSection ||
                !!props.sectionTheme.backgroundColor.referenceSection) && (
                <>
                    <Grid2>
                        <Grid2 sx={{ my: '10px' }}>
                            <BackgroundThemeCustomizer
                                theme={props.sectionTheme.backgroundColor}
                                handleThemeChange={handleBackgroundColorThemeChange}
                            />
                        </Grid2>
                    </Grid2>
                    <Divider sx={{ my: 2 }} />
                </>
            )}

            <Grid2>
                <SectionWidthCustomizer {...props} />
                <Box sx={{ mb: 2 }}></Box>
                <SectionPositionCustomizer {...props} />
            </Grid2>
        </Box>
    );
};

export default LayoutThemeCustomizer;
