import React from 'react';
import { Box, Typography } from '@mui/material';
import { IExperience, IExperienceItem } from '../../interfaces/Experience.interface';
import { IExperienceTheme } from '../../interfaces/Theme.interface';
import { useMode } from '../../context/Mode.context';
import { Mode } from '../../interfaces/Mode.interface';
import ExperiencesForm from '../builder/forms/ExperiencesForm';
import { PrintableDatePickerRange } from '../DatePickerRange';
import dayjs from 'dayjs';
import { getFontSize, getFontSizeForIcon } from '../../utils/common.util';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PrintableCompany from './Company';

interface ExperiencesProps {
    cvId: number;
    experiences: IExperience[];
    theme: IExperienceTheme;
}

const Experiences: React.FC<ExperiencesProps> = (props: ExperiencesProps) => {
    const { mode } = useMode();

    const isEditing: boolean = mode === Mode.EDIT;

    return (
        <Box id="experience" sx={{ mb: `${props.theme.margin.bottom}px` }}>
            {isEditing ? (
                <ExperiencesForm {...props} />
            ) : (
                props.experiences.map((experience: IExperience) => (
                    <Box key={'printable-experiences-' + experience.id}>
                        <Typography display={'flex'} flexWrap={'wrap'}>
                            <span
                                style={{
                                    color: props.theme.header.color,
                                    fontSize: getFontSize(props.theme.header.variant),
                                    fontWeight: props.theme.header.weight === 'light' ? 'lighter' : props.theme.header.weight,
                                    fontStyle: props.theme.header.style,
                                }}
                            >
                                {experience.title}
                            </span>
                            {experience.company && (
                                <>
                                    <span
                                        style={{
                                            color: props.theme.header.color,
                                            fontSize: getFontSize(props.theme.header.variant),
                                            fontWeight: props.theme.header.weight === 'light' ? 'lighter' : props.theme.header.weight,
                                            fontStyle: props.theme.header.style,
                                        }}
                                    >{`\u200C | \u200C`}</span>
                                    <PrintableCompany experience={experience} theme={props.theme.header} />
                                </>
                            )}
                        </Typography>
                        <PrintableDatePickerRange
                            startDate={dayjs(experience.startDate)}
                            endDate={experience.endDate ? dayjs(experience.endDate) : null}
                            theme={props.theme.date}
                        />
                        <Box sx={{ margin: '5px' }}></Box>
                        {!!experience.items.length && (
                            <Box>
                                {experience.items.map((experienceItem: IExperienceItem) => (
                                    <Box display={'flex'} key={'printable-item' + experienceItem.id}>
                                        <Typography sx={{ mr: '10px' }}>
                                            <FiberManualRecordIcon
                                                sx={{
                                                    fontSize: getFontSizeForIcon(props.theme.item.variant),
                                                    color: props.theme.item.color,
                                                }}
                                            />
                                        </Typography>
                                        <Typography
                                            component="p"
                                            variant={props.theme.item.variant}
                                            fontWeight={props.theme.item.weight}
                                            fontStyle={props.theme.item.style}
                                            color={props.theme.item.color}
                                            sx={{ color: `${props.theme.item.color} !important` }}
                                            alignContent={'center'}
                                        >
                                            {experienceItem.description}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </Box>
                ))
            )}
        </Box>
    );
};

export default Experiences;
