import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { ICV } from '../../interfaces/CV.interface';
import { IPDFStyle } from '../../interfaces/pdfStyle.interface';

interface SectionPDFProps {
    cv: ICV;
    styles: IPDFStyle;
}

const InterestsPDF = (props: SectionPDFProps): React.ReactElement => {
    return (
        <View style={props.styles.interestsView}>
            {props.cv.interests && <Text style={props.styles.interestsFont}>{props.cv.interests}</Text>}
        </View>
    );
};

export default InterestsPDF;
