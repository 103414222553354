export const TranslationKey = {
    helmet: {
        title: {
            homePage: 'helmet.title.homePage',
            cvPage: 'helmet.title.cvPage',
            cvCreationPage: 'helmet.title.cvCreationPage',
            profilePage: 'helmet.title.profilePage',
            loginPage: 'helmet.title.loginPage',
            signupPage: 'helmet.title.signupPage',
            activationPage: 'helmet.title.activationPage',
            passwordResetRequestPage: 'helmet.title.passwordResetRequestPage',
            passwordResetPage: 'helmet.title.passwordResetPage',
            privacyPolicyPage: 'helmet.title.privacyPolicyPage',
            termsOfServicePage: 'helmet.title.termsOfServicePage',
        },
        meta: {
            title: 'helmet.meta.title',
            description: 'helmet.meta.description',
            keywords: 'helmet.meta.keywords',
            og_title: 'helmet.meta.og_title',
            og_description: 'helmet.meta.og_description',
            twitter_title: 'helmet.meta.twitter_title',
            twitter_description: 'helmet.meta.twitter_description',
        },
    },
    image: {
        _name: 'image._name',
        profileImage: 'image.profileImage',
        upload: 'image.upload',
        remove: 'image.remove',
    },
    personalInfo: 'personalInfo',
    name: 'name',
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
    phoneNumber: 'phoneNumber',
    address: {
        _name: 'address._name',
        street: 'address.street',
        postalCode: 'address.postalCode',
        city: 'address.city',
        state: 'address.state',
        country: 'address.country',
    },
    profession: 'profession',
    contact: 'contact',
    skillsAndLanguages: 'skillsAndLanguages',
    skill: {
        _name: 'skill._name',
        plural: 'skill.plural',
    },
    language: {
        _name: 'language._name',
        plural: 'language.plural',
    },
    interests: 'interests',
    activitiesAndInterests: 'activitiesAndInterests',
    cvProfile: 'cvProfile',
    profile: 'profile',
    date: {
        _name: 'date._name',
        start: 'date.start',
        end: 'date.end',
        issue: 'date.issue',
        current: 'date.current',
        noDate: 'date.noDate',
    },
    experience: {
        _name: 'experience._name',
        title: 'experience.title',
        company: {
            _name: 'experience.company._name',
            name: 'experience.company.name',
            location: 'experience.company.location',
        },
        item: {
            _name: 'experience.item._name',
            experienceItems: 'experience.item.experienceItems',
        },
    },
    education: {
        _name: 'education._name',
        degree: 'education.degree',
        institution: 'education.institution',
    },
    certification: {
        _name: 'certification._name',
        plural: 'certification.plural',
        issuer: 'certification.issuer',
        name: 'certification.name',
    },
    educationAndCertification: 'educationAndCertification',
    reference: {
        _name: 'reference._name',
        plural: 'reference.plural',
        name: 'reference.name',
        contact: 'reference.contact',
        relationship: 'reference.relationship',
    },
    socialLink: {
        _name: 'socialLink._name',
        plural: 'socialLink.plural',
        url: 'socialLink.url',
        platform: 'socialLink.platform',
    },
    user: {
        uuid: 'user.uuid',
        details: 'user.details',
        password: 'user.password',
        confirmPassword: 'user.confirmPassword',
        createAccount: 'user.createAccount',
    },
    accountActivation: {
        _name: 'accountActivation._name',
        requestActivationSuccessMsg: 'accountActivation.requestActivationSuccessMsg',
        requestActivationErrorNoAccountMsg: 'accountActivation.requestActivationErrorNoAccountMsg',
        requestActivationErrorActiveMsg: 'accountActivation.requestActivationErrorActiveMsg',
        activationSuccessMsg: 'accountActivation.activationSuccessMsg',
        activationErrorActiveMsg: 'accountActivation.activationErrorActiveMsg',
        activationErrorExpiredMsg: 'accountActivation.activationErrorExpiredMsg',
    },
    passwordReset: {
        _name: 'passwordReset._name',
        request: 'passwordReset.request',
        requestResetPasswordSuccessMsg: 'passwordReset.requestResetPasswordSuccessMsg',
        passwordResetTokenExpiredMsg: 'passwordReset.passwordResetTokenExpiredMsg',
        passwordResetSuccessMsg: 'passwordReset.passwordResetSuccessMsg',
    },
    buttons: {
        add: 'buttons.add',
        next: 'buttons.next',
        getStarted: 'buttons.getStarted',
        myResume: 'buttons.myResume',
        createCV: 'buttons.createCV',
        useThisTemplate: 'buttons.useThisTemplate',
        cancel: 'buttons.cancel',
        save: 'buttons.save',
        close: 'buttons.close',
        login: 'buttons.login',
        logout: 'buttons.logout',
        signup: 'buttons.signup',
        print: 'buttons.print',
        editMode: 'buttons.editMode',
        printMode: 'buttons.printMode',
        download: 'buttons.download',
        preview: 'buttons.preview',
        back: 'buttons.back',
        send: 'buttons.send',
        activate: 'buttons.activate',
        confirm: 'buttons.confirm',
        forgotPassword: 'buttons.forgotPassword',
        changePassword: 'buttons.changePassword',
        deleteAccount: 'buttons.deleteAccount',
        jobTitle: 'buttons.jobTitle',
    },
    landingPage: {
        _name: 'landingPage._name',
        header: 'landingPage.header',
        headerDec1: 'landingPage.headerDec1',
        headerDec2: 'landingPage.headerDec2',
        whyChooseUs: 'landingPage.whyChooseUs',
        whyChooseUsDesc: 'landingPage.whyChooseUsDesc',
        easyToUse: 'landingPage.easyToUse',
        easyToUsDesc: 'landingPage.easyToUsDesc',
        professionalDesigns: 'landingPage.professionalDesigns',
        professionalDesignsDesc: 'landingPage.professionalDesignsDesc',
        astFriendly: 'landingPage.astFriendly',
        astFriendlyDesc: 'landingPage.astFriendlyDesc',
        howItWorks: 'landingPage.howItWorks',
        step1: 'landingPage.step1',
        step1Desc: 'landingPage.step1Desc',
        step2: 'landingPage.step2',
        step2Desc: 'landingPage.step2Desc',
        step3: 'landingPage.step3',
        step3Desc: 'landingPage.step3Desc',
        step4: 'landingPage.step4',
        step4Desc: 'landingPage.step4Desc',
        chooseTemplate: 'landingPage.chooseTemplate',
    },
    creationPage: {
        header: 'creationPage.header',
        headerDesc: 'creationPage.headerDesc',
    },
    footer: {
        aboutUs: 'footer.aboutUs',
        aboutUsDesc: 'footer.aboutUsDesc',
        quickLinks: 'footer.quickLinks',
        followUs: 'footer.followUs',
        allRightsReserved: 'footer.allRightsReserved',
    },
    contactUs: {
        _name: 'contactUs._name',
        topic: 'contactUs.topic',
        message: 'contactUs.message',
        bugReport: 'contactUs.bugReport',
        featureRequest: 'contactUs.featureRequest',
        authIssue: 'contactUs.authIssue',
        generalInquiry: 'contactUs.generalInquiry',
        other: 'contactUs.other',
        successMessage: 'contactUs.successMessage',
        errorMessage: 'contactUs.errorMessage',
    },
    terms: {
        privacyPolicy: 'terms.privacyPolicy',
        termsOfService: 'terms.termsOfService',
        agreeTo: 'terms.agreeTo',
    },
    info: {
        _name: 'info._name',
        zoom: 'info.zoom',
        clickToEdit: 'info.clickToEdit',
        passwordUppercase: 'info.passwordUppercase',
        passwordLowercase: 'info.passwordLowercase',
        passwordNumber: 'info.passwordNumber',
        passwordSpecialCharacter: 'info.passwordSpecialCharacter',
        passwordLength: 'info.passwordLength',
    },
    error: {
        _name: 'error._name',
        requiredField: 'error.requiredField',
        invalidEmail: 'error.invalidEmail',
        invalidLanguage: 'error.invalidLanguage',
        duplicatedValue: 'error.duplicatedValue',
        invalidCredentials: 'error.invalidCredentials',
        notRegisteredYet: 'error.notRegisteredYet',
        accountExists: 'error.accountExists',
        accountIsDeleted: 'error.accountIsDeleted',
        cannotLogin: 'error.cannotLogin',
        passwordsMismatch: 'error.passwordsMismatch',
        cannotSignup: 'error.cannotSignup',
        weakPassword: 'error.weakPassword',
        somethingWrongPassword: 'error.somethingWrongPassword',
        error404Title: 'error.error404Title',
        error404Desc: 'error.error404Desc',
    },
    template: {
        _name: 'template._name',
        damascus: 'template.damascus',
        damascusDesc: 'template.damascusDesc',
        homs: 'template.homs',
        homsDesc: 'template.homsDesc',
        aleppo: 'template.aleppo',
        aleppoDesc: 'template.aleppoDesc',
        mecca: 'template.mecca',
        meccaDesc: 'template.meccaDesc',
        madina: 'template.madina',
        madinaDesc: 'template.madinaDesc',
        doha: 'template.doha',
        dohaDesc: 'template.dohaDesc',
        golan: 'template.golan',
        golanDesc: 'template.golanDesc',
        gaza: 'template.gaza',
        gazaDesc: 'template.gazaDesc',
        alQuds: 'template.alQuds',
        alQudsDesc: 'template.alQudsDesc',
        mosul: 'template.mosul',
        mosulDesc: 'template.mosulDesc',
        tripoli: 'template.tripoli',
        tripoliDesc: 'template.tripoliDesc',
        cairo: 'template.cairo',
        cairoDesc: 'template.cairoDesc',
        riadh: 'template.riadh',
        riadhDesc: 'template.riadhDesc',
        hama: 'template.hama',
        hamaDesc: 'template.hamaDesc',
        idlib: 'template.idlib',
        idlibDesc: 'template.idlibDesc',
    },
    theme: {
        _name: 'theme._name',
        designer: 'theme.designer',
        layout: 'theme.layout',
        section: 'theme.section',
        title: 'theme.title',
        type: 'theme.type',
        header: 'theme.header',
        subHeader: 'theme.subHeader',
        sectionWidth: {
            top: 'theme.sectionWidth.top',
            middle: 'theme.sectionWidth.middle',
            bottom: 'theme.sectionWidth.bottom',
        },
        pageWidthMargin: 'theme.pageWidthMargin',
        pageHeightMargin: 'theme.pageHeightMargin',
        imageWidth: 'theme.imageWidth',
        imageHeight: 'theme.imageHeight',
        imageHasBorder: 'theme.imageHasBorder',
        imageBorderWidth: 'theme.imageBorderWidth',
        circularImage: 'theme.circularImage',
        nameSpace: 'theme.nameSpace',
        bottomMargin: 'theme.bottomMargin',
        dateFormat: 'theme.dateFormat',
        showTitle: 'theme.showTitle',
        backgroundColor: {
            _name: 'theme.backgroundColor._name',
            page: 'theme.backgroundColor.page',
            leftSection: 'theme.backgroundColor.leftSection',
            rightSection: 'theme.backgroundColor.rightSection',
        },
        color: {
            _name: 'theme.color._name',
            primary: 'theme.color.primary',
            secondary: 'theme.color.secondary',
            title: 'theme.color.title',
            profession: 'theme.color.profession',
            firstName: 'theme.color.firstName',
            lastName: 'theme.color.lastName',
            sectionHeader: 'theme.color.sectionHeader',
            skillBar: 'theme.color.skillBar',
            languageBar: 'theme.color.languageBar',
            date: 'theme.color.date',
            subHeader: 'theme.color.subHeader',
            imageBorder: 'theme.color.imageBorder',
        },
        fontWeight: {
            _name: 'theme.fontWeight._name',
            light: 'theme.fontWeight.light',
            normal: 'theme.fontWeight.normal',
            bold: 'theme.fontWeight.bold',
        },
        fontStyle: {
            _name: 'theme.fontStyle._name',
            normal: 'theme.fontStyle.normal',
            italic: 'theme.fontStyle.italic',
        },
        fontSize: {
            _name: 'theme.fontSize._name',
            XXL: 'theme.fontSize.XXL',
            XL: 'theme.fontSize.XL',
            L: 'theme.fontSize.L',
            M: 'theme.fontSize.M',
            S: 'theme.fontSize.S',
            XS: 'theme.fontSize.XS',
            XXS: 'theme.fontSize.XXS',
        },
        proficiency: {
            _name: 'theme.proficiency._name',
            linear: 'theme.proficiency.linear',
            dashed: 'theme.proficiency.dashed',
            dotted: 'theme.proficiency.dotted',
            activeColor: 'theme.proficiency.activeColor',
            inactiveColor: 'theme.proficiency.inactiveColor',
            gap: 'theme.proficiency.gap',
            thickness: 'theme.proficiency.thickness',
            borderRadius: 'theme.proficiency.borderRadius',
        },
    },
    privacyPolicy: {
        title: 'privacyPolicy.title',
        lastUpdated: 'privacyPolicy.lastUpdated',
        introduction: 'privacyPolicy.introduction',
        introductionSub: 'privacyPolicy.introductionSub',
        informationWeCollect: {
            title: 'privacyPolicy.informationWeCollect.title',
            description: 'privacyPolicy.informationWeCollect.description',
            accountInformation: {
                title: 'privacyPolicy.informationWeCollect.accountInformation.title',
                description: 'privacyPolicy.informationWeCollect.accountInformation.description',
            },
            resumeData: {
                title: 'privacyPolicy.informationWeCollect.resumeData.title',
                description: 'privacyPolicy.informationWeCollect.resumeData.description',
            },
            cookies: {
                title: 'privacyPolicy.informationWeCollect.cookies.title',
                description: 'privacyPolicy.informationWeCollect.cookies.description',
            },
        },
        howWeUseYourInformation: {
            title: 'privacyPolicy.howWeUseYourInformation.title',
            description: 'privacyPolicy.howWeUseYourInformation.description',
            item1: 'privacyPolicy.howWeUseYourInformation.item1',
            item2: 'privacyPolicy.howWeUseYourInformation.item2',
            item3: 'privacyPolicy.howWeUseYourInformation.item3',
            item4: 'privacyPolicy.howWeUseYourInformation.item4',
        },
        dataStorageAndSecurity: {
            title: 'privacyPolicy.dataStorageAndSecurity.title',
            item1: 'privacyPolicy.dataStorageAndSecurity.item1',
            item2: 'privacyPolicy.dataStorageAndSecurity.item2',
            item3: 'privacyPolicy.dataStorageAndSecurity.item3',
        },
        cookies: {
            title: 'privacyPolicy.cookies.title',
            item1: 'privacyPolicy.cookies.item1',
            item2: 'privacyPolicy.cookies.item2',
        },
        donations: {
            title: 'privacyPolicy.donations.title',
            item1: 'privacyPolicy.donations.item1',
            item2: 'privacyPolicy.donations.item2',
        },
        yourRights: {
            title: 'privacyPolicy.yourRights.title',
            item1: 'privacyPolicy.yourRights.item1',
            item2: 'privacyPolicy.yourRights.item2',
        },
        changesToThisPrivacyPolicy: {
            title: 'privacyPolicy.changesToThisPrivacyPolicy.title',
            description: 'privacyPolicy.changesToThisPrivacyPolicy.description',
        },
        contactUs: {
            title: 'privacyPolicy.contactUs.title',
            description: 'privacyPolicy.contactUs.description',
        },
    },
    termsOfService: {
        title: 'termsOfService.title',
        lastUpdated: 'termsOfService.lastUpdated',
        introduction: 'termsOfService.introduction',
        eligibility: {
            title: 'termsOfService.eligibility.title',
            item1: 'termsOfService.eligibility.item1',
            item2: 'termsOfService.eligibility.item2',
        },
        accountResponsibility: {
            title: 'termsOfService.accountResponsibility.title',
            item1: 'termsOfService.accountResponsibility.item1',
            item2: 'termsOfService.accountResponsibility.item2',
        },
        useOfServices: {
            title: 'termsOfService.useOfServices.title',
            item1: 'termsOfService.useOfServices.item1',
            item2: 'termsOfService.useOfServices.item2',
            item3: 'termsOfService.useOfServices.item3',
        },
        donations: {
            title: 'termsOfService.donations.title',
            item1: 'termsOfService.donations.item1',
            item2: 'termsOfService.donations.item2',
        },
        intellectualProperty: {
            title: 'termsOfService.intellectualProperty.title',
            item1: 'termsOfService.intellectualProperty.item1',
            item2: 'termsOfService.intellectualProperty.item2',
        },
        limitationOfLiability: {
            title: 'termsOfService.limitationOfLiability.title',
            item1: 'termsOfService.limitationOfLiability.item1',
            item2: 'termsOfService.limitationOfLiability.item2',
        },
        termination: {
            title: 'termsOfService.termination.title',
            description: 'termsOfService.termination.description',
        },
        changesToTheseTerms: {
            title: 'termsOfService.changesToTheseTerms.title',
            description: 'termsOfService.changesToTheseTerms.description',
        },
        contactUs: {
            title: 'termsOfService.contactUs.title',
            description: 'termsOfService.contactUs.description',
        },
    },
    tooltip: {
        deleteAccount: 'tooltip.deleteAccount',
        deleteCv: 'tooltip.deleteCv',
        reorderItems: 'tooltip.reorderItems',
        moveUp: 'tooltip.moveUp',
        moveDown: 'tooltip.moveDown',
    },
    confirmationModal: {
        deleteUser: {
            title: 'confirmationModal.deleteUser.title',
            message: 'confirmationModal.deleteUser.message',
        },
        deleteCV: {
            title: 'confirmationModal.deleteCV.title',
            message: 'confirmationModal.deleteCV.message',
        },
    },
};
