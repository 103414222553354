import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { ICV } from '../../interfaces/CV.interface';
import { IPDFStyle } from '../../interfaces/pdfStyle.interface';
import { ISocialLink } from '../../interfaces/SocialLink.interface';

interface SectionPDFProps {
    cv: ICV;
    styles: IPDFStyle;
}

const SocialLinksPDF = (props: SectionPDFProps): React.ReactElement => {
    return (
        <View style={props.styles.socialLinkView}>
            {props.cv.socialLinks.map((socialLink: ISocialLink) => {
                return (
                    <Text key={`PDF-socialLinks-${socialLink.id}`} style={props.styles.socialLinkHeaderFont}>
                        {socialLink.url}
                    </Text>
                );
            })}
        </View>
    );
};

export default SocialLinksPDF;
