import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useRefresh } from '../hooks/useRefresh';
import { useAuth } from '../context/Auth.context';
import { Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PrivateRoute = () => {
    const { i18n } = useTranslation();
    const { accessToken, setAccessToken } = useAuth();
    const refresh = useRefresh();
    const [loading, setLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

    useEffect(() => {
        const verifyUser = async () => {
            if (!accessToken) {
                try {
                    const newAccessToken = await refresh();
                    if (newAccessToken) {
                        setAccessToken(newAccessToken);
                        setIsAuthenticated(true);
                    } else {
                        setIsAuthenticated(false);
                    }
                } catch {
                    setIsAuthenticated(false);
                }
            } else {
                setIsAuthenticated(true);
            }
            setLoading(false);
        };

        verifyUser();
    }, [accessToken, refresh, setAccessToken]);

    const loginPath = `/${i18n.language}/login`;

    if (loading)
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress size="3rem" />
            </Box>
        );

    return isAuthenticated ? <Outlet /> : <Navigate to={loginPath} replace />;
};

export default PrivateRoute;
