import React from 'react';
import { Box, Divider, Grid2, MenuItem, Typography } from '@mui/material';
import { IFontTheme, IMarginTheme, ISocialLinkTheme, ITitleTheme, ThemeValue } from '../../interfaces/Theme.interface';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../interfaces/TranslationKey';
import MarginThemeCustomizer from './MarginTheme';
import FontThemeCustomizer from './FontTheme';
import TitleThemeCustomizer from './TitleTheme';

interface SocialLinkThemeCustomizerProps {
    theme: ISocialLinkTheme;
    setTheme: (theme: (prev: ISocialLinkTheme) => ISocialLinkTheme) => void;
    setShouldUpdateThemed: (value: boolean) => void;
}

const SocialLinkThemeCustomizer = (props: SocialLinkThemeCustomizerProps): React.ReactElement => {
    const { t } = useTranslation();

    const handleHeaderFontThemeChange = (key: keyof IFontTheme, value: string) => {
        handleThemeChange('header', key, value);
    };

    const handleMarginThemeChange = (key: keyof IMarginTheme, value: number) => {
        handleThemeChange('margin', key, value);
    };

    const handleTitleThemeChange = (key: keyof ITitleTheme, value: ThemeValue) => {
        handleThemeChange('title', key, value);
    };

    const handleThemeChange = <T extends keyof ISocialLinkTheme, K extends keyof ISocialLinkTheme[T]>(
        section: T,
        key: K,
        value: ThemeValue,
    ) => {
        props.setTheme((prev: ISocialLinkTheme) => ({
            ...prev,
            [section]: {
                ...prev[section],
                [key]: value,
            },
        }));
        props.setShouldUpdateThemed(true);
    };

    return (
        <Box>
            <Typography variant="h6">{t(TranslationKey.theme.title)}</Typography>
            <Grid2 container spacing={2} sx={{ mt: '10px' }}>
                <Grid2 size={12}>
                    <TitleThemeCustomizer
                        labelKey={'title-SocialLink'}
                        theme={props.theme.title}
                        handleThemeChange={handleTitleThemeChange}
                    />
                </Grid2>
            </Grid2>
            <Divider sx={{ my: 2 }} />

            <Typography variant="h6">{t(TranslationKey.socialLink.url)}</Typography>
            <FontThemeCustomizer
                labelKey={'SocialLink-header'}
                theme={props.theme.header}
                handleThemeChange={handleHeaderFontThemeChange}
                variantItems={[
                    <MenuItem key={'SocialLink-header-variant-h6'} value="h6">
                        {t(TranslationKey.theme.fontSize.L)}
                    </MenuItem>,
                    <MenuItem key={'SocialLink-header-variant-body1'} value="body1">
                        {t(TranslationKey.theme.fontSize.M)}
                    </MenuItem>,
                    <MenuItem key={'SocialLink-header-variant-body2'} value="body2">
                        {t(TranslationKey.theme.fontSize.S)}
                    </MenuItem>,
                ]}
            />
            <Divider sx={{ my: 2 }} />

            <Grid2 container spacing={2} sx={{ mt: '10px' }}>
                <Grid2 size={12}>
                    <MarginThemeCustomizer theme={props.theme.margin} handleThemeChange={handleMarginThemeChange} />
                </Grid2>
            </Grid2>
        </Box>
    );
};

export default SocialLinkThemeCustomizer;
