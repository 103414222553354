import React from 'react';
import { Box } from '@mui/material';
import { IReferenceTheme } from '../../interfaces/Theme.interface';
import { IReference } from '../../interfaces/Reference.interface';
import { useMode } from '../../context/Mode.context';
import { Mode } from '../../interfaces/Mode.interface';
import ReferencesForm from '../builder/forms/ReferencesForm';
import { PrintableTextField } from '../GeneralTextField';

interface ReferencesProps {
    cvId: number;
    references: IReference[];
    theme: IReferenceTheme;
}

const References: React.FC<ReferencesProps> = (props: ReferencesProps) => {
    const { mode } = useMode();

    const isEditing: boolean = mode === Mode.EDIT;

    return (
        <Box id="reference" sx={{ mb: `${props.theme.margin.bottom}px` }}>
            {isEditing ? (
                <ReferencesForm {...props} />
            ) : (
                props.references.map((reference: IReference) => (
                    <Box key={'printable-references-' + reference.id}>
                        <PrintableTextField value={reference.name} typographyComponent={'p'} fontTheme={props.theme.header} />
                        {reference.contact && (
                            <PrintableTextField value={reference.contact} typographyComponent={'p'} fontTheme={props.theme.subHeader} />
                        )}
                    </Box>
                ))
            )}
        </Box>
    );
};

export default References;
