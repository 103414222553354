import React from 'react';
import { ICV } from '../../interfaces/CV.interface';
import { ILayout, ITheme, SectionName } from '../../interfaces/Theme.interface';
import { getPDFStyles } from '../../utils/theme.util';
import { Document, Page, View } from '@react-pdf/renderer/lib/react-pdf';
import { IPDFStyle } from '../../interfaces/pdfStyle.interface';
import { useTranslation } from 'react-i18next';
import { TemplateName } from '../../common/enum';
import { getSections } from '../Template.util';
import { getPDFSectionElements, IPDFSectionElementsProps } from '../PDFTemplate.util';

interface PDFProps {
    cv: ICV;
    imageSrc: string | null;
    theme: ITheme;
}

const MeccaTemplatePDF = (props: PDFProps): React.ReactElement => {
    const { i18n } = useTranslation();

    const isRTL: boolean = i18n.dir() === 'rtl';

    const styles: IPDFStyle = getPDFStyles(props.theme, isRTL);

    const templateName: TemplateName = TemplateName.MECCA;

    const layout: ILayout = getSections(props.theme.layoutTheme, templateName);

    const showTopSection: boolean = layout.top.sections.some((i) => !!i);
    const showTopLeftSection: boolean = layout.topLeft.sections.some((i) => !!i);
    const showTopLeftRightSection: boolean = showTopLeftSection || layout.topRight.sections.some((i) => !!i);

    const showMiddleSection: boolean = layout.middle.sections.some((i) => !!i);
    const showMiddleLeftRightSection: boolean = layout.middleLeft.sections.some((i) => !!i) || layout.middleRight.sections.some((i) => !!i);

    const showBottomSection: boolean = layout.bottom.sections.some((i) => !!i);
    const showBottomLeftRightSection: boolean = layout.bottomLeft.sections.some((i) => !!i) || layout.bottomRight.sections.some((i) => !!i);

    const getProps = (sections: SectionName[]): IPDFSectionElementsProps => ({
        templateName: templateName,
        sections: sections,
        styles: styles,
        pdfProps: props,
    });

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* top */}
                {showTopSection && (
                    <View style={{ padding: '20px 20px 20px 0' }}>{getPDFSectionElements(getProps(layout.top.sections))}</View>
                )}

                {/* top left/right */}
                {showTopLeftRightSection && (
                    <View style={{ ...styles.rowStyle, marginBottom: '15px' }}>
                        {showTopLeftSection && (
                            <View style={{ ...styles.leftTopView, justifyContent: 'center' }}>
                                {getPDFSectionElements(getProps(layout.topLeft.sections))}
                            </View>
                        )}
                        <View style={{ width: showTopLeftSection ? styles.rightTopView.width : '100%' }}>
                            {getPDFSectionElements(getProps(layout.topRight.sections))}
                        </View>
                    </View>
                )}

                {/* middle */}
                {showMiddleSection && (
                    <View style={{ padding: '20px 20px 20px 0' }}>{getPDFSectionElements(getProps(layout.middle.sections))}</View>
                )}

                {/* middle left/right */}
                {showMiddleLeftRightSection && (
                    <View style={{ ...styles.rowStyle }}>
                        <View style={{ ...styles.leftMiddleView, margin: isRTL ? '0 0 0 30px' : '0 30px 0 0' }}>
                            {getPDFSectionElements(getProps(layout.middleLeft.sections))}
                        </View>
                        <View style={styles.rightMiddleView}>{getPDFSectionElements(getProps(layout.middleRight.sections))}</View>
                    </View>
                )}

                {/* bottom */}
                {showBottomSection && (
                    <View style={{ padding: '20px 20px 20px 0' }}>{getPDFSectionElements(getProps(layout.bottom.sections))}</View>
                )}

                {/* bottom left/right */}
                {showBottomLeftRightSection && (
                    <View style={{ ...styles.rowStyle }}>
                        <View style={{ ...styles.leftBottomView, margin: isRTL ? '0 0 0 30px' : '0 30px 0 0' }}>
                            {getPDFSectionElements(getProps(layout.bottomLeft.sections))}
                        </View>
                        <View style={styles.rightBottomView}>{getPDFSectionElements(getProps(layout.bottomRight.sections))}</View>
                    </View>
                )}
            </Page>
        </Document>
    );
};

export default MeccaTemplatePDF;
