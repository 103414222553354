import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Grid2,
    Step,
    StepLabel,
    Stepper,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import PersonalInfoForm from './forms/PersonalInfoForm';
import { useCV } from '../../context/CV.context';
import {
    ArrowBackIos,
    ArrowForwardIos,
    CheckCircle,
    Home,
    Image,
    Interests,
    Language,
    Link,
    Person,
    School,
    Work,
} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { TranslationKey } from '../../interfaces/TranslationKey';
import { useTranslation } from 'react-i18next';
import { ITheme } from '../../interfaces/Theme.interface';
import { defaultThemeTemplateName } from '../../utils/theme.util';
import { TemplateName } from '../../common/enum';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ICV, ICVCreationDto } from '../../interfaces/CV.interface';
import { createCV } from '../../services/cv.service';
import { IApi } from '../../services/api.service';
import { useAxiosApi } from '../../hooks/useAxiosApi';
import { IProfession, IProfessionDto } from '../../interfaces/Profession.interface';
import { getOrCreateProfession } from '../../services/profession.service';
import AddressForm from './forms/AddressForm';
import ProfileForm from './forms/ProfileForm';
import ExperiencesForm from './forms/ExperiencesForm';
import EducationsForm from './forms/EducationsForm';
import ReferencesForm from './forms/ReferencesForm';
import SocialLinksForm from './forms/SocialLinksForm';
import InterestsForm from './forms/InterestsForm';
import SkillsForm from './forms/SkillsForm';
import LanguagesForm from './forms/LanguagesForm';
import { useMode } from '../../context/Mode.context';
import { Mode } from '../../interfaces/Mode.interface';
import ImageForm from './forms/ImageForm';
import { getLocalizedPath } from '../../utils/common.util';

const steps = [
    { label: TranslationKey.personalInfo, icon: <Person /> },
    { label: TranslationKey.image._name, icon: <Image /> },
    { label: TranslationKey.address._name, icon: <Home /> },
    { label: TranslationKey.cvProfile, icon: <Work /> },
    { label: TranslationKey.skill.plural, icon: <School /> },
    { label: TranslationKey.language.plural, icon: <Language /> },
    { label: TranslationKey.experience._name, icon: <Work /> },
    { label: TranslationKey.educationAndCertification, icon: <School /> },
    { label: TranslationKey.reference.plural, icon: <Person /> },
    { label: TranslationKey.socialLink.plural, icon: <Link /> },
    { label: TranslationKey.interests, icon: <Interests /> },
];

const CVBuilder: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { setMode } = useMode();
    const api: IApi = useAxiosApi();
    const [activeStep, setActiveStep] = useState(0);
    const { templateName } = useParams();
    const { cv, dispatch } = useCV();
    const [isLoading, setIsLoading] = React.useState(false);
    const queryClient = useQueryClient();
    const [error, setError] = useState<string>('');
    const [profession] = useState<IProfession>(cv?.profession || { id: 0, name: '' });

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const scrollRef = useRef<HTMLDivElement>(null);

    const scroll = (direction: 'left' | 'right') => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({
                left: direction === 'left' ? -100 : 100,
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        if (!cv) {
            dispatch({ type: 'SET_CV', cv: {} as ICV });
        }
    }, []);

    const defaultTheme: ITheme = useMemo(() => {
        const currentTemplate: TemplateName = Object.values(TemplateName).includes(templateName as TemplateName)
            ? (templateName as TemplateName)
            : TemplateName.DAMASCUS;

        return defaultThemeTemplateName[currentTemplate]();
    }, []);

    const { mutate: createNewCV } = useMutation({
        mutationFn: (cvDto: ICVCreationDto) => createCV(api, cvDto),
        onSuccess: (newCV: ICV | null) => {
            if (newCV) {
                dispatch({ type: 'SET_CV', cv: newCV });
                queryClient.invalidateQueries({ queryKey: ['cvs'] }).then();
                setActiveStep((prev: number) => prev + 1);
                setError('');
            }
            setIsLoading(false);
        },
        onError: (error) => {
            console.error(error);
            setIsLoading(false);
        },
    });

    const createCv = async () => {
        if (!cv) return;

        setIsLoading(true);
        let professionId: number = cv.profession.id;
        if (!professionId) {
            const professionDto: IProfessionDto = { name: profession.name.trimEnd() };
            const newProfession: IProfession | null = await getOrCreateProfession(api, professionDto);
            if (newProfession) {
                professionId = newProfession.id;
            }
        }

        const cvDto: ICVCreationDto = {
            firstName: cv.firstName,
            lastName: cv.lastName,
            email: cv.email,
            profession: { id: professionId },
        };
        createNewCV(cvDto);
    };

    const handleNext = async () => {
        if (!cv?.id) {
            if (cv?.email && cv?.firstName && cv?.lastName && cv.profession.id) {
                createCv().then();
            } else {
                setError(TranslationKey.error.requiredField);
            }
        } else {
            setActiveStep((prev: number) => prev + 1);
        }
    };

    const handlePreview = () => {
        if (!!cv?.uuid) {
            setMode(Mode.PRINT);
            navigate(getLocalizedPath(`/cv/${cv.uuid}/template/${templateName}`));
        }
    };

    const handleBack = () => setActiveStep((prev) => prev - 1);

    return (
        <Container maxWidth="md">
            <Box sx={{ my: 4 }}>
                <Grid2 size={12}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        {t(TranslationKey.creationPage.header)}
                    </Typography>
                </Grid2>
                <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', width: '100%' }}>
                    {isMobile && (
                        <IconButton onClick={() => scroll('left')} sx={{ position: 'absolute', left: -10, zIndex: 2 }}>
                            <ArrowBackIos />
                        </IconButton>
                    )}

                    <Box ref={scrollRef} sx={{ overflowX: isMobile ? 'auto' : 'visible', width: '100%', pb: 1, display: 'flex', mx: 4 }}>
                        <Stepper
                            activeStep={activeStep}
                            alternativeLabel={!isMobile}
                            sx={{ minWidth: isMobile ? 'max-content' : '100%', mb: 1 }}
                        >
                            {steps.map((step, index) => {
                                const isCompleted = index < activeStep;
                                const isActive = index === activeStep;

                                return (
                                    <Step key={index} completed={isCompleted}>
                                        <StepLabel
                                            icon={
                                                <Tooltip title={t(step.label)}>
                                                    {isCompleted ? (
                                                        <CheckCircle sx={{ color: theme.palette.primary.main }} />
                                                    ) : (
                                                        React.cloneElement(step.icon, {
                                                            sx: { color: isActive ? theme.palette.error.main : 'gray' }, // Active = primary, else gray
                                                        })
                                                    )}
                                                </Tooltip>
                                            }
                                            onClick={!!cv?.uuid ? () => setActiveStep(index) : undefined}
                                        />
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Box>

                    {isMobile && (
                        <IconButton onClick={() => scroll('right')} sx={{ position: 'absolute', right: -10, zIndex: 2 }}>
                            <ArrowForwardIos />
                        </IconButton>
                    )}
                </Box>

                <Box>
                    <Box height={30} alignItems={'center'}>
                        <Typography variant={'caption'} color={'error'}>
                            {t(error)}
                        </Typography>
                    </Box>

                    {activeStep === 0 && <PersonalInfoForm setError={setError} />}
                    {!!cv?.id && activeStep === 1 && <ImageForm theme={defaultTheme.imageTheme} />}
                    {!!cv?.id && activeStep === 2 && <AddressForm cvId={cv.id} address={cv.address} theme={defaultTheme.contactTheme} />}
                    {!!cv?.id && activeStep === 3 && <ProfileForm cvId={cv.id} profile={cv.profile} theme={defaultTheme.contactTheme} />}
                    {!!cv?.id && activeStep === 4 && (
                        <SkillsForm cvId={cv.id} skillProficiencies={cv.skillProficiencies || []} theme={defaultTheme.skillTheme} />
                    )}
                    {!!cv?.id && activeStep === 5 && (
                        <LanguagesForm
                            cvId={cv.id}
                            languageProficiencies={cv.languageProficiencies || []}
                            theme={defaultTheme.languageTheme}
                        />
                    )}
                    {!!cv?.id && activeStep === 6 && (
                        <ExperiencesForm cvId={cv.id} experiences={cv.experiences || []} theme={defaultTheme.experienceTheme} />
                    )}
                    {!!cv?.id && activeStep === 7 && (
                        <EducationsForm
                            cvId={cv.id}
                            certifications={cv.certifications || []}
                            educations={cv.educations || []}
                            theme={defaultTheme.educationTheme}
                        />
                    )}
                    {!!cv?.id && activeStep === 8 && (
                        <ReferencesForm cvId={cv.id} references={cv.references || []} theme={defaultTheme.referenceTheme} />
                    )}
                    {!!cv?.id && activeStep === 9 && (
                        <SocialLinksForm cvId={cv.id} socialLinks={cv.socialLinks || []} theme={defaultTheme.socialLinkTheme} />
                    )}
                    {!!cv?.id && activeStep === 10 && (
                        <InterestsForm cvId={cv?.id} interests={cv.interests} theme={defaultTheme.interestsTheme} />
                    )}
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                    <Button disabled={activeStep === 0} onClick={handleBack} variant="contained">
                        {t(TranslationKey.buttons.back)}
                    </Button>
                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <Button onClick={handleNext} variant="contained" color="primary">
                            {isLoading ? <CircularProgress /> : t(TranslationKey.buttons.next)}
                        </Button>
                        {!!cv?.uuid && (
                            <Button onClick={handlePreview} variant="outlined" color="primary">
                                {t(TranslationKey.buttons.preview)}
                            </Button>
                        )}
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export default CVBuilder;
