import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, List, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import { PrintableTextField } from './GeneralTextField';
import { useMode } from '../context/Mode.context';
import { Mode } from '../interfaces/Mode.interface';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../interfaces/TranslationKey';
import { IFontTheme } from '../interfaces/Theme.interface';
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import EditButton from './EditButton';
import CloseIcon from '@mui/icons-material/Close';

export interface ISearchFieldError {
    type: null | 'REQUIRED' | 'INVALID' | 'DUPLICATE';
    message: string;
}

interface SearchableTextFieldProps<T> {
    label: string;
    value: T;
    setValue: (value: T) => void;
    fetchResults: (query?: string) => Promise<T[]>;
    getDisplayValue: (value: T) => string;
    error: ISearchFieldError;
    setError: (error: ISearchFieldError) => void;
    onDoneClicked?: (value: string) => Promise<T | null>;
    onSelect?: (value: T) => T | null;
    textFieldMargin?: string;
    fontTheme: IFontTheme;
    typographyComponent?: React.ElementType;
    alwaysEditable?: boolean;
}

const EditableSearchField = <T,>(props: SearchableTextFieldProps<T>): React.ReactElement => {
    const { t } = useTranslation();
    const [query, setQuery] = useState(props.getDisplayValue(props.value));
    const [isEditing, setIsEditing] = useState(false);
    const [results, setResults] = useState<T[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [tempValue, setTempValue] = useState<T>(props.value);
    const [isFocused, setIsFocused] = useState(false);

    const handleClick = () => {
        setTempValue(props.value);
        setQuery(props.getDisplayValue(props.value));
        setIsEditing(true);
    };

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsEditing(true);
        const value = e.target.value;
        setQuery(value);
        if (!value) {
            props.setError({ type: 'REQUIRED', message: TranslationKey.error.requiredField });
        } else {
            props.setError({ type: null, message: '' });
        }
    };

    const handleDoneClick = async () => {
        if (query.length < 2) {
            props.setError({ type: 'REQUIRED', message: TranslationKey.error.requiredField });
            return;
        }

        if (query === props.getDisplayValue(props.value)) {
            handleCancel();
            return;
        }

        if (props.onDoneClicked) {
            const newObject: T | null = await props.onDoneClicked(query);
            if (!!newObject) {
                props.setValue(newObject);
                props.setError({ type: null, message: '' });
                setIsEditing(false);
            } else {
                setIsEditing(true);
            }
        }
    };

    const handleSelect = (value: T) => {
        props.setValue(value);
        setQuery(props.getDisplayValue(value));
        setIsEditing(false);
        setResults([]);
        if (props.onSelect) {
            const result: T | null = props.onSelect(value);
            if (!result) {
                setIsEditing(true);
            }
        }
    };

    const handleCancel = () => {
        setQuery(props.getDisplayValue(tempValue));
        setIsEditing(false);
        props.setError({ type: null, message: '' });
    };

    const onFocus = () => {
        setIsFocused(true);
        setIsLoading(true);
        props
            .fetchResults(query)
            .then(setResults)
            .catch(() => setResults([]))
            .finally(() => setIsLoading(false));
    };

    const onBlur = () => {
        setIsFocused(false);
        setResults([]);
    };

    useEffect(() => {
        if (!isFocused) return;

        setIsLoading(true);
        props
            .fetchResults(query)
            .then(setResults)
            .catch(() => setResults([]))
            .finally(() => setIsLoading(false));
    }, [query]);

    return (
        <Box display="flex" alignItems="center" gap={1} sx={{ position: 'relative' }}>
            {isEditing || props.alwaysEditable ? (
                <>
                    <Box sx={{ flex: 1 }}>
                        <TextField
                            value={query}
                            label={t(props.label)}
                            onChange={handleChange}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            autoFocus={isEditing}
                            fullWidth
                            variant="outlined"
                            required={true}
                            error={!!props.error.type}
                            helperText={t(props.error.message)}
                            sx={{ flex: 1, margin: '8px 0' }}
                        />
                        {isLoading && <CircularProgress size={24} sx={{ position: 'absolute', right: 16, top: 16 }} />}
                        {results.length > 0 && (
                            <List
                                sx={{
                                    position: 'absolute',
                                    top: '100%',
                                    left: 0,
                                    right: 0,
                                    maxHeight: 200,
                                    overflowY: 'auto',
                                    m: 0,
                                    p: 0,
                                    zIndex: 100,
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    borderRadius: '4px',
                                    color: '#fff',
                                    backgroundColor: '#555',
                                }}
                                onMouseDown={(e) => e.preventDefault()}
                            >
                                {results.map((result: T, index: number) => (
                                    <ListItem
                                        key={props.label + index + props.getDisplayValue(result)}
                                        onClick={() => handleSelect(result)}
                                        sx={{ cursor: 'pointer', bgcolor: '#333', mb: '2px' }}
                                    >
                                        <ListItemText primary={props.getDisplayValue(result)} />
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </Box>
                    {!props.alwaysEditable && (
                        <>
                            <IconButton onClick={handleDoneClick} color="primary">
                                <DoneIcon />
                            </IconButton>
                            <IconButton onClick={handleCancel} color="secondary">
                                <CloseIcon />
                            </IconButton>
                        </>
                    )}
                </>
            ) : (
                <>
                    <Typography
                        variant={'body1'}
                        component={'p'}
                        fontStyle={query ? undefined : 'italic'}
                        sx={{
                            cursor: 'pointer',
                            flex: 1,
                            margin: '8px 0',
                            padding: '6px 8px',
                            borderRadius: '4px',
                            '&:hover': { backgroundColor: '#f5f5f5' },
                        }}
                        onClick={handleClick}
                    >
                        {query || <span style={{ color: 'gray' }}>{t(TranslationKey.info.clickToEdit)}</span>}
                    </Typography>
                    <EditButton label={t(props.label)} onClick={handleClick} />
                </>
            )}
        </Box>
    );
};

const SearchField = <T,>(props: SearchableTextFieldProps<T>): React.ReactElement => {
    const { mode } = useMode();

    switch (mode) {
        case Mode.EDIT:
            return <EditableSearchField {...props} />;
        case Mode.PRINT:
            return (
                <PrintableTextField
                    value={props.getDisplayValue(props.value)}
                    fontTheme={props.fontTheme}
                    typographyComponent={props.typographyComponent}
                />
            );
    }
};

export { SearchField, EditableSearchField };
