import React from 'react';
import { Box } from '@mui/material';
import { ILanguageProficiency, LanguageProficiencyToValue } from '../../interfaces/LanguageProficiency.interface';
import { ILanguageTheme } from '../../interfaces/Theme.interface';
import { useMode } from '../../context/Mode.context';
import { Mode } from '../../interfaces/Mode.interface';
import LanguagesForm from '../builder/forms/LanguagesForm';
import { PrintableTextField } from '../GeneralTextField';
import { PrintableProficiency } from '../proficiency/Proficiency';

interface LanguageProps {
    cvId: number;
    languageProficiencies: ILanguageProficiency[];
    theme: ILanguageTheme;
    inline: boolean;
}

const Languages: React.FC<LanguageProps> = (props: LanguageProps) => {
    const { mode } = useMode();

    const isEditing: boolean = mode === Mode.EDIT;

    const eliminateMargin: boolean = props.theme.proficiency.type === 'none';
    const displayInline: boolean = props.inline && mode === Mode.PRINT;

    return (
        <Box id="language" sx={{ mb: `${props.theme.margin.bottom}px` }}>
            {isEditing ? (
                <LanguagesForm {...props} />
            ) : (
                props.languageProficiencies.map((languageProficiency: ILanguageProficiency) => (
                    <Box
                        key={'printable-languageProficiencies-' + languageProficiency.id}
                        sx={{ marginBottom: eliminateMargin || displayInline ? 0 : '10px' }}
                        display={displayInline ? 'flex' : 'inherit'}
                        justifyContent={'space-between'}
                    >
                        <PrintableTextField
                            value={languageProficiency.language.name}
                            fontTheme={props.theme.font}
                            typographyComponent={'p'}
                        />
                        <Box
                            display={displayInline ? 'flex' : 'inherit'}
                            width={displayInline ? '50%' : '100%'}
                            justifyContent={'flex-end'}
                            alignItems={'center'}
                        >
                            <PrintableProficiency
                                theme={props.theme.proficiency}
                                value={languageProficiency.proficiency}
                                proficiencyToValue={LanguageProficiencyToValue}
                                labelKey={'Languages'}
                            />
                        </Box>
                    </Box>
                ))
            )}
        </Box>
    );
};

export default Languages;
