import React from 'react';
import { Box, Typography } from '@mui/material';
import { ISocialLinkTheme } from '../../interfaces/Theme.interface';
import { useMode } from '../../context/Mode.context';
import { Mode } from '../../interfaces/Mode.interface';
import { ISocialLink } from '../../interfaces/SocialLink.interface';
import SocialLinksForm from '../builder/forms/SocialLinksForm';

interface SocialLinksProps {
    cvId: number;
    socialLinks: ISocialLink[];
    theme: ISocialLinkTheme;
}

const SocialLinks = (props: SocialLinksProps): React.ReactElement => {
    const { mode } = useMode();

    const isEditing: boolean = mode === Mode.EDIT;

    return (
        <Box id="socialLink" sx={{ mb: `${props.theme.margin.bottom}px` }}>
            {isEditing ? (
                <SocialLinksForm {...props} />
            ) : (
                props.socialLinks.map((socialLink: ISocialLink) => (
                    <Box key={'printable-socialLinks-' + socialLink.id}>
                        <Typography
                            variant={props.theme.header.variant ?? 'body1'}
                            color={props.theme.header.color}
                            component={'a'}
                            fontWeight={props.theme.header.weight}
                            fontStyle={props.theme.header.style}
                            sx={{ padding: '0px', color: `${props.theme.header.color} !important` }}
                        >
                            {socialLink.url}
                        </Typography>
                    </Box>
                ))
            )}
        </Box>
    );
};

export default SocialLinks;
