import React, { useState } from 'react';
import { Grid2, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ListIcon from '@mui/icons-material/List';
import { useTranslation } from 'react-i18next';
import { EditableTextField } from '../../../GeneralTextField';
import { IExperienceItem, IExperienceItemCreationDto, IExperienceItemUpdateDto } from '../../../../interfaces/Experience.interface';
import { IFontTheme } from '../../../../interfaces/Theme.interface';
import { useAxiosApi } from '../../../../hooks/useAxiosApi';
import { IApi } from '../../../../services/api.service';
import { createExperienceItem, removeExperienceItem, updateExperienceItem } from '../../../../services/experience.service';
import { TranslationKey } from '../../../../interfaces/TranslationKey';
import { iconClickableButton, IconType } from '../../../../common/style';

interface ExperienceItemProps {
    experienceId: number;
    experienceItem: IExperienceItem;
    items: IExperienceItem[];
    setItems: (items: IExperienceItem[]) => void;
    iconColor?: string;
    theme: IFontTheme;
}

const ExperienceItemForm = (props: ExperienceItemProps): React.ReactElement => {
    const { t } = useTranslation();
    const api: IApi = useAxiosApi();
    const [experienceItem, setExperienceItem] = useState(props.experienceItem);
    const [description, setDescription] = useState(experienceItem.description);

    const onDoneClickedDescription = (value: string) => {
        if (!value || value === props.experienceItem.description) return;

        setDescription(value);

        const dto: IExperienceItemUpdateDto = {
            description: value,
        };

        const updateState = (_item: IExperienceItem) => {
            setExperienceItem(_item);
            const updatedList = props.items.map((item: IExperienceItem) => (item.id === experienceItem.id ? _item : item));
            props.setItems(updatedList);
        };

        if (!experienceItem.id) {
            const creationDto: IExperienceItemCreationDto = {
                ...dto,
                experience: { id: props.experienceId },
            } as IExperienceItemCreationDto;

            createExperienceItem(api, creationDto).then((newItem: IExperienceItem | null) => {
                if (newItem) {
                    updateState(newItem);
                }
            });
        } else {
            updateExperienceItem(api, experienceItem.id, dto).then((updatedItem: IExperienceItem | null) => {
                if (updatedItem) {
                    updateState(updatedItem);
                }
            });
        }
    };

    const handleRemoveItem = (id: number) => {
        if (id) {
            removeExperienceItem(api, id).then();
        }
        const filteredItems: IExperienceItem[] = props.items.filter((item: IExperienceItem) => item.id !== id);
        props.setItems(filteredItems);
    };

    return (
        <Grid2 container>
            <Grid2 display={'flex'} size="auto" alignContent="center" alignItems={'center'}>
                <Tooltip title={t(TranslationKey.tooltip.reorderItems)}>
                    <ListIcon sx={iconClickableButton(IconType.SECONDARY)} />
                </Tooltip>
                <DeleteIcon onClick={() => handleRemoveItem(experienceItem.id)} sx={iconClickableButton(IconType.DANGER)} />
            </Grid2>
            <Grid2 size="grow">
                <EditableTextField
                    value={description}
                    setValue={setDescription}
                    label={TranslationKey.experience.item._name}
                    minLength={5}
                    required={true}
                    onDoneClicked={onDoneClickedDescription}
                    fontTheme={props.theme}
                    onlyEditIcon={true}
                />
            </Grid2>
        </Grid2>
    );
};

export default ExperienceItemForm;
