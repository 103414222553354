import { ISkillProficiency, SkillProficiencyLevel } from '../../../interfaces/SkillProficiency.interface';
import { ISkillTheme } from '../../../interfaces/Theme.interface';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { IApi } from '../../../services/api.service';
import { useAxiosApi } from '../../../hooks/useAxiosApi';
import { useCV } from '../../../context/CV.context';
import { reorderItems } from '../../../services/common.services';
import { ApiEndpoints } from '../../../services/endpoints';
import { removeSkillProficiency } from '../../../services/skill.service';
import OrderableList from '../../OrderableList';
import { IOrderableEntity } from '../../../interfaces/Common.interface';
import AddSectionButton from '../../AddSectionButton';
import SkillProficiencyForm from './skill/SkillProficiencyForm';

interface SkillProps {
    cvId: number;
    skillProficiencies: ISkillProficiency[];
    theme: ISkillTheme;
}

const SkillsForm = (props: SkillProps): React.ReactElement => {
    const api: IApi = useAxiosApi();
    const { dispatch } = useCV();
    const [skillProficiencies, setSkillProficiencies] = React.useState<ISkillProficiency[]>(props.skillProficiencies);

    useEffect(() => {
        dispatch({ type: 'UPDATE_FIELD', field: 'skillProficiencies', value: skillProficiencies });
    }, [skillProficiencies]);

    const skillAddCallback = () => {
        if (skillProficiencies.map((skillProficiency: ISkillProficiency) => skillProficiency.skill.name).includes('')) {
            return;
        }
        const newSkill: ISkillProficiency = {
            id: 0,
            skill: { id: 0, name: '' },
            proficiency: SkillProficiencyLevel.BEGINNER,
            weight: 100,
        };
        setSkillProficiencies([...skillProficiencies, newSkill]);
    };

    const _reorderItems = async (movedItemId: number, newIndex: number) => {
        if (movedItemId) {
            reorderItems(ApiEndpoints.skillProficiencyIdReorder, api, movedItemId, newIndex).then();
        }
    };

    const _removeSkillProficiency = (id: number) => {
        if (id) {
            removeSkillProficiency(api, id).then();
        }

        const filteredSkills: ISkillProficiency[] = skillProficiencies.filter((s: ISkillProficiency) => s.id !== id);
        setSkillProficiencies(filteredSkills);
    };

    return (
        <>
            <OrderableList
                labelKey={'skillProficiency-'}
                items={skillProficiencies}
                setItems={setSkillProficiencies as Dispatch<SetStateAction<IOrderableEntity[]>>}
                onMoveEnd={_reorderItems}
                onRemoveCallback={_removeSkillProficiency}
                child={(item) => (
                    <SkillProficiencyForm
                        cvId={props.cvId}
                        skillProficiency={item as ISkillProficiency}
                        skillProficiencies={skillProficiencies}
                        setSkillProficiencies={setSkillProficiencies}
                        theme={props.theme}
                    />
                )}
            ></OrderableList>
            <AddSectionButton onClick={skillAddCallback} disabled={skillProficiencies.some((sp) => !sp.id)} />
        </>
    );
};

export default SkillsForm;
