import React from 'react';
import { ICV } from '../../interfaces/CV.interface';
import { ILayout, ITheme, SectionName } from '../../interfaces/Theme.interface';
import { getPDFStyles } from '../../utils/theme.util';
import { Document, Page, View } from '@react-pdf/renderer/lib/react-pdf';
import { IPDFStyle } from '../../interfaces/pdfStyle.interface';
import { useTranslation } from 'react-i18next';
import { TemplateName } from '../../common/enum';
import { getSections } from '../Template.util';
import { getPDFSectionElements, IPDFSectionElementsProps } from '../PDFTemplate.util';

interface PDFProps {
    cv: ICV;
    imageSrc: string | null;
    theme: ITheme;
}

const DohaTemplatePDF = (props: PDFProps): React.ReactElement => {
    const { i18n } = useTranslation();

    const isRTL: boolean = i18n.dir() === 'rtl';

    const styles: IPDFStyle = getPDFStyles(props.theme, isRTL);

    const templateName: TemplateName = TemplateName.DOHA;

    const layout: ILayout = getSections(props.theme.layoutTheme, templateName);

    const showTopSection: boolean = layout.top.sections.some((i) => !!i);
    const showTopRightSection: boolean = layout.topRight.sections.some((i) => !!i);
    const showTopLeftRightSection: boolean = showTopRightSection || layout.topLeft.sections.some((i) => !!i);

    const showMiddleSection: boolean = layout.middle.sections.some((i) => !!i);
    const showMiddleLeftRightSection: boolean = layout.middleLeft.sections.some((i) => !!i) || layout.middleRight.sections.some((i) => !!i);

    const showBottomSection: boolean = layout.bottom.sections.some((i) => !!i);
    const showBottomLeftRightSection: boolean = layout.bottomLeft.sections.some((i) => !!i) || layout.bottomRight.sections.some((i) => !!i);

    const getProps = (sections: SectionName[]): IPDFSectionElementsProps => ({
        templateName: templateName,
        sections: sections,
        pdfProps: props,
        styles: styles,
        extraSectionStyle: { flexDirection: isRTL ? 'row-reverse' : 'row', gap: '20px' },
        sectionHeaderWidth: 25,
    });

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* middle */}
                {showTopSection && getPDFSectionElements(getProps(layout.top.sections))}

                {/* top left/right */}
                {showTopLeftRightSection && (
                    <View style={styles.rowStyle}>
                        <View style={{ width: showTopRightSection ? styles.leftTopView.width : '100%' }}>
                            {getPDFSectionElements(getProps(layout.topLeft.sections))}
                        </View>
                        {showTopRightSection && (
                            <View style={styles.rightTopView}>{getPDFSectionElements(getProps(layout.topRight.sections))}</View>
                        )}
                    </View>
                )}

                {/* middle */}
                {showMiddleSection && getPDFSectionElements(getProps(layout.middle.sections))}

                {/* middle left/right */}
                {showMiddleLeftRightSection && (
                    <View style={{ ...styles.rowStyle, gap: '42px' }}>
                        <View style={styles.leftMiddleView}>
                            {getPDFSectionElements({
                                ...getProps(layout.middleLeft.sections),
                                inlineSkills: true,
                                inlineLanguages: true,
                                extraSectionStyle: undefined,
                                sectionHeaderWidth: undefined,
                            })}
                        </View>
                        <View style={styles.rightMiddleView}>
                            {getPDFSectionElements({
                                ...getProps(layout.middleRight.sections),
                                inlineSkills: true,
                                inlineLanguages: true,
                                extraSectionStyle: undefined,
                                sectionHeaderWidth: undefined,
                            })}
                        </View>
                    </View>
                )}

                {/* bottom */}
                {showBottomSection && getPDFSectionElements(getProps(layout.bottom.sections))}

                {/* bottom left/right */}
                {showBottomLeftRightSection && (
                    <View style={{ ...styles.rowStyle, gap: '42px' }}>
                        <View style={styles.leftBottomView}>
                            {getPDFSectionElements({
                                ...getProps(layout.bottomLeft.sections),
                                inlineSkills: true,
                                inlineLanguages: true,
                                extraSectionStyle: undefined,
                                sectionHeaderWidth: undefined,
                            })}
                        </View>
                        <View style={styles.rightBottomView}>
                            {getPDFSectionElements({
                                ...getProps(layout.bottomRight.sections),
                                inlineSkills: true,
                                inlineLanguages: true,
                                extraSectionStyle: undefined,
                                sectionHeaderWidth: undefined,
                            })}
                        </View>
                    </View>
                )}
            </Page>
        </Document>
    );
};

export default DohaTemplatePDF;
