import { ApiEndpoints } from './endpoints';
import { ICV, ICVCreationDto, ICVUpdateDto } from '../interfaces/CV.interface';
import { GenericAbortSignal } from 'axios';
import { IApi } from './api.service';

export const getAllCVs = async (api: IApi, genericAbortSignal?: { signal: GenericAbortSignal }): Promise<ICV[]> => {
    const cvs: ICV[] | null = await api.get<ICV[]>(ApiEndpoints.cv, { signal: genericAbortSignal?.signal });
    return cvs ?? [];
};

export const createCV = async (api: IApi, cvDto: ICVCreationDto): Promise<ICV | null> => {
    return api.post<ICV, ICVCreationDto>(ApiEndpoints.cv, cvDto);
};

export const getCV = async (api: IApi, uuid: string, { signal }: { signal: GenericAbortSignal }): Promise<ICV | null> => {
    return api.get<ICV>(ApiEndpoints.cvId.replace(':id', uuid), { signal: signal });
};

export const updateCV = async (api: IApi, id: number, cvDto: ICVUpdateDto): Promise<ICV | null> => {
    return api.put<ICV, ICVUpdateDto>(ApiEndpoints.cvId.replace(':id', String(id)), cvDto);
};

export const removeCV = async (api: IApi, uuid: string): Promise<void> => {
    await api.delete(ApiEndpoints.cvId.replace(':id', uuid));
};

export const uploadImage = async (api: IApi, id: number, formData: FormData): Promise<ICV | null> => {
    return await api.put<ICV, FormData>(ApiEndpoints.cvIdImage.replace(':id', String(id)), formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
    });
};

export const getImage = async (api: IApi, id: number): Promise<string | null> => {
    const blob = await api.getBlob(ApiEndpoints.cvIdImage.replace(':id', String(id)));
    if (!blob) return null;

    return URL.createObjectURL(blob as Blob);
};

export const removeImage = async (api: IApi, id: number): Promise<void> => {
    await api.delete(ApiEndpoints.cvIdImage.replace(':id', String(id)));
};

export const updateProfileViaAI = async (api: IApi, id: number): Promise<ICV | null> => {
    return api.put<ICV, object>(ApiEndpoints.cvIdProfileIdAI.replace(':id', String(id)), {});
};
