import React from 'react';
import { Grid2 } from '@mui/material';
import { useMode } from '../../context/Mode.context';
import { Mode } from '../../interfaces/Mode.interface';
import { ITemplateProps } from '../../interfaces/Template.interface';
import { ILayout, SectionName } from '../../interfaces/Theme.interface';
import { TemplateName } from '../../common/enum';
import { getSectionElements, getSections, ISectionElementsProps } from '../Template.util';

const MadinaTemplate = (props: ITemplateProps): React.ReactElement => {
    const { mode } = useMode();
    const isEditing: boolean = mode === Mode.EDIT;

    const templateName: TemplateName = TemplateName.MADINA;

    const layout: ILayout = getSections(props.theme.layoutTheme, templateName);

    const showTopSection: boolean = layout.top.sections.some((i) => !!i);
    const showTopRightSection: boolean = layout.topRight.sections.some((i) => !!i);
    const showTopLeftRightSection: boolean = showTopRightSection || layout.topLeft.sections.some((i) => !!i);

    const showMiddleSection: boolean = layout.middle.sections.some((i) => !!i);
    const showMiddleLeftRightSection: boolean = layout.middleLeft.sections.some((i) => !!i) || layout.middleRight.sections.some((i) => !!i);

    const showBottomSection: boolean = layout.bottom.sections.some((i) => !!i);
    const showBottomLeftRightSection: boolean = layout.bottomLeft.sections.some((i) => !!i) || layout.bottomRight.sections.some((i) => !!i);

    const getProps = (sections: SectionName[]): ISectionElementsProps => ({
        templateName: templateName,
        sections: sections,
        templateProps: props,
        isEditing: isEditing,
        inlineSkills: true,
        inlineLanguages: true,
    });

    return (
        <>
            {/* top */}
            {showTopSection && getSectionElements(getProps(layout.top.sections))}

            {/* top left/right */}
            {showTopLeftRightSection && (
                <Grid2 container size={12} spacing={1} mb={'20px'}>
                    <Grid2 size={isEditing ? 12 : showTopRightSection ? props.theme.layoutTheme.sectionWidth.topLeftWidth : 12}>
                        {getSectionElements(getProps(layout.topLeft.sections))}
                    </Grid2>
                    <Grid2 size={isEditing ? 12 : 12 - props.theme.layoutTheme.sectionWidth.topLeftWidth} container justifyContent={'end'}>
                        {getSectionElements(getProps(layout.topRight.sections))}
                    </Grid2>
                </Grid2>
            )}

            {/* middle */}
            {showMiddleSection && getSectionElements(getProps(layout.middle.sections))}

            {/* middle left/right */}
            {showMiddleLeftRightSection && (
                <Grid2 container size={12} columnSpacing={'68px'}>
                    <Grid2 size={isEditing ? 12 : showTopRightSection ? props.theme.layoutTheme.sectionWidth.middleLeftWidth : 12}>
                        {getSectionElements(getProps(layout.middleLeft.sections))}
                    </Grid2>
                    <Grid2 size={isEditing ? 12 : 12 - props.theme.layoutTheme.sectionWidth.middleLeftWidth}>
                        {getSectionElements(getProps(layout.middleRight.sections))}
                    </Grid2>
                </Grid2>
            )}

            {/* bottom */}
            {showBottomSection && getSectionElements(getProps(layout.bottom.sections))}

            {/* bottom left/right */}
            {showBottomLeftRightSection && (
                <Grid2 container size={12} columnSpacing={'68px'}>
                    <Grid2 size={isEditing ? 12 : showTopRightSection ? props.theme.layoutTheme.sectionWidth.bottomLeftWidth : 12}>
                        {getSectionElements(getProps(layout.bottomLeft.sections))}
                    </Grid2>
                    <Grid2 size={isEditing ? 12 : 12 - props.theme.layoutTheme.sectionWidth.bottomLeftWidth}>
                        {getSectionElements(getProps(layout.bottomRight.sections))}
                    </Grid2>
                </Grid2>
            )}
        </>
    );
};

export default MadinaTemplate;
