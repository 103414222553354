import { IAddress, IAddressCreationDto, IAddressUpdateDto } from '../../../interfaces/Address.interface';
import { IContactTheme } from '../../../interfaces/Theme.interface';
import React from 'react';
import { IApi } from '../../../services/api.service';
import { useAxiosApi } from '../../../hooks/useAxiosApi';
import { useCV } from '../../../context/CV.context';
import { createAddress, updateAddress } from '../../../services/address.service';
import { Grid2 } from '@mui/material';
import { EditableTextField } from '../../GeneralTextField';
import { TranslationKey } from '../../../interfaces/TranslationKey';

interface AddressProps {
    cvId: number;
    address: IAddress | null;
    theme: IContactTheme;
}

const AddressForm = (props: AddressProps): React.ReactElement => {
    const api: IApi = useAxiosApi();
    const { cv, dispatch } = useCV();
    const [street, setStreet] = React.useState<string | null>(cv?.address?.street ?? null);
    const [postalCode, setPostalCode] = React.useState<string | null>(cv?.address?.postalCode ?? null);
    const [city, setCity] = React.useState<string | null>(cv?.address?.city ?? null);
    const [state, setState] = React.useState<string | null>(cv?.address?.state ?? null);
    const [country, setCountry] = React.useState<string | null>(cv?.address?.country ?? null);

    const onDoneClicked = async (dto: IAddressUpdateDto) => {
        const ready: boolean = !!dto.country || !!dto.city || !!dto.state || !!dto.postalCode || !!dto.street;
        if (!ready) return;

        let updatedAddress: IAddress | null;

        if (!cv?.address?.id) {
            const creationDto: IAddressCreationDto = { ...dto, cv: { id: props.cvId } };
            updatedAddress = await createAddress(api, creationDto);
        } else {
            updatedAddress = await updateAddress(api, cv?.address.id, dto);
        }

        if (updatedAddress) {
            dispatch({ type: 'UPDATE_FIELD', field: 'address', value: updatedAddress });
        }
    };

    const onDoneClickedStreet = (value: string) => {
        const cleanValue = value === '' ? null : value;
        if (cleanValue === cv?.address?.street) return;

        const dto: IAddressUpdateDto = {
            country: country,
            city: city,
            state: state,
            postalCode: postalCode,
            street: cleanValue,
        };

        onDoneClicked(dto).then();
        setStreet(cleanValue);
    };

    const onDoneClickedState = (value: string) => {
        const cleanValue = value === '' ? null : value;
        if (cleanValue === cv?.address?.state) return;

        const dto: IAddressUpdateDto = {
            country: country,
            city: city,
            state: cleanValue,
            postalCode: postalCode,
            street: street,
        };

        onDoneClicked(dto).then();
        setState(cleanValue);
    };

    const onDoneClickedPostalCode = (value: string) => {
        const cleanValue = value === '' ? null : value;
        if (cleanValue === cv?.address?.postalCode) return;

        const dto: IAddressUpdateDto = {
            country: country,
            city: city,
            state: state,
            postalCode: cleanValue,
            street: street,
        };

        onDoneClicked(dto).then();
        setPostalCode(cleanValue);
    };

    const onDoneClickedCity = (value: string) => {
        if (!value || value === cv?.address?.city) return;

        const dto: IAddressUpdateDto = {
            country: country,
            city: value,
            state: state,
            postalCode: postalCode,
            street: street,
        };

        onDoneClicked(dto).then();
        setCity(value);
    };

    const onDoneClickedCountry = (value: string) => {
        if (!value || value === cv?.address?.country) return;

        const dto: IAddressUpdateDto = {
            country: value,
            city: city,
            state: state,
            postalCode: postalCode,
            street: street,
        };

        onDoneClicked(dto).then();
        setCountry(value);
    };

    return (
        <Grid2 container>
            <Grid2 size={12}>
                <EditableTextField
                    value={street ?? ''}
                    setValue={setStreet}
                    label={TranslationKey.address.street}
                    required={false}
                    onDoneClicked={onDoneClickedStreet}
                    fontTheme={props.theme.font}
                />
            </Grid2>
            <Grid2 size={12}>
                <EditableTextField
                    value={postalCode ?? ''}
                    setValue={setPostalCode}
                    label={TranslationKey.address.postalCode}
                    required={false}
                    onDoneClicked={onDoneClickedPostalCode}
                    fontTheme={props.theme.font}
                />
            </Grid2>
            <Grid2 size={12}>
                <EditableTextField
                    value={city ?? ''}
                    setValue={setCity}
                    label={TranslationKey.address.city}
                    required={true}
                    minLength={2}
                    onDoneClicked={onDoneClickedCity}
                    fontTheme={props.theme.font}
                />
            </Grid2>
            <Grid2 size={12}>
                <EditableTextField
                    value={state ?? ''}
                    setValue={setState}
                    label={TranslationKey.address.state}
                    required={false}
                    onDoneClicked={onDoneClickedState}
                    fontTheme={props.theme.font}
                />
            </Grid2>
            <Grid2 size={12}>
                <EditableTextField
                    value={country ?? ''}
                    setValue={setCountry}
                    label={TranslationKey.address.country}
                    required={true}
                    minLength={2}
                    onDoneClicked={onDoneClickedCountry}
                    fontTheme={props.theme.font}
                />
            </Grid2>
        </Grid2>
    );
};

export default AddressForm;
