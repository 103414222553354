import React, { useState } from 'react';
import { Box } from '@mui/material';
import { IProfession, IProfessionFilter } from '../../interfaces/Profession.interface';
import { ISearchFieldError, SearchField } from '../SearchField';
import { IProfessionTheme } from '../../interfaces/Theme.interface';
import { TranslationKey } from '../../interfaces/TranslationKey';
import { getAllProfession, getOrCreateProfession } from '../../services/profession.service';
import { IPaginatedListResponse } from '../../interfaces/Pagination.interface';
import { cleanString } from '../../utils/common.util';
import { IApi } from '../../services/api.service';
import { useAxiosApi } from '../../hooks/useAxiosApi';

interface ProfessionProps {
    profession: IProfession;
    setProfession: (profession: IProfession) => void;
    theme: IProfessionTheme;
    alwaysEditable?: boolean;
    createOnBlur?: boolean;
    onCompleted?: (value: IProfession) => void;
    label?: string;
}

const Profession = (props: ProfessionProps): React.ReactElement => {
    const api: IApi = useAxiosApi();
    const [searchFieldError, setSearchFieldError] = useState<ISearchFieldError>({ type: null, message: '' });

    const fetchAllProfessions = async (query?: string): Promise<IProfession[]> => {
        const filter: IProfessionFilter = {
            page: 1,
            size: 50,
        } as IProfessionFilter;
        if (query) {
            filter.name = query;
        }
        const paginatedProfessions: IPaginatedListResponse<IProfession> = await getAllProfession(api, filter);
        return paginatedProfessions.items;
    };

    const displayProfession = (profession: IProfession): string => profession.name;

    const onDoneClicked = async (name: string): Promise<IProfession> => {
        const cleanName: string | undefined = cleanString(name);

        if (props.createOnBlur && !!cleanName && cleanName !== cleanString(props.profession.name)) {
            const profession: IProfession | null = await getOrCreateProfession(api, { name: cleanName });
            if (profession) {
                if (props.onCompleted) {
                    props.onCompleted(profession);
                }

                return profession;
            }
        }

        return new Promise((resolve) => {
            resolve({ name: name, id: 0 });
        });
    };

    const onSelect = (profession: IProfession): IProfession | null => {
        if (props.onCompleted) {
            props.onCompleted(profession);
        }

        return profession;
    };

    return (
        <Box sx={{ marginBottom: `${props.theme.margin.bottom}px` }}>
            <SearchField<IProfession>
                value={props.profession}
                setValue={props.setProfession}
                fetchResults={fetchAllProfessions}
                label={props.label || TranslationKey.profession}
                getDisplayValue={displayProfession}
                onDoneClicked={onDoneClicked}
                onSelect={onSelect}
                fontTheme={props.theme.font}
                typographyComponent={'h3'}
                alwaysEditable={props.alwaysEditable}
                error={searchFieldError}
                setError={setSearchFieldError}
            />
        </Box>
    );
};

export default Profession;
