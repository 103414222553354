import React from 'react';
import { ICV } from '../../interfaces/CV.interface';
import { ILayout, ITheme, SectionName } from '../../interfaces/Theme.interface';
import { getPDFStyles } from '../../utils/theme.util';
import { Document, Page, View } from '@react-pdf/renderer/lib/react-pdf';
import { IPDFStyle } from '../../interfaces/pdfStyle.interface';
import { useTranslation } from 'react-i18next';
import { TemplateName } from '../../common/enum';
import { getSections } from '../Template.util';
import { getPDFSectionElements, IPDFSectionElementsProps } from '../PDFTemplate.util';

interface PDFProps {
    cv: ICV;
    imageSrc: string | null;
    theme: ITheme;
}

const IdlibTemplatePDF = (props: PDFProps): React.ReactElement => {
    const { i18n } = useTranslation();

    const isRTL: boolean = i18n.dir() === 'rtl';

    const styles: IPDFStyle = getPDFStyles(props.theme, isRTL);

    const templateName: TemplateName = TemplateName.IDLIB;

    const layout: ILayout = getSections(props.theme.layoutTheme, templateName);

    const showTopSection: boolean = layout.top.sections.some((i) => !!i);
    const showTopLeftSection: boolean = layout.topLeft.sections.some((i) => !!i);
    const showTopLeftRightSection: boolean = showTopLeftSection || layout.topRight.sections.some((i) => !!i);

    const showMiddleSection: boolean = layout.middle.sections.some((i) => !!i);
    const showMiddleLeftRightSection: boolean = layout.middleLeft.sections.some((i) => !!i) || layout.middleRight.sections.some((i) => !!i);

    const showBottomSection: boolean = layout.bottom.sections.some((i) => !!i);
    const showBottomLeftRightSection: boolean = layout.bottomLeft.sections.some((i) => !!i) || layout.bottomRight.sections.some((i) => !!i);

    const getProps = (sections: SectionName[]): IPDFSectionElementsProps => ({
        templateName: templateName,
        sections: sections,
        styles: styles,
        pdfProps: props,
    });

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* top */}
                {showTopSection && getPDFSectionElements(getProps(layout.top.sections))}

                {/* top left/right */}
                {showTopLeftRightSection && (
                    <View
                        style={{
                            ...styles.rowStyle,
                            justifyContent: 'space-between',
                            backgroundColor: styles.backgroundColor.nameSection,
                            padding: '10px',
                        }}
                    >
                        {showTopLeftSection && (
                            <View style={styles.leftTopView}>{getPDFSectionElements(getProps(layout.topLeft.sections))}</View>
                        )}

                        <View
                            style={{
                                width: showTopLeftSection ? styles.rightTopView.width : '100%',
                                marginLeft: '30px',
                                alignItems: isRTL ? 'flex-end' : 'flex-start',
                            }}
                        >
                            {getPDFSectionElements({ ...getProps(layout.topRight.sections), inlineContact: true })}
                        </View>
                    </View>
                )}

                <View
                    style={{
                        borderBottom: 'solid',
                        borderBottomColor: props.theme.layoutTheme.borderColor ?? '#999',
                        borderBottomWidth: '2px',
                        margin: '0 20px 7px',
                    }}
                ></View>

                {/* middle */}
                {showMiddleSection && getPDFSectionElements(getProps(layout.middle.sections))}

                {/* middle left/right */}
                {showMiddleLeftRightSection && (
                    <View style={{ ...styles.rowStyle }}>
                        <View
                            style={{
                                ...styles.leftMiddleView,
                                backgroundColor: styles.backgroundColor.leftSection,
                                padding: '20px',
                            }}
                        >
                            {getPDFSectionElements(getProps(layout.middleLeft.sections))}
                        </View>
                        <View
                            style={{
                                ...styles.rightMiddleView,
                                padding: '0 20px',
                                borderLeft: 'solid',
                                borderLeftColor: props.theme.layoutTheme.borderColor || '#999',
                                borderLeftWidth: '2px',
                                margin: '20px 0px',
                            }}
                        >
                            {getPDFSectionElements(getProps(layout.middleRight.sections))}
                        </View>
                    </View>
                )}

                {/* bottom */}
                {showBottomSection && getPDFSectionElements(getProps(layout.bottom.sections))}

                {/* bottom left/right */}
                {showBottomLeftRightSection && (
                    <View style={{ ...styles.rowStyle }}>
                        <View
                            style={{
                                ...styles.leftBottomView,
                                backgroundColor: styles.backgroundColor.leftSection,
                                padding: '20px',
                            }}
                        >
                            {getPDFSectionElements(getProps(layout.middleLeft.sections))}
                        </View>
                        <View
                            style={{
                                ...styles.rightBottomView,
                                padding: '0 20px',
                                borderLeft: 'solid',
                                borderLeftColor: props.theme.layoutTheme.borderColor || '#999',
                                borderLeftWidth: '2px',
                                margin: '20px 0px',
                            }}
                        >
                            {getPDFSectionElements(getProps(layout.middleRight.sections))}
                        </View>
                    </View>
                )}
            </Page>
        </Document>
    );
};

export default IdlibTemplatePDF;
