import React, { useEffect, useState } from 'react';
import { Box, Button, Grid2, Paper, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { signup } from '../../services/auth.service';
import { useAuth } from '../../context/Auth.context';
import { ITokenResponse } from '../../interfaces/Auth.interface';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../interfaces/TranslationKey';
import FeedbackModal from '../../components/FeedbackModal';
import { IApi } from '../../services/api.service';
import { useAxiosApi } from '../../hooks/useAxiosApi';
import { Helmet } from 'react-helmet-async';
import { AxiosError, AxiosResponse } from 'axios';
import PasswordFields from '../../components/auth/PasswordFields';
import { getLocalizedPath } from '../../utils/common.util';

const SignupPage = (): React.ReactElement => {
    const { t } = useTranslation();
    const api: IApi = useAxiosApi();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { setAccessToken } = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [canRegister, setCanRegister] = useState(false);
    const [activationAnchor, setActivationAnchor] = useState<null | SVGSVGElement | HTMLElement>(null);

    useEffect(() => {
        setCanRegister(!!email && !!password && !!confirmPassword && password === confirmPassword);
    }, [email, password, confirmPassword]);

    const handleSignup = async (event: React.FormEvent<HTMLElement>) => {
        event.preventDefault();
        if (canRegister) {
            signup(api, { email, password, confirmPassword })
                .then((res: AxiosResponse<ITokenResponse>) => {
                    const token: ITokenResponse = res.data;
                    const newToken = token?.accessToken ?? null;
                    setAccessToken(newToken);
                    if (!!token) {
                        setError('');
                        setActivationAnchor(event.target as HTMLElement);
                    }
                })
                .catch((e: AxiosError) => {
                    if (e?.response?.status === 400) {
                        setError(t(TranslationKey.error.passwordsMismatch));
                    } else if (e?.response?.status === 409) {
                        setError(t(TranslationKey.error.accountExists));
                    } else {
                        setError(t(TranslationKey.error.cannotSignup));
                    }
                });
        }
    };

    const handleEmail = (value: string) => {
        setEmail(value);
        setError('');

        if (value) {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]{3,}\.[a-zA-Z]{2,}$/;
            setEmailError(emailRegex.test(value) ? '' : t(TranslationKey.error.invalidEmail));
        } else {
            setEmailError('');
        }
    };

    return (
        <>
            <Helmet>
                <title>{t(TranslationKey.helmet.title.signupPage)}</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <Grid2 container height="60vh" alignItems="center" justifyContent="center">
                <Paper elevation={3} sx={{ padding: '2rem', marginTop: '4rem', width: '350px' }}>
                    <Typography variant="h4" align="center" gutterBottom>
                        {t(TranslationKey.user.createAccount)}
                    </Typography>
                    <Box height={30}>
                        <Typography color="error" variant={'caption'}>
                            {error}
                        </Typography>
                    </Box>
                    <Box component="form" noValidate autoComplete="off" onSubmit={handleSignup}>
                        <TextField
                            label={t(TranslationKey.email)}
                            dir={'ltr'}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            autoFocus={true}
                            value={email}
                            error={!!emailError}
                            helperText={emailError ?? ' '}
                            onChange={(e) => handleEmail(e.target.value)}
                            sx={{ height: 70 }}
                            required
                            slotProps={{ htmlInput: { autoComplete: 'new-password' } }}
                        />
                        <PasswordFields
                            password={password}
                            confirmPassword={confirmPassword}
                            setPassword={setPassword}
                            setConfirmPassword={setConfirmPassword}
                            setError={setError}
                        />
                        <Box textAlign="center" mt={2}>
                            <Typography variant="body2">
                                {t(TranslationKey.terms.agreeTo)}
                                <Button
                                    color="primary"
                                    size="small"
                                    variant={'text'}
                                    onClick={() => window.open('/privacy-policy', '_blank', 'noopener,noreferrer')}
                                >
                                    {t(TranslationKey.terms.privacyPolicy)}
                                </Button>
                                {'&'}
                                <Button
                                    color="primary"
                                    size="small"
                                    variant={'text'}
                                    onClick={() => window.open('/terms-of-service', '_blank', 'noopener,noreferrer')}
                                >
                                    {t(TranslationKey.terms.termsOfService)}
                                </Button>
                            </Typography>
                        </Box>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            style={{ marginTop: '1rem' }}
                            disabled={!canRegister}
                            type={'submit'}
                        >
                            {t(TranslationKey.buttons.signup)}
                        </Button>
                        <Button
                            variant="text"
                            color="info"
                            fullWidth
                            style={{ marginTop: '1rem' }}
                            onClick={() => navigate(getLocalizedPath('/login'))}
                        >
                            {t(TranslationKey.buttons.login)}
                        </Button>
                    </Box>
                </Paper>
                <FeedbackModal
                    anchorEl={activationAnchor}
                    setAnchorEl={setActivationAnchor}
                    title={t(TranslationKey.accountActivation._name)}
                    message={t(TranslationKey.accountActivation.requestActivationSuccessMsg) + email}
                    error={false}
                    loading={false}
                    onClose={() => navigate(getLocalizedPath('/profile'))}
                />
            </Grid2>
        </>
    );
};

export default SignupPage;
