import { css } from '@emotion/react';

export const globalStyles = css`
    @font-face {
        font-family: 'Roboto';
        src: url('/fonts/roboto/Roboto-Light.ttf') format('truetype');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'Roboto';
        src: url('/fonts/roboto/Roboto-LightItalic.ttf') format('truetype');
        font-weight: 300;
        font-style: italic;
    }
    @font-face {
        font-family: 'Roboto';
        src: url('/fonts/roboto/Roboto-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'Roboto';
        src: url('/fonts/roboto/Roboto-RegularItalic.ttf') format('truetype');
        font-weight: normal;
        font-style: italic;
    }
    @font-face {
        font-family: 'Roboto';
        src: url('/fonts/roboto/Roboto-Bold.ttf') format('truetype');
        font-weight: bold;
        font-style: normal;
    }
    @font-face {
        font-family: 'Roboto';
        src: url('/fonts/roboto/Roboto-BoldItalic.ttf') format('truetype');
        font-weight: bold;
        font-style: italic;
    }

    @font-face {
        font-family: 'Tajawal';
        src: url('/fonts/tajawal/Tajawal-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Tajawal';
        src: url('/fonts/tajawal/Tajawal-Bold.ttf') format('truetype');
        font-weight: bold;
        font-style: normal;
    }

    @font-face {
        font-family: 'Tajawal';
        src: url('/fonts/tajawal/Tajawal-Light.ttf') format('truetype');
        font-weight: 300;
        font-style: normal;
    }
`;
