import React, { createContext, ReactNode, useContext, useReducer } from 'react';
import { ICV } from '../interfaces/CV.interface';

type CVAction = { type: 'UPDATE_FIELD'; field: keyof ICV; value: any } | { type: 'SET_CV'; cv: ICV };

const cvReducer = (state: ICV | null, action: CVAction): ICV | null => {
    switch (action.type) {
        case 'SET_CV':
            return action.cv;
        case 'UPDATE_FIELD':
            return state ? { ...state, [action.field]: action.value } : state;
        default:
            return state;
    }
};

// Create context
const CVContext = createContext<{
    cv: ICV | null;
    dispatch: React.Dispatch<CVAction>;
}>({
    cv: null,
    dispatch: () => {},
});

export const CVProvider = ({ children }: { children: ReactNode }) => {
    const [cv, dispatch] = useReducer(cvReducer, null);

    return <CVContext.Provider value={{ cv, dispatch }}>{children}</CVContext.Provider>;
};

export const useCV = () => useContext(CVContext);
