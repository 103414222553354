import { ISocialLink, ISocialLinkCreationDto, ISocialLinkUpdateDto } from '../../../../interfaces/SocialLink.interface';
import { ISocialLinkTheme } from '../../../../interfaces/Theme.interface';
import React, { useEffect, useState } from 'react';
import { IApi } from '../../../../services/api.service';
import { useAxiosApi } from '../../../../hooks/useAxiosApi';
import { createSocialLink, updateSocialLink } from '../../../../services/socialLink.service';
import SocialLinkHeaderForm from './SocialLinkHeaderForm';
import { LinearProgress } from '@mui/material';

interface SocialLinkProps {
    cvId: number;
    socialLink: ISocialLink;
    socialLinks: ISocialLink[];
    setSocialLinks: (socialLink: ISocialLink[]) => void;
    theme: ISocialLinkTheme;
}

const SocialLinkForm = (props: SocialLinkProps): React.ReactElement => {
    const api: IApi = useAxiosApi();
    const [socialLink, setSocialLink] = useState<ISocialLink>(props.socialLink);
    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {
        updateState(socialLink);
    }, [socialLink]);

    const updateState = (_socialLink: ISocialLink) => {
        setSocialLink(_socialLink);
        const updatedList = props.socialLinks.map((e: ISocialLink) => (e.id === socialLink.id ? _socialLink : e));
        props.setSocialLinks(updatedList);
    };

    const _updateSocialLink = (dto: ISocialLinkUpdateDto) => {
        updateSocialLink(api, socialLink.id, dto).then((updatedSocialLink: ISocialLink | null) => {
            if (updatedSocialLink) {
                updateState(updatedSocialLink);
            }
        });
    };

    const onDoneClickedHeader = (dto: ISocialLinkUpdateDto) => {
        if (!socialLink.id) {
            const readyToCreate: boolean = !!dto.url;

            if (readyToCreate) {
                setIsLoading(true);
                const creationDto: ISocialLinkCreationDto = {
                    ...dto,
                    cv: { id: props.cvId },
                } as ISocialLinkCreationDto;

                createSocialLink(api, creationDto)
                    .then((newSocialLink: ISocialLink | null) => {
                        if (newSocialLink) {
                            updateState(newSocialLink);
                        }
                    })
                    .finally(() => setIsLoading(false));
            }
        } else {
            _updateSocialLink(dto);
        }
    };

    return (
        <>
            <SocialLinkHeaderForm
                socialLink={socialLink}
                setSocialLink={setSocialLink}
                headerTheme={props.theme.header}
                onDoneClicked={onDoneClickedHeader}
            />
            {isLoading && <LinearProgress sx={{ my: 3 }} />}
        </>
    );
};

export default SocialLinkForm;
