import React from 'react';
import { Box, Checkbox, Grid2, Typography } from '@mui/material';
import { useMode } from '../context/Mode.context';
import { Mode } from '../interfaces/Mode.interface';
import { EditableDate, PrintableDate } from './DatePickerYM';
import dayjs, { Dayjs } from 'dayjs';
import { IDateTheme, IFontTheme } from '../interfaces/Theme.interface';
import { TranslationKey } from '../interfaces/TranslationKey';
import { useTranslation } from 'react-i18next';
import { IDateRange } from '../interfaces/Date.interface';

interface DatePickerRangeProps {
    startDate: Dayjs;
    setStartDate: (value: Dayjs) => void;
    endDate: Dayjs | null;
    setEndDate: (value: Dayjs | null) => void;
    theme: IFontTheme & IDateTheme;
    onAcceptRange: (range: IDateRange) => void;
}

export const EditableDatePickerRange: React.FC<DatePickerRangeProps> = (props: DatePickerRangeProps) => {
    const { t } = useTranslation();

    const onChangeCheckbox = () => {
        const value: Dayjs | null = props.endDate ? null : dayjs();
        props.setEndDate(value);
        props.onAcceptRange({ start: props.startDate, end: value });
    };

    const onAcceptStartDate = (value: Dayjs | null) => {
        props.onAcceptRange({ start: value ?? dayjs(), end: props.endDate });
    };

    const onAcceptEndDate = (value: Dayjs | null) => {
        props.onAcceptRange({ start: props.startDate, end: value });
    };

    return (
        <Grid2 container spacing={2}>
            <Grid2 size={'grow'}>
                <EditableDate
                    value={props.startDate}
                    setValue={props.setStartDate}
                    theme={props.theme}
                    label={TranslationKey.date.start}
                    onAccept={onAcceptStartDate}
                />
            </Grid2>
            <Grid2 size={'grow'} alignContent={'center'}>
                {!props.endDate ? (
                    <Typography color={props.theme.color} variant={props.theme.variant}>
                        {t(TranslationKey.date.current)}
                    </Typography>
                ) : (
                    <EditableDate
                        value={props.endDate}
                        setValue={props.setEndDate}
                        theme={props.theme}
                        label={TranslationKey.date.end}
                        onAccept={onAcceptEndDate}
                    />
                )}
            </Grid2>
            <Grid2>
                <Checkbox checked={!props.endDate} onChange={onChangeCheckbox} inputProps={{ 'aria-label': 'controlled' }} />
            </Grid2>
        </Grid2>
    );
};

export const PrintableDatePickerRange = (props: Pick<DatePickerRangeProps, 'startDate' | 'endDate' | 'theme'>): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <Grid2 size={12} display="flex">
            <PrintableDate value={props.startDate} theme={props.theme} />
            <Box sx={{ margin: '0 5px' }}>
                <Typography
                    component={'p'}
                    color={props.theme.color}
                    variant={props.theme.variant}
                    fontWeight={props.theme.weight}
                    fontStyle={props.theme.style}
                    sx={{ color: `${props.theme.color} !important` }}
                >
                    &mdash;
                </Typography>
            </Box>
            {!props.endDate ? (
                <Grid2>
                    <Typography
                        component={'p'}
                        color={props.theme.color}
                        variant={props.theme.variant}
                        fontWeight={props.theme.weight}
                        fontStyle={props.theme.style}
                        sx={{ color: `${props.theme.color} !important` }}
                    >
                        {t(TranslationKey.date.current)}
                    </Typography>
                </Grid2>
            ) : (
                <PrintableDate value={props.endDate} theme={props.theme} />
            )}
        </Grid2>
    );
};

const DatePickerRange: React.FC<DatePickerRangeProps> = (props: DatePickerRangeProps) => {
    const { mode } = useMode();

    switch (mode) {
        case Mode.EDIT:
            return <EditableDatePickerRange {...props} />;
        case Mode.PRINT:
            return <PrintableDatePickerRange {...props} />;
    }
};

export default DatePickerRange;
