import React, { createContext, ReactNode, useContext, useState } from 'react';
import { Mode } from '../interfaces/Mode.interface';

interface ModeContextType {
    mode: Mode;
    setMode: (mode: Mode) => void;
}

const ModeContext = createContext<ModeContextType | undefined>(undefined);

interface ModeProviderProps {
    children: ReactNode;
}

export const ModeProvider: React.FC<ModeProviderProps> = ({ children }) => {
    const [mode, setMode] = useState<Mode>(Mode.PRINT); // Default mode

    return <ModeContext.Provider value={{ mode, setMode }}>{children}</ModeContext.Provider>;
};

// Custom hook to use the ModeContext
export const useMode = (): ModeContextType => {
    const context = useContext(ModeContext);
    if (!context) {
        throw new Error('useMode must be used within a ModeProvider');
    }
    return context;
};
