import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { ICV } from '../../interfaces/CV.interface';
import { IPDFStyle } from '../../interfaces/pdfStyle.interface';
import { Style } from '@react-pdf/types';

interface SectionPDFProps {
    cv: ICV;
    styles: IPDFStyle;
    inline?: boolean;
}

const ContactPDF = (props: SectionPDFProps): React.ReactElement => {
    const flex: Style = props.inline ? { ...props.styles.rowStyle, justifyContent: 'space-between' } : {};
    const width = props.inline ? '50%' : '100%';

    return (
        <View style={{ ...props.styles.contactView, ...flex }}>
            <View style={{ width: width }}>
                <View style={props.styles.rowStyle}>
                    {props.cv.address?.street && <Text style={props.styles.contactFont}>{props.cv.address.street}</Text>}
                </View>
                <View style={props.styles.rowStyle}>
                    {props.cv.address?.postalCode && (
                        <Text style={{ ...props.styles.contactFont }}>{`${props.cv.address.postalCode} `}</Text>
                    )}
                    {props.cv.address?.city && <Text style={props.styles.contactFont}>{props.cv.address.city}</Text>}
                </View>
                <View style={props.styles.rowStyle}>
                    {props.cv.address?.state && <Text style={props.styles.contactFont}>{props.cv.address.state}</Text>}
                </View>
                <View style={props.styles.rowStyle}>
                    {props.cv.address?.country && <Text style={props.styles.contactFont}>{props.cv.address.country}</Text>}
                </View>
            </View>
            <View style={{ width: width }}>
                <View style={props.styles.rowStyle}>
                    {props.cv.phoneNumber && <Text style={props.styles.contactFont}>{props.cv.phoneNumber}</Text>}
                </View>
                <View style={props.styles.rowStyle}>
                    {props.cv.email && <Text style={props.styles.contactFont}>{props.cv.email}</Text>}
                </View>
            </View>
        </View>
    );
};

export default ContactPDF;
