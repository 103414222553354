import React, { useState } from 'react';
import { Box, Button, Grid2, Paper, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { requestPasswordReset } from '../../services/auth.service';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../interfaces/TranslationKey';
import { IApi } from '../../services/api.service';
import { useAxiosApi } from '../../hooks/useAxiosApi';
import { Helmet } from 'react-helmet-async';
import { AxiosError, AxiosResponse } from 'axios';
import FeedbackModal from '../../components/FeedbackModal';
import { getLocalizedPath } from '../../utils/common.util';

const RequestPasswordResetPage = (): React.ReactElement => {
    const { t } = useTranslation();
    const api: IApi = useAxiosApi();
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const [error, setError] = useState<string>('');
    const [passwordResetRequestAnchor, setPasswordResetRequestAnchor] = useState<null | SVGSVGElement | HTMLElement>(null);

    const handleRequestPasswordReset = async (event: React.FormEvent<HTMLElement>) => {
        if (!email) {
            setError(t(TranslationKey.error.invalidEmail));
            return;
        }

        requestPasswordReset(api, { email })
            .then((res: AxiosResponse) => {
                if (res.status === 204) {
                    setError('');
                    setPasswordResetRequestAnchor(event.target as HTMLElement);
                }
            })
            .catch((e: AxiosError) => {
                if (e?.response?.status === 400) {
                    setError(t(TranslationKey.error.invalidEmail));
                } else if (e?.response?.status === 404) {
                    setError(t(TranslationKey.error.notRegisteredYet));
                } else {
                    setError(t(TranslationKey.error.somethingWrongPassword));
                }
            });
    };

    const handleEmail = (value: string) => {
        setEmail(value);

        if (value) {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]{3,}\.[a-zA-Z]{2,}$/;
            setError(emailRegex.test(value) ? '' : t(TranslationKey.error.invalidEmail));
        } else {
            setError('');
        }
    };

    return (
        <>
            <Helmet>
                <title>{t(TranslationKey.helmet.title.passwordResetRequestPage)}</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <Grid2 container height="60vh" alignItems="center" justifyContent="center">
                <Paper elevation={3} sx={{ padding: '2rem', marginTop: '4rem', width: '350px' }}>
                    <Typography variant="h4" align="center" gutterBottom>
                        {t(TranslationKey.passwordReset.request)}
                    </Typography>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleRequestPasswordReset(e).then();
                        }}
                    >
                        <TextField
                            label={t(TranslationKey.email)}
                            dir={'ltr'}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            autoFocus={true}
                            value={email}
                            error={!!error}
                            helperText={error ?? ' '}
                            onChange={(e) => handleEmail(e.target.value)}
                            sx={{ height: 70 }}
                            required
                            slotProps={{ htmlInput: { autoComplete: 'new-password' } }}
                        />

                        <Button variant="contained" color="primary" fullWidth style={{ marginTop: '1rem' }} type={'submit'}>
                            {t(TranslationKey.buttons.send)}
                        </Button>
                        <Button
                            variant="text"
                            color="info"
                            fullWidth
                            style={{ marginTop: '1rem' }}
                            onClick={() => navigate(getLocalizedPath('/login'))}
                        >
                            {t(TranslationKey.buttons.login)}
                        </Button>
                    </Box>
                </Paper>
            </Grid2>
            <FeedbackModal
                anchorEl={passwordResetRequestAnchor}
                setAnchorEl={setPasswordResetRequestAnchor}
                title={t(TranslationKey.passwordReset.request)}
                message={t(TranslationKey.passwordReset.requestResetPasswordSuccessMsg) + email}
                error={false}
                loading={false}
                onClose={() => navigate(getLocalizedPath('/'))}
            />
        </>
    );
};

export default RequestPasswordResetPage;
