import React, { MouseEventHandler } from 'react';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../interfaces/TranslationKey';

interface ButtonProps {
    onClick: MouseEventHandler | undefined;
    disabled?: boolean;
}

const AddSectionButton = (props: ButtonProps): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <Box mt={2}>
            <Button
                onClick={props.onClick}
                variant={'contained'}
                color={'primary'}
                fullWidth
                endIcon={<AddIcon />}
                disabled={props.disabled}
            >
                {t(TranslationKey.buttons.add)}
            </Button>
        </Box>
    );
};

export default AddSectionButton;
