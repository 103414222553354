import React from 'react';
import { Box, Divider, Grid2 } from '@mui/material';
import { useMode } from '../../context/Mode.context';
import { Mode } from '../../interfaces/Mode.interface';
import { ITemplateProps } from '../../interfaces/Template.interface';
import { ILayout, SectionName } from '../../interfaces/Theme.interface';
import { TemplateName } from '../../common/enum';
import { getSectionElements, getSections, ISectionElementsProps } from '../Template.util';

const IdlibTemplate = (props: ITemplateProps): React.ReactElement => {
    const { mode } = useMode();
    const isEditing: boolean = mode === Mode.EDIT;

    const templateName: TemplateName = TemplateName.IDLIB;

    const layout: ILayout = getSections(props.theme.layoutTheme, templateName);

    const showTopSection: boolean = layout.top.sections.some((i) => !!i);
    const showTopLeftSection: boolean = layout.topLeft.sections.some((i) => !!i);
    const showTopLeftRightSection: boolean = showTopLeftSection || layout.topRight.sections.some((i) => !!i);

    const showMiddleSection: boolean = layout.middle.sections.some((i) => !!i);
    const showMiddleLeftRightSection: boolean = layout.middleLeft.sections.some((i) => !!i) || layout.middleRight.sections.some((i) => !!i);

    const showBottomSection: boolean = layout.bottom.sections.some((i) => !!i);
    const showBottomLeftRightSection: boolean = layout.bottomLeft.sections.some((i) => !!i) || layout.bottomRight.sections.some((i) => !!i);

    const getProps = (sections: SectionName[]): ISectionElementsProps => ({
        templateName: templateName,
        sections: sections,
        templateProps: props,
        isEditing: isEditing,
    });

    return (
        <Box>
            {/* top */}
            {showTopSection && getSectionElements(getProps(layout.top.sections))}

            {/* top left/right */}
            {showTopLeftRightSection && (
                <Grid2 container size={12} sx={{ padding: '10px 20px' }}>
                    <Grid2
                        size={{
                            xs: isEditing ? 12 : props.theme.layoutTheme.sectionWidth.topLeftWidth,
                            lg: props.theme.layoutTheme.sectionWidth.topLeftWidth,
                        }}
                    >
                        {showTopLeftSection && getSectionElements(getProps(layout.topLeft.sections))}
                    </Grid2>
                    <Grid2
                        size={{
                            xs: isEditing ? 12 : 12 - props.theme.layoutTheme.sectionWidth.topLeftWidth,
                            lg: 12 - props.theme.layoutTheme.sectionWidth.topLeftWidth,
                        }}
                        alignItems="center"
                        alignContent="center"
                        justifyItems={'flex-start'}
                        paddingLeft={'40px'}
                    >
                        {getSectionElements({ ...getProps(layout.topRight.sections), inlineContact: true })}
                    </Grid2>
                </Grid2>
            )}
            <Divider color={props.theme.layoutTheme.borderColor ?? '#999'} sx={{ height: '2px', margin: '0 20px 10px' }} />

            {/* middle */}
            {showMiddleSection && getSectionElements(getProps(layout.middle.sections))}

            {/* middle left/right */}
            {showMiddleLeftRightSection && (
                <Grid2 container size={12}>
                    {/* Left Section */}
                    <Grid2
                        size={{
                            xs: isEditing ? 12 : props.theme.layoutTheme.sectionWidth.middleLeftWidth,
                            lg: props.theme.layoutTheme.sectionWidth.middleLeftWidth,
                        }}
                        sx={{ padding: '20px 30px 20px 10px' }}
                    >
                        {getSectionElements(getProps(layout.middleLeft.sections))}
                    </Grid2>

                    {/* Right Section */}
                    <Grid2
                        size={{
                            xs: isEditing ? 12 : 12 - props.theme.layoutTheme.sectionWidth.middleLeftWidth,
                            lg: 12 - props.theme.layoutTheme.sectionWidth.middleLeftWidth,
                        }}
                        sx={{
                            padding: '0px 10px 20px 30px',
                            borderLeft: `solid 5px ${props.theme.layoutTheme.borderColor}`,
                            margin: '20px 0px',
                        }}
                    >
                        {getSectionElements(getProps(layout.middleRight.sections))}
                    </Grid2>
                </Grid2>
            )}

            {/* bottom */}
            {showBottomSection && getSectionElements(getProps(layout.bottom.sections))}

            {/* bottom left/right */}
            {showBottomLeftRightSection && (
                <Grid2 container size={12}>
                    {/* Left Section */}
                    <Grid2
                        size={{
                            xs: isEditing ? 12 : props.theme.layoutTheme.sectionWidth.middleLeftWidth,
                            lg: props.theme.layoutTheme.sectionWidth.middleLeftWidth,
                        }}
                        sx={{ padding: '20px 30px 20px 10px' }}
                    >
                        {getSectionElements(getProps(layout.bottomLeft.sections))}
                    </Grid2>

                    {/* Right Section */}
                    <Grid2
                        size={{
                            xs: isEditing ? 12 : 12 - props.theme.layoutTheme.sectionWidth.middleLeftWidth,
                            lg: 12 - props.theme.layoutTheme.sectionWidth.middleLeftWidth,
                        }}
                        sx={{
                            padding: '0px 10px 20px 30px',
                            borderLeft: `solid 5px ${props.theme.layoutTheme.borderColor}`,
                            margin: '20px 0px',
                        }}
                    >
                        {getSectionElements(getProps(layout.bottomRight.sections))}
                    </Grid2>
                </Grid2>
            )}
        </Box>
    );
};

export default IdlibTemplate;
