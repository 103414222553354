import React, { useEffect, useState } from 'react';
import { Grid2, TextField } from '@mui/material';
import { TranslationKey } from '../../../interfaces/TranslationKey';
import Profession from '../../cv/Profession';
import { ICV } from '../../../interfaces/CV.interface';
import { IProfession } from '../../../interfaces/Profession.interface';
import { useCV } from '../../../context/CV.context';
import { EditableTextField } from '../../GeneralTextField';
import { EmailField } from '../../Email';
import { updateCV } from '../../../services/cv.service';
import { IApi } from '../../../services/api.service';
import { useAxiosApi } from '../../../hooks/useAxiosApi';
import { useTranslation } from 'react-i18next';

interface PersonalInfoProps {
    setError: (err: string) => void;
}

const PersonalInfoForm = (props: PersonalInfoProps): React.ReactElement => {
    const { t } = useTranslation();
    const api: IApi = useAxiosApi();
    const { cv, dispatch } = useCV();
    const [firstName, setFirstName] = useState<string>(cv?.firstName || '');
    const [lastName, setLastName] = useState<string>(cv?.lastName || '');
    const [email, setEmail] = useState<string>(cv?.email || '');
    const [profession, setProfession] = useState<IProfession>(cv?.profession || { id: 0, name: '' });

    useEffect(() => {
        setFirstName(cv?.firstName || '');
    }, [cv?.firstName]);

    useEffect(() => {
        setLastName(cv?.lastName || '');
    }, [cv?.lastName]);

    useEffect(() => {
        setEmail(cv?.email || '');
    }, [cv?.email]);

    useEffect(() => {
        setProfession(cv?.profession || profession);
    }, [cv?.profession]);

    useEffect(() => {
        dispatch({ type: 'UPDATE_FIELD', field: 'profession', value: profession });

        if (firstName && lastName && email && profession.id) {
            props.setError('');
        }
    }, [profession]);

    const onChangeField = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({ type: 'UPDATE_FIELD', field: e.target.name as keyof ICV, value: e.target.value });

        if (firstName && lastName && email && profession.id) {
            props.setError('');
        }
    };

    const onDoneClicked = (value: string | IProfession, key: keyof ICV) => {
        if (!cv) return;
        switch (key) {
            case 'firstName':
                updateCV(api, cv.id, { firstName: value as string }).then(() =>
                    dispatch({ type: 'UPDATE_FIELD', field: 'firstName', value: value }),
                );
                break;
            case 'lastName':
                updateCV(api, cv.id, { lastName: value as string }).then(() =>
                    dispatch({ type: 'UPDATE_FIELD', field: 'lastName', value: value }),
                );
                break;
            case 'email':
                updateCV(api, cv.id, { email: value as string }).then(() =>
                    dispatch({ type: 'UPDATE_FIELD', field: 'email', value: value }),
                );
                break;
            case 'profession':
                updateCV(api, cv.id, { profession: { id: (value as IProfession).id } }).then(() =>
                    dispatch({ type: 'UPDATE_FIELD', field: 'profession', value: value }),
                );
                break;
        }
    };

    return !cv?.id ? (
        <Grid2 container size={{ xs: 12, md: 6 }} rowSpacing={2}>
            <Grid2 size={12}>
                <TextField
                    value={firstName}
                    name="firstName"
                    onChange={(e) => {
                        setFirstName(e.target.value);
                        onChangeField(e);
                    }}
                    label={t(TranslationKey.firstName)}
                    fullWidth={true}
                    autoFocus={true}
                    required={true}
                />
            </Grid2>
            <Grid2 size={12}>
                <TextField
                    value={lastName}
                    name="lastName"
                    onChange={(e) => {
                        setLastName(e.target.value);
                        onChangeField(e);
                    }}
                    label={t(TranslationKey.lastName)}
                    fullWidth={true}
                    autoFocus={true}
                    required={true}
                />
            </Grid2>
            <Grid2 size={12}>
                <TextField
                    value={email}
                    name="email"
                    onChange={(e) => {
                        setEmail(e.target.value);
                        onChangeField(e);
                    }}
                    label={t(TranslationKey.email)}
                    fullWidth={true}
                    autoFocus={true}
                    required={true}
                />
            </Grid2>
            <Grid2 size={12}>
                <Profession
                    profession={profession}
                    setProfession={setProfession}
                    theme={{
                        font: {
                            variant: 'body1',
                            color: 'text.secondary',
                            weight: 'normal',
                            style: 'normal',
                        },
                        margin: {
                            bottom: 0,
                        },
                    }}
                    alwaysEditable={true}
                />
            </Grid2>
        </Grid2>
    ) : (
        <Grid2 container size={{ xs: 12, md: 6 }} rowSpacing={2}>
            <Grid2 size={12}>
                <EditableTextField
                    value={firstName}
                    setValue={setFirstName}
                    label={t(TranslationKey.firstName)}
                    required={true}
                    onDoneClicked={(value) => onDoneClicked(value, 'firstName')}
                    fontTheme={{ variant: 'body1', color: 'text.secondary', weight: 'normal', style: 'normal' }}
                />
            </Grid2>
            <Grid2 size={12}>
                <EditableTextField
                    value={lastName}
                    setValue={setLastName}
                    label={t(TranslationKey.lastName)}
                    required={true}
                    onDoneClicked={(value) => onDoneClicked(value, 'lastName')}
                    fontTheme={{ variant: 'body1', color: 'text.secondary', weight: 'normal', style: 'normal' }}
                />
            </Grid2>
            <Grid2 size={12}>
                <EmailField
                    email={email}
                    setEmail={setEmail}
                    onDoneClicked={(value) => onDoneClicked(value, 'email')}
                    fontTheme={{ variant: 'body1', color: 'text.secondary', weight: 'normal', style: 'normal' }}
                />
            </Grid2>
            <Grid2 size={12}>
                <Profession
                    profession={profession}
                    setProfession={setProfession}
                    onCompleted={(_profession) => onDoneClicked(_profession, 'profession')}
                    theme={{
                        font: {
                            variant: 'body1',
                            color: 'text.secondary',
                            weight: 'normal',
                            style: 'normal',
                        },
                        margin: {
                            bottom: 0,
                        },
                    }}
                />
            </Grid2>
        </Grid2>
    );
};

export default PersonalInfoForm;
