export enum TemplateName {
    DAMASCUS = 'DAMASCUS',
    HOMS = 'HOMS',
    ALEPPO = 'ALEPPO',
    MECCA = 'MECCA',
    MADINA = 'MADINA',
    ALQUDS = 'ALQUDS',
    GOLAN = 'GOLAN',
    IDLIB = 'IDLIB',
    HAMA = 'HAMA',
    DOHA = 'DOHA',
    GAZA = 'GAZA',
    CAIRO = 'CAIRO',
    RIADH = 'RIADH',
    MOSUL = 'MOSUL',
    TRIPOLI = 'TRIPOLI',
}
