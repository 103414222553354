import { ICertification, ICertificationCreationDto, ICertificationUpdateDto } from '../../../../interfaces/Certification.interface';
import { IEducationTheme } from '../../../../interfaces/Theme.interface';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMode } from '../../../../context/Mode.context';
import { IApi } from '../../../../services/api.service';
import { useAxiosApi } from '../../../../hooks/useAxiosApi';
import dayjs, { Dayjs } from 'dayjs';
import { createCertification, updateCertification } from '../../../../services/certification.service';
import { Mode } from '../../../../interfaces/Mode.interface';
import { Checkbox, FormControlLabel, Grid2, LinearProgress } from '@mui/material';
import { DatePickerMY } from '../../../DatePickerYM';
import { TranslationKey } from '../../../../interfaces/TranslationKey';
import CertificationHeaderForm from './CertificationHeaderForm';

interface CertificationProps {
    cvId: number;
    certification: ICertification;
    certifications: ICertification[];
    setCertifications: (certifications: ICertification[]) => void;
    theme: IEducationTheme;
}

const CertificationForm = (props: CertificationProps): React.ReactElement => {
    const { t } = useTranslation();
    const { mode } = useMode();
    const api: IApi = useAxiosApi();
    const [certification, setCertification] = React.useState<ICertification>(props.certification);
    const [issueDate, setIssueDate] = React.useState<Dayjs | null>(certification.issueDate ? dayjs(certification.issueDate) : null);
    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {
        updateState(certification);
    }, [certification]);

    const updateState = (_certification: ICertification) => {
        setCertification(_certification);
        const updatedList = props.certifications.map((e: ICertification) => (e.id === certification.id ? _certification : e));
        props.setCertifications(updatedList);
    };

    const _updateCertification = (dto: ICertificationUpdateDto) => {
        updateCertification(api, certification.id, dto).then((updatedCertification: ICertification | null) => {
            if (updatedCertification) {
                updateState(updatedCertification);
            }
        });
    };

    const onBlurHeader = (CertificationHeaderDto: Pick<ICertificationCreationDto, 'name' | 'issuer'>) => {
        const dto: ICertificationUpdateDto = {
            ...CertificationHeaderDto,
            issueDate: issueDate ? issueDate.toDate() : null,
        };

        if (!certification.id) {
            const readyToCreate: boolean = !!CertificationHeaderDto.name;

            if (readyToCreate) {
                setIsLoading(true);
                const creationDto: ICertificationCreationDto = {
                    ...dto,
                    cv: { id: props.cvId },
                } as ICertificationCreationDto;

                createCertification(api, creationDto)
                    .then((newCertification: ICertification | null) => {
                        if (newCertification) {
                            updateState(newCertification);
                        }
                    })
                    .finally(() => setIsLoading(false));
            }
        } else {
            _updateCertification(dto);
        }
    };

    const onChangeCheckbox = () => {
        const value = issueDate ? null : dayjs();
        setIssueDate(value);
        onAcceptDatePicker(value);
    };

    const onAcceptDatePicker = (value: Dayjs | null) => {
        if (!certification.id) return;

        const dto: ICertificationUpdateDto = {
            name: certification.name,
            issuer: certification.issuer,
            issueDate: value ? value.toDate() : null,
        };
        _updateCertification(dto);
    };

    const isEditing: boolean = mode === Mode.EDIT;

    return (
        <>
            <CertificationHeaderForm
                certification={certification}
                setCertification={setCertification}
                theme={props.theme.header}
                onBlur={onBlurHeader}
            />
            {isLoading && <LinearProgress sx={{ my: 3 }} />}
            <Grid2 container spacing={2} minHeight={'60px'}>
                {issueDate && (
                    <Grid2 size={'grow'}>
                        <DatePickerMY
                            value={issueDate}
                            setValue={setIssueDate}
                            label={TranslationKey.date.issue}
                            theme={props.theme.date}
                            onAccept={onAcceptDatePicker}
                        />
                    </Grid2>
                )}
                {isEditing && (
                    <FormControlLabel
                        label={t(TranslationKey.date.noDate)}
                        control={<Checkbox checked={!issueDate} onChange={onChangeCheckbox} inputProps={{ 'aria-label': 'controlled' }} />}
                    />
                )}
            </Grid2>
        </>
    );
};

export default CertificationForm;
