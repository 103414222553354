import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { ICV } from '../../interfaces/CV.interface';
import { IPDFStyle } from '../../interfaces/pdfStyle.interface';

interface SectionPDFProps {
    cv: ICV;
    styles: IPDFStyle;
}

const NamePDF = (props: SectionPDFProps): React.ReactElement => {
    return (
        <View style={[props.styles.rowStyle, { flexWrap: 'wrap' }]}>
            <Text style={props.styles.firstNameFont}>{props.cv.firstName}</Text>
            <Text style={{ ...props.styles.lastNameFont, margin: `0 ${props.styles.nameView.gap}px` }}>{props.cv.lastName}</Text>
        </View>
    );
};

export default NamePDF;
