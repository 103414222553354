import React from 'react';
import { Box, Typography } from '@mui/material';
import { Mode } from '../../interfaces/Mode.interface';
import { useMode } from '../../context/Mode.context';
import { IEducation } from '../../interfaces/Education.interface';
import { ICertification } from '../../interfaces/Certification.interface';
import { IEducationTheme } from '../../interfaces/Theme.interface';
import EducationsForm from '../builder/forms/EducationsForm';
import { PrintableDatePickerRange } from '../DatePickerRange';
import { PrintableDate } from '../DatePickerYM';
import { getFontSize } from '../../utils/common.util';
import { PrintableTextField } from '../GeneralTextField';
import dayjs from 'dayjs';

interface EducationsProps {
    cvId: number;
    educations: IEducation[];
    certifications: ICertification[];
    theme: IEducationTheme;
}

const Educations: React.FC<EducationsProps> = (props: EducationsProps) => {
    const { mode } = useMode();

    const isEditing: boolean = mode === Mode.EDIT;

    const educationHeaderStyle = {
        color: props.theme.header.color,
        fontSize: getFontSize(props.theme.header.variant),
        fontWeight: props.theme.header.weight === 'light' ? 'lighter' : props.theme.header.weight,
        fontStyle: props.theme.header.style,
    };

    return (
        <Box id="education" sx={{ mb: `${props.theme.margin.bottom}px` }}>
            {isEditing ? (
                <EducationsForm {...props} />
            ) : (
                <>
                    {props.educations.map((education: IEducation) => (
                        <Box key={'printable-educations-' + education.id}>
                            <Typography display={'flex'} flexWrap={'wrap'}>
                                <span style={educationHeaderStyle}>{education.degree}</span>
                                {education.institution && <span style={educationHeaderStyle}>{`\u200C | \u200C`}</span>}
                                {education.institution && <span style={educationHeaderStyle}>{education.institution}</span>}
                            </Typography>
                            <PrintableDatePickerRange
                                startDate={dayjs(education.startDate)}
                                endDate={education.endDate ? dayjs(education.endDate) : null}
                                theme={props.theme.date}
                            />
                        </Box>
                    ))}

                    {props.certifications.map((certification: ICertification) => (
                        <Box key={'printable-certifications-' + certification.id}>
                            <Box display="flex">
                                <PrintableTextField value={certification.name} fontTheme={props.theme.header} />
                                {!!certification.issuer && (
                                    <Box sx={{ margin: '0 10px' }}>
                                        <Typography
                                            color={props.theme.header.color}
                                            variant={props.theme.header.variant}
                                            fontWeight={props.theme.header.weight}
                                            fontStyle={props.theme.header.style}
                                        >
                                            {`\u200C `} &mdash; {` \u200C`}
                                        </Typography>
                                    </Box>
                                )}
                                {!!certification.issuer && (
                                    <PrintableTextField value={certification.issuer} fontTheme={props.theme.header} />
                                )}
                            </Box>
                            {certification.issueDate && (
                                <Box sx={{ marginRight: '10px' }}>
                                    <PrintableDate value={dayjs(certification.issueDate)} theme={props.theme.date} />
                                </Box>
                            )}
                        </Box>
                    ))}
                </>
            )}
        </Box>
    );
};

export default Educations;
