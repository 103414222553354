import { allSections, ILayout, ILayoutTheme, ITitleTheme, SectionName } from '../interfaces/Theme.interface';
import React from 'react';
import { ITemplateProps } from '../interfaces/Template.interface';
import { Box, Grid2 } from '@mui/material';
import Name from '../components/cv/Name';
import Profession from '../components/cv/Profession';
import ProfileImage from '../components/cv/ProfileImage';
import Contact from '../components/cv/Contact';
import Profile from '../components/cv/Profile';
import Experiences from '../components/cv/Experiences';
import Educations from '../components/cv/Educations';
import Skills from '../components/cv/Skills';
import Languages from '../components/cv/Languages';
import References from '../components/cv/References';
import Interests from '../components/cv/Interest';
import SocialLinks from '../components/cv/SocialLinks';
import { CVMetadata } from '../interfaces/CV.interface';
import SectionHeader from '../components/SectionHeader';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Theme } from '@mui/material/styles';
import { defaultTemplateSectionPosition } from '../utils/theme.util';
import { TemplateName } from '../common/enum';

export const getSections = (layoutTheme: ILayoutTheme, templateName: TemplateName): ILayout => {
    if (layoutTheme.layout) {
        const definedSections: SectionName[] = [
            ...layoutTheme.layout.top.sections,
            ...layoutTheme.layout.topLeft.sections,
            ...layoutTheme.layout.topRight.sections,
            ...layoutTheme.layout.middle.sections,
            ...layoutTheme.layout.middleLeft.sections,
            ...layoutTheme.layout.middleRight.sections,
            ...layoutTheme.layout.bottom.sections,
            ...layoutTheme.layout.bottomLeft.sections,
            ...layoutTheme.layout.bottomRight.sections,
        ];

        layoutTheme.layout.excludedSections = {
            immutable: false,
            sections: allSections.filter((s) => !definedSections.includes(s)),
        };

        return layoutTheme.layout;
    }

    return defaultTemplateSectionPosition[templateName];
};

export interface ISectionElementsProps {
    templateName: TemplateName;
    sections: SectionName[];
    templateProps: ITemplateProps;
    isEditing: boolean;
    extraBottomElement?: React.ReactElement;
    sectionHeaderStyle?: SxProps<Theme>;
    sectionHeaderWidth?: number;
    inlineSkills?: boolean;
    inlineLanguages?: boolean;
    inlineContact?: boolean;
}

export const getSectionElements = (props: ISectionElementsProps) => {
    const showImagePlaceholder: boolean = props.isEditing || !!props.templateProps.cv?.profileImage;
    const getSectionHeader = (key: keyof CVMetadata, titleTheme: ITitleTheme): React.ReactElement => {
        return <SectionHeader metadataKey={key} cv={props.templateProps.cv} theme={titleTheme} />;
    };

    return props.sections.map((section) => {
        const sectionWithBackgroundStyle: SxProps<Theme> = { padding: '10px 15px', borderRadius: '15px', marginBottom: '10px' };
        let sectionStyle: SxProps<Theme> = {};

        const sectionHeaderWidth: number = props.sectionHeaderWidth || 12;
        const sectionBodyWidth: number = sectionHeaderWidth === 12 ? 12 : 12 - sectionHeaderWidth; // make it inline when the width is less than 12 grids

        switch (section) {
            case 'name':
                return (
                    <Name
                        key={`${props.templateName}-${section}`}
                        cvId={props.templateProps.cv.id}
                        firstName={props.templateProps.cv.firstName}
                        lastName={props.templateProps.cv.lastName}
                        theme={props.templateProps.theme.nameTheme}
                    />
                );

            case 'profession':
                return (
                    <Box
                        key={`${props.templateName}-${section}`}
                        mb={`${props.templateProps.theme.professionTheme.margin.bottom}px`}
                        width={props.isEditing ? '100%' : undefined}
                    >
                        <Profession
                            profession={props.templateProps.profession ?? props.templateProps.cv.profession}
                            setProfession={props.templateProps.setProfession}
                            theme={props.templateProps.theme.professionTheme}
                            createOnBlur={true}
                            onCompleted={props.templateProps.onProfessionSuccess}
                        />
                    </Box>
                );
            case 'image':
                return showImagePlaceholder ? (
                    <ProfileImage key={`${props.templateName}-${section}`} theme={props.templateProps.theme.imageTheme} />
                ) : null;
            case 'contact':
                if (props.templateProps.theme.subSectionTheme.backgroundColor.contactSection) {
                    sectionStyle = {
                        ...sectionStyle,
                        ...sectionWithBackgroundStyle,
                        backgroundColor: props.templateProps.theme.subSectionTheme.backgroundColor.contactSection,
                    } as SxProps<Theme>;
                }
                return (
                    <Grid2
                        container
                        key={`${props.templateName}-${section}`}
                        sx={{ ...sectionStyle, width: props.isEditing ? '100%' : undefined }}
                    >
                        {props.templateProps.theme.contactTheme.title.show && (
                            <Grid2 size={sectionHeaderWidth} sx={props.sectionHeaderStyle}>
                                {getSectionHeader('contact', props.templateProps.theme.contactTheme.title)}
                            </Grid2>
                        )}
                        <Grid2 size={sectionBodyWidth}>
                            <Contact
                                cvId={props.templateProps.cv.id}
                                address={props.templateProps.cv.address}
                                email={props.templateProps.cv.email}
                                phoneNumber={props.templateProps.cv.phoneNumber}
                                theme={props.templateProps.theme.contactTheme}
                                inline={props.inlineContact}
                            />
                            {props.extraBottomElement}
                        </Grid2>
                    </Grid2>
                );
            case 'profile':
                if (props.templateProps.theme.subSectionTheme.backgroundColor.profileSection) {
                    sectionStyle = {
                        ...sectionStyle,
                        ...sectionWithBackgroundStyle,
                        backgroundColor: props.templateProps.theme.subSectionTheme.backgroundColor.profileSection,
                    } as SxProps<Theme>;
                }
                return !!props.templateProps.cv.profile || props.isEditing ? (
                    <Grid2 container key={`${props.templateName}-${section}`} sx={sectionStyle}>
                        {props.templateProps.theme.profileTheme.title.show && (
                            <Grid2 size={sectionHeaderWidth} sx={props.sectionHeaderStyle}>
                                {getSectionHeader('profile', props.templateProps.theme.profileTheme.title)}
                            </Grid2>
                        )}
                        <Grid2 size={sectionBodyWidth}>
                            <Profile
                                cvId={props.templateProps.cv.id}
                                profile={props.templateProps.cv.profile}
                                theme={props.templateProps.theme.profileTheme}
                            />
                            {props.extraBottomElement}
                        </Grid2>
                    </Grid2>
                ) : null;
            case 'experiences':
                if (props.templateProps.theme.subSectionTheme.backgroundColor.experienceSection) {
                    sectionStyle = {
                        ...sectionStyle,
                        ...sectionWithBackgroundStyle,
                        backgroundColor: props.templateProps.theme.subSectionTheme.backgroundColor.experienceSection,
                    } as SxProps<Theme>;
                }
                return !!props.templateProps.cv.experiences.length || props.isEditing ? (
                    <Grid2 container key={`${props.templateName}-${section}`} sx={sectionStyle}>
                        {props.templateProps.theme.experienceTheme.title.show && (
                            <Grid2 size={sectionHeaderWidth} sx={props.sectionHeaderStyle}>
                                {getSectionHeader('experiences', props.templateProps.theme.experienceTheme.title)}
                            </Grid2>
                        )}
                        <Grid2 size={sectionBodyWidth}>
                            <Experiences
                                cvId={props.templateProps.cv.id}
                                experiences={props.templateProps.cv.experiences}
                                theme={props.templateProps.theme.experienceTheme}
                            />
                            {props.extraBottomElement}
                        </Grid2>
                    </Grid2>
                ) : null;
            case 'educations':
                if (props.templateProps.theme.subSectionTheme.backgroundColor.educationSection) {
                    sectionStyle = {
                        ...sectionStyle,
                        ...sectionWithBackgroundStyle,
                        backgroundColor: props.templateProps.theme.subSectionTheme.backgroundColor.educationSection,
                    } as SxProps<Theme>;
                }
                return !!props.templateProps.cv.educations.length || !!props.templateProps.cv.certifications.length || props.isEditing ? (
                    <Grid2 container key={`${props.templateName}-${section}`} sx={sectionStyle}>
                        {props.templateProps.theme.educationTheme.title.show && (
                            <Grid2 size={sectionHeaderWidth} sx={props.sectionHeaderStyle}>
                                {getSectionHeader('educationsAndCertifications', props.templateProps.theme.educationTheme.title)}
                            </Grid2>
                        )}
                        <Grid2 size={sectionBodyWidth}>
                            <Educations
                                cvId={props.templateProps.cv.id}
                                educations={props.templateProps.cv.educations}
                                certifications={props.templateProps.cv.certifications}
                                theme={props.templateProps.theme.educationTheme}
                            />
                            {props.extraBottomElement}
                        </Grid2>
                    </Grid2>
                ) : null;
            case 'skills':
                if (props.templateProps.theme.subSectionTheme.backgroundColor.skillSection) {
                    sectionStyle = {
                        ...sectionStyle,
                        ...sectionWithBackgroundStyle,
                        backgroundColor: props.templateProps.theme.subSectionTheme.backgroundColor.skillSection,
                    } as SxProps<Theme>;
                }
                return !!props.templateProps.cv.skillProficiencies.length || props.isEditing ? (
                    <Grid2 container key={`${props.templateName}-${section}`} sx={sectionStyle}>
                        {props.templateProps.theme.skillTheme.title.show && (
                            <Grid2 size={sectionHeaderWidth} sx={props.sectionHeaderStyle}>
                                {getSectionHeader('skills', props.templateProps.theme.skillTheme.title)}
                            </Grid2>
                        )}
                        <Grid2 size={sectionBodyWidth}>
                            <Skills
                                cvId={props.templateProps.cv.id}
                                skillProficiencies={props.templateProps.cv.skillProficiencies}
                                theme={props.templateProps.theme.skillTheme}
                                inline={props.inlineSkills ?? false}
                            />
                            {props.extraBottomElement}
                        </Grid2>
                    </Grid2>
                ) : null;
            case 'languages':
                if (props.templateProps.theme.subSectionTheme.backgroundColor.languageSection) {
                    sectionStyle = {
                        ...sectionStyle,
                        ...sectionWithBackgroundStyle,
                        backgroundColor: props.templateProps.theme.subSectionTheme.backgroundColor.languageSection,
                    } as SxProps<Theme>;
                }
                return !!props.templateProps.cv.languageProficiencies.length || props.isEditing ? (
                    <Grid2 container key={`${props.templateName}-${section}`} sx={sectionStyle}>
                        {props.templateProps.theme.languageTheme.title.show && (
                            <Grid2 size={sectionHeaderWidth} sx={props.sectionHeaderStyle}>
                                {getSectionHeader('languages', props.templateProps.theme.languageTheme.title)}
                            </Grid2>
                        )}
                        <Grid2 size={sectionBodyWidth}>
                            <Languages
                                cvId={props.templateProps.cv.id}
                                languageProficiencies={props.templateProps.cv.languageProficiencies}
                                theme={props.templateProps.theme.languageTheme}
                                inline={props.inlineLanguages ?? false}
                            />
                            {props.extraBottomElement}
                        </Grid2>
                    </Grid2>
                ) : null;
            case 'references':
                if (props.templateProps.theme.subSectionTheme.backgroundColor.referenceSection) {
                    sectionStyle = {
                        ...sectionStyle,
                        ...sectionWithBackgroundStyle,
                        backgroundColor: props.templateProps.theme.subSectionTheme.backgroundColor.referenceSection,
                    } as SxProps<Theme>;
                }
                return !!props.templateProps.cv.references.length || props.isEditing ? (
                    <Grid2 container key={`${props.templateName}-${section}`} sx={sectionStyle}>
                        {props.templateProps.theme.referenceTheme.title.show && (
                            <Grid2 size={sectionHeaderWidth} sx={props.sectionHeaderStyle}>
                                {getSectionHeader('references', props.templateProps.theme.referenceTheme.title)}
                            </Grid2>
                        )}
                        <Grid2 size={sectionBodyWidth}>
                            <References
                                cvId={props.templateProps.cv.id}
                                references={props.templateProps.cv.references}
                                theme={props.templateProps.theme.referenceTheme}
                            />
                            {props.extraBottomElement}
                        </Grid2>
                    </Grid2>
                ) : null;
            case 'interests':
                if (props.templateProps.theme.subSectionTheme.backgroundColor.interestSection) {
                    sectionStyle = {
                        ...sectionStyle,
                        ...sectionWithBackgroundStyle,
                        backgroundColor: props.templateProps.theme.subSectionTheme.backgroundColor.interestSection,
                    } as SxProps<Theme>;
                }
                return !!props.templateProps.cv.interests || props.isEditing ? (
                    <Grid2 container key={`${props.templateName}-${section}`} sx={sectionStyle}>
                        {props.templateProps.theme.interestsTheme.title.show && (
                            <Grid2 size={sectionHeaderWidth} sx={props.sectionHeaderStyle}>
                                {getSectionHeader('interest', props.templateProps.theme.interestsTheme.title)}
                            </Grid2>
                        )}
                        <Grid2 size={sectionBodyWidth}>
                            <Interests
                                cvId={props.templateProps.cv.id}
                                interests={props.templateProps.cv.interests}
                                theme={props.templateProps.theme.interestsTheme}
                            />
                            {props.extraBottomElement}
                        </Grid2>
                    </Grid2>
                ) : null;
            case 'links':
                if (props.templateProps.theme.subSectionTheme.backgroundColor.socialLinkSection) {
                    sectionStyle = {
                        ...sectionStyle,
                        ...sectionWithBackgroundStyle,
                        backgroundColor: props.templateProps.theme.subSectionTheme.backgroundColor.socialLinkSection,
                    } as SxProps<Theme>;
                }
                return !!props.templateProps.cv.socialLinks.length || props.isEditing ? (
                    <Grid2 container key={`${props.templateName}-${section}`} sx={sectionStyle}>
                        {props.templateProps.theme.socialLinkTheme.title.show && (
                            <Grid2 size={sectionHeaderWidth} sx={props.sectionHeaderStyle}>
                                {getSectionHeader('socialLinks', props.templateProps.theme.socialLinkTheme.title)}
                            </Grid2>
                        )}
                        <Grid2 size={sectionBodyWidth}>
                            <SocialLinks
                                cvId={props.templateProps.cv.id}
                                socialLinks={props.templateProps.cv.socialLinks}
                                theme={props.templateProps.theme.socialLinkTheme}
                            />
                            {props.extraBottomElement}
                        </Grid2>
                    </Grid2>
                ) : null;
            default:
                return null;
        }
    });
};
