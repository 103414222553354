import React, { useEffect } from 'react';
import ExperienceHeaderForm from './ExperienceHeaderForm';
import ExperienceItemsForm from './ExperienceItemsForm';
import dayjs, { Dayjs } from 'dayjs';
import { IExperience, IExperienceCreationDto, IExperienceUpdateDto } from '../../../../interfaces/Experience.interface';
import { IExperienceTheme } from '../../../../interfaces/Theme.interface';
import { useAxiosApi } from '../../../../hooks/useAxiosApi';
import { IApi } from '../../../../services/api.service';
import { createExperience, updateExperience } from '../../../../services/experience.service';
import { IDateRange } from '../../../../interfaces/Date.interface';
import { EditableDatePickerRange } from '../../../DatePickerRange';
import { Box, LinearProgress } from '@mui/material';

interface ExperienceProps {
    cvId: number;
    experience: IExperience;
    experiences: IExperience[];
    setExperiences: (experiences: IExperience[]) => void;
    them: IExperienceTheme;
}

const ExperienceForm = (props: ExperienceProps): React.ReactElement => {
    const api: IApi = useAxiosApi();
    const [experience, setExperience] = React.useState<IExperience>(props.experience);
    const [startDate, setStartDate] = React.useState<Dayjs>(dayjs(experience.startDate));
    const [endDate, setEndDate] = React.useState<Dayjs | null>(experience.endDate ? dayjs(experience.endDate) : null);
    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {
        updateState(experience);
    }, [experience]);

    const updateState = (_experience: IExperience) => {
        _experience.items = _experience.items ?? [];
        setExperience(_experience);
        const updatedList = props.experiences.map((e: IExperience) => (e.id === experience.id ? _experience : e));
        props.setExperiences(updatedList);
    };

    const _updateExperience = (dto: IExperienceUpdateDto) => {
        updateExperience(api, experience.id, dto).then((updatedExperience: IExperience | null) => {
            if (updatedExperience) {
                updateState(updatedExperience);
            }
        });
    };

    const onDoneClickedHeader = (experienceHeaderDto: Pick<IExperienceCreationDto, 'title'>) => {
        const dto: IExperienceUpdateDto = {
            ...experienceHeaderDto,
            startDate: startDate.toDate(),
            endDate: endDate ? endDate.toDate() : null,
        };

        if (!experience.id) {
            setIsLoading(true);
            const readyToCreate: boolean = !!experienceHeaderDto.title && !!startDate;

            if (readyToCreate) {
                const creationDto: IExperienceCreationDto = {
                    ...dto,
                    cv: { id: props.cvId },
                } as IExperienceCreationDto;

                createExperience(api, creationDto)
                    .then((newExperience: IExperience | null) => {
                        if (newExperience) {
                            updateState(newExperience);
                        }
                    })
                    .finally(() => setIsLoading(false));
            }
        } else {
            _updateExperience(dto);
        }
    };

    const onAcceptDateRange = (dateRange: IDateRange) => {
        if (!experience.id) return;

        const dto: IExperienceUpdateDto = {
            title: experience.title,
            startDate: dateRange.start.toDate(),
            endDate: dateRange.end?.toDate() ?? null,
        };

        _updateExperience(dto);
    };

    return (
        <>
            <ExperienceHeaderForm
                experience={experience}
                setExperience={setExperience}
                theme={props.them.header}
                onDoneClicked={onDoneClickedHeader}
            />
            {isLoading && <LinearProgress sx={{ my: 3 }} />}
            <Box sx={{ margin: '0 0 20px' }} />
            <EditableDatePickerRange
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                theme={props.them.date}
                onAcceptRange={onAcceptDateRange}
            />

            {!!experience.id && (
                <ExperienceItemsForm
                    experience={experience}
                    setExperience={setExperience}
                    items={experience.items}
                    theme={props.them.item}
                />
            )}
        </>
    );
};

export default ExperienceForm;
