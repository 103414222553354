import { IEducation, IEducationCreationDto } from '../../../../interfaces/Education.interface';
import { IFontTheme } from '../../../../interfaces/Theme.interface';
import React, { useState } from 'react';
import { Box } from '@mui/material';
import { EditableTextField } from '../../../GeneralTextField';
import { TranslationKey } from '../../../../interfaces/TranslationKey';

interface EducationProps {
    education: IEducation;
    setEducation: (edu: IEducation) => void;
    theme: IFontTheme;
    onDoneClicked: (dto: Pick<IEducationCreationDto, 'degree' | 'institution'>) => void;
}

const EducationHeaderForm = (props: EducationProps): React.ReactElement => {
    const [degree, setDegree] = useState(props.education.degree);
    const [institution, setInstitution] = useState(props.education.institution);

    const onDoneClickedDegree = (value: string) => {
        if (!value || value === props.education.degree) return;

        props.onDoneClicked({ institution: institution || null, degree: value });
        setDegree(value);
    };

    const onDoneClickedInstitution = (value: string) => {
        const cleanValue = value || null;
        if (cleanValue === props.education.institution) return;

        props.onDoneClicked({ institution: cleanValue, degree: degree });
        setInstitution(cleanValue);
    };

    return (
        <Box sx={{ marginBottom: '20px' }}>
            <EditableTextField
                value={degree}
                setValue={setDegree}
                label={TranslationKey.education.degree}
                minLength={2}
                required={true}
                fontTheme={props.theme}
                onDoneClicked={onDoneClickedDegree}
            />
            {!!props.education.id && (
                <EditableTextField
                    value={institution ?? ''}
                    setValue={setInstitution}
                    label={TranslationKey.education.institution}
                    minLength={3}
                    fontTheme={props.theme}
                    onDoneClicked={onDoneClickedInstitution}
                    required={false}
                />
            )}
        </Box>
    );
};

export default EducationHeaderForm;
