import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/en';
import 'dayjs/locale/ar';

dayjs.extend(localizedFormat);

export const setDayjsLocale = (language: string) => {
    dayjs.locale(language);
};
