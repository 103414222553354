import React from 'react';
import { Image } from '@react-pdf/renderer';
import { IPDFStyle } from '../../interfaces/pdfStyle.interface';
import { View } from '@react-pdf/renderer/lib/react-pdf';

interface SectionPDFProps {
    imageSrc: string;
    styles: IPDFStyle;
}

const ImagePDF = (props: SectionPDFProps): React.ReactElement => {
    return (
        <View style={{ ...props.styles.rowStyle, marginBottom: '10px' }}>
            <View style={{ ...props.styles.imageView }}>
                <Image
                    src={props.imageSrc}
                    source={props.imageSrc}
                    style={{
                        width: '100%',
                        height: '100%',
                        borderWidth: 0,
                        borderRadius: props.styles.imageView.borderRadius,
                        objectFit: 'cover',
                    }}
                />
            </View>
        </View>
    );
};

export default ImagePDF;
