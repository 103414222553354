export enum ContactTopic {
    BUG_REPORT = 'BUG_REPORT',
    FEATURE_REQUEST = 'FEATURE_REQUEST',
    AUTH_ISSUE = 'AUTH_ISSUE',
    GENERAL_INQUIRY = 'GENERAL_INQUIRY',
    OTHER = 'OTHER',
}

export interface IContactUs {
    id: string;
    email: string;
    topic: ContactTopic;
    message: string;
}

export type IContactUsDto = Omit<IContactUs, 'id'>;
