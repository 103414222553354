import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { GeneralTextField } from '../GeneralTextField';
import { INameTheme } from '../../interfaces/Theme.interface';
import { updateCV } from '../../services/cv.service';
import { IApi } from '../../services/api.service';
import { useAxiosApi } from '../../hooks/useAxiosApi';
import { useMode } from '../../context/Mode.context';
import { Mode } from '../../interfaces/Mode.interface';
import { getFontSize } from '../../utils/common.util';
import { useCV } from '../../context/CV.context';

interface NameProps {
    cvId: number;
    firstName: string;
    lastName: string;
    theme: INameTheme;
}

const Name = (props: NameProps): React.ReactElement => {
    const { mode } = useMode();
    const api: IApi = useAxiosApi();
    const { cv, dispatch } = useCV();

    const [firstName, setFirstName] = useState<string>(props.firstName);
    const [lastName, setLastName] = useState<string>(props.lastName);

    const onBlurFirstName = (value: string) => {
        if (!value || value === cv?.firstName) return;

        updateCV(api, props.cvId, { firstName: value }).then(() => {
            dispatch({ type: 'UPDATE_FIELD', field: 'firstName', value: value });
        });
        setFirstName(value);
    };

    const onBlurLastName = (value: string) => {
        if (!value || value === cv?.lastName) return;

        updateCV(api, props.cvId, { lastName: value }).then(() => {
            dispatch({ type: 'UPDATE_FIELD', field: 'lastName', value: value });
        });
        setLastName(value);
    };

    const isEditing: boolean = mode === Mode.EDIT;

    return isEditing ? (
        <Box id="name" display="flex" gap={5}>
            <GeneralTextField
                value={firstName}
                setValue={setFirstName}
                label={'firstName'}
                minLength={3}
                required={true}
                fontTheme={props.theme.first}
                typographyComponent="h1"
                onDoneClicked={onBlurFirstName}
            />
            <GeneralTextField
                value={lastName}
                setValue={setLastName}
                label={'lastName'}
                minLength={3}
                required={true}
                fontTheme={props.theme.last}
                typographyComponent="h1"
                onDoneClicked={onBlurLastName}
            />
        </Box>
    ) : (
        <Typography display={'flex'} flexWrap={'wrap'}>
            <span
                style={{
                    color: props.theme.first.color,
                    fontSize: getFontSize(props.theme.first.variant),
                    fontWeight: props.theme.first.weight === 'light' ? 'lighter' : props.theme.first.weight,
                    fontStyle: props.theme.first.style,
                }}
            >{`${firstName}`}</span>
            <span
                style={{
                    color: props.theme.last.color,
                    fontSize: getFontSize(props.theme.last.variant),
                    fontWeight: props.theme.last.weight === 'light' ? 'lighter' : props.theme.last.weight,
                    fontStyle: props.theme.last.style,
                    margin: `0 ${props.theme.margin.gap}px`,
                }}
            >{`\u200C${lastName}`}</span>
        </Typography>
    );
};

export default Name;
