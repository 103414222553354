import Cropper, { Area } from 'react-easy-crop';
import { IImageTheme } from '../../../interfaces/Theme.interface';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAxiosApi } from '../../../hooks/useAxiosApi';
import { getImage, removeImage, uploadImage } from '../../../services/cv.service';
import { Box, Button, Dialog, DialogActions, DialogContent, Grid2, Slider, Typography } from '@mui/material';
import { TranslationKey } from '../../../interfaces/TranslationKey';
import { IApi } from '../../../services/api.service';
import { getExtensionFromMimeType } from '../../../utils/common.util';
import { useCV } from '../../../context/CV.context';
import { ICV } from '../../../interfaces/CV.interface';

const getCroppedImg = (imageSrc: string, crop: Area): Promise<Blob | null> => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = imageSrc;
        image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            if (!ctx) {
                reject(new Error('Failed to get canvas context'));
                return;
            }

            canvas.width = crop.width;
            canvas.height = crop.height;

            ctx.drawImage(image, crop.x, crop.y, crop.width, crop.height, 0, 0, canvas.width, canvas.height);

            const mimeType = () => {
                if (imageSrc.startsWith('data:')) {
                    const match = imageSrc.match(/data:(image\/[a-zA-Z+.-]+);base64,/);
                    return match ? match[1] : 'image/jpeg';
                }
                return 'image/jpeg';
            };

            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        reject(new Error('Canvas is empty'));
                        return;
                    }
                    resolve(blob);
                },
                mimeType(),
                0.8,
            );
        };

        image.onerror = (error) => reject(error);
    });
};

export interface ImageProps {
    theme: IImageTheme;
}

const ImageForm = (props: ImageProps): React.ReactElement => {
    const { t } = useTranslation();
    const api: IApi = useAxiosApi();
    const { cv, dispatch } = useCV();
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [selectedImg, setSelectedImg] = useState<string | null>(null);
    const [open, setOpen] = useState(false);
    const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState<number>(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

    useEffect(() => {
        if (!!cv?.profileImage) {
            getImage(api, cv.id).then((image) => {
                if (image) {
                    setImageSrc(image);
                }
            });
        } else {
            setImageSrc(null);
        }
    }, [cv?.profileImage, cv?.id]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (reader.result) {
                    setSelectedImg(reader.result as string);
                    setZoom(1);
                    setOpen(true); // Open the modal after selecting an image
                }
            };
        }
    };

    const onCropComplete = useCallback((_: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const _removeImage = () => {
        setImageSrc(null);
        if (cv?.id) {
            removeImage(api, cv.id).then();
            dispatch({ type: 'UPDATE_FIELD', field: 'profileImage', value: null });
        }
    };

    const _uploadImage = () => {
        document.getElementById('image-profile-input')?.click();
    };

    const handleCropSave = async () => {
        if (!selectedImg || !croppedAreaPixels) return;
        try {
            const blob: Blob | null = await getCroppedImg(selectedImg, croppedAreaPixels);

            if (!blob) return;

            const formData: FormData = new FormData();
            formData.append('profileImage', new File([blob], `profile-image.${getExtensionFromMimeType(blob.type)}`, { type: blob.type }));

            if (cv?.id) {
                uploadImage(api, cv.id, formData).then((_cv: ICV | null) => {
                    if (_cv) {
                        dispatch({ type: 'UPDATE_FIELD', field: 'profileImage', value: _cv.profileImage });
                    }
                });
            }

            const fileUrl = URL.createObjectURL(blob);
            setImageSrc(fileUrl);

            setOpen(false);
        } catch (e) {
            console.error(e);
        }
    };

    const handleCropCancel = () => {
        setOpen(false);
        setSelectedImg(null);
    };

    return (
        <Box>
            <input
                id="image-profile-input"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: 'none', marginBottom: 16 }}
            />
            <Grid2 container alignItems={'center'} justifyContent={'center'} spacing={4}>
                <Grid2
                    display={'flex'}
                    alignItems="center"
                    justifyContent="center"
                    onClick={_uploadImage}
                    sx={{
                        cursor: 'pointer',
                        border: !imageSrc ? '2px dashed red' : null,
                        width: props.theme.width,
                        height: props.theme.height,
                    }}
                >
                    {imageSrc ? (
                        <Box
                            component="img"
                            src={imageSrc}
                            alt={t(TranslationKey.image.profileImage)}
                            sx={{
                                width: props.theme.width,
                                height: props.theme.height,
                                objectFit: 'cover',
                                borderRadius: props.theme.circular ? '50%' : null,
                                border: props.theme.hasBorder ? `solid ${props.theme.borderColor} ${props.theme.borderWidth}px` : undefined,
                            }}
                        />
                    ) : (
                        <Typography>Profile Image</Typography>
                    )}
                </Grid2>
                <Grid2 size={{ xs: 4, sm: 3, md: 2 }}>
                    <Button fullWidth sx={{ mb: 2 }} variant={'outlined'} onClick={_uploadImage}>
                        {t(TranslationKey.image.upload)}
                    </Button>
                    <Button fullWidth variant={'outlined'} onClick={_removeImage}>
                        {t(TranslationKey.image.remove)}
                    </Button>
                </Grid2>
            </Grid2>

            {/* Modal for Cropping */}
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
                <DialogContent>
                    {selectedImg && (
                        <Box
                            sx={{
                                position: 'relative',
                                width: '100%',
                                height: 400,
                                background: '#333',
                            }}
                        >
                            <Cropper
                                image={selectedImg}
                                crop={crop}
                                zoom={zoom}
                                aspect={1}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                            />
                        </Box>
                    )}
                    <Box sx={{ mt: 2 }}>
                        <Typography>{t(TranslationKey.info.zoom)}</Typography>
                        <Slider value={zoom} min={1} max={3} step={0.1} onChange={(e, value) => setZoom(value as number)} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleCropCancel} color="error">
                        {t(TranslationKey.buttons.cancel)}
                    </Button>
                    <Button variant="contained" onClick={handleCropSave} color="primary">
                        {t(TranslationKey.buttons.save)}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ImageForm;
