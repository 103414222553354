import { createTheme, ThemeOptions } from '@mui/material/styles';
import createCache from '@emotion/cache';
import stylisRtlPlugin from 'stylis-plugin-rtl';

import '@fontsource/tajawal';

export const cacheRtl = createCache({
    key: 'mui-rtl',
    stylisPlugins: [stylisRtlPlugin],
});

export const cacheLtr = createCache({
    key: 'mui-ltr', // Separate cache for LTR
});

export const themeOptions: { [key: string]: ThemeOptions } = {
    ltr: {
        direction: 'ltr',
        typography: {
            fontFamily: 'Roboto, Arial, sans-serif',
        },
        palette: {
            primary: {
                main: '#1d8fbe',
            },
            secondary: {
                main: '#10334c',
            },
        },
    },
    rtl: {
        direction: 'rtl',
        typography: {
            fontFamily: 'Tajawal, Roboto, Arial, sans-serif',
        },
        palette: {
            primary: {
                main: '#1d8fbe',
            },
            secondary: {
                main: '#10334c',
            },
        },
    },
};

export const createAppTheme = (direction: 'ltr' | 'rtl') => createTheme(themeOptions[direction]);
