import React from 'react';
import { ICV } from '../../interfaces/CV.interface';
import { ILayout, ITheme, SectionName } from '../../interfaces/Theme.interface';
import { getPDFStyles } from '../../utils/theme.util';
import { Document, Page, View } from '@react-pdf/renderer/lib/react-pdf';
import { IPDFStyle } from '../../interfaces/pdfStyle.interface';
import { useTranslation } from 'react-i18next';
import { TemplateName } from '../../common/enum';
import { getSections } from '../Template.util';
import { Style } from '@react-pdf/types';
import { getPDFSectionElements, IPDFSectionElementsProps } from '../PDFTemplate.util';

interface PDFProps {
    cv: ICV;
    imageSrc: string | null;
    theme: ITheme;
}

const AlqudsTemplatePDF = (props: PDFProps): React.ReactElement => {
    const { i18n } = useTranslation();

    const isRTL: boolean = i18n.dir() === 'rtl';

    const styles: IPDFStyle = getPDFStyles(props.theme, isRTL);

    const padding: string = '0 30px';

    const templateName: TemplateName = TemplateName.ALQUDS;

    const layout: ILayout = getSections(props.theme.layoutTheme, templateName);

    const showTopSection: boolean = layout.top.sections.some((i) => !!i);
    const showTopRightSection: boolean = layout.topRight.sections.some((i) => !!i);
    const showTopLeftRightSection: boolean = showTopRightSection || layout.topLeft.sections.some((i) => !!i);

    const showMiddleSection: boolean = layout.middle.sections.some((i) => !!i);
    const showMiddleLeftRightSection: boolean = layout.middleLeft.sections.some((i) => !!i) || layout.middleRight.sections.some((i) => !!i);

    const showBottomSection: boolean = layout.bottom.sections.some((i) => !!i);
    const showBottomLeftRightSection: boolean = layout.bottomLeft.sections.some((i) => !!i) || layout.bottomRight.sections.some((i) => !!i);

    const sectionHeaderStyle: Style = {
        backgroundColor: props.theme.layoutTheme.borderColor ?? undefined,
        margin: '0 -30px',
        padding: isRTL ? '8px 30px 0' : '2px 30px',
    };

    const getProps = (sections: SectionName[]): IPDFSectionElementsProps => ({
        templateName: templateName,
        sections: sections,
        styles: styles,
        pdfProps: props,
        sectionHeaderStyle: sectionHeaderStyle,
    });

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* top */}
                {showTopSection && <View style={{ padding: padding }}>{getPDFSectionElements(getProps(layout.top.sections))}</View>}

                {/* top left/right */}
                {showTopLeftRightSection && (
                    <View style={{ ...styles.rowStyle, gap: '16px', padding: padding }}>
                        <View style={{ width: showTopRightSection ? styles.leftTopView.width : '100%' }}>
                            {getPDFSectionElements(getProps(layout.topLeft.sections))}
                        </View>
                        {showTopRightSection && (
                            <View style={{ width: styles.rightTopView.width, alignItems: isRTL ? 'flex-end' : 'flex-start' }}>
                                {getPDFSectionElements(getProps(layout.topRight.sections))}
                            </View>
                        )}
                    </View>
                )}

                {/* middle */}
                {showMiddleSection && <View style={{ padding: padding }}>{getPDFSectionElements(getProps(layout.middle.sections))}</View>}

                {/* middle left/right */}
                {showMiddleLeftRightSection && (
                    <View style={{ ...styles.rowStyle, padding: padding }}>
                        <View style={styles.leftMiddleView}>{getPDFSectionElements(getProps(layout.middleLeft.sections))}</View>
                        <View style={styles.rightMiddleView}>{getPDFSectionElements(getProps(layout.middleRight.sections))}</View>
                    </View>
                )}

                {/* bottom */}
                {showBottomSection && <View style={{ padding: padding }}>{getPDFSectionElements(getProps(layout.bottom.sections))}</View>}

                {/* bottom left/right */}
                {showBottomLeftRightSection && (
                    <View style={{ ...styles.rowStyle, padding: padding }}>
                        <View style={styles.leftBottomView}>{getPDFSectionElements(getProps(layout.bottomLeft.sections))}</View>
                        <View style={styles.rightBottomView}>{getPDFSectionElements(getProps(layout.bottomRight.sections))}</View>
                    </View>
                )}
            </Page>
        </Document>
    );
};

export default AlqudsTemplatePDF;
