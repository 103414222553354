import React from 'react';
import { Box, Container, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../interfaces/TranslationKey';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Helmet } from 'react-helmet-async';

const PrivacyPolicyPage: React.FC = () => {
    const { t } = useTranslation();
    return (
        <>
            <Helmet>
                <title>{t(TranslationKey.helmet.title.privacyPolicyPage)}</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <Container>
                <Paper elevation={3} sx={{ padding: 3, marginY: 3 }}>
                    <Typography variant="h4" gutterBottom>
                        {t(TranslationKey.privacyPolicy.title)}
                    </Typography>
                    <Typography variant="h6">{t(TranslationKey.privacyPolicy.lastUpdated)}</Typography>
                    <Typography variant="body1">{t(TranslationKey.privacyPolicy.introduction)}</Typography>
                    <Typography variant="body1" sx={{ mt: 1 }}>
                        {t(TranslationKey.privacyPolicy.introductionSub)}
                    </Typography>

                    <Box sx={{ mt: 3 }}>
                        <Typography variant="h6">{t(TranslationKey.privacyPolicy.informationWeCollect.title)}</Typography>
                        <Typography variant="body1">{t(TranslationKey.privacyPolicy.informationWeCollect.description)}</Typography>
                        <Box sx={{ mt: 2 }}>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">
                                    <span style={{ fontWeight: 'bold' }}>
                                        {t(TranslationKey.privacyPolicy.informationWeCollect.accountInformation.title)}
                                    </span>
                                    {t(TranslationKey.privacyPolicy.informationWeCollect.accountInformation.description)}
                                </Typography>
                            </Box>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">
                                    <span style={{ fontWeight: 'bold' }}>
                                        {t(TranslationKey.privacyPolicy.informationWeCollect.resumeData.title)}
                                    </span>
                                    {t(TranslationKey.privacyPolicy.informationWeCollect.resumeData.description)}
                                </Typography>
                            </Box>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">
                                    <span style={{ fontWeight: 'bold' }}>
                                        {t(TranslationKey.privacyPolicy.informationWeCollect.cookies.title)}
                                    </span>
                                    {t(TranslationKey.privacyPolicy.informationWeCollect.cookies.description)}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <Typography variant="h6">{t(TranslationKey.privacyPolicy.howWeUseYourInformation.title)}</Typography>
                        <Typography variant="body1">{t(TranslationKey.privacyPolicy.howWeUseYourInformation.description)}</Typography>
                        <Box sx={{ mt: 2 }}>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">{t(TranslationKey.privacyPolicy.howWeUseYourInformation.item1)}</Typography>
                            </Box>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">{t(TranslationKey.privacyPolicy.howWeUseYourInformation.item2)}</Typography>
                            </Box>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">{t(TranslationKey.privacyPolicy.howWeUseYourInformation.item3)}</Typography>
                            </Box>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">{t(TranslationKey.privacyPolicy.howWeUseYourInformation.item4)}</Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <Typography variant="h6">{t(TranslationKey.privacyPolicy.dataStorageAndSecurity.title)}</Typography>
                        <Box sx={{ mt: 1 }}>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">{t(TranslationKey.privacyPolicy.dataStorageAndSecurity.item1)}</Typography>
                            </Box>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">{t(TranslationKey.privacyPolicy.dataStorageAndSecurity.item2)}</Typography>
                            </Box>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">{t(TranslationKey.privacyPolicy.dataStorageAndSecurity.item3)}</Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <Typography variant="h6">{t(TranslationKey.privacyPolicy.cookies.title)}</Typography>
                        <Box sx={{ mt: 1 }}>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">{t(TranslationKey.privacyPolicy.cookies.item1)}</Typography>
                            </Box>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">{t(TranslationKey.privacyPolicy.cookies.item2)}</Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <Typography variant="h6">{t(TranslationKey.privacyPolicy.donations.title)}</Typography>
                        <Box sx={{ mt: 1 }}>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">{t(TranslationKey.privacyPolicy.donations.item1)}</Typography>
                            </Box>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">{t(TranslationKey.privacyPolicy.donations.item2)}</Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <Typography variant="h6">{t(TranslationKey.privacyPolicy.yourRights.title)}</Typography>
                        <Box sx={{ mt: 1 }}>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">{t(TranslationKey.privacyPolicy.yourRights.item1)}</Typography>
                            </Box>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">{t(TranslationKey.privacyPolicy.yourRights.item2)}</Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <Typography variant="h6">{t(TranslationKey.privacyPolicy.changesToThisPrivacyPolicy.title)}</Typography>
                        <Typography variant="body1">{t(TranslationKey.privacyPolicy.changesToThisPrivacyPolicy.description)}</Typography>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <Typography variant="h6">{t(TranslationKey.privacyPolicy.contactUs.title)}</Typography>
                        <Typography variant="body1">{t(TranslationKey.privacyPolicy.contactUs.description)}</Typography>
                    </Box>
                </Paper>
            </Container>
        </>
    );
};

export default PrivacyPolicyPage;
