import React from 'react';
import { Box, LinearProgress, Slider } from '@mui/material';
import { useMode } from '../../context/Mode.context';
import { Mode } from '../../interfaces/Mode.interface';
import { useTranslation } from 'react-i18next';
import { IProficiencyTheme } from '../../interfaces/Theme.interface';
import DashedProgress from './Dahsed';
import DottedProgress from './Dotted';

interface ProficiencyProps<T> {
    value: T;
    setValue: (value: T) => void;
    valueToProficiency: Record<number, T>;
    proficiencyToValue: Record<string, number>;
    theme: IProficiencyTheme;
    onChange?: (value: T) => void;
    labelKey: string;
}

export const EditableProficiency = <T,>(props: ProficiencyProps<T>): React.ReactElement => {
    const { t } = useTranslation();

    const handleSliderChange = (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
        props.setValue(props.valueToProficiency[newValue as number]);
        if (props.onChange) {
            props.onChange(props.valueToProficiency[newValue as number]);
        }
    };

    return (
        <Box sx={{ p: 2 }}>
            {!!props.value && (
                <Slider
                    value={props.proficiencyToValue[props.value as string]}
                    onChangeCommitted={handleSliderChange}
                    defaultValue={40}
                    step={20}
                    marks={[
                        { value: 20, label: <Box sx={{ fontSize: '0.9em' }}>{t(props.valueToProficiency[20] as string)}</Box> },
                        { value: 40, label: <Box sx={{ fontSize: '0.9em' }}>{t(props.valueToProficiency[40] as string)}</Box> },
                        { value: 60, label: <Box sx={{ fontSize: '0.9em' }}>{t(props.valueToProficiency[60] as string)}</Box> },
                        { value: 80, label: <Box sx={{ fontSize: '0.9em' }}>{t(props.valueToProficiency[80] as string)}</Box> },
                        { value: 100, label: <Box sx={{ fontSize: '0.9em' }}>{t(props.valueToProficiency[100] as string)}</Box> },
                    ]}
                    min={20}
                    max={100}
                    sx={{ mb: 2 }}
                />
            )}
        </Box>
    );
};

export const PrintableProficiency = <T,>(
    props: Pick<ProficiencyProps<T>, 'value' | 'labelKey' | 'proficiencyToValue' | 'theme'>,
): React.ReactElement => {
    switch (props.theme.type) {
        case 'none':
            return <></>;
        case 'dashed':
            return <DashedProgress value={props.proficiencyToValue[props.value as string]} theme={props.theme} labelKey={props.labelKey} />;
        case 'dotted':
            return <DottedProgress value={props.proficiencyToValue[props.value as string]} theme={props.theme} labelKey={props.labelKey} />;
        default:
            return (
                <LinearProgress
                    variant="determinate"
                    value={props.proficiencyToValue[props.value as string]}
                    sx={{
                        width: '100%',
                        height: props.theme.thickness,
                        borderRadius: props.theme.borderRadius,
                        bgcolor: props.theme.inactiveColor,
                        '& .MuiLinearProgress-bar': {
                            bgcolor: props.theme.activeColor,
                        },
                    }}
                />
            );
    }
};

const Proficiency = <T,>(props: ProficiencyProps<T>): React.ReactElement => {
    const { mode } = useMode();

    switch (mode) {
        case Mode.EDIT:
            return <EditableProficiency {...props} />;
        case Mode.PRINT:
            return <PrintableProficiency {...props} />;
    }
};

export default Proficiency;
