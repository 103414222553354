import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const Loading = (): React.ReactElement => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '80vh' }}>
            <CircularProgress size="3rem" />
        </Box>
    );
};

export default Loading;
