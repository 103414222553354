import React from 'react';
import { Box, Grid2 } from '@mui/material';
import { useMode } from '../../context/Mode.context';
import { Mode } from '../../interfaces/Mode.interface';
import { ITemplateProps } from '../../interfaces/Template.interface';
import { ILayout, SectionName } from '../../interfaces/Theme.interface';
import { TemplateName } from '../../common/enum';
import { getSectionElements, getSections, ISectionElementsProps } from '../Template.util';

const AlqudsTemplate = (props: ITemplateProps): React.ReactElement => {
    const { mode } = useMode();
    const isEditing: boolean = mode === Mode.EDIT;

    const templateName: TemplateName = TemplateName.ALQUDS;

    const layout: ILayout = getSections(props.theme.layoutTheme, templateName);

    const showTopSection: boolean = layout.top.sections.some((i) => !!i);
    const showTopRightSection: boolean = layout.topRight.sections.some((i) => !!i);
    const showTopLeftRightSection: boolean = showTopRightSection || layout.topLeft.sections.some((i) => !!i);

    const showMiddleSection: boolean = layout.middle.sections.some((i) => !!i);
    const showMiddleLeftRightSection: boolean = layout.middleLeft.sections.some((i) => !!i) || layout.middleRight.sections.some((i) => !!i);

    const showBottomSection: boolean = layout.bottom.sections.some((i) => !!i);
    const showBottomLeftRightSection: boolean = layout.bottomLeft.sections.some((i) => !!i) || layout.bottomRight.sections.some((i) => !!i);

    const padding: string = '40px';

    const getProps = (sections: SectionName[]): ISectionElementsProps => ({
        templateName: templateName,
        sections: sections,
        templateProps: props,
        isEditing: isEditing,
        sectionHeaderStyle: {
            backgroundColor: props.theme.layoutTheme.borderColor,
            mx: '-' + padding,
            px: padding,
        },
        sectionHeaderWidth: 12,
    });

    return (
        <Box>
            {/* top */}
            {showTopSection && (
                <Box width={'100%'} sx={{ px: padding }}>
                    {getSectionElements(getProps(layout.top.sections))}
                </Box>
            )}

            {/* top left/right */}
            {showTopLeftRightSection && (
                <Grid2 container size={12} columnSpacing={'20px'} px={padding}>
                    <Grid2
                        size={isEditing ? 12 : showTopRightSection ? props.theme.layoutTheme.sectionWidth.topLeftWidth : 12}
                        alignItems="flex-start"
                        alignContent="flex-start"
                        spacing={isEditing ? 2 : 0}
                    >
                        {getSectionElements(getProps(layout.topLeft.sections))}
                    </Grid2>
                    {showTopRightSection && (
                        <Grid2 size={isEditing ? 12 : 12 - props.theme.layoutTheme.sectionWidth.topLeftWidth} spacing={isEditing ? 2 : 0}>
                            {getSectionElements(getProps(layout.topRight.sections))}
                        </Grid2>
                    )}
                </Grid2>
            )}

            {/* middle */}
            {showMiddleSection && (
                <Box width={'100%'} sx={{ px: padding }}>
                    {getSectionElements(getProps(layout.middle.sections))}
                </Box>
            )}

            {/* middle left/right */}
            {showMiddleLeftRightSection && (
                <Box width={'100%'}>
                    <Grid2 container={true} size={12} px={padding}>
                        <Grid2 size={isEditing ? 12 : props.theme.layoutTheme.sectionWidth.middleLeftWidth} spacing={isEditing ? 2 : 0}>
                            {getSectionElements({
                                ...getProps(layout.middleLeft.sections),
                                sectionHeaderStyle: {
                                    backgroundColor: props.theme.layoutTheme.borderColor,
                                    mx: '-' + padding,
                                    width: '200%',
                                    px: padding,
                                },
                            })}
                        </Grid2>
                        <Grid2
                            size={isEditing ? 12 : 12 - props.theme.layoutTheme.sectionWidth.middleLeftWidth}
                            spacing={isEditing ? 2 : 0}
                        >
                            {getSectionElements({
                                ...getProps(layout.middleRight.sections),
                                sectionHeaderStyle: {
                                    backgroundColor: props.theme.layoutTheme.borderColor,
                                    mx: '-' + padding,
                                    width: '200%',
                                    px: padding,
                                },
                            })}
                        </Grid2>
                    </Grid2>
                </Box>
            )}

            {/* bottom */}
            {showBottomSection && (
                <Box width={'100%'} sx={{ px: padding }}>
                    {getSectionElements(getProps(layout.bottom.sections))}
                </Box>
            )}

            {/* bottom left/right */}
            {showBottomLeftRightSection && (
                <Box width={'100%'}>
                    <Grid2 container={true} size={12} px={padding}>
                        <Grid2 size={isEditing ? 12 : props.theme.layoutTheme.sectionWidth.bottomLeftWidth} spacing={isEditing ? 2 : 0}>
                            {getSectionElements({
                                ...getProps(layout.bottomLeft.sections),
                                sectionHeaderStyle: {
                                    backgroundColor: props.theme.layoutTheme.borderColor,
                                    mx: '-' + padding,
                                    width: '200%',
                                    px: padding,
                                },
                            })}
                        </Grid2>
                        <Grid2
                            size={isEditing ? 12 : 12 - props.theme.layoutTheme.sectionWidth.bottomLeftWidth}
                            spacing={isEditing ? 2 : 0}
                        >
                            {getSectionElements({
                                ...getProps(layout.bottomRight.sections),
                                sectionHeaderStyle: {
                                    backgroundColor: props.theme.layoutTheme.borderColor,
                                    mx: '-' + padding,
                                    width: '200%',
                                    px: padding,
                                },
                            })}
                        </Grid2>
                    </Grid2>
                </Box>
            )}
        </Box>
    );
};

export default AlqudsTemplate;
