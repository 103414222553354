import { ISocialLink, ISocialLinkUpdateDto, SocialPlatform } from '../../../../interfaces/SocialLink.interface';
import { IFontTheme } from '../../../../interfaces/Theme.interface';
import React, { useState } from 'react';
import { EditableTextField } from '../../../GeneralTextField';
import { TranslationKey } from '../../../../interfaces/TranslationKey';

interface SocialLinkProps {
    socialLink: ISocialLink;
    setSocialLink: (s: ISocialLink) => void;
    headerTheme: IFontTheme;
    onDoneClicked: (dto: ISocialLinkUpdateDto) => void;
}

const SocialLinkHeaderForm = (props: SocialLinkProps): React.ReactElement => {
    const [url, setUrl] = useState<string>(props.socialLink.url);
    const [platform, setPlatform] = useState<SocialPlatform>(props.socialLink.platform);

    const onDoneClickedUrl = (value: string) => {
        if (!value || value === props.socialLink.url) return;

        props.onDoneClicked({ url: value, platform: platform || SocialPlatform.OTHER });
        setUrl(value);
    };

    const onDoneClickedPlatform = (value: SocialPlatform) => {
        const cleanValue = value || SocialPlatform.OTHER;
        if (cleanValue === props.socialLink.platform) return;

        props.onDoneClicked({ url: url, platform: cleanValue });
        setPlatform(cleanValue);
    };

    return (
        <EditableTextField
            value={url}
            setValue={setUrl}
            label={TranslationKey.socialLink.url}
            minLength={3}
            required={true}
            fontTheme={props.headerTheme}
            onDoneClicked={onDoneClickedUrl}
        />
    );
};

export default SocialLinkHeaderForm;
