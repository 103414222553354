import React from 'react';
import { Box, Grid2 } from '@mui/material';
import { useMode } from '../../context/Mode.context';
import { Mode } from '../../interfaces/Mode.interface';
import { ITemplateProps } from '../../interfaces/Template.interface';
import { ILayout, SectionName } from '../../interfaces/Theme.interface';
import { TemplateName } from '../../common/enum';
import { getSectionElements, getSections, ISectionElementsProps } from '../Template.util';

const HamaTemplate = (props: ITemplateProps): React.ReactElement => {
    const { mode } = useMode();
    const isEditing: boolean = mode === Mode.EDIT;

    const showImagePlaceholder: boolean = isEditing || !!props.cv.profileImage;

    const templateName: TemplateName = TemplateName.HAMA;

    const layout: ILayout = getSections(props.theme.layoutTheme, templateName);

    const showTopSection: boolean = layout.top.sections.some((i) => !!i);
    const showTopLeftSection: boolean = layout.topLeft.sections.some((i) => !!i);
    const showTopLeftRightSection: boolean = showTopLeftSection || layout.topRight.sections.some((i) => !!i);

    const showMiddleSection: boolean = layout.middle.sections.some((i) => !!i);
    const showMiddleLeftRightSection: boolean = layout.middleLeft.sections.some((i) => !!i) || layout.middleRight.sections.some((i) => !!i);

    const showBottomSection: boolean = layout.bottom.sections.some((i) => !!i);
    const showBottomLeftRightSection: boolean = layout.bottomLeft.sections.some((i) => !!i) || layout.bottomRight.sections.some((i) => !!i);

    const padding = '20px 20px 0';

    const getProps = (sections: SectionName[]): ISectionElementsProps => ({
        templateName: templateName,
        sections: sections,
        templateProps: props,
        isEditing: isEditing,
    });

    return (
        <>
            {/* top */}
            {showTopSection && <Box sx={{ p: padding }}>{getSectionElements(getProps(layout.top.sections))}</Box>}

            {/* top left/right */}
            {showTopLeftRightSection && (
                <Grid2
                    container
                    size={12}
                    sx={{
                        backgroundColor: isEditing ? undefined : props.theme.subSectionTheme.backgroundColor.nameSection,
                        p: padding,
                    }}
                >
                    {showTopLeftSection && (
                        <Grid2
                            size={{
                                xs: isEditing ? 12 : props.theme.layoutTheme.sectionWidth.topLeftWidth,
                                lg: props.theme.layoutTheme.sectionWidth.topLeftWidth,
                            }}
                            sx={{ position: 'relative' }}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: '-40%',
                                    left: '50%',
                                    transform: 'translate(-60%, 0%)', // Center horizontally, move it further down
                                    zIndex: 2, // Ensure it appears above other content
                                }}
                            >
                                {getSectionElements(getProps(layout.topLeft.sections))}
                            </Box>
                        </Grid2>
                    )}
                    <Grid2
                        size={{
                            xs: isEditing ? 12 : 12 - props.theme.layoutTheme.sectionWidth.topLeftWidth,
                            lg: 12 - props.theme.layoutTheme.sectionWidth.topLeftWidth,
                        }}
                        alignItems="center"
                        alignContent="center"
                        justifyItems={'flex-start'}
                        marginLeft={showTopLeftSection ? 0 : '20px'}
                    >
                        {getSectionElements(getProps(layout.topRight.sections))}
                    </Grid2>
                </Grid2>
            )}

            {/* middle */}
            {showMiddleSection && <Box sx={{ p: padding }}>{getSectionElements(getProps(layout.middle.sections))}</Box>}

            {/* middle left/right */}
            {showMiddleLeftRightSection && (
                <Grid2 container size={12}>
                    <Grid2
                        size={{
                            xs: isEditing ? 12 : props.theme.layoutTheme.sectionWidth.middleLeftWidth,
                            lg: props.theme.layoutTheme.sectionWidth.middleLeftWidth,
                        }}
                        sx={{
                            backgroundColor: isEditing ? undefined : props.theme.subSectionTheme.backgroundColor.leftSection,
                            p: padding,
                            pt: showImagePlaceholder ? '50px' : '20px',
                            borderRight: isEditing ? 'solid 1px #444' : undefined,
                        }}
                    >
                        {getSectionElements(getProps(layout.middleLeft.sections))}
                    </Grid2>
                    <Grid2
                        size={{
                            xs: isEditing ? 12 : 12 - props.theme.layoutTheme.sectionWidth.middleLeftWidth,
                            lg: 12 - props.theme.layoutTheme.sectionWidth.middleLeftWidth,
                        }}
                        sx={{ p: padding }}
                    >
                        {getSectionElements(getProps(layout.middleRight.sections))}
                    </Grid2>
                </Grid2>
            )}

            {/* bottom */}
            {showBottomSection && <Box sx={{ p: padding }}>{getSectionElements(getProps(layout.bottom.sections))}</Box>}

            {/* bottom left/right */}
            {showBottomLeftRightSection && (
                <Grid2 container size={12}>
                    <Grid2
                        size={{
                            xs: isEditing ? 12 : props.theme.layoutTheme.sectionWidth.bottomLeftWidth,
                            lg: props.theme.layoutTheme.sectionWidth.bottomLeftWidth,
                        }}
                        sx={{
                            backgroundColor: isEditing ? undefined : props.theme.subSectionTheme.backgroundColor.leftSection,
                            p: padding,
                            borderRight: isEditing ? 'solid 1px #444' : undefined,
                        }}
                    >
                        {getSectionElements(getProps(layout.bottomLeft.sections))}
                    </Grid2>
                    <Grid2
                        size={{
                            xs: isEditing ? 12 : 12 - props.theme.layoutTheme.sectionWidth.bottomLeftWidth,
                            lg: 12 - props.theme.layoutTheme.sectionWidth.bottomLeftWidth,
                        }}
                        sx={{ p: padding }}
                    >
                        {getSectionElements(getProps(layout.bottomRight.sections))}
                    </Grid2>
                </Grid2>
            )}
        </>
    );
};

export default HamaTemplate;
