import React, { useState } from 'react';
import { AppBar, Box, Button, Divider, Drawer, List, ListItem, ListItemText, Toolbar } from '@mui/material';
import LanguageSelector from './LanguageSelector';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../interfaces/TranslationKey';
import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import { useAuth } from '../context/Auth.context';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import InboxIcon from '@mui/icons-material/Inbox';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LanguageIcon from '@mui/icons-material/Language';
import LogoutButton from './auth/LogoutButton';
import Logo from './Logo';
import CVCreationButton from './CVCreationButton';
import ActivationRequestButton from './auth/ActivationRequestButton';
import { getLocalizedPath } from '../utils/common.util';

interface NavbarProps {
    setDirection: (direction: 'rtl' | 'ltr') => void;
    onContactUsClicked: (event: React.MouseEvent<HTMLElement>) => void;
}

const Navbar = (props: NavbarProps): React.ReactElement => {
    const { t, i18n } = useTranslation();
    const { user } = useAuth();
    const navigate = useNavigate();
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handlePersonIconClick = () => {
        navigate(getLocalizedPath('/profile'));
    };

    const handleHomeClick = () => {
        navigate(getLocalizedPath(`/`));
    };

    const handleLoginClick = () => {
        navigate(getLocalizedPath(`/login`));
    };

    const handleSignUpClick = () => {
        navigate(getLocalizedPath(`/signup`));
    };

    const drawerContent = (
        <Box sx={{ width: 250 }} onClick={handleDrawerToggle}>
            <List>
                <Box display={'flex'} sx={{ flexGrow: 1 }} dir={'ltr'} justifyContent={'center'}>
                    <Logo />
                </Box>
                <Divider sx={{ my: 1 }} />

                <ListItem onClick={handlePersonIconClick}>
                    <PersonIcon sx={{ cursor: 'pointer', mx: 1 }} />
                    <ListItemText primary={t(TranslationKey.profile)} />
                </ListItem>
                <ListItem onClick={handleHomeClick}>
                    <HomeIcon sx={{ mx: 1 }} />
                    <ListItemText primary={t(TranslationKey.landingPage._name)} />
                </ListItem>
                <CVCreationButton isListItem={true} />
                <ListItem onClick={props.onContactUsClicked}>
                    <InboxIcon sx={{ mx: 1 }} />
                    <ListItemText primary={t(TranslationKey.contactUs._name)} />
                </ListItem>
                <ListItem>
                    <LanguageIcon sx={{ mx: 1 }} />
                    <LanguageSelector setDirection={props.setDirection} />
                </ListItem>
                {!user && (
                    <>
                        <ListItem onClick={handleLoginClick}>
                            <LoginIcon sx={{ mx: 1 }} />
                            <ListItemText primary={t(TranslationKey.buttons.login)} />
                        </ListItem>
                        <ListItem onClick={handleSignUpClick}>
                            <PersonAddIcon sx={{ mx: 1 }} />
                            <ListItemText primary={t(TranslationKey.buttons.signup)} />
                        </ListItem>
                    </>
                )}
                {!!user && !user.isActive && <ActivationRequestButton isListItem={true} />}
                {!!user && <LogoutButton isListItem={true} />}
            </List>
        </Box>
    );

    return (
        <AppBar color="inherit" position="sticky">
            <Toolbar variant="regular">
                <Box display={'flex'} sx={{ flexGrow: 1 }} dir={'ltr'} justifyContent={i18n.dir() === 'rtl' ? 'flex-end' : 'flex-start'}>
                    <Logo />
                </Box>

                {/* Hamburger Menu on Small Screens */}
                <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                    <IconButton color="inherit" onClick={handleDrawerToggle}>
                        <MenuIcon />
                    </IconButton>
                </Box>

                {/* Links / Buttons */}
                <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
                    <Button color="inherit" onClick={handleHomeClick}>
                        {t(TranslationKey.landingPage._name)}
                    </Button>
                    <CVCreationButton />
                    <Button color="inherit" onClick={props.onContactUsClicked}>
                        {t(TranslationKey.contactUs._name)}
                    </Button>
                    {!user && (
                        <Box display={'flex'}>
                            <Button color="primary" variant={'outlined'} onClick={handleLoginClick}>
                                {t(TranslationKey.buttons.login)}
                            </Button>
                            <Box sx={{ mx: 1 }}></Box>
                            <Button color="primary" variant="contained" onClick={handleSignUpClick}>
                                {t(TranslationKey.buttons.signup)}
                            </Button>
                        </Box>
                    )}
                    {!!user && !user.isActive && <ActivationRequestButton variant={'outlined'} />}
                    {!!user && (
                        <Button color={'inherit'} endIcon={<PersonIcon />} onClick={handlePersonIconClick} sx={{ mx: 1 }}>
                            {t(TranslationKey.profile)}
                        </Button>
                    )}
                    <Box sx={{ mx: 1 }}>
                        <LanguageSelector setDirection={props.setDirection} />
                    </Box>
                    {!!user && <LogoutButton />}
                </Box>

                <Drawer anchor="right" open={mobileOpen} onClose={handleDrawerToggle}>
                    {drawerContent}
                </Drawer>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
