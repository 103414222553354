import { IPaginationFilter } from './Pagination.interface';
import { INumericId } from './Id.interface';
import { IOrderableEntity } from './Common.interface';

export enum LanguageProficiencyLevel {
    BEGINNER = 'Beginner',
    INTERMEDIATE = 'Intermediate',
    ADVANCED = 'Advanced',
    FLUENT = 'Fluent',
    NATIVE = 'Native',
}

export const LanguageProficiencyToValue = {
    [LanguageProficiencyLevel.BEGINNER]: 20,
    [LanguageProficiencyLevel.INTERMEDIATE]: 40,
    [LanguageProficiencyLevel.ADVANCED]: 60,
    [LanguageProficiencyLevel.FLUENT]: 80,
    [LanguageProficiencyLevel.NATIVE]: 100,
};

export const ValueToLanguageProficiency = {
    20: LanguageProficiencyLevel.BEGINNER,
    40: LanguageProficiencyLevel.INTERMEDIATE,
    60: LanguageProficiencyLevel.ADVANCED,
    80: LanguageProficiencyLevel.FLUENT,
    100: LanguageProficiencyLevel.NATIVE,
};

export interface ILanguage {
    id: number;
    name: string;
}

export type ILanguageFilter = IPaginationFilter & Pick<ILanguage, 'name'>;

export interface ILanguageProficiency extends IOrderableEntity {
    language: ILanguage;
    proficiency: LanguageProficiencyLevel;
}

export type ILanguageProficiencyCreationDto = Pick<ILanguageProficiency, 'proficiency'> & {
    language: INumericId;
    cv: INumericId;
};

export type ILanguageProficiencyUpdateDto = Omit<ILanguageProficiencyCreationDto, 'cv'>;
