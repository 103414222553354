import { Variant } from '@mui/material/styles/createTypography';
import i18n from 'i18next';

export const getLocalizedPath = (path: string) => {
    return `/${i18n.language}${path.startsWith('/') ? path : `/${path}`}`;
};

export function cleanString(str?: string): string | undefined {
    return str?.trimEnd().toLowerCase();
}

export const getExtensionFromMimeType = (mimeType: string): string => {
    if (mimeType.startsWith('image/')) {
        return mimeType.split('/')[1]; // Extract everything after 'image/'
    }
    return 'jpeg'; // Default if not an image
};

const typographySizes = {
    h1: 96,
    h2: 60,
    h3: 48,
    h4: 34,
    h5: 24,
    h6: 20,
    subtitle1: 16,
    subtitle2: 14,
    body1: 16,
    body2: 14,
    button: 14,
    caption: 12,
    overline: 10,
};

export function getFontSize(variant: Variant): number {
    return typographySizes[variant] || 16;
}

const typographySizesToPDF = {
    h1: 55,
    h2: 40,
    h3: 32,
    h4: 24,
    h5: 18,
    h6: 16,
    subtitle1: 12,
    subtitle2: 11,
    body1: 12,
    body2: 11,
    button: 11,
    caption: 10,
    overline: 8,
};

export function getFontSizeToPDF(variant: Variant): number {
    return typographySizesToPDF[variant] || 8;
}

const typographyToIconSizes = {
    h1: '96px',
    h2: '60px',
    h3: '48px',
    h4: '34px',
    h5: '34px',
    h6: '18px',
    subtitle1: '14px',
    subtitle2: '12px',
    body1: '14px',
    body2: '12px',
    button: '10px',
    caption: '9px',
    overline: '8px',
};

export function getFontSizeForIcon(variant: Variant) {
    return typographyToIconSizes[variant] || '18px';
}

export const removeIdKey = (obj: any): any => {
    if (Array.isArray(obj)) {
        return obj.map(removeIdKey);
    } else if (typeof obj === 'object' && obj !== null) {
        const newObj: any = {};
        for (const key in obj) {
            if (key !== 'id') {
                newObj[key] = removeIdKey(obj[key]);
            }
        }
        return newObj;
    }
    return obj;
};
