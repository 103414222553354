import { Button, ListItem, ListItemText } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { TranslationKey } from '../../interfaces/TranslationKey';
import React from 'react';
import { logout } from '../../services/auth.service';
import { useAxiosApi } from '../../hooks/useAxiosApi';
import { IApi } from '../../services/api.service';
import { useAuth } from '../../context/Auth.context';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLocalizedPath } from '../../utils/common.util';

interface LogoutButtonProps {
    isListItem?: boolean;
}

const LogoutButton = (props: LogoutButtonProps) => {
    const { t } = useTranslation();
    const api: IApi = useAxiosApi();
    const navigate = useNavigate();
    const { setAccessToken } = useAuth();

    const handleLogout = () => {
        logout(api)
            .then(() => {
                setAccessToken(null);
                navigate(getLocalizedPath(`/login`));
            })
            .catch((e) => console.error(e));
    };

    return props.isListItem ? (
        <ListItem onClick={handleLogout}>
            <LogoutIcon sx={{ mx: 1 }} />
            <ListItemText primary={t(TranslationKey.buttons.logout)} />
        </ListItem>
    ) : (
        <Button variant="outlined" color="secondary" onClick={handleLogout} endIcon={<LogoutIcon />}>
            {t(TranslationKey.buttons.logout)}
        </Button>
    );
};

export default LogoutButton;
