import React, { useEffect, useState } from 'react';
import { Box, Button, Grid2, Paper, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { resetPassword } from '../../services/auth.service';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../interfaces/TranslationKey';
import FeedbackModal from '../../components/FeedbackModal';
import { IApi } from '../../services/api.service';
import { useAxiosApi } from '../../hooks/useAxiosApi';
import { Helmet } from 'react-helmet-async';
import { AxiosError, AxiosResponse } from 'axios';
import PasswordFields from '../../components/auth/PasswordFields';
import { getLocalizedPath } from '../../utils/common.util';

const ResetPasswordPage = (): React.ReactElement => {
    const { t } = useTranslation();
    const api: IApi = useAxiosApi();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();
    const [error, setError] = useState<string>('');
    const [canResetPassword, setCanResetPassword] = useState(false);
    const [passwordResetAnchor, setPasswordResetAnchor] = useState<null | SVGSVGElement | HTMLElement>(null);

    useEffect(() => {
        setCanResetPassword(!!password && !!confirmPassword && password === confirmPassword);
    }, [password, confirmPassword]);

    const handleSignup = async (event: React.FormEvent<HTMLElement>) => {
        event.preventDefault();
        if (canResetPassword && !!token) {
            resetPassword(api, { token, password, confirmPassword })
                .then((res: AxiosResponse) => {
                    if (res.status === 204) {
                        setError('');
                        setPasswordResetAnchor(event.target as HTMLElement);
                    }
                })
                .catch((e: AxiosError) => {
                    if (e?.response?.status === 400) {
                        setError(t(TranslationKey.error.passwordsMismatch));
                    } else if (e?.response?.status === 403) {
                        setError(t(TranslationKey.passwordReset.passwordResetTokenExpiredMsg));
                    } else {
                        setError(t(TranslationKey.error.somethingWrongPassword));
                    }
                });
        }
    };

    return (
        <>
            <Helmet>
                <title>{t(TranslationKey.helmet.title.passwordResetPage)}</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <Grid2 container height="60vh" alignItems="center" justifyContent="center">
                <Paper elevation={3} sx={{ padding: '2rem', marginTop: '4rem', width: '350px' }}>
                    <Typography variant="h4" align="center" gutterBottom>
                        {t(TranslationKey.passwordReset._name)}
                    </Typography>
                    {!!token ? (
                        <>
                            <Box height={40}>
                                <Typography color="error" variant={'caption'}>
                                    {error}
                                </Typography>
                            </Box>
                            <Box component="form" noValidate autoComplete="off" onSubmit={handleSignup}>
                                <PasswordFields
                                    password={password}
                                    confirmPassword={confirmPassword}
                                    setPassword={setPassword}
                                    setConfirmPassword={setConfirmPassword}
                                    setError={setError}
                                />

                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    style={{ marginTop: '1rem' }}
                                    disabled={!canResetPassword}
                                    type={'submit'}
                                >
                                    {t(TranslationKey.passwordReset._name)}
                                </Button>
                                <Button
                                    variant="text"
                                    color="info"
                                    fullWidth
                                    style={{ marginTop: '1rem' }}
                                    onClick={() => navigate(getLocalizedPath('/login'))}
                                >
                                    {t(TranslationKey.buttons.login)}
                                </Button>
                            </Box>
                        </>
                    ) : (
                        <Typography color="error">{t(TranslationKey.passwordReset.passwordResetTokenExpiredMsg)}</Typography>
                    )}
                </Paper>
                <FeedbackModal
                    anchorEl={passwordResetAnchor}
                    setAnchorEl={setPasswordResetAnchor}
                    title={t(TranslationKey.passwordReset._name)}
                    message={t(TranslationKey.passwordReset.passwordResetSuccessMsg)}
                    error={false}
                    loading={false}
                    onClose={() => navigate(getLocalizedPath('/login'))}
                />
            </Grid2>
        </>
    );
};

export default ResetPasswordPage;
