import React from 'react';
import { Box, Typography } from '@mui/material';
import { IBackgroundColorTheme } from '../../interfaces/Theme.interface';
import { TranslationKey } from '../../interfaces/TranslationKey';
import { useTranslation } from 'react-i18next';
import ColorPickerDialog from './ColorPickerDialog';

interface ThemeCustomizerProps {
    theme: IBackgroundColorTheme;
    handleThemeChange: (key: keyof IBackgroundColorTheme, value: string) => void;
}

const BackgroundThemeCustomizer = (props: ThemeCustomizerProps): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <Box>
            <Typography variant="h6">{t(TranslationKey.theme.backgroundColor._name)}</Typography>
            <Box display={'flex'} gap={2}>
                {!!props.theme.page && (
                    <ColorPickerDialog
                        label={t(TranslationKey.theme.backgroundColor.page)}
                        color={props.theme.page}
                        onColorChange={(color: string) => props.handleThemeChange('page', color)}
                    />
                )}
                {!!props.theme.leftSection && (
                    <ColorPickerDialog
                        label={t(TranslationKey.theme.backgroundColor.leftSection)}
                        color={props.theme.leftSection}
                        onColorChange={(color: string) => props.handleThemeChange('leftSection', color)}
                    />
                )}
                {!!props.theme.rightSection && (
                    <ColorPickerDialog
                        label={t(TranslationKey.theme.backgroundColor.rightSection)}
                        color={props.theme.rightSection}
                        onColorChange={(color: string) => props.handleThemeChange('rightSection', color)}
                    />
                )}
                {!!props.theme.nameSection && (
                    <ColorPickerDialog
                        label={t(TranslationKey.name)}
                        color={props.theme.nameSection}
                        onColorChange={(color: string) => props.handleThemeChange('nameSection', color)}
                    />
                )}
                {!!props.theme.skillSection && (
                    <ColorPickerDialog
                        label={t(TranslationKey.skill.plural)}
                        color={props.theme.skillSection}
                        onColorChange={(color: string) => props.handleThemeChange('skillSection', color)}
                    />
                )}
                {!!props.theme.languageSection && (
                    <ColorPickerDialog
                        label={t(TranslationKey.language.plural)}
                        color={props.theme.languageSection}
                        onColorChange={(color: string) => props.handleThemeChange('languageSection', color)}
                    />
                )}
                {!!props.theme.profileSection && (
                    <ColorPickerDialog
                        label={t(TranslationKey.cvProfile)}
                        color={props.theme.profileSection}
                        onColorChange={(color: string) => props.handleThemeChange('profileSection', color)}
                    />
                )}
                {!!props.theme.interestSection && (
                    <ColorPickerDialog
                        label={t(TranslationKey.interests)}
                        color={props.theme.interestSection}
                        onColorChange={(color: string) => props.handleThemeChange('interestSection', color)}
                    />
                )}
                {!!props.theme.contactSection && (
                    <ColorPickerDialog
                        label={t(TranslationKey.contact)}
                        color={props.theme.contactSection}
                        onColorChange={(color: string) => props.handleThemeChange('contactSection', color)}
                    />
                )}
                {!!props.theme.experienceSection && (
                    <ColorPickerDialog
                        label={t(TranslationKey.experience._name)}
                        color={props.theme.experienceSection}
                        onColorChange={(color: string) => props.handleThemeChange('experienceSection', color)}
                    />
                )}
                {!!props.theme.educationSection && (
                    <ColorPickerDialog
                        label={t(TranslationKey.educationAndCertification)}
                        color={props.theme.educationSection}
                        onColorChange={(color: string) => props.handleThemeChange('educationSection', color)}
                    />
                )}
                {!!props.theme.referenceSection && (
                    <ColorPickerDialog
                        label={t(TranslationKey.reference.plural)}
                        color={props.theme.referenceSection}
                        onColorChange={(color: string) => props.handleThemeChange('referenceSection', color)}
                    />
                )}
            </Box>
        </Box>
    );
};

export default BackgroundThemeCustomizer;
