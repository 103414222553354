import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Box, Button, Grid2, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ExperienceItemForm from './ExperienceItemForm';
import { useTranslation } from 'react-i18next';
import { IExperience, IExperienceItem } from '../../../../interfaces/Experience.interface';
import { IFontTheme } from '../../../../interfaces/Theme.interface';
import { useAxiosApi } from '../../../../hooks/useAxiosApi';
import { IApi } from '../../../../services/api.service';
import { updateExperienceViaAI } from '../../../../services/experience.service';
import { ApiEndpoints } from '../../../../services/endpoints';
import { reorderItems } from '../../../../services/common.services';
import { TranslationKey } from '../../../../interfaces/TranslationKey';
import { iconClickable, IconType } from '../../../../common/style';
import AIButton from '../../../AIButton';
import DragAndDropList from '../../../DragAndDropList';
import { IOrderableEntity } from '../../../../interfaces/Common.interface';

interface ExperienceItemsProps {
    experience: IExperience;
    setExperience: (experience: IExperience) => void;
    items: IExperienceItem[];
    iconColor?: string;
    theme: IFontTheme;
}

const ExperienceItemsForm = (props: ExperienceItemsProps): React.ReactElement => {
    const { t } = useTranslation();
    const api: IApi = useAxiosApi();
    const [items, setItems] = React.useState<IExperienceItem[]>(props.items);

    useEffect(() => {
        props.setExperience({ ...props.experience, items: items });
    }, [items]);

    const itemAddCallback = () => {
        if (items.map((item: IExperienceItem) => item.description).includes('')) {
            return;
        }
        const newItem: IExperienceItem = {
            id: 0,
            weight: 100,
            description: '',
        };

        setItems([...items, newItem]);
    };

    const generateAI = async () => {
        const exp: IExperience | null = await updateExperienceViaAI(api, props.experience.id);
        if (exp) {
            setItems(exp.items);
        }
    };

    const _updateItems = async (movedItemId: number, newIndex: number) => {
        reorderItems(ApiEndpoints.experienceItemIdReorder, api, movedItemId, newIndex).then();
    };

    return (
        <Grid2>
            <Grid2 size={12}>
                <Box display="flex" alignItems={'center'} mb={2}>
                    <Typography>{t(TranslationKey.experience.item.experienceItems)}</Typography>
                    <Button onClick={itemAddCallback}>
                        <AddIcon sx={{ ...iconClickable(IconType.PRIMARY) }} />
                    </Button>
                    {!!props.experience?.id && <AIButton apiCall={generateAI} />}
                </Box>
            </Grid2>
            <Grid2 size={12}>
                <DragAndDropList
                    items={items}
                    setItems={setItems as Dispatch<SetStateAction<IOrderableEntity[]>>}
                    onDragEnd={_updateItems}
                    child={(item) => (
                        <ExperienceItemForm
                            experienceId={props.experience.id}
                            experienceItem={item as IExperienceItem}
                            items={items}
                            setItems={setItems}
                            iconColor={props.iconColor}
                            theme={props.theme}
                        />
                    )}
                ></DragAndDropList>
            </Grid2>
        </Grid2>
    );
};

export default ExperienceItemsForm;
