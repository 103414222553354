import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { ICV } from '../../interfaces/CV.interface';
import { IPDFStyle } from '../../interfaces/pdfStyle.interface';
import { IReference } from '../../interfaces/Reference.interface';

interface SectionPDFProps {
    cv: ICV;
    styles: IPDFStyle;
}

const ReferencePDF = (props: SectionPDFProps): React.ReactElement => {
    return (
        <View style={props.styles.referenceView}>
            {props.cv.references.map((reference: IReference) => {
                return (
                    <View key={`PDF-reference-${reference.id}`}>
                        <Text style={props.styles.referenceHeaderFont}>{reference.name}</Text>
                        {!!reference.contact && <Text style={props.styles.referenceSubHeaderFont}>{reference.contact}</Text>}
                    </View>
                );
            })}
        </View>
    );
};

export default ReferencePDF;
