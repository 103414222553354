import React from 'react';
import { ICV } from '../../interfaces/CV.interface';
import { ILayout, ITheme, SectionName } from '../../interfaces/Theme.interface';
import { getPDFStyles } from '../../utils/theme.util';
import { Document, Page, View } from '@react-pdf/renderer/lib/react-pdf';
import { IPDFStyle } from '../../interfaces/pdfStyle.interface';
import { useTranslation } from 'react-i18next';
import { TemplateName } from '../../common/enum';
import { getSections } from '../Template.util';
import { getPDFSectionElements, IPDFSectionElementsProps } from '../PDFTemplate.util';

interface PDFProps {
    cv: ICV;
    imageSrc: string | null;
    theme: ITheme;
}

const HamaTemplatePDF = (props: PDFProps): React.ReactElement => {
    const { i18n } = useTranslation();

    const isRTL: boolean = i18n.dir() === 'rtl';

    const styles: IPDFStyle = getPDFStyles(props.theme, isRTL);

    const templateName: TemplateName = TemplateName.HAMA;

    const layout: ILayout = getSections(props.theme.layoutTheme, templateName);

    const showTopSection: boolean = layout.top.sections.some((i) => !!i);
    const showTopLeftSection: boolean = layout.topLeft.sections.some((i) => !!i);

    const showMiddleSection: boolean = layout.middle.sections.some((i) => !!i);
    const showMiddleLeftRightSection: boolean = layout.middleLeft.sections.some((i) => !!i) || layout.middleRight.sections.some((i) => !!i);

    const showBottomSection: boolean = layout.bottom.sections.some((i) => !!i);
    const showBottomLeftRightSection: boolean = layout.bottomLeft.sections.some((i) => !!i) || layout.bottomRight.sections.some((i) => !!i);

    const padding = '15px 15px 0';

    const getProps = (sections: SectionName[]): IPDFSectionElementsProps => ({
        templateName: templateName,
        sections: sections,
        styles: styles,
        pdfProps: props,
    });

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* top */}
                {showTopSection && <View style={{ padding: padding }}>{getPDFSectionElements(getProps(layout.top.sections))}</View>}

                {/* top left/right */}
                <View
                    style={{
                        ...styles.rowStyle,
                        justifyContent: 'space-between',
                        backgroundColor: styles.backgroundColor.nameSection,
                        padding: padding,
                    }}
                >
                    {showTopLeftSection && <View style={{ ...styles.leftTopView }}></View>}

                    <View style={{ ...styles.rightTopView, marginLeft: showTopLeftSection ? 0 : '15px' }}>
                        {getPDFSectionElements(getProps(layout.topRight.sections))}
                    </View>
                </View>

                {/* middle */}
                {showMiddleSection && <View style={{ padding: padding }}>{getPDFSectionElements(getProps(layout.middle.sections))}</View>}

                {/* middle left/right */}
                {showMiddleLeftRightSection && (
                    <View style={{ ...styles.rowStyle, zIndex: 0 }}>
                        <View
                            style={{
                                ...styles.leftMiddleView,
                                backgroundColor: styles.backgroundColor.leftSection,
                                padding: !!props.imageSrc ? '35px 15px 0' : padding,
                            }}
                        >
                            {showTopLeftSection && (
                                <View style={{ position: 'relative' }}>
                                    <View style={{ position: 'absolute', bottom: '-42%', left: '50%', transform: 'translate(-50%, +5%)' }}>
                                        {getPDFSectionElements(getProps(layout.topLeft.sections))}
                                    </View>
                                </View>
                            )}
                            {getPDFSectionElements(getProps(layout.middleLeft.sections))}
                        </View>
                        <View style={{ ...styles.rightMiddleView, padding: padding }}>
                            {getPDFSectionElements(getProps(layout.middleRight.sections))}
                        </View>
                    </View>
                )}

                {/* bottom */}
                {showBottomSection && <View style={{ padding: padding }}>{getPDFSectionElements(getProps(layout.bottom.sections))}</View>}

                {/* bottom left/right */}
                {showBottomLeftRightSection && (
                    <View style={{ ...styles.rowStyle, zIndex: 0 }}>
                        <View
                            style={{
                                ...styles.leftBottomView,
                                backgroundColor: styles.backgroundColor.leftSection,
                                padding: padding,
                            }}
                        >
                            {getPDFSectionElements(getProps(layout.bottomLeft.sections))}
                        </View>
                        <View style={{ ...styles.rightBottomView, padding: padding }}>
                            {getPDFSectionElements(getProps(layout.bottomRight.sections))}
                        </View>
                    </View>
                )}
            </Page>
        </Document>
    );
};

export default HamaTemplatePDF;
