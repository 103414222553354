import React from 'react';
import { Grid2, InputLabel, Slider } from '@mui/material';
import { ILayoutTheme, ISectionWidth } from '../../interfaces/Theme.interface';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../interfaces/TranslationKey';
import { LABEL_SIZE } from './ThemeDesigner';

interface BaseThemeCustomizerProps {
    templateName: string;
    theme: ILayoutTheme;
    setTheme: (layoutTheme: (prev: ILayoutTheme) => ILayoutTheme) => void;
    setShouldUpdateThemed: (value: boolean) => void;
}

const SectionWidthCustomizer = (props: BaseThemeCustomizerProps): React.ReactElement => {
    const { t } = useTranslation();

    const handleThemeChange = (key: keyof ILayoutTheme, value: ISectionWidth) => {
        props.setTheme((prev: ILayoutTheme) => ({
            ...prev,
            [key]: value,
        }));
        props.setShouldUpdateThemed(true);
    };

    return (
        <>
            <Grid2 container spacing={2}>
                <Grid2 size={LABEL_SIZE}>
                    <InputLabel>{t(TranslationKey.theme.sectionWidth.top)}</InputLabel>
                </Grid2>
                <Grid2 size="grow">
                    <Slider
                        value={props.theme.sectionWidth.topLeftWidth}
                        onChange={(_, newValue: number | number[]) =>
                            handleThemeChange('sectionWidth', { ...props.theme.sectionWidth, topLeftWidth: newValue as number })
                        }
                        valueLabelDisplay={'auto'}
                        valueLabelFormat={String(props.theme.sectionWidth.topLeftWidth)}
                        step={0.1}
                        min={2}
                        max={10}
                        marks={[
                            { value: 2, label: 2 },
                            { value: 10, label: 10 },
                        ]}
                    />
                </Grid2>
            </Grid2>

            <Grid2 container spacing={2}>
                <Grid2 size={LABEL_SIZE}>
                    <InputLabel>{t(TranslationKey.theme.sectionWidth.middle)}</InputLabel>
                </Grid2>
                <Grid2 size="grow">
                    <Slider
                        value={props.theme.sectionWidth.middleLeftWidth}
                        onChange={(_, newValue: number | number[]) =>
                            handleThemeChange('sectionWidth', { ...props.theme.sectionWidth, middleLeftWidth: newValue as number })
                        }
                        valueLabelDisplay={'auto'}
                        valueLabelFormat={String(props.theme.sectionWidth.middleLeftWidth)}
                        step={0.1}
                        min={2}
                        max={10}
                        marks={[
                            { value: 2, label: 2 },
                            { value: 10, label: 10 },
                        ]}
                    />
                </Grid2>
            </Grid2>

            <Grid2 container spacing={2}>
                <Grid2 size={LABEL_SIZE}>
                    <InputLabel>{t(TranslationKey.theme.sectionWidth.bottom)}</InputLabel>
                </Grid2>
                <Grid2 size="grow">
                    <Slider
                        value={props.theme.sectionWidth.bottomLeftWidth}
                        onChange={(_, newValue: number | number[]) =>
                            handleThemeChange('sectionWidth', { ...props.theme.sectionWidth, bottomLeftWidth: newValue as number })
                        }
                        valueLabelDisplay={'auto'}
                        valueLabelFormat={String(props.theme.sectionWidth.bottomLeftWidth)}
                        step={0.1}
                        min={2}
                        max={10}
                        marks={[
                            { value: 2, label: 2 },
                            { value: 10, label: 10 },
                        ]}
                    />
                </Grid2>
            </Grid2>
        </>
    );
};

export default SectionWidthCustomizer;
