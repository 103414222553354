import React, { useEffect, useState } from 'react';
import { Box, Button, Grid2, Paper, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { login } from '../../services/auth.service';
import { useAuth } from '../../context/Auth.context';
import { ITokenResponse } from '../../interfaces/Auth.interface';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../interfaces/TranslationKey';
import { IApi } from '../../services/api.service';
import { useAxiosApi } from '../../hooks/useAxiosApi';
import PasswordField from '../../components/auth/PasswordField';
import { Helmet } from 'react-helmet-async';
import { AxiosError, AxiosResponse } from 'axios';
import { getLocalizedPath } from '../../utils/common.util';

const LoginPage = (): React.ReactElement => {
    const { t } = useTranslation();
    const api: IApi = useAxiosApi();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { accessToken, setAccessToken } = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');

    useEffect(() => {
        if (!!accessToken) {
            navigate(getLocalizedPath('/profile'));
        }
    }, [accessToken]);

    const handleLogin = async () => {
        if (email && password) {
            login(api, { email, password })
                .then((res: AxiosResponse<ITokenResponse>) => {
                    const token: ITokenResponse = res.data;
                    const newToken = token?.accessToken ?? null;
                    setAccessToken(newToken);
                    if (!!token) {
                        setError('');
                        navigate(getLocalizedPath('/profile'));
                    }
                })
                .catch((e: AxiosError) => {
                    if (e?.response?.status === 400) {
                        setError(t(TranslationKey.error.invalidCredentials));
                    } else if (e?.response?.status === 404) {
                        setError(t(TranslationKey.error.notRegisteredYet));
                    } else if (e?.response?.status === 405) {
                        setError(t(TranslationKey.error.accountIsDeleted));
                    } else {
                        setError(t(TranslationKey.error.cannotLogin));
                    }
                });
        } else {
            setError(t(TranslationKey.error.invalidCredentials));
        }
    };

    const handleEmail = (value: string) => {
        setEmail(value);

        if (value) {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]{3,}\.[a-zA-Z]{2,}$/;
            setEmailError(emailRegex.test(value) ? '' : t(TranslationKey.error.invalidEmail));
        } else {
            setEmailError('');
        }
    };

    const handleForgotPassword = () => {
        navigate(getLocalizedPath('/password-reset-request'));
    };

    return (
        <>
            <Helmet>
                <title>{t(TranslationKey.helmet.title.loginPage)}</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <Grid2 container height="60vh" alignItems="center" justifyContent="center">
                <Paper elevation={3} sx={{ padding: '2rem', marginTop: '4rem', width: '350px' }}>
                    <Typography variant="h4" align="center" gutterBottom>
                        {t(TranslationKey.buttons.login)}
                    </Typography>
                    <Box height={30}>
                        <Typography color="error" variant={'caption'}>
                            {error}
                        </Typography>
                    </Box>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleLogin().then();
                        }}
                    >
                        <TextField
                            label={t(TranslationKey.email)}
                            dir={'ltr'}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            autoFocus={true}
                            value={email}
                            error={!!emailError}
                            helperText={emailError ?? ' '}
                            onChange={(e) => handleEmail(e.target.value)}
                            sx={{ height: 70 }}
                            required
                            slotProps={{ htmlInput: { autoComplete: 'new-password' } }}
                        />
                        <PasswordField
                            label={t(TranslationKey.user.password)}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            error={false}
                        />
                        <Button variant="text" color="primary" style={{ marginTop: '1rem' }} onClick={handleForgotPassword}>
                            {t(TranslationKey.buttons.forgotPassword)}
                        </Button>
                        <Button variant="contained" color="primary" fullWidth style={{ marginTop: '1rem' }} type={'submit'}>
                            {t(TranslationKey.buttons.login)}
                        </Button>
                        <Button
                            variant="text"
                            color="info"
                            fullWidth
                            style={{ marginTop: '1rem' }}
                            onClick={() => navigate(getLocalizedPath('/signup'))}
                        >
                            {t(TranslationKey.user.createAccount)}
                        </Button>
                    </Box>
                </Paper>
            </Grid2>
        </>
    );
};

export default LoginPage;
