import { IEducation } from '../../../interfaces/Education.interface';
import { ICertification } from '../../../interfaces/Certification.interface';
import { IEducationTheme } from '../../../interfaces/Theme.interface';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { IApi } from '../../../services/api.service';
import { useAxiosApi } from '../../../hooks/useAxiosApi';
import { useCV } from '../../../context/CV.context';
import { reorderItems } from '../../../services/common.services';
import { ApiEndpoints } from '../../../services/endpoints';
import { removeEducation } from '../../../services/education.service';
import { removeCertification } from '../../../services/certification.service';
import { Divider } from '@mui/material';
import { PrintableSectionHeader } from '../../SectionHeader';
import { TranslationKey } from '../../../interfaces/TranslationKey';
import OrderableList from '../../OrderableList';
import { IOrderableEntity } from '../../../interfaces/Common.interface';
import AddSectionButton from '../../AddSectionButton';
import EducationForm from './education/EducationForm';
import CertificationForm from './education/CertificationForm';

interface EducationsProps {
    cvId: number;
    educations: IEducation[];
    certifications: ICertification[];
    theme: IEducationTheme;
}

const EducationsForm = (props: EducationsProps): React.ReactElement => {
    const api: IApi = useAxiosApi();
    const { dispatch } = useCV();
    const [educations, setEducations] = React.useState<IEducation[]>(props.educations);
    const [certifications, setCertifications] = React.useState<ICertification[]>(props.certifications);

    useEffect(() => {
        dispatch({ type: 'UPDATE_FIELD', field: 'educations', value: educations });
    }, [educations]);

    useEffect(() => {
        dispatch({ type: 'UPDATE_FIELD', field: 'certifications', value: certifications });
    }, [certifications]);

    const educationAddCallback = () => {
        if (educations.map((entity: IEducation) => entity.id).includes(0)) {
            return;
        }
        const newEducation: IEducation = {
            degree: '',
            endDate: new Date(),
            institution: '',
            startDate: new Date(),
            id: 0,
            weight: 100,
        };
        setEducations([...educations, newEducation]);
    };

    const certificationAddCallback = () => {
        if (certifications.map((entity: ICertification) => entity.id).includes(0)) {
            return;
        }
        const newCertification: ICertification = {
            name: '',
            issuer: '',
            issueDate: new Date(),
            id: 0,
            weight: 100,
        };
        setCertifications([...certifications, newCertification]);
    };

    const _reorderEducations = async (movedItemId: number, newIndex: number) => {
        if (movedItemId) {
            reorderItems(ApiEndpoints.educationIdReorder, api, movedItemId, newIndex).then();
        }
    };

    const _reorderCertifications = async (movedItemId: number, newIndex: number) => {
        if (movedItemId) {
            reorderItems(ApiEndpoints.certificationIdReorder, api, movedItemId, newIndex).then();
        }
    };

    const handleRemoveEducation = (id: number): void => {
        if (id) {
            removeEducation(api, id).then();
        }
        const filteredList: IEducation[] = educations.filter((_education: IEducation) => _education.id !== id);
        setEducations(filteredList);
    };

    const handleRemoveCertification = (id: number): void => {
        if (id) {
            removeCertification(api, id).then();
        }
        const filteredList: ICertification[] = certifications.filter((_Certification: ICertification) => _Certification.id !== id);
        setCertifications(filteredList);
    };

    return (
        <>
            <PrintableSectionHeader title={TranslationKey.education._name} theme={{ ...props.theme.title, ...{ variant: 'h5' } }} />
            <OrderableList
                labelKey={'education-'}
                items={educations}
                setItems={setEducations as Dispatch<SetStateAction<IOrderableEntity[]>>}
                onMoveEnd={_reorderEducations}
                onRemoveCallback={handleRemoveEducation}
                child={(item) => (
                    <EducationForm
                        cvId={props.cvId}
                        education={item as IEducation}
                        educations={educations}
                        setEducations={setEducations}
                        theme={props.theme}
                    />
                )}
            ></OrderableList>
            <AddSectionButton disabled={educations.map((entity: IEducation) => entity.id).includes(0)} onClick={educationAddCallback} />

            <Divider sx={{ my: 2 }} />

            <PrintableSectionHeader title={TranslationKey.certification.plural} theme={{ ...props.theme.title, ...{ variant: 'h5' } }} />
            <OrderableList
                labelKey={'certification-'}
                items={certifications}
                setItems={setCertifications as Dispatch<SetStateAction<IOrderableEntity[]>>}
                onMoveEnd={_reorderCertifications}
                onRemoveCallback={handleRemoveCertification}
                child={(item) => (
                    <CertificationForm
                        cvId={props.cvId}
                        certification={item as ICertification}
                        certifications={certifications}
                        setCertifications={setCertifications}
                        theme={props.theme}
                    />
                )}
            ></OrderableList>
            <AddSectionButton
                disabled={certifications.map((entity: ICertification) => entity.id).includes(0)}
                onClick={certificationAddCallback}
            />
        </>
    );
};

export default EducationsForm;
