import React, { Suspense, useEffect } from 'react';
import { TranslationKey } from '../interfaces/TranslationKey';
import { useTranslation } from 'react-i18next';
import { Mode } from '../interfaces/Mode.interface';
import { useMode } from '../context/Mode.context';
import { Helmet } from 'react-helmet-async';
import CVBuilder from '../components/builder/cv.builder';
import { CVProvider } from '../context/CV.context';

function CVCreationPage(): React.ReactElement {
    const { t } = useTranslation();
    const { setMode } = useMode();

    useEffect(() => {
        setMode(Mode.EDIT);
    }, []);

    return (
        <>
            <Helmet>
                <title>{t(TranslationKey.helmet.title.cvCreationPage)}</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <Suspense fallback="loading">
                <CVProvider>
                    <CVBuilder />
                </CVProvider>
            </Suspense>
        </>
    );
}

export default CVCreationPage;
