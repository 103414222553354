import React from 'react';
import { ILayout, SectionName } from '../../interfaces/Theme.interface';
import { getPDFStyles } from '../../utils/theme.util';
import { Document, Page, View } from '@react-pdf/renderer/lib/react-pdf';
import { IPDFStyle } from '../../interfaces/pdfStyle.interface';
import { useTranslation } from 'react-i18next';
import { TemplateName } from '../../common/enum';
import { IPDFProps } from '../../interfaces/Template.interface';
import { getSections } from '../Template.util';
import { getPDFSectionElements, IPDFSectionElementsProps } from '../PDFTemplate.util';

const DamascusTemplatePDF = (props: IPDFProps): React.ReactElement => {
    const { i18n } = useTranslation();

    const isRTL: boolean = i18n.dir() === 'rtl';

    const styles: IPDFStyle = getPDFStyles(props.theme, isRTL);

    const templateName: TemplateName = TemplateName.DAMASCUS;

    const layout: ILayout = getSections(props.theme.layoutTheme, templateName);

    const showTopRightSection: boolean = layout.topRight.sections.some((i) => !!i);
    const showMiddleSection: boolean = layout.middleLeft.sections.some((i) => !!i) || layout.middleRight.sections.some((i) => !!i);
    const showBottomSection: boolean = layout.bottomLeft.sections.some((i) => !!i) || layout.bottomRight.sections.some((i) => !!i);

    const getProps = (sections: SectionName[]): IPDFSectionElementsProps => ({
        templateName: templateName,
        sections: sections,
        styles: styles,
        pdfProps: props,
    });

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* top */}
                <View style={{ ...styles.rowStyle }}>{getPDFSectionElements(getProps(layout.top.sections))}</View>

                {/* top left/right */}
                <View style={{ ...styles.rowStyle, justifyContent: 'space-between' }}>
                    <View style={{ width: showTopRightSection ? styles.leftTopView.width : '100%' }}>
                        {getPDFSectionElements(getProps(layout.topLeft.sections))}
                    </View>
                    {showTopRightSection && (
                        <View style={styles.rightTopView}>{getPDFSectionElements(getProps(layout.topRight.sections))}</View>
                    )}
                </View>

                {/* middle */}
                <View style={{ ...styles.rowStyle }}>{getPDFSectionElements(getProps(layout.middle.sections))}</View>

                {/* middle left/right */}
                {showMiddleSection && (
                    <View style={{ ...styles.horizontalDivider, ...styles.rowStyle }}>
                        <View style={styles.leftMiddleView}>
                            {getPDFSectionElements({
                                ...getProps(layout.middleLeft.sections),
                                extraSectionStyle: styles.leftSectionPaddingStyle,
                            })}
                        </View>
                        <View style={styles.rightMiddleView}>
                            {getPDFSectionElements({
                                ...getProps(layout.middleRight.sections),
                                extraSectionStyle: {
                                    ...styles.rightSectionPaddingStyle,
                                    ...styles.layoutBorderStyle,
                                },
                            })}
                        </View>
                    </View>
                )}

                {/* bottom */}
                <View style={{ ...styles.rowStyle }}>{getPDFSectionElements(getProps(layout.bottom.sections))}</View>

                {/* bottom left/right */}
                {showBottomSection && (
                    <View style={{ ...styles.horizontalDivider, ...styles.rowStyle }}>
                        <View style={styles.leftBottomView}>
                            {getPDFSectionElements({
                                ...getProps(layout.bottomLeft.sections),
                                extraSectionStyle: styles.leftSectionPaddingStyle,
                            })}
                        </View>
                        <View style={styles.rightBottomView}>
                            {getPDFSectionElements({
                                ...getProps(layout.bottomRight.sections),
                                extraSectionStyle: {
                                    ...styles.rightSectionPaddingStyle,
                                    ...styles.layoutBorderStyle,
                                },
                            })}
                        </View>
                    </View>
                )}
            </Page>
        </Document>
    );
};

export default DamascusTemplatePDF;
