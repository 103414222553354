import React from 'react';
import { Box, Grid2 } from '@mui/material';
import { PrintableTextField } from '../GeneralTextField';
import { IAddress } from '../../interfaces/Address.interface';
import { useMode } from '../../context/Mode.context';
import { Mode } from '../../interfaces/Mode.interface';
import { IContactTheme } from '../../interfaces/Theme.interface';
import AddressForm from '../builder/forms/AddressForm';

interface AddressProps {
    cvId: number;
    address: IAddress | null;
    theme: IContactTheme;
}

const PrintableAddress: React.FC<AddressProps> = (props: AddressProps) => {
    return (
        <Grid2 container>
            <Grid2 size={12}>
                <PrintableTextField value={props.address?.street ?? ''} typographyComponent={'p'} fontTheme={props.theme.font} />
            </Grid2>
            <Box display="flex">
                {!!props.address?.postalCode && (
                    <PrintableTextField
                        value={props.address?.postalCode ?? ''}
                        typographyComponent={'p'}
                        fontTheme={props.theme.font}
                        sx={{ mr: '5px' }}
                    />
                )}
                <PrintableTextField value={props.address?.city ?? ''} typographyComponent={'p'} fontTheme={props.theme.font} />
            </Box>
            <Grid2 size={12}>
                <PrintableTextField value={props.address?.state ?? ''} typographyComponent={'p'} fontTheme={props.theme.font} />
            </Grid2>
            <Grid2 size={12}>
                <PrintableTextField value={props.address?.country ?? ''} typographyComponent={'p'} fontTheme={props.theme.font} />
            </Grid2>
        </Grid2>
    );
};

const Address = (props: AddressProps): React.ReactElement => {
    const { mode } = useMode();

    switch (mode) {
        case Mode.EDIT:
            return <AddressForm {...props} />;
        case Mode.PRINT:
            return <PrintableAddress {...props} />;
    }
};

export default Address;
