import { IInterestsTheme } from '../../../interfaces/Theme.interface';
import React, { useState } from 'react';
import { IApi } from '../../../services/api.service';
import { useAxiosApi } from '../../../hooks/useAxiosApi';
import { useCV } from '../../../context/CV.context';
import { updateCV } from '../../../services/cv.service';
import { EditableTextField } from '../../GeneralTextField';
import { TranslationKey } from '../../../interfaces/TranslationKey';

interface InterestProps {
    cvId: number;
    interests: string | null;
    theme: IInterestsTheme;
}

const InterestsForm: React.FC<InterestProps> = (props: InterestProps) => {
    const api: IApi = useAxiosApi();
    const { cv, dispatch } = useCV();
    const [interests, setInterests] = useState<string | null>(props.interests);

    const onDoneClicked = (value: string) => {
        const cleanValue = value === '' ? null : value;
        if (cleanValue === cv?.interests) return;

        updateCV(api, props.cvId, { interests: cleanValue }).then();
        dispatch({ type: 'UPDATE_FIELD', field: 'interests', value: cleanValue });
        setInterests(cleanValue);
    };

    return (
        <EditableTextField
            multiline={true}
            value={interests ?? ''}
            setValue={setInterests}
            label={TranslationKey.activitiesAndInterests}
            required={false}
            onDoneClicked={onDoneClicked}
            typographyComponent={'p'}
            fontTheme={props.theme.font}
        />
    );
};

export default InterestsForm;
