import React, { createContext, useContext, useEffect, useState } from 'react';
import { IUser } from '../interfaces/User.interface';
import { getUserFromToken } from '../utils/token.util';
import { useRefresh } from '../hooks/useRefresh';

interface AuthContextType {
    accessToken: string | null;
    setAccessToken: (accessToken: string | null) => void;
    user: IUser | null;
    setUser: (user: IUser | null) => void;
}

const AuthContext = createContext<AuthContextType>({
    accessToken: null,
    setAccessToken: () => {},
    user: null,
    setUser: () => {},
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [accessToken, _setAccessToken] = useState<string | null>(null);
    const [user, setUser] = useState<IUser | null>(null);
    const refresh = useRefresh();

    const setAccessToken = (token: string | null) => {
        _setAccessToken(token);
    };

    useEffect(() => {
        const initializeAuth = async () => {
            try {
                const newAccessToken = await refresh(); // Try refreshing token on load
                if (newAccessToken) {
                    setAccessToken(newAccessToken);
                    setUser(getUserFromToken(newAccessToken));
                }
            } catch {
                setAccessToken(null);
                setUser(null);
            }
        };

        initializeAuth();
    }, []);

    useEffect(() => {
        setUser(getUserFromToken(accessToken));
    }, [accessToken]);

    return <AuthContext.Provider value={{ accessToken, setAccessToken, user, setUser }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context: AuthContextType = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
