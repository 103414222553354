import React from 'react';
import { Box, Button, CircularProgress, Modal, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../interfaces/TranslationKey';

interface FeedbackProps {
    anchorEl: null | SVGSVGElement | HTMLElement;
    setAnchorEl: (v: null | HTMLElement | SVGSVGElement) => void;
    loading: boolean;
    error: boolean;
    title: string;
    message: string;
    onClose?: () => void;
}

const FeedbackModal = (props: FeedbackProps): React.ReactElement => {
    const { t } = useTranslation();

    const isOpen: boolean = Boolean(props.anchorEl);

    const handleClose = () => {
        props.setAnchorEl(null);
        if (props.onClose) {
            props.onClose();
        }
    };

    return (
        <Modal open={isOpen}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}
            >
                <Typography variant="h6" mb={2}>
                    {props.title}
                </Typography>
                <Box>
                    {props.loading ? (
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={60}>
                            <CircularProgress size={'30px'} />
                        </Box>
                    ) : (
                        <Typography color={props.error ? 'error.main' : ''}>{props.message}</Typography>
                    )}
                    <Button variant="outlined" color="inherit" fullWidth sx={{ mt: 2 }} onClick={handleClose}>
                        {t(TranslationKey.buttons.close)}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default FeedbackModal;
