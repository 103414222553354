import { ILanguageProficiency, LanguageProficiencyLevel } from '../../../interfaces/LanguageProficiency.interface';
import { ILanguageTheme } from '../../../interfaces/Theme.interface';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { IApi } from '../../../services/api.service';
import { useAxiosApi } from '../../../hooks/useAxiosApi';
import { useCV } from '../../../context/CV.context';
import { reorderItems } from '../../../services/common.services';
import { ApiEndpoints } from '../../../services/endpoints';
import { removeLanguageProficiency } from '../../../services/language.service';
import { Box } from '@mui/material';
import OrderableList from '../../OrderableList';
import { IOrderableEntity } from '../../../interfaces/Common.interface';
import AddSectionButton from '../../AddSectionButton';
import LanguageProficiencyForm from './language/LanguageProficiencyForm';

interface LanguageProps {
    cvId: number;
    languageProficiencies: ILanguageProficiency[];
    theme: ILanguageTheme;
}

const LanguagesForm = (props: LanguageProps): React.ReactElement => {
    const api: IApi = useAxiosApi();
    const { dispatch } = useCV();
    const [languageProficiencies, setLanguageProficiencies] = React.useState<ILanguageProficiency[]>(props.languageProficiencies);

    useEffect(() => {
        dispatch({ type: 'UPDATE_FIELD', field: 'languageProficiencies', value: languageProficiencies });
    }, [languageProficiencies]);

    const languageAddCallback = () => {
        if (languageProficiencies.map((languageProficiency: ILanguageProficiency) => languageProficiency.language.name).includes('')) {
            return;
        }
        const newLanguage: ILanguageProficiency = {
            id: 0,
            language: { id: 0, name: '' },
            proficiency: LanguageProficiencyLevel.BEGINNER,
            weight: 100,
        };
        setLanguageProficiencies([...languageProficiencies, newLanguage]);
    };

    const _reorderItems = async (movedItemId: number, newIndex: number) => {
        if (movedItemId) {
            reorderItems(ApiEndpoints.languageProficiencyIdReorder, api, movedItemId, newIndex).then();
        }
    };

    const _removeLanguageProficiency = (id: number) => {
        if (id) {
            removeLanguageProficiency(api, id).then();
        }

        const filteredLanguages: ILanguageProficiency[] = languageProficiencies.filter((l: ILanguageProficiency) => l.id !== id);
        setLanguageProficiencies(filteredLanguages);
    };

    return (
        <Box id="language" sx={{ mb: `${props.theme.margin.bottom}px` }}>
            <OrderableList
                labelKey={'languageProficiency-'}
                items={languageProficiencies}
                setItems={setLanguageProficiencies as Dispatch<SetStateAction<IOrderableEntity[]>>}
                onMoveEnd={_reorderItems}
                onRemoveCallback={_removeLanguageProficiency}
                child={(item) => (
                    <LanguageProficiencyForm
                        cvId={props.cvId}
                        languageProficiency={item as ILanguageProficiency}
                        languageProficiencies={languageProficiencies}
                        setLanguageProficiencies={setLanguageProficiencies}
                        theme={props.theme}
                    />
                )}
            ></OrderableList>

            <AddSectionButton
                disabled={languageProficiencies
                    .map((languageProficiency: ILanguageProficiency) => languageProficiency.language.name)
                    .includes('')}
                onClick={languageAddCallback}
            />
        </Box>
    );
};

export default LanguagesForm;
