import React from 'react';
import { Box } from '@mui/material';
import { ISkillProficiency, SkillProficiencyToValue } from '../../interfaces/SkillProficiency.interface';
import { ISkillTheme } from '../../interfaces/Theme.interface';
import { useMode } from '../../context/Mode.context';
import { Mode } from '../../interfaces/Mode.interface';
import SkillsForm from '../builder/forms/SkillsForm';
import { PrintableProficiency } from '../proficiency/Proficiency';
import { PrintableTextField } from '../GeneralTextField';

interface SkillProps {
    cvId: number;
    skillProficiencies: ISkillProficiency[];
    theme: ISkillTheme;
    inline: boolean;
}

const Skills: React.FC<SkillProps> = (props: SkillProps) => {
    const { mode } = useMode();

    const isEditing: boolean = mode === Mode.EDIT;

    const eliminateMargin: boolean = props.theme.proficiency.type === 'none';
    const displayInline: boolean = props.inline && mode === Mode.PRINT;

    return (
        <Box id="skill" sx={{ mb: `${props.theme.margin.bottom}px` }}>
            {isEditing ? (
                <SkillsForm {...props} />
            ) : (
                props.skillProficiencies.map((skillProficiency) => (
                    <Box
                        key={'printable-skillProficiencies-' + skillProficiency.id}
                        sx={{ marginBottom: eliminateMargin || displayInline ? 0 : '10px' }}
                        display={displayInline ? 'flex' : 'inherit'}
                        justifyContent={'space-between'}
                    >
                        <PrintableTextField value={skillProficiency.skill.name} fontTheme={props.theme.font} typographyComponent={'p'} />
                        <Box
                            display={displayInline ? 'flex' : 'inherit'}
                            width={displayInline ? '50%' : '100%'}
                            justifyContent={'flex-end'}
                            alignItems={'center'}
                        >
                            <PrintableProficiency
                                theme={props.theme.proficiency}
                                value={skillProficiency.proficiency}
                                proficiencyToValue={SkillProficiencyToValue}
                                labelKey={'Skills'}
                            />
                        </Box>
                    </Box>
                ))
            )}
        </Box>
    );
};

export default Skills;
