import { IPaginationFilter } from './Pagination.interface';
import { INumericId } from './Id.interface';
import { IOrderableEntity } from './Common.interface';

export enum SkillProficiencyLevel {
    BEGINNER = 'Beginner',
    INTERMEDIATE = 'Intermediate',
    ADVANCED = 'Advanced',
    EXPERT = 'Expert',
    MASTER = 'Master',
}

export const SkillProficiencyToValue = {
    [SkillProficiencyLevel.BEGINNER]: 20,
    [SkillProficiencyLevel.INTERMEDIATE]: 40,
    [SkillProficiencyLevel.ADVANCED]: 60,
    [SkillProficiencyLevel.EXPERT]: 80,
    [SkillProficiencyLevel.MASTER]: 100,
};

export const ValueToSkillProficiency = {
    20: SkillProficiencyLevel.BEGINNER,
    40: SkillProficiencyLevel.INTERMEDIATE,
    60: SkillProficiencyLevel.ADVANCED,
    80: SkillProficiencyLevel.EXPERT,
    100: SkillProficiencyLevel.MASTER,
};

export interface ISkill {
    id: number;
    name: string;
}

export type ISkillFilter = IPaginationFilter & Pick<ISkill, 'name'>;

export type ISkillCreationDto = Pick<ISkill, 'name'>;

export interface ISkillProficiency extends IOrderableEntity {
    skill: ISkill;
    proficiency: SkillProficiencyLevel;
}

export type ISkillProficiencyCreationDto = Pick<ISkillProficiency, 'proficiency'> & {
    skill: INumericId;
    cv: INumericId;
};

export type ISkillProficiencyUpdateDto = Omit<ISkillProficiencyCreationDto, 'cv'>;
