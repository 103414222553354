import { Button, ListItem, ListItemText } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../context/Auth.context';
import { TranslationKey } from '../../interfaces/TranslationKey';
import ActivationRequestModal from './ActivationRequestModal';
import CelebrationIcon from '@mui/icons-material/Celebration';

interface Props {
    isListItem?: boolean;
    variant?: 'text' | 'outlined' | 'contained';
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    fullWidth?: boolean;
}

const ActivationRequestButton = (props: Props) => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const [activationAnchor, setActivationAnchor] = useState<null | SVGSVGElement | HTMLElement>(null);

    const handleRequestActivationClick = (event: React.MouseEvent<HTMLElement>) => {
        setActivationAnchor(event.currentTarget);
    };

    return (
        <>
            {props.isListItem ? (
                <ListItem onClick={handleRequestActivationClick}>
                    <CelebrationIcon sx={{ mx: 1 }} color={'error'} />
                    <ListItemText primary={t(TranslationKey.buttons.activate)} sx={{ color: (theme) => theme.palette.error.main }} />
                </ListItem>
            ) : (
                <Button
                    variant={props.variant || undefined}
                    color={props.color || 'error'}
                    fullWidth={props.fullWidth || false}
                    size="large"
                    onClick={handleRequestActivationClick}
                >
                    {t(TranslationKey.buttons.activate)}
                </Button>
            )}
            {!!user?.email && <ActivationRequestModal anchorEl={activationAnchor} setAnchorEl={setActivationAnchor} email={user?.email} />}
        </>
    );
};

export default ActivationRequestButton;
