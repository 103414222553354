import { Button, ListItem, ListItemText } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { TemplateName } from '../common/enum';
import ActivationRequestModal from './auth/ActivationRequestModal';
import { useAuth } from '../context/Auth.context';
import { TranslationKey } from '../interfaces/TranslationKey';
import { getLocalizedPath } from '../utils/common.util';

interface Props {
    isListItem?: boolean;
    variant?: 'text' | 'outlined' | 'contained';
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    fullWidth?: boolean;
}

const CVCreationButton = (props: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [activationAnchor, setActivationAnchor] = useState<null | SVGSVGElement | HTMLElement>(null);

    const handleCreateCV = (event: React.MouseEvent<HTMLElement>) => {
        if (!!user && !user.isActive) {
            setActivationAnchor(event.currentTarget);
        } else {
            navigate(getLocalizedPath(`/cv/builder/template/${TemplateName.DAMASCUS}`));
        }
    };

    const buttonName = !!user ? t(TranslationKey.buttons.createCV) : t(TranslationKey.buttons.getStarted);

    return (
        <>
            {props.isListItem ? (
                <ListItem onClick={handleCreateCV}>
                    <PostAddIcon sx={{ mx: 1 }} />
                    <ListItemText primary={buttonName} />
                </ListItem>
            ) : (
                <Button
                    variant={props.variant || undefined}
                    color={props.color || 'inherit'}
                    fullWidth={props.fullWidth || false}
                    size="large"
                    onClick={handleCreateCV}
                >
                    {buttonName}
                </Button>
            )}
            {!!user?.email && <ActivationRequestModal anchorEl={activationAnchor} setAnchorEl={setActivationAnchor} email={user?.email} />}
        </>
    );
};

export default CVCreationButton;
