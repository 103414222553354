import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { ICV } from '../../interfaces/CV.interface';
import { IPDFStyle } from '../../interfaces/pdfStyle.interface';
import { ILanguageProficiency, LanguageProficiencyToValue } from '../../interfaces/LanguageProficiency.interface';
import PDFProficiency from './Proficiency';
import { Style } from '@react-pdf/types';

interface SectionPDFProps {
    cv: ICV;
    styles: IPDFStyle;
    inline: boolean;
}

const LanguagesPDF = (props: SectionPDFProps): React.ReactElement => {
    const eliminateMargin: boolean = props.styles.languageBarStyle.type === 'none';

    const flex: Style = props.inline ? { ...props.styles.rowStyle, justifyContent: 'space-between' } : {};

    return (
        <View style={props.styles.languageView}>
            {props.cv.languageProficiencies.map((lp: ILanguageProficiency, index: number) => {
                return (
                    <View
                        key={`PDF-languages-${lp.id}`}
                        style={{
                            ...flex,
                            marginBottom: index === props.cv.skillProficiencies.length - 1 || eliminateMargin || props.inline ? 0 : '5px',
                        }}
                    >
                        <Text style={{ ...props.styles.languageFont, marginBottom: eliminateMargin || props.inline ? 0 : '3px' }}>
                            {lp.language.name}
                        </Text>
                        <View style={props.inline ? { display: 'flex', width: '50%', alignItems: 'flex-end' } : {}}>
                            <PDFProficiency
                                rowStyle={props.styles.rowStyle}
                                theme={props.styles.languageBarStyle}
                                value={LanguageProficiencyToValue[lp.proficiency]}
                                labelKey={'Languages'}
                            />
                        </View>
                    </View>
                );
            })}
        </View>
    );
};

export default LanguagesPDF;
