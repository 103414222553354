import React, { useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { Mode } from '../interfaces/Mode.interface';
import { useMode } from '../context/Mode.context';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { IFontTheme } from '../interfaces/Theme.interface';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import EditButton from './EditButton';
import { TranslationKey } from '../interfaces/TranslationKey';

export interface TextFieldProps {
    label: string;
    value: string;
    setValue: (value: string) => void;
    fontTheme: IFontTheme;
    typographyComponent?: React.ElementType;
    required: boolean;
    minLength?: number;
    multiline?: boolean;
    alwaysEditable?: boolean;
    onDoneClicked?: (value: string) => void;
    sx?: SxProps<Theme>;
    autofocus?: boolean;
    onlyEditIcon?: boolean;
}

const EditableTextField: React.FC<TextFieldProps> = (props: TextFieldProps) => {
    const { t } = useTranslation();
    const [isEditing, setIsEditing] = useState(false);
    const [error, setError] = useState(false);
    const [tempValue, setTempValue] = useState(props.value);

    const handleClick = () => {
        setTempValue(props.value);
        setIsEditing(true);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setTempValue(value);
        if (props.required && !value) {
            setError(true);
        } else {
            setError(false);
        }
    };

    const handleDoneClick = () => {
        if (props.required && !tempValue) {
            setError(true);
            return;
        }
        if (tempValue.length > 0 && tempValue.length < (props.minLength ?? 2)) {
            setError(true);
            return;
        }
        props.setValue(tempValue);
        setError(false);
        setIsEditing(false);
        if (props.onDoneClicked) {
            props.onDoneClicked(tempValue);
        }
    };

    const handleCancel = () => {
        setTempValue(props.value);
        setIsEditing(false);
        setError(false);
    };

    return (
        <Box display="flex" alignItems="center" gap={1}>
            {isEditing || props.alwaysEditable ? (
                <>
                    <TextField
                        value={tempValue}
                        label={t(props.label)}
                        multiline={props.multiline}
                        rows={props.multiline ? 4 : 1}
                        onChange={handleChange}
                        fullWidth
                        autoFocus={props.autofocus}
                        variant="outlined"
                        required={props.required}
                        error={error}
                        helperText={error ? t(TranslationKey.error.requiredField) : ''}
                        sx={{ flex: 1, margin: '8px 0' }}
                    />
                    {!props.alwaysEditable && (
                        <>
                            <IconButton onClick={handleDoneClick} color="primary">
                                <DoneIcon />
                            </IconButton>
                            <IconButton onClick={handleCancel} color="secondary">
                                <CloseIcon />
                            </IconButton>
                        </>
                    )}
                </>
            ) : (
                <>
                    <Typography
                        variant={'body1'}
                        component={'p'}
                        fontStyle={props.value ? undefined : 'italic'}
                        sx={{
                            cursor: 'pointer',
                            flex: 1,
                            padding: '6px 8px',
                            borderRadius: '4px',
                            '&:hover': { backgroundColor: '#f5f5f5' },
                        }}
                        onClick={handleClick}
                    >
                        {props.value || <span style={{ color: 'gray' }}>{t(TranslationKey.info.clickToEdit)}</span>}
                    </Typography>
                    <EditButton label={t(props.label)} onClick={handleClick} />
                </>
            )}
        </Box>
    );
};

const PrintableTextField = (props: Pick<TextFieldProps, 'value' | 'fontTheme' | 'typographyComponent' | 'sx'>): React.ReactElement => {
    return (
        <Typography
            variant={props.fontTheme.variant ?? 'body1'}
            color={props.fontTheme.color}
            component={props.typographyComponent ?? 'p'}
            fontWeight={props.fontTheme.weight}
            fontStyle={props.fontTheme.style}
            sx={{ padding: '0px', color: `${props.fontTheme.color} !important`, ...props.sx }}
        >
            {props.value}
        </Typography>
    );
};

const GeneralTextField: React.FC<TextFieldProps> = (props: TextFieldProps) => {
    const { mode } = useMode();

    switch (mode) {
        case Mode.EDIT:
            return <EditableTextField {...props} />;
        case Mode.PRINT:
            return <PrintableTextField {...props} />;
    }
};

export { GeneralTextField, PrintableTextField, EditableTextField };
