import { IColorScheme, ILayout, ITheme } from '../interfaces/Theme.interface';
import { TemplateName } from '../common/enum';
import { Font, StyleSheet } from '@react-pdf/renderer';
import { getFontSizeToPDF } from './common.util';
import { IPDFStyle } from '../interfaces/pdfStyle.interface';
import { merge } from 'lodash';

const defaultTheme = (colorScheme: IColorScheme, customTheme: ITheme): ITheme => {
    const DEFAULT_THEME: ITheme = {
        layoutTheme: {
            main: 'LEFT',
            layout: null,
            sectionWidth: {
                topLeftWidth: 9,
                middleLeftWidth: 9,
                bottomLeftWidth: 9,
            },
            borderColor: colorScheme.primary,
            pageHeightMargin: 20,
            pageWidthMargin: 20,
        },
        imageTheme: {
            height: 120,
            width: 120,
            circular: false,
            hasBorder: false,
            borderColor: null,
            borderWidth: null,
        },
        nameTheme: {
            first: {
                variant: 'h2',
                color: colorScheme.base,
                weight: 'normal',
                style: 'normal',
            },
            last: {
                variant: 'h2',
                color: colorScheme.primary,
                weight: 'normal',
                style: 'normal',
            },
            margin: {
                gap: 5,
            },
        },
        professionTheme: {
            font: {
                variant: 'h4',
                color: colorScheme.secondary,
                weight: 'normal',
                style: 'normal',
            },
            margin: {
                bottom: 20,
            },
        },
        subSectionTheme: {
            backgroundColor: {},
        },
        profileTheme: {
            font: {
                variant: 'body1',
                color: colorScheme.base,
                weight: 'normal',
                style: 'normal',
            },
            margin: {
                bottom: 20,
            },
            title: {
                variant: 'h5',
                color: colorScheme.primary,
                weight: 'normal',
                style: 'normal',
                show: true,
            },
        },
        interestsTheme: {
            font: {
                variant: 'body1',
                color: colorScheme.base,
                weight: 'normal',
                style: 'normal',
            },
            margin: {
                bottom: 20,
            },
            title: {
                variant: 'h5',
                color: colorScheme.primary,
                weight: 'normal',
                style: 'normal',
                show: true,
            },
        },
        skillTheme: {
            font: {
                variant: 'body1',
                color: colorScheme.base,
                weight: 'normal',
                style: 'normal',
            },
            margin: {
                bottom: 20,
            },
            proficiency: {
                type: 'linear',
                activeColor: colorScheme.primary,
                inactiveColor: '#bdbdbd',
                gap: 0,
                borderRadius: 5,
                thickness: 10,
            },
            title: {
                variant: 'h5',
                color: colorScheme.primary,
                weight: 'normal',
                style: 'normal',
                show: true,
            },
        },
        languageTheme: {
            font: {
                variant: 'body1',
                color: colorScheme.base,
                weight: 'normal',
                style: 'normal',
            },
            margin: {
                bottom: 20,
            },
            proficiency: {
                type: 'linear',
                activeColor: colorScheme.primary,
                inactiveColor: '#bdbdbd',
                gap: 0,
                borderRadius: 5,
                thickness: 10,
            },
            title: {
                variant: 'h5',
                color: colorScheme.primary,
                weight: 'normal',
                style: 'normal',
                show: true,
            },
        },
        contactTheme: {
            font: {
                variant: 'body1',
                color: colorScheme.base,
                weight: 'normal',
                style: 'normal',
            },
            margin: {
                bottom: 20,
            },
            title: {
                variant: 'h5',
                color: colorScheme.primary,
                weight: 'normal',
                style: 'normal',
                show: true,
            },
        },
        experienceTheme: {
            header: {
                variant: 'h6',
                color: colorScheme.base,
                weight: 'normal',
                style: 'normal',
            },
            date: {
                variant: 'body1',
                color: colorScheme.secondary,
                weight: 'normal',
                style: 'normal',
                format: 'YYYY-MM',
            },
            item: {
                variant: 'body1',
                color: colorScheme.base,
                weight: 'normal',
                style: 'normal',
            },
            margin: {
                bottom: 20,
            },
            title: {
                variant: 'h5',
                color: colorScheme.primary,
                weight: 'normal',
                style: 'normal',
                show: true,
            },
        },
        educationTheme: {
            header: {
                variant: 'h6',
                color: colorScheme.base,
                weight: 'normal',
                style: 'normal',
            },
            date: {
                variant: 'body1',
                color: colorScheme.secondary,
                weight: 'normal',
                style: 'normal',
                format: 'YYYY-MM',
            },
            margin: {
                bottom: 20,
            },
            title: {
                variant: 'h5',
                color: colorScheme.primary,
                weight: 'normal',
                style: 'normal',
                show: true,
            },
        },
        referenceTheme: {
            header: {
                variant: 'h6',
                color: colorScheme.base,
                weight: 'normal',
                style: 'normal',
            },
            subHeader: {
                variant: 'body1',
                color: colorScheme.secondary,
                weight: 'normal',
                style: 'normal',
            },
            margin: {
                bottom: 20,
            },
            title: {
                variant: 'h5',
                color: colorScheme.primary,
                weight: 'normal',
                style: 'normal',
                show: true,
            },
        },
        socialLinkTheme: {
            header: {
                variant: 'h6',
                color: colorScheme.base,
                weight: 'normal',
                style: 'normal',
            },
            margin: {
                bottom: 20,
            },
            title: {
                variant: 'h5',
                color: colorScheme.primary,
                weight: 'normal',
                style: 'normal',
                show: true,
            },
        },
    };

    return merge({}, DEFAULT_THEME, customTheme);
};

export const colorSchemeTemplateName: { [key: string]: IColorScheme } = {
    [TemplateName.DAMASCUS]: { base: '#000', primary: '#095f4f', secondary: '#8f9594' },
    [TemplateName.HOMS]: { base: '#000', primary: '#1f5de0', secondary: '#8f9594' },
    [TemplateName.MECCA]: { base: '#000', primary: '#000', secondary: '#676b6b' },
    [TemplateName.MADINA]: { base: '#000', primary: '#000', secondary: '#676b6b' },
    [TemplateName.DOHA]: { base: '#000', primary: '#000', secondary: '#676b6b' },
    [TemplateName.ALQUDS]: { base: '#000', primary: '#000', secondary: '#676b6b' },
    [TemplateName.HAMA]: { base: '#000', primary: '#29426C', secondary: '#676b6b' },
    [TemplateName.CAIRO]: { base: '#000', primary: '#15364f', secondary: '#676b6b' },
    [TemplateName.IDLIB]: { base: '#000', primary: '#000', secondary: '#676b6b' },
};

export const defaultTemplateSectionPosition: { [key: string]: ILayout } = {
    [TemplateName.DAMASCUS]: {
        top: { immutable: false, sections: [] },
        topLeft: { immutable: false, sections: ['name', 'profession'] },
        topRight: { immutable: false, sections: ['image'] },
        middle: { immutable: false, sections: [] },
        middleLeft: { immutable: false, sections: ['contact', 'skills', 'languages', 'references', 'interests'] },
        middleRight: { immutable: false, sections: ['profile', 'experiences', 'educations', 'links'] },
        bottom: { immutable: false, sections: [] },
        bottomLeft: { immutable: false, sections: [] },
        bottomRight: { immutable: false, sections: [] },
        excludedSections: { immutable: false, sections: [] },
    },
    [TemplateName.HOMS]: {
        top: { immutable: false, sections: [] },
        topLeft: { immutable: false, sections: ['image'] },
        topRight: { immutable: false, sections: ['name', 'profession'] },
        middle: { immutable: false, sections: [] },
        middleLeft: { immutable: false, sections: ['profile', 'experiences', 'educations', 'links'] },
        middleRight: { immutable: false, sections: ['contact', 'skills', 'languages', 'references', 'interests'] },
        bottom: { immutable: false, sections: [] },
        bottomLeft: { immutable: false, sections: [] },
        bottomRight: { immutable: false, sections: [] },
        excludedSections: { immutable: false, sections: [] },
    },
    [TemplateName.MECCA]: {
        top: { immutable: false, sections: [] },
        topLeft: { immutable: false, sections: ['image', 'contact'] },
        topRight: { immutable: false, sections: ['name', 'profession', 'profile'] },
        middle: { immutable: false, sections: [] },
        middleLeft: { immutable: false, sections: ['experiences', 'educations', 'links'] },
        middleRight: { immutable: false, sections: ['skills', 'languages', 'references', 'interests'] },
        bottom: { immutable: false, sections: [] },
        bottomLeft: { immutable: false, sections: [] },
        bottomRight: { immutable: false, sections: [] },
        excludedSections: { immutable: false, sections: [] },
    },
    [TemplateName.MADINA]: {
        top: { immutable: false, sections: [] },
        topLeft: { immutable: false, sections: ['name', 'profession', 'contact'] },
        topRight: { immutable: false, sections: ['image'] },
        middle: { immutable: false, sections: ['profile'] },
        middleLeft: { immutable: false, sections: ['skills'] },
        middleRight: { immutable: false, sections: ['languages'] },
        bottom: { immutable: false, sections: ['experiences', 'educations'] },
        bottomLeft: { immutable: false, sections: ['references', 'links'] },
        bottomRight: { immutable: false, sections: ['interests'] },
        excludedSections: { immutable: false, sections: [] },
    },
    [TemplateName.DOHA]: {
        top: { immutable: false, sections: [] },
        topLeft: { immutable: false, sections: ['name', 'profession'] },
        topRight: { immutable: false, sections: ['image'] },
        middle: { immutable: false, sections: ['contact', 'profile'] },
        middleLeft: { immutable: false, sections: ['skills'] },
        middleRight: { immutable: false, sections: ['languages'] },
        bottom: { immutable: false, sections: ['experiences', 'educations', 'references', 'links', 'interests'] },
        bottomLeft: { immutable: false, sections: [] },
        bottomRight: { immutable: false, sections: [] },
        excludedSections: { immutable: false, sections: [] },
    },
    [TemplateName.ALQUDS]: {
        top: { immutable: false, sections: [] },
        topLeft: { immutable: false, sections: ['name', 'profession', 'profile'] },
        topRight: { immutable: false, sections: ['image', 'contact'] },
        middle: { immutable: false, sections: [] },
        middleLeft: { immutable: false, sections: ['skills'] },
        middleRight: { immutable: false, sections: ['languages'] },
        bottom: { immutable: false, sections: ['experiences', 'educations', 'links', 'references', 'interests'] },
        bottomLeft: { immutable: false, sections: [] },
        bottomRight: { immutable: false, sections: [] },
        excludedSections: { immutable: false, sections: [] },
    },
    [TemplateName.HAMA]: {
        top: { immutable: false, sections: [] },
        topLeft: { immutable: false, sections: ['image'] },
        topRight: { immutable: true, sections: ['name', 'profession'] },
        middle: { immutable: false, sections: [] },
        middleLeft: { immutable: false, sections: ['contact', 'skills', 'languages', 'references', 'interests'] },
        middleRight: { immutable: false, sections: ['profile', 'experiences', 'educations', 'links'] },
        bottom: { immutable: false, sections: [] },
        bottomLeft: { immutable: false, sections: [] },
        bottomRight: { immutable: false, sections: [] },
        excludedSections: { immutable: false, sections: [] },
    },
    [TemplateName.CAIRO]: {
        top: { immutable: false, sections: [] },
        topLeft: { immutable: false, sections: ['image'] },
        topRight: { immutable: false, sections: ['name', 'profession'] },
        middle: { immutable: false, sections: [] },
        middleLeft: { immutable: false, sections: ['contact', 'skills', 'languages', 'references', 'interests'] },
        middleRight: { immutable: false, sections: ['profile', 'experiences', 'educations', 'links'] },
        bottom: { immutable: false, sections: [] },
        bottomLeft: { immutable: false, sections: [] },
        bottomRight: { immutable: false, sections: [] },
        excludedSections: { immutable: false, sections: [] },
    },
    [TemplateName.IDLIB]: {
        top: { immutable: false, sections: [] },
        topLeft: { immutable: false, sections: ['image'] },
        topRight: { immutable: false, sections: ['name', 'profession', 'contact'] },
        middle: { immutable: false, sections: [] },
        middleLeft: { immutable: false, sections: ['skills', 'languages', 'references', 'interests'] },
        middleRight: { immutable: false, sections: ['profile', 'experiences', 'educations', 'links'] },
        bottom: { immutable: false, sections: [] },
        bottomLeft: { immutable: false, sections: [] },
        bottomRight: { immutable: false, sections: [] },
        excludedSections: { immutable: false, sections: [] },
    },
};

type DefaultThemeTemplateName = {
    [key in TemplateName]: () => ITheme;
};

export const defaultThemeTemplateName: DefaultThemeTemplateName = {
    DAMASCUS(): ITheme {
        return defaultTheme(colorSchemeTemplateName[TemplateName.DAMASCUS], {
            layoutTheme: {
                main: 'RIGHT',
                layout: defaultTemplateSectionPosition[TemplateName.DAMASCUS],
                sectionWidth: {
                    topLeftWidth: 10,
                    middleLeftWidth: 3.2,
                    bottomLeftWidth: 3.2,
                },
            },
            professionTheme: {
                margin: { bottom: 10 },
            },
            experienceTheme: {
                header: { variant: 'body1', style: 'italic' },
                date: { variant: 'body2' },
                item: { variant: 'body2' },
            },
            educationTheme: {
                header: { variant: 'body1', style: 'italic' },
                date: { variant: 'body2' },
            },
            referenceTheme: {
                header: { variant: 'body1', style: 'italic' },
                subHeader: { variant: 'body2' },
            },
        } as ITheme);
    },
    HOMS(): ITheme {
        return defaultTheme(colorSchemeTemplateName[TemplateName.HOMS], {
            layoutTheme: {
                main: 'LEFT',
                layout: defaultTemplateSectionPosition[TemplateName.HOMS],
                sectionWidth: {
                    topLeftWidth: 2.5,
                    middleLeftWidth: 9,
                    bottomLeftWidth: 9,
                },
                borderColor: null,
            },
            professionTheme: {
                margin: { bottom: 10 },
            },
            experienceTheme: {
                header: { variant: 'body1', style: 'italic' },
                date: { variant: 'body2' },
                item: { variant: 'body2' },
                margin: { bottom: 5 },
            },
            educationTheme: {
                header: { variant: 'body1', style: 'italic' },
                date: { variant: 'body2' },
                margin: { bottom: 5 },
            },
            referenceTheme: {
                header: { variant: 'body1', style: 'italic' },
                subHeader: { variant: 'body2' },
                margin: { bottom: 5 },
            },
        } as ITheme);
    },
    MECCA(): ITheme {
        const colorScheme: IColorScheme = colorSchemeTemplateName[TemplateName.MECCA];
        return defaultTheme(colorScheme, {
            layoutTheme: {
                main: 'LEFT',
                layout: defaultTemplateSectionPosition[TemplateName.MECCA],
                sectionWidth: {
                    topLeftWidth: 3.5,
                    middleLeftWidth: 8,
                    bottomLeftWidth: 8,
                },
                borderColor: null,
            },
            nameTheme: {
                first: {
                    variant: 'h4',
                    weight: 'bold',
                },
                last: {
                    variant: 'h4',
                    weight: 'bold',
                },
            },
            professionTheme: {
                font: { variant: 'h6' },
                margin: { bottom: 0 },
            },
            subSectionTheme: {
                backgroundColor: {
                    profileSection: '#eee',
                    skillSection: '#eee',
                    languageSection: '#eee',
                    interestSection: '#eee',
                },
            },
            contactTheme: { title: { show: false } },
            profileTheme: {
                title: { variant: 'h6', weight: 'bold' },
                font: { variant: 'body2' },
                margin: { bottom: 0 },
            },
            experienceTheme: {
                title: { variant: 'h6', weight: 'bold' },
                header: { variant: 'body1', style: 'italic' },
                date: { variant: 'body2' },
                item: { variant: 'body2' },
                margin: { bottom: 5 },
            },
            educationTheme: {
                title: { variant: 'h6', weight: 'bold' },
                header: { variant: 'body1', style: 'italic' },
                date: { variant: 'body2' },
                margin: { bottom: 5 },
            },
            referenceTheme: {
                title: { variant: 'h6', weight: 'bold' },
                header: { variant: 'body1', style: 'italic' },
                subHeader: { variant: 'body2' },
                margin: { bottom: 5 },
            },
            skillTheme: {
                title: { variant: 'h6', weight: 'bold' },
                font: { variant: 'body2' },
                margin: { bottom: 0 },
                proficiency: { type: 'dotted', borderRadius: 10, gap: 0.8, thickness: 8, inactiveColor: '#aaa' },
            },
            languageTheme: {
                title: { variant: 'h6', weight: 'bold' },
                font: { variant: 'body2' },
                margin: { bottom: 0 },
                proficiency: { type: 'dotted', borderRadius: 10, gap: 0.8, thickness: 8, inactiveColor: '#aaa' },
            },
            interestsTheme: {
                title: { variant: 'h6', weight: 'bold' },
                font: { variant: 'body2' },
                margin: { bottom: 0 },
            },
        } as ITheme);
    },
    MADINA(): ITheme {
        return defaultTheme(colorSchemeTemplateName[TemplateName.MADINA], {
            layoutTheme: {
                layout: defaultTemplateSectionPosition[TemplateName.MADINA],
                sectionWidth: {
                    topLeftWidth: 9,
                    middleLeftWidth: 6,
                    bottomLeftWidth: 6,
                },
                pageWidthMargin: 30,
                pageHeightMargin: 30,
                borderColor: null,
            },
            imageTheme: {
                width: 175,
                height: 240,
            },
            nameTheme: {
                first: {
                    variant: 'h4',
                },
                last: {
                    variant: 'h4',
                },
                margin: {
                    gap: 5,
                },
            },
            contactTheme: {
                title: { show: false, variant: 'h4', weight: 'light' },
            },
            profileTheme: {
                title: { variant: 'h4', weight: 'light' },
            },
            experienceTheme: {
                title: { variant: 'h4', weight: 'light' },
            },
            educationTheme: {
                title: { variant: 'h4', weight: 'light' },
            },
            referenceTheme: {
                title: { variant: 'h4', weight: 'light' },
            },
            interestsTheme: {
                title: { variant: 'h4', weight: 'light' },
            },
            skillTheme: {
                title: { variant: 'h4', weight: 'light' },
                proficiency: { type: 'dotted', borderRadius: 10, gap: 1, thickness: 10, activeColor: '#000', inactiveColor: '#aaa' },
            },
            languageTheme: {
                title: { variant: 'h4', weight: 'light' },
                proficiency: { type: 'dotted', borderRadius: 10, gap: 1, thickness: 10, activeColor: '#000', inactiveColor: '#aaa' },
            },
        } as ITheme);
    },
    DOHA(): ITheme {
        const colorScheme: IColorScheme = colorSchemeTemplateName[TemplateName.DOHA];
        return defaultTheme(colorScheme, {
            layoutTheme: {
                sectionWidth: {
                    topLeftWidth: 10,
                    middleLeftWidth: 6,
                    bottomLeftWidth: 6,
                },
                main: 'RIGHT',
                layout: defaultTemplateSectionPosition[TemplateName.DOHA],
                borderColor: null,
            },
            imageTheme: {
                width: 100,
                height: 100,
            },
            nameTheme: {
                first: {
                    variant: 'h4',
                },
                last: {
                    variant: 'h4',
                },
                margin: {
                    gap: 5,
                },
            },
            professionTheme: {
                font: { variant: 'h5' },
            },
            contactTheme: {
                title: { variant: 'h6', weight: 'bold' },
            },
            profileTheme: {
                title: { variant: 'h6', weight: 'bold' },
            },
            experienceTheme: {
                title: { variant: 'h6', weight: 'bold' },
                item: { variant: 'body2' },
            },
            educationTheme: {
                title: { variant: 'h6', weight: 'bold' },
            },
            referenceTheme: {
                title: { variant: 'h6', weight: 'bold' },
            },
            interestsTheme: {
                title: { variant: 'h6', weight: 'bold' },
            },
            skillTheme: {
                title: { variant: 'h6', weight: 'bold' },
                proficiency: { type: 'dotted', borderRadius: 10, gap: 1, thickness: 10, activeColor: '#000', inactiveColor: '#aaa' },
            },
            languageTheme: {
                title: { variant: 'h6', weight: 'bold' },
                proficiency: { type: 'dotted', borderRadius: 10, gap: 1, thickness: 10, activeColor: '#000', inactiveColor: '#aaa' },
            },
        } as ITheme);
    },
    ALQUDS(): ITheme {
        const colorScheme: IColorScheme = colorSchemeTemplateName[TemplateName.ALQUDS];
        return defaultTheme(colorScheme, {
            layoutTheme: {
                layout: defaultTemplateSectionPosition[TemplateName.ALQUDS],
                borderColor: '#E6E7E7',
                pageWidthMargin: 0,
                sectionWidth: {
                    topLeftWidth: 9,
                    middleLeftWidth: 6,
                    bottomLeftWidth: 6,
                },
            },
            imageTheme: {
                width: 120,
                height: 120,
            },
            nameTheme: {
                first: {
                    variant: 'h4',
                },
                last: {
                    variant: 'h4',
                },
                margin: {
                    gap: 5,
                },
            },
            professionTheme: {
                font: {
                    variant: 'h5',
                },
            },
            profileTheme: {
                title: { show: false, variant: 'h5', weight: 'bold' },
            },
            contactTheme: {
                title: { show: false, variant: 'h5', weight: 'bold' },
            },
            experienceTheme: {
                title: { variant: 'h5', weight: 'bold' },
            },
            educationTheme: {
                title: { variant: 'h5', weight: 'bold' },
            },
            referenceTheme: {
                title: { variant: 'h5', weight: 'bold' },
            },
            interestsTheme: {
                title: { variant: 'h5', weight: 'bold' },
            },
            skillTheme: {
                title: { variant: 'h5', weight: 'bold' },
                proficiency: { type: 'dotted', borderRadius: 10, gap: 1, thickness: 10, activeColor: '#000', inactiveColor: '#999' },
            },
            languageTheme: {
                title: { variant: 'h5', weight: 'bold' },
                proficiency: { type: 'dotted', borderRadius: 10, gap: 1, thickness: 10, activeColor: '#000', inactiveColor: '#999' },
            },
        } as ITheme);
    },
    HAMA(): ITheme {
        const colorScheme: IColorScheme = colorSchemeTemplateName[TemplateName.HAMA];
        return defaultTheme(colorScheme, {
            layoutTheme: {
                sectionWidth: {
                    topLeftWidth: 3.5,
                    middleLeftWidth: 3.5,
                    bottomLeftWidth: 3.5,
                },
                main: 'RIGHT',
                layout: defaultTemplateSectionPosition[TemplateName.HAMA],
                borderColor: null,
                pageWidthMargin: 0,
                pageHeightMargin: 0,
            },
            subSectionTheme: {
                backgroundColor: {
                    nameSection: colorScheme.primary,
                    leftSection: '#ECEDEF',
                },
            },
            imageTheme: {
                width: 140,
                height: 140,
                circular: true,
                hasBorder: true,
                borderColor: '#fff',
                borderWidth: 3,
            },
            nameTheme: {
                first: {
                    variant: 'h3',
                    weight: 'bold',
                    color: '#fff',
                },
                last: {
                    variant: 'h3',
                    weight: 'bold',
                    color: '#fff',
                },
                margin: {
                    gap: 5,
                },
            },
            professionTheme: {
                font: {
                    variant: 'h5',
                    weight: 'light',
                    color: '#fff',
                },
            },
            contactTheme: {
                title: { variant: 'h5', weight: 'bold' },
            },
            profileTheme: {
                title: { variant: 'h5', weight: 'bold' },
            },
            experienceTheme: {
                title: { variant: 'h5', weight: 'bold' },
            },
            educationTheme: {
                title: { variant: 'h5', weight: 'bold' },
                header: {
                    variant: 'body1',
                },
            },
            referenceTheme: {
                title: { variant: 'h5', weight: 'bold' },
                header: {
                    variant: 'body1',
                },
                subHeader: {
                    variant: 'body2',
                },
            },
            interestsTheme: {
                title: { variant: 'h5', weight: 'bold' },
            },
            skillTheme: {
                title: { variant: 'h5', weight: 'bold' },
                proficiency: { type: 'none' },
            },
            languageTheme: {
                title: { variant: 'h5', weight: 'bold' },
                proficiency: { type: 'none' },
            },
        } as ITheme);
    },
    CAIRO(): ITheme {
        const colorScheme: IColorScheme = colorSchemeTemplateName[TemplateName.HAMA];
        return defaultTheme(colorScheme, {
            layoutTheme: {
                sectionWidth: {
                    topLeftWidth: 3.5,
                    middleLeftWidth: 3.5,
                    bottomLeftWidth: 3.5,
                },
                main: 'RIGHT',
                layout: defaultTemplateSectionPosition[TemplateName.CAIRO],
                borderColor: null,
                pageWidthMargin: 0,
                pageHeightMargin: 0,
            },
            subSectionTheme: {
                backgroundColor: {
                    leftSection: colorScheme.primary,
                },
            },
            imageTheme: {
                width: 140,
                height: 140,
                circular: true,
                hasBorder: true,
                borderColor: '#fff',
                borderWidth: 3,
            },
            nameTheme: {
                first: {
                    variant: 'h3',
                    weight: 'bold',
                    color: colorScheme.primary,
                },
                last: {
                    variant: 'h3',
                    weight: 'bold',
                },
                margin: {
                    gap: 8,
                },
            },
            professionTheme: {
                font: {
                    variant: 'h5',
                    weight: 'light',
                },
            },
            contactTheme: {
                title: { variant: 'h5', weight: 'bold', color: '#fff' },
                font: { color: '#fff' },
            },
            profileTheme: {
                title: { variant: 'h5', weight: 'bold' },
            },
            experienceTheme: {
                title: { variant: 'h5', weight: 'bold' },
            },
            educationTheme: {
                title: { variant: 'h5', weight: 'bold' },
                header: {
                    variant: 'body1',
                },
            },
            referenceTheme: {
                title: { variant: 'h5', weight: 'bold', color: '#fff' },
                header: {
                    variant: 'body1',
                    color: '#fff',
                },
                subHeader: {
                    variant: 'body2',
                    color: '#fff',
                },
            },
            interestsTheme: {
                title: { variant: 'h5', weight: 'bold' },
            },
            skillTheme: {
                title: { variant: 'h5', weight: 'bold', color: '#fff' },
                font: { color: '#fff' },
                proficiency: { type: 'none' },
            },
            languageTheme: {
                title: { variant: 'h5', weight: 'bold', color: '#fff' },
                font: { color: '#fff' },
                proficiency: { type: 'none' },
            },
        } as ITheme);
    },
    IDLIB(): ITheme {
        const colorScheme: IColorScheme = colorSchemeTemplateName[TemplateName.IDLIB];
        return defaultTheme(colorScheme, {
            layoutTheme: {
                sectionWidth: {
                    topLeftWidth: 3.5,
                    middleLeftWidth: 3.5,
                    bottomLeftWidth: 3.5,
                },
                main: 'RIGHT',
                layout: defaultTemplateSectionPosition[TemplateName.IDLIB],
                borderColor: '#E3E3E3',
            },
            imageTheme: {
                width: 140,
                height: 140,
                circular: true,
            },
            nameTheme: {
                first: {
                    variant: 'h4',
                    color: colorScheme.primary,
                },
                last: {
                    variant: 'h4',
                    color: colorScheme.primary,
                },
                margin: {
                    gap: 5,
                },
            },
            professionTheme: {
                font: {
                    variant: 'h5',
                },
            },
            contactTheme: {
                title: { variant: 'h6', weight: 'bold' },
                font: { variant: 'body2', color: colorScheme.secondary },
            },
            profileTheme: {
                title: { variant: 'h6', weight: 'bold' },
                font: { variant: 'body2', color: colorScheme.secondary },
            },
            experienceTheme: {
                title: { variant: 'h6', weight: 'bold' },
                header: {
                    variant: 'body1',
                    style: 'italic',
                },
                date: {
                    variant: 'body2',
                },
                item: {
                    variant: 'body2',
                },
            },
            educationTheme: {
                title: { variant: 'h6', weight: 'bold' },
                header: {
                    variant: 'body1',
                    style: 'italic',
                },
                date: {
                    variant: 'body2',
                },
            },
            referenceTheme: {
                title: { variant: 'h6', weight: 'bold' },
                header: { variant: 'body1' },
                subHeader: { variant: 'body2' },
            },
            interestsTheme: {
                title: { variant: 'h6', weight: 'bold' },
            },
            skillTheme: {
                title: { variant: 'h6', weight: 'bold' },
                font: { variant: 'body2' },
                proficiency: {
                    type: 'dashed',
                    borderRadius: 0,
                    gap: 1,
                    thickness: 5,
                    activeColor: colorScheme.primary,
                    inactiveColor: '#999',
                },
            },
            languageTheme: {
                title: { variant: 'h6', weight: 'bold' },
                font: { variant: 'body2' },
                proficiency: {
                    type: 'dashed',
                    borderRadius: 0,
                    gap: 1,
                    thickness: 5,
                    activeColor: colorScheme.primary,
                    inactiveColor: '#999',
                },
            },
        } as ITheme);
    },
    ALEPPO(): ITheme {
        return defaultTheme(colorSchemeTemplateName[TemplateName.ALEPPO], {
            layoutTheme: { main: 'RIGHT', layout: defaultTemplateSectionPosition[TemplateName.ALEPPO] },
        } as ITheme);
    },
    RIADH(): ITheme {
        return defaultTheme(colorSchemeTemplateName[TemplateName.RIADH], {
            layoutTheme: { main: 'RIGHT', layout: defaultTemplateSectionPosition[TemplateName.RIADH] },
        } as ITheme);
    },
    GAZA(): ITheme {
        return defaultTheme(colorSchemeTemplateName[TemplateName.GAZA], {
            layoutTheme: { main: 'RIGHT', layout: defaultTemplateSectionPosition[TemplateName.GAZA] },
        } as ITheme);
    },
    GOLAN(): ITheme {
        return defaultTheme(colorSchemeTemplateName[TemplateName.GOLAN], {
            layoutTheme: { main: 'RIGHT', layout: defaultTemplateSectionPosition[TemplateName.GOLAN] },
        } as ITheme);
    },
    MOSUL(): ITheme {
        return defaultTheme(colorSchemeTemplateName[TemplateName.MOSUL], {
            layoutTheme: { main: 'RIGHT', layout: defaultTemplateSectionPosition[TemplateName.MOSUL] },
        } as ITheme);
    },
    TRIPOLI(): ITheme {
        return defaultTheme(colorSchemeTemplateName[TemplateName.TRIPOLI], {
            layoutTheme: {
                main: 'RIGHT',
                layout: defaultTemplateSectionPosition[TemplateName.TRIPOLI],
            },
        } as ITheme);
    },
};

Font.register({
    family: 'Tajawal',
    fonts: [
        { src: '/fonts/tajawal/Tajawal-Light.ttf', fontWeight: 'light' },
        { src: '/fonts/tajawal/Tajawal-Light.ttf', fontWeight: 'light', fontStyle: 'italic' },
        { src: '/fonts/tajawal/Tajawal-Regular.ttf', fontWeight: 'normal' },
        { src: '/fonts/tajawal/Tajawal-Regular.ttf', fontWeight: 'normal', fontStyle: 'italic' },
        { src: '/fonts/tajawal/Tajawal-Bold.ttf', fontWeight: 'bold' },
        { src: '/fonts/tajawal/Tajawal-Bold.ttf', fontWeight: 'bold', fontStyle: 'italic' },
    ],
});

Font.register({
    family: 'Roboto',
    fonts: [
        { src: '/fonts/roboto/Roboto-Light.ttf', fontWeight: 'light' },
        { src: '/fonts/roboto/Roboto-LightItalic.ttf', fontWeight: 'light', fontStyle: 'italic' },
        { src: '/fonts/roboto/Roboto-Regular.ttf', fontWeight: 'normal' },
        { src: '/fonts/roboto/Roboto-RegularItalic.ttf', fontWeight: 'normal', fontStyle: 'italic' },
        { src: '/fonts/roboto/Roboto-Bold.ttf', fontWeight: 'bold' },
        { src: '/fonts/roboto/Roboto-BoldItalic.ttf', fontWeight: 'bold', fontStyle: 'italic' },
    ],
});

export const getPDFStyles = (theme: ITheme, isRTL: boolean): IPDFStyle => {
    const leftTopWidth: number = (theme.layoutTheme.sectionWidth.topLeftWidth / 12) * 100;
    const rightTopWidth: number = 100 - leftTopWidth;

    const leftMiddleWidth: number = (theme.layoutTheme.sectionWidth.middleLeftWidth / 12) * 100;
    const rightMiddleWidth: number = 100 - leftMiddleWidth;

    const leftBottomWidth: number = (theme.layoutTheme.sectionWidth.bottomLeftWidth / 12) * 100;
    const rightBottomWidth: number = 100 - leftBottomWidth;

    return StyleSheet.create({
        page: {
            paddingTop: theme.layoutTheme.pageHeightMargin,
            paddingBottom: theme.layoutTheme.pageHeightMargin,
            paddingLeft: theme.layoutTheme.pageWidthMargin,
            paddingRight: theme.layoutTheme.pageWidthMargin,
            fontFamily: isRTL ? 'Tajawal' : 'Roboto',
            textAlign: isRTL ? 'right' : 'left',
            direction: isRTL ? 'rtl' : 'ltr',
            unicodeBidi: 'plaintext',
        },
        rowStyle: {
            flexDirection: isRTL ? 'row-reverse' : 'row',
        },
        leftSectionPaddingStyle: {
            padding: isRTL ? '10px 0 0 10px' : '10px 10px 0 0',
        },
        rightSectionPaddingStyle: {
            padding: isRTL ? '10px 10px 0 0' : '10px 0 0 10px',
        },
        nameView: {
            gap: theme.nameTheme.margin.gap,
        },
        firstNameFont: {
            fontSize: getFontSizeToPDF(theme.nameTheme.first.variant),
            color: theme.nameTheme.first.color,
            fontWeight: theme.nameTheme.first.weight,
            fontStyle: theme.nameTheme.first.style,
        },
        lastNameFont: {
            fontSize: getFontSizeToPDF(theme.nameTheme.last.variant),
            color: theme.nameTheme.last.color,
            fontWeight: theme.nameTheme.last.weight,
            fontStyle: theme.nameTheme.last.style,
        },
        professionView: {
            marginBottom: theme.professionTheme.margin.bottom,
        },
        professionFont: {
            fontSize: getFontSizeToPDF(theme.professionTheme.font.variant),
            color: theme.professionTheme.font.color,
            fontWeight: theme.professionTheme.font.weight,
            fontStyle: theme.professionTheme.font.style,
        },
        imageView: {
            width: theme.imageTheme.width * 0.7,
            height: theme.imageTheme.height * 0.7,
            borderRadius: theme.imageTheme.circular ? '50%' : '',
            borderStyle: theme.imageTheme.hasBorder ? `solid` : undefined,
            borderColor: theme.imageTheme.borderColor ?? undefined,
            borderWidth: theme.imageTheme.hasBorder ? (theme.imageTheme.borderWidth ?? 1) : undefined,
        },
        layoutBorderStyle: {
            borderLeft: !isRTL && theme.layoutTheme.borderColor ? 4 : undefined,
            borderLeftColor: !isRTL ? (theme.layoutTheme.borderColor ?? undefined) : undefined,
            borderRight: isRTL && theme.layoutTheme.borderColor ? 4 : undefined,
            borderRightColor: isRTL ? (theme.layoutTheme.borderColor ?? undefined) : undefined,
            borderBottom: theme.layoutTheme.borderColor ? 2 : undefined,
            borderBottomColor: theme.layoutTheme.borderColor ?? undefined,
        },
        leftTopView: {
            width: `${leftTopWidth}%`,
        },
        rightTopView: {
            width: `${rightTopWidth}%`,
        },
        leftMiddleView: {
            width: `${leftMiddleWidth}%`,
        },
        rightMiddleView: {
            width: `${rightMiddleWidth}%`,
        },
        leftBottomView: {
            width: `${leftBottomWidth}%`,
        },
        rightBottomView: {
            width: `${rightBottomWidth}%`,
        },
        horizontalDivider: {
            borderTop: 4,
            borderColor: theme.layoutTheme.borderColor ?? undefined,
        },
        contactView: {
            marginBottom: theme.contactTheme.margin.bottom,
            showTitle: theme.contactTheme.title.show,
        },
        contactFont: {
            fontSize: getFontSizeToPDF(theme.contactTheme.font.variant),
            color: theme.contactTheme.font.color,
            fontWeight: theme.contactTheme.font.weight,
            fontStyle: theme.contactTheme.font.style,
        },
        contactTitleFont: {
            fontSize: getFontSizeToPDF(theme.contactTheme.title.variant),
            color: theme.contactTheme.title.color,
            fontWeight: theme.contactTheme.title.weight,
            fontStyle: theme.contactTheme.title.style,
        },
        skillView: {
            marginBottom: theme.skillTheme.margin.bottom,
            showTitle: theme.skillTheme.title.show,
        },
        skillFont: {
            fontSize: getFontSizeToPDF(theme.skillTheme.font.variant),
            color: theme.skillTheme.font.color,
            fontWeight: theme.skillTheme.font.weight,
            fontStyle: theme.skillTheme.font.style,
        },
        skillTitleFont: {
            fontSize: getFontSizeToPDF(theme.skillTheme.title.variant),
            color: theme.skillTheme.title.color,
            fontWeight: theme.skillTheme.title.weight,
            fontStyle: theme.skillTheme.title.style,
        },
        skillBarStyle: {
            color: '',
            ...theme.skillTheme.proficiency,
        },
        languageView: {
            marginBottom: theme.languageTheme.margin.bottom,
            showTitle: theme.languageTheme.title.show,
        },
        languageFont: {
            fontSize: getFontSizeToPDF(theme.languageTheme.font.variant),
            color: theme.languageTheme.font.color,
            fontWeight: theme.languageTheme.font.weight,
            fontStyle: theme.languageTheme.font.style,
        },
        languageTitleFont: {
            fontSize: getFontSizeToPDF(theme.languageTheme.title.variant),
            color: theme.languageTheme.title.color,
            fontWeight: theme.languageTheme.title.weight,
            fontStyle: theme.languageTheme.title.style,
        },
        languageBarStyle: {
            color: '',
            ...theme.languageTheme.proficiency,
        },
        interestsView: {
            marginBottom: theme.interestsTheme.margin.bottom,
            showTitle: theme.interestsTheme.title.show,
        },
        interestsFont: {
            fontSize: getFontSizeToPDF(theme.interestsTheme.font.variant),
            color: theme.interestsTheme.font.color,
            fontWeight: theme.interestsTheme.font.weight,
            fontStyle: theme.interestsTheme.font.style,
        },
        interestsTitleFont: {
            fontSize: getFontSizeToPDF(theme.interestsTheme.title.variant),
            color: theme.interestsTheme.title.color,
            fontWeight: theme.interestsTheme.title.weight,
            fontStyle: theme.interestsTheme.title.style,
        },
        profileView: {
            marginBottom: theme.profileTheme.margin.bottom,
            showTitle: theme.profileTheme.title.show,
        },
        profileFont: {
            fontSize: getFontSizeToPDF(theme.profileTheme.font.variant),
            color: theme.profileTheme.font.color,
            fontWeight: theme.profileTheme.font.weight,
            fontStyle: theme.profileTheme.font.style,
        },
        profileTitleFont: {
            fontSize: getFontSizeToPDF(theme.profileTheme.title.variant),
            color: theme.profileTheme.title.color,
            fontWeight: theme.profileTheme.title.weight,
            fontStyle: theme.profileTheme.title.style,
        },
        experienceView: {
            marginBottom: theme.experienceTheme.margin.bottom,
            showTitle: theme.experienceTheme.title.show,
        },
        experienceTitleFont: {
            fontSize: getFontSizeToPDF(theme.experienceTheme.title.variant),
            color: theme.experienceTheme.title.color,
            fontWeight: theme.experienceTheme.title.weight,
            fontStyle: theme.experienceTheme.title.style,
        },
        experienceHeaderFont: {
            fontSize: getFontSizeToPDF(theme.experienceTheme.header.variant),
            color: theme.experienceTheme.header.color,
            fontWeight: theme.experienceTheme.header.weight,
            fontStyle: theme.experienceTheme.header.style,
        },
        experienceDateFormat: {
            color: '',
            format: theme.experienceTheme.date.format,
        },
        experienceDateFont: {
            fontSize: getFontSizeToPDF(theme.experienceTheme.date.variant),
            color: theme.experienceTheme.date.color,
            fontWeight: theme.experienceTheme.date.weight,
            fontStyle: theme.experienceTheme.date.style,
        },
        experienceItemFont: {
            fontSize: getFontSizeToPDF(theme.experienceTheme.item.variant),
            color: theme.experienceTheme.item.color,
            fontWeight: theme.experienceTheme.item.weight,
            fontStyle: theme.experienceTheme.item.style,
        },
        educationView: {
            marginBottom: theme.educationTheme.margin.bottom,
            showTitle: theme.educationTheme.title.show,
        },
        educationTitleFont: {
            fontSize: getFontSizeToPDF(theme.educationTheme.title.variant),
            color: theme.educationTheme.title.color,
            fontWeight: theme.educationTheme.title.weight,
            fontStyle: theme.educationTheme.title.style,
        },
        educationHeaderFont: {
            fontSize: getFontSizeToPDF(theme.educationTheme.header.variant),
            color: theme.educationTheme.header.color,
            fontWeight: theme.educationTheme.header.weight,
            fontStyle: theme.educationTheme.header.style,
        },
        educationDateFormat: {
            color: '',
            format: theme.educationTheme.date.format,
        },
        educationDateFont: {
            fontSize: getFontSizeToPDF(theme.educationTheme.date.variant),
            color: theme.educationTheme.date.color,
            fontWeight: theme.educationTheme.date.weight,
            fontStyle: theme.educationTheme.date.style,
        },
        certificationView: {
            marginBottom: theme.educationTheme.margin.bottom,
        },
        certificationHeaderFont: {
            fontSize: getFontSizeToPDF(theme.educationTheme.header.variant),
            color: theme.educationTheme.header.color,
            fontWeight: theme.educationTheme.header.weight,
            fontStyle: theme.educationTheme.header.style,
        },
        certificationDateFormat: {
            color: '',
            format: theme.experienceTheme.date.format,
        },
        certificationDateFont: {
            fontSize: getFontSizeToPDF(theme.experienceTheme.date.variant),
            color: theme.experienceTheme.date.color,
            fontWeight: theme.educationTheme.date.weight,
            fontStyle: theme.educationTheme.date.style,
        },
        referenceView: {
            marginBottom: theme.referenceTheme.margin.bottom,
            showTitle: theme.referenceTheme.title.show,
        },

        referenceTitleFont: {
            fontSize: getFontSizeToPDF(theme.referenceTheme.title.variant),
            color: theme.referenceTheme.title.color,
            fontWeight: theme.referenceTheme.title.weight,
            fontStyle: theme.referenceTheme.title.style,
        },
        referenceHeaderFont: {
            fontSize: getFontSizeToPDF(theme.referenceTheme.header.variant),
            color: theme.referenceTheme.header.color,
            fontWeight: theme.referenceTheme.header.weight,
            fontStyle: theme.referenceTheme.header.style,
        },
        referenceSubHeaderFont: {
            fontSize: getFontSizeToPDF(theme.referenceTheme.subHeader.variant),
            color: theme.referenceTheme.subHeader.color,
            fontWeight: theme.referenceTheme.subHeader.weight,
            fontStyle: theme.referenceTheme.subHeader.style,
        },
        socialLinkView: {
            marginBottom: theme.socialLinkTheme.margin.bottom,
            showTitle: theme.socialLinkTheme.title.show,
        },
        socialLinkTitleFont: {
            fontSize: getFontSizeToPDF(theme.socialLinkTheme.title.variant),
            color: theme.socialLinkTheme.title.color,
            fontWeight: theme.socialLinkTheme.title.weight,
            fontStyle: theme.socialLinkTheme.title.style,
        },
        socialLinkHeaderFont: {
            fontSize: getFontSizeToPDF(theme.socialLinkTheme.header.variant),
            color: theme.socialLinkTheme.header.color,
            fontWeight: theme.socialLinkTheme.header.weight,
            fontStyle: theme.socialLinkTheme.header.style,
        },
        backgroundColor: {
            color: '',
            ...theme.subSectionTheme.backgroundColor,
        },
    });
};
