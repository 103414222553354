import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, View } from '@react-pdf/renderer';
import { ICV } from '../../interfaces/CV.interface';
import { IPDFStyle } from '../../interfaces/pdfStyle.interface';
import { TranslationKey } from '../../interfaces/TranslationKey';
import { IEducation } from '../../interfaces/Education.interface';
import dayjs from 'dayjs';
import { ICertification } from '../../interfaces/Certification.interface';

interface SectionPDFProps {
    cv: ICV;
    styles: IPDFStyle;
}

const EducationPDF = (props: SectionPDFProps): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <View style={props.styles.educationView}>
            {!!props.cv.educations.length && (
                <View>
                    {props.cv.educations.map((education: IEducation) => {
                        const startDateStr: string = dayjs(education.startDate).format(props.styles.educationDateFormat.format);
                        const endDateStr: string = education.endDate
                            ? dayjs(education.endDate).format(props.styles.educationDateFormat.format)
                            : t(TranslationKey.date.current);
                        return (
                            <View key={`PDF-education-${education.id}`}>
                                <View style={{ ...props.styles.rowStyle, flexWrap: 'wrap' }}>
                                    <Text style={props.styles.educationHeaderFont}>{education.degree}</Text>
                                    {education.institution && (
                                        <Text style={{ ...props.styles.educationHeaderFont, margin: '0 5px' }}>|</Text>
                                    )}
                                    {education.institution && <Text style={props.styles.educationHeaderFont}>{education.institution}</Text>}
                                </View>
                                <View style={{ ...props.styles.rowStyle, margin: '5px 0' }}>
                                    <Text style={props.styles.educationDateFont}>{startDateStr}</Text>
                                    <Text style={{ ...props.styles.educationDateFont, margin: '0 5px' }}>&mdash;</Text>
                                    <Text style={props.styles.educationDateFont}>{endDateStr}</Text>
                                </View>
                            </View>
                        );
                    })}
                </View>
            )}
            {!!props.cv.certifications.length && (
                <View>
                    {props.cv.certifications.map((certification: ICertification) => {
                        const issueDateStr: string | null = certification.issueDate
                            ? dayjs(certification.issueDate).format(props.styles.certificationDateFormat.format)
                            : null;

                        return (
                            <View key={`PDF-certification-${certification.id}`}>
                                <View style={props.styles.rowStyle}>
                                    <Text style={props.styles.certificationHeaderFont}>{certification.name}</Text>
                                    {certification.issuer && (
                                        <Text style={{ ...props.styles.certificationHeaderFont, margin: '0 5px' }}>|</Text>
                                    )}
                                    {certification.issuer && (
                                        <Text style={props.styles.certificationHeaderFont}>{certification.issuer}</Text>
                                    )}
                                </View>
                                {issueDateStr && <Text style={props.styles.certificationDateFont}>{issueDateStr}</Text>}
                            </View>
                        );
                    })}
                </View>
            )}
        </View>
    );
};

export default EducationPDF;
