import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { ICV } from '../../interfaces/CV.interface';
import { IPDFStyle } from '../../interfaces/pdfStyle.interface';

interface SectionPDFProps {
    cv: ICV;
    styles: IPDFStyle;
}

const ProfilePDF = (props: SectionPDFProps): React.ReactElement => {
    return (
        <View style={props.styles.profileView}>{props.cv.profile && <Text style={props.styles.profileFont}>{props.cv.profile}</Text>}</View>
    );
};

export default ProfilePDF;
