import React from 'react';
import { Box } from '@mui/material';
import { IExperience, IExperienceCreationDto } from '../../../../interfaces/Experience.interface';
import { IFontTheme } from '../../../../interfaces/Theme.interface';
import { IProfession } from '../../../../interfaces/Profession.interface';
import Profession from '../../../cv/Profession';
import { TranslationKey } from '../../../../interfaces/TranslationKey';
import CompanyForm from './CompanyForm';

interface ExperienceHeaderProps {
    experience: IExperience;
    setExperience: (experiences: IExperience) => void;
    theme: IFontTheme;
    onDoneClicked: (experienceHeaderDto: Pick<IExperienceCreationDto, 'title'>) => void;
}

const ExperienceHeaderForm = (props: ExperienceHeaderProps): React.ReactElement => {
    const [profession, setProfession] = React.useState<IProfession>({ name: props.experience.title, id: 0 });

    const onSuccess = (_profession: IProfession): void => {
        if (_profession.name === profession.name) return;
        setProfession(_profession);

        const experienceHeaderDto: Pick<IExperienceCreationDto, 'title'> = {
            title: _profession.name,
        };

        props.onDoneClicked(experienceHeaderDto);
    };

    return (
        <Box>
            <Profession
                profession={profession}
                setProfession={setProfession}
                theme={{
                    font: props.theme,
                    margin: { bottom: 0 },
                }}
                createOnBlur={true}
                onCompleted={onSuccess}
                label={TranslationKey.buttons.jobTitle}
            />
            {!!props.experience.id && <CompanyForm experience={props.experience} setExperience={props.setExperience} theme={props.theme} />}
        </Box>
    );
};

export default ExperienceHeaderForm;
