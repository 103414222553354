import React from 'react';
import { Box, Grid2, InputLabel, Slider } from '@mui/material';
import { IMarginTheme } from '../../interfaces/Theme.interface';
import { LABEL_SIZE } from './ThemeDesigner';
import { TranslationKey } from '../../interfaces/TranslationKey';
import { useTranslation } from 'react-i18next';

interface MarginThemeCustomizerProps {
    theme: IMarginTheme;
    handleThemeChange: (key: keyof IMarginTheme, value: number) => void;
}

const MarginThemeCustomizer = (props: MarginThemeCustomizerProps): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <Box>
            <Grid2 container spacing={2}>
                <Grid2 size={LABEL_SIZE}>
                    <InputLabel>{t(TranslationKey.theme.bottomMargin)}</InputLabel>
                </Grid2>
                <Grid2 size="grow">
                    <Slider
                        value={props.theme.bottom}
                        onChange={(_, newValue: number | number[]) => props.handleThemeChange('bottom', newValue as number)}
                        valueLabelDisplay={'auto'}
                        valueLabelFormat={String(props.theme.bottom)}
                        step={1}
                        min={5}
                        max={100}
                        marks={[
                            { value: 5, label: 5 },
                            { value: 100, label: 100 },
                        ]}
                    />
                </Grid2>
            </Grid2>
        </Box>
    );
};

export default MarginThemeCustomizer;
