import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../interfaces/TranslationKey';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DoneIcon from '@mui/icons-material/Done';

interface PasswordStrengthProps {
    password: string;
}

const PasswordStrengthHelpText = (props: PasswordStrengthProps): React.ReactElement => {
    const { t } = useTranslation();

    const isLengthValid = props.password.length >= 8;
    const hasLowercase = /[a-z]/.test(props.password);
    const hasUppercase = /[A-Z]/.test(props.password);
    const hasNumber = /\d/.test(props.password);
    const hasSpecialChar = /[@$#!%*?&,;^]/.test(props.password);

    useEffect(() => {}, [props.password]);

    return (
        <Box sx={{ mx: '5px' }}>
            <Box display={'flex'} sx={{ alignItems: 'center', alignContent: 'center' }} height={15}>
                {isLengthValid ? (
                    <DoneIcon style={{ fontSize: 10, margin: '0 5px', color: 'green' }} />
                ) : (
                    <FiberManualRecordIcon style={{ fontSize: 10, margin: '0 5px', color: '#777' }} />
                )}
                <Box>
                    <Typography variant={'body2'} sx={{ color: isLengthValid ? 'green' : '#777' }}>
                        {t(TranslationKey.info.passwordLength)}
                    </Typography>
                </Box>
            </Box>
            <Box display={'flex'} sx={{ alignItems: 'center', alignContent: 'center' }} height={15}>
                {hasLowercase ? (
                    <DoneIcon style={{ fontSize: 10, margin: '0 5px', color: 'green' }} />
                ) : (
                    <FiberManualRecordIcon style={{ fontSize: 10, margin: '0 5px', color: '#777' }} />
                )}
                <Box>
                    <Typography variant={'body2'} sx={{ color: hasLowercase ? 'green' : '#777' }}>
                        {t(TranslationKey.info.passwordLowercase)}
                    </Typography>
                </Box>
            </Box>
            <Box display={'flex'} sx={{ alignItems: 'center', alignContent: 'center' }} height={15}>
                {hasUppercase ? (
                    <DoneIcon style={{ fontSize: 10, margin: '0 5px', color: 'green' }} />
                ) : (
                    <FiberManualRecordIcon style={{ fontSize: 10, margin: '0 5px', color: '#777' }} />
                )}
                <Box>
                    <Typography variant={'body2'} sx={{ color: hasUppercase ? 'green' : '#777' }}>
                        {t(TranslationKey.info.passwordUppercase)}
                    </Typography>
                </Box>
            </Box>
            <Box display={'flex'} sx={{ alignItems: 'center', alignContent: 'center' }} height={15}>
                {hasNumber ? (
                    <DoneIcon style={{ fontSize: 10, margin: '0 5px', color: 'green' }} />
                ) : (
                    <FiberManualRecordIcon style={{ fontSize: 10, margin: '0 5px', color: '#777' }} />
                )}
                <Box>
                    <Typography variant={'body2'} sx={{ color: hasNumber ? 'green' : '#777' }}>
                        {t(TranslationKey.info.passwordNumber)}
                    </Typography>
                </Box>
            </Box>
            <Box display={'flex'} sx={{ alignItems: 'center', alignContent: 'center' }} height={15}>
                {hasSpecialChar ? (
                    <DoneIcon style={{ fontSize: 10, margin: '0 5px', color: 'green' }} />
                ) : (
                    <FiberManualRecordIcon style={{ fontSize: 10, margin: '0 5px', color: '#777' }} />
                )}
                <Box>
                    <Typography variant={'body2'} sx={{ color: hasSpecialChar ? 'green' : '#777' }}>
                        {t(TranslationKey.info.passwordSpecialCharacter)}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default PasswordStrengthHelpText;
