import { IApi } from './api.service';
import { ApiEndpoints } from './endpoints';
import { ITemplate, ITemplateCreationDto, ITemplateUpdateDto } from '../interfaces/Template.interface';
import { TemplateName } from '../common/enum';
import { GenericAbortSignal } from 'axios';

export const createTemplate = async (api: IApi, dto: ITemplateCreationDto, signal?: GenericAbortSignal): Promise<ITemplate | null> => {
    return api.post<ITemplate, ITemplateCreationDto>(ApiEndpoints.template, dto, { signal: signal });
};

export const updateTemplate = async (api: IApi, id: number, dto: ITemplateUpdateDto): Promise<ITemplate | null> => {
    return api.put<ITemplate, ITemplateUpdateDto>(ApiEndpoints.templateId.replace(':id', String(id)), dto);
};

export const getTemplateForCV = async (
    api: IApi,
    templateName: string,
    cvId: number,
    signal?: GenericAbortSignal,
): Promise<ITemplate | null> => {
    return api.get<ITemplate>(ApiEndpoints.templateNameCvId.replace(':name', templateName).replace(':id', String(cvId)), {
        signal: signal,
    });
};

export const getOrCreateTemplateForCV = async (
    api: IApi,
    templateName: TemplateName,
    cvId: number,
    dto: ITemplateCreationDto,
    { signal }: { signal: GenericAbortSignal },
): Promise<ITemplate | null> => {
    const template: ITemplate | null = await getTemplateForCV(api, templateName, cvId, signal);
    if (template) {
        return template;
    }

    return createTemplate(api, dto, signal);
};
