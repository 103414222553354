import { ApiEndpoints } from './endpoints';
import {
    IExperience,
    IExperienceCreationDto,
    IExperienceItem,
    IExperienceItemCreationDto,
    IExperienceItemUpdateDto,
    IExperienceUpdateDto,
} from '../interfaces/Experience.interface';
import { IApi } from './api.service';
import { ICompanyDto } from '../interfaces/Company.interface';

export const createExperience = async (api: IApi, dto: IExperienceCreationDto): Promise<IExperience | null> => {
    return api.post<IExperience, IExperienceCreationDto>(ApiEndpoints.experience, dto);
};

export const updateExperience = async (api: IApi, id: number, dto: IExperienceUpdateDto): Promise<IExperience | null> => {
    return api.put<IExperience, IExperienceUpdateDto>(ApiEndpoints.experienceId.replace(':id', String(id)), dto);
};

export const createCompany = async (api: IApi, experienceId: number, companyDto: ICompanyDto): Promise<IExperience | null> => {
    return api.post<IExperience, ICompanyDto>(ApiEndpoints.experienceIdCompany.replace(':id', String(experienceId)), companyDto);
};

export const updateCompany = async (api: IApi, experienceId: number, dto: ICompanyDto): Promise<IExperience | null> => {
    return api.put<IExperience, ICompanyDto>(ApiEndpoints.experienceIdCompany.replace(':id', String(experienceId)), dto);
};

export const removeCompany = async (api: IApi, experienceId: number): Promise<void> => {
    await api.delete(ApiEndpoints.experienceIdCompany.replace(':id', String(experienceId)));
};

export const updateExperienceViaAI = async (api: IApi, id: number): Promise<IExperience | null> => {
    return api.put<IExperience, object>(ApiEndpoints.experienceIdAI.replace(':id', String(id)), {});
};

export const removeExperience = async (api: IApi, id: number): Promise<void> => {
    return api.delete(ApiEndpoints.experienceId.replace(':id', String(id)));
};

export const createExperienceItem = async (api: IApi, dto: IExperienceItemCreationDto): Promise<IExperienceItem | null> => {
    return api.post<IExperienceItem, IExperienceItemCreationDto>(ApiEndpoints.experienceItem, dto);
};

export const updateExperienceItem = async (api: IApi, id: number, dto: IExperienceItemUpdateDto): Promise<IExperienceItem | null> => {
    return api.put<IExperienceItem, IExperienceItemUpdateDto>(ApiEndpoints.experienceItemId.replace(':id', String(id)), dto);
};

export const removeExperienceItem = async (api: IApi, id: number): Promise<void> => {
    return api.delete(ApiEndpoints.experienceItemId.replace(':id', String(id)));
};
