import React, { useState } from 'react';
import { Divider, Grid2 } from '@mui/material';
import Address from './Address';
import { IAddress } from '../../interfaces/Address.interface';
import { EmailField } from '../Email';
import { GeneralTextField } from '../GeneralTextField';
import { IContactTheme } from '../../interfaces/Theme.interface';
import { TranslationKey } from '../../interfaces/TranslationKey';
import { useTranslation } from 'react-i18next';
import { updateCV } from '../../services/cv.service';
import { IApi } from '../../services/api.service';
import { useAxiosApi } from '../../hooks/useAxiosApi';
import { Mode } from '../../interfaces/Mode.interface';
import { useMode } from '../../context/Mode.context';
import { useCV } from '../../context/CV.context';

interface ContactProps {
    cvId: number;
    address: IAddress;
    email: string;
    phoneNumber: string | null;
    theme: IContactTheme;
    inline?: boolean;
    reverse?: boolean;
}

const Contact: React.FC<ContactProps> = (props: ContactProps) => {
    const { t } = useTranslation();
    const { mode } = useMode();
    const api: IApi = useAxiosApi();
    const { cv, dispatch } = useCV();

    const isEditing: boolean = mode === Mode.EDIT;

    const [phoneNumber, setPhoneNumber] = useState<string | null>(props.phoneNumber);
    const [email, setEmail] = useState<string>(props.email);

    const onEmailBlur = (value: string) => {
        if (!value || value === cv?.email) return;

        updateCV(api, props.cvId, { email: email }).then();
        dispatch({ type: 'UPDATE_FIELD', field: 'email', value: value });
    };

    const onPhoneBlur = (value: string) => {
        const cleanValue = value === '' ? null : value;
        if (cleanValue === cv?.phoneNumber) return;

        updateCV(api, props.cvId, { phoneNumber: cleanValue }).then();
        dispatch({ type: 'UPDATE_FIELD', field: 'phoneNumber', value: cleanValue });
    };

    const displayInline: boolean = !!props.inline && mode === Mode.PRINT;

    return (
        <Grid2
            id="contact"
            size={12}
            container
            flexDirection={props.reverse ? 'row-reverse' : 'row'}
            sx={{ mb: `${props.theme.margin.bottom}px` }}
        >
            <Grid2 size={isEditing ? 12 : displayInline ? 6 : 12}>
                <Address cvId={props.cvId} address={props.address} theme={props.theme} />
            </Grid2>
            <Grid2 size={isEditing ? 12 : displayInline ? 6 : 12}>
                <GeneralTextField
                    value={phoneNumber ?? ''}
                    setValue={setPhoneNumber}
                    label={t(TranslationKey.phoneNumber)}
                    required={false}
                    onDoneClicked={onPhoneBlur}
                    typographyComponent={'p'}
                    fontTheme={props.theme.font}
                />
                <EmailField email={email} setEmail={setEmail} onDoneClicked={onEmailBlur} fontTheme={props.theme.font} />
            </Grid2>
            {isEditing && (
                <Grid2 size={12}>
                    <Divider color={'#666'} sx={{ my: 2 }} />
                </Grid2>
            )}
        </Grid2>
    );
};

export default Contact;
