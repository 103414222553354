import React from 'react';
import { Box, Grid2 } from '@mui/material';
import { useMode } from '../../context/Mode.context';
import { Mode } from '../../interfaces/Mode.interface';
import { ITemplateProps } from '../../interfaces/Template.interface';
import { ILayout, SectionName } from '../../interfaces/Theme.interface';
import { TemplateName } from '../../common/enum';
import { getSectionElements, getSections, ISectionElementsProps } from '../Template.util';

const HomsTemplate = (props: ITemplateProps): React.ReactElement => {
    const { mode } = useMode();
    const isEditing: boolean = mode === Mode.EDIT;

    const templateName: TemplateName = TemplateName.HOMS;

    const layout: ILayout = getSections(props.theme.layoutTheme, templateName);

    const showTopLeftSection: boolean = layout.topLeft.sections.some((i) => !!i);
    const showTopSection: boolean = layout.top.sections.some((i) => !!i);
    const showMiddleSection: boolean = layout.middle.sections.some((i) => !!i);
    const showMiddleLeftRightSection: boolean = layout.middleLeft.sections.some((i) => !!i) || layout.middleRight.sections.some((i) => !!i);
    const showBottomSection: boolean = layout.bottom.sections.some((i) => !!i);
    const showBottomLeftRightSection: boolean = layout.bottomLeft.sections.some((i) => !!i) || layout.bottomRight.sections.some((i) => !!i);

    const padding = 20;

    const getProps = (sections: SectionName[]): ISectionElementsProps => ({
        templateName: templateName,
        sections: sections,
        templateProps: props,
        isEditing: isEditing,
    });

    return (
        <Box>
            {/* top */}
            {showTopSection && <Box>{getSectionElements(getProps(layout.top.sections))}</Box>}

            {/* top left/right */}
            <Grid2 container columnSpacing={showTopLeftSection ? 2 : 0}>
                {showTopLeftSection && (
                    <Grid2 size={isEditing ? 12 : props.theme.layoutTheme.sectionWidth.topLeftWidth}>
                        {getSectionElements(getProps(layout.topLeft.sections))}
                    </Grid2>
                )}
                <Grid2
                    size={isEditing ? 12 : showTopLeftSection ? 12 - props.theme.layoutTheme.sectionWidth.topLeftWidth : 12}
                    alignItems="center"
                    alignContent="center"
                    sx={{ margin: showTopLeftSection ? undefined : '0 10px' }}
                >
                    {getSectionElements(getProps(layout.topRight.sections))}
                </Grid2>
            </Grid2>

            {/* middle */}
            {showMiddleSection && <Box>{getSectionElements(getProps(layout.middle.sections))}</Box>}

            {/* middle left/right */}
            {showMiddleLeftRightSection && (
                <Grid2 container size={12}>
                    <Grid2
                        size={isEditing ? 12 : props.theme.layoutTheme.sectionWidth.middleLeftWidth}
                        sx={{ paddingRight: `${padding}px` }}
                    >
                        {getSectionElements(getProps(layout.middleLeft.sections))}
                    </Grid2>
                    <Grid2
                        size={isEditing ? 12 : 12 - props.theme.layoutTheme.sectionWidth.middleLeftWidth}
                        sx={{ paddingLeft: `${padding}px` }}
                    >
                        <Box sx={{ border: isEditing ? 'solid 1px #444' : undefined }}>
                            {getSectionElements(getProps(layout.middleRight.sections))}
                        </Box>
                    </Grid2>
                </Grid2>
            )}

            {/* bottom */}
            {showBottomSection && <Box>{getSectionElements(getProps(layout.bottom.sections))}</Box>}

            {/* bottom left/right */}
            {showBottomLeftRightSection && (
                <Grid2 container size={12}>
                    <Grid2
                        size={isEditing ? 12 : props.theme.layoutTheme.sectionWidth.bottomLeftWidth}
                        sx={{ paddingRight: `${padding}px` }}
                    >
                        {getSectionElements(getProps(layout.bottomLeft.sections))}
                    </Grid2>
                    <Grid2
                        size={isEditing ? 12 : 12 - props.theme.layoutTheme.sectionWidth.bottomLeftWidth}
                        sx={{ paddingLeft: `${padding}px` }}
                    >
                        <Box sx={{ border: isEditing ? 'solid 1px #444' : undefined }}>
                            {getSectionElements(getProps(layout.bottomRight.sections))}
                        </Box>
                    </Grid2>
                </Grid2>
            )}
        </Box>
    );
};

export default HomsTemplate;
