import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import { TranslationKey } from '../interfaces/TranslationKey';
import { createContactUs } from '../services/contactUs.service';
import { ContactTopic } from '../interfaces/ContactUs.interface';
import { useTranslation } from 'react-i18next';
import { EditableEmail } from './Email';
import { IFontTheme } from '../interfaces/Theme.interface';
import { IApi } from '../services/api.service';
import { useAxiosApi } from '../hooks/useAxiosApi';

const topics = [
    { value: ContactTopic.BUG_REPORT, label: TranslationKey.contactUs.bugReport },
    { value: ContactTopic.FEATURE_REQUEST, label: TranslationKey.contactUs.featureRequest },
    { value: ContactTopic.AUTH_ISSUE, label: TranslationKey.contactUs.authIssue },
    { value: ContactTopic.GENERAL_INQUIRY, label: TranslationKey.contactUs.generalInquiry },
    { value: ContactTopic.OTHER, label: TranslationKey.contactUs.other },
];

interface ContactUsProps {
    anchorEl: null | SVGSVGElement | HTMLElement;
    setAnchorEl: (v: null | HTMLElement | SVGSVGElement) => void;
}

const ContactFormModal = (props: ContactUsProps) => {
    const { t } = useTranslation();
    const api: IApi = useAxiosApi();
    const [email, setEmail] = useState('');
    const [topic, setTopic] = useState<ContactTopic>(ContactTopic.FEATURE_REQUEST);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError(false);
        setSuccess(false);

        createContactUs(api, { email: email, message: message, topic: topic })
            .then(() => setSuccess(true))
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        setLoading(false);
        setError(false);
        setSuccess(false);
        setEmail('');
        setMessage('');
        setTopic(ContactTopic.FEATURE_REQUEST);
    }, [props.anchorEl]);

    const isOpen: boolean = Boolean(props.anchorEl);

    const getCloseButton = (): React.ReactElement => {
        return (
            <Button variant="outlined" color="inherit" fullWidth sx={{ mt: 2 }} onClick={() => props.setAnchorEl(null)}>
                {t(TranslationKey.buttons.close)}
            </Button>
        );
    };

    return (
        <Modal open={isOpen}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}
            >
                <Typography variant="h6" mb={2}>
                    {t(TranslationKey.contactUs._name)}
                </Typography>
                {error && (
                    <Typography variant={'caption'} color="error">
                        {t(TranslationKey.contactUs.errorMessage)}
                    </Typography>
                )}
                {success ? (
                    <Box>
                        <Typography color="success.main">{t(TranslationKey.contactUs.successMessage)}</Typography>
                        {getCloseButton()}
                    </Box>
                ) : (
                    <Box component={'form'} onSubmit={handleSubmit}>
                        <EditableEmail email={email} setEmail={setEmail} alwaysEditable={true} fontTheme={{} as IFontTheme} />

                        <Select
                            label={t(TranslationKey.contactUs.topic)}
                            fullWidth
                            value={topic}
                            onChange={(e) => setTopic(e.target.value as ContactTopic)}
                            variant={'outlined'}
                        >
                            {topics.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {t(option.label)}
                                </MenuItem>
                            ))}
                        </Select>

                        <TextField
                            label={t(TranslationKey.contactUs.message)}
                            multiline
                            rows={5}
                            fullWidth
                            margin="normal"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        />
                        <Box display={'flex'} gap={2}>
                            <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading} sx={{ mt: 2 }}>
                                {loading ? <CircularProgress size={'16px'} /> : t(TranslationKey.buttons.send)}
                            </Button>
                            {getCloseButton()}
                        </Box>
                    </Box>
                )}
            </Box>
        </Modal>
    );
};

export default ContactFormModal;
