import { IApi } from './api.service';
import { IEmailDto, ILoginDto, IPasswordResetDto, ISignupDto, ITokenDto, ITokenResponse } from '../interfaces/Auth.interface';
import { ApiEndpoints } from './endpoints';
import { AxiosResponse, GenericAbortSignal } from 'axios';

export const login = async (api: IApi, loginDto: ILoginDto): Promise<AxiosResponse<ITokenResponse>> => {
    return api.axiosInstance.post(ApiEndpoints.login, loginDto);
};

export const signup = async (api: IApi, signupDto: ISignupDto): Promise<AxiosResponse<ITokenResponse>> => {
    return api.axiosInstance.post(ApiEndpoints.signup, signupDto);
};

export const logout = async (api: IApi): Promise<void> => {
    await api.axiosInstance.get(ApiEndpoints.logout);
};

export const requestActivation = async (api: IApi, dto: IEmailDto): Promise<void> => {
    await api.axiosInstance.post(ApiEndpoints.requestActivation, dto);
};

export const activateUser = async (api: IApi, dto: ITokenDto, signal: GenericAbortSignal): Promise<ITokenResponse> => {
    const res: AxiosResponse<ITokenResponse> = await api.axiosInstance.post(ApiEndpoints.activate, dto, { signal });
    return res.data;
};

export const requestPasswordReset = async (api: IApi, dto: IEmailDto): Promise<AxiosResponse> => {
    return api.axiosInstance.post(ApiEndpoints.requestPasswordReset, dto);
};

export const resetPassword = async (api: IApi, dto: IPasswordResetDto): Promise<AxiosResponse> => {
    return api.axiosInstance.post(ApiEndpoints.passwordReset, dto);
};

export const removeUser = async (api: IApi): Promise<void> => {
    await api.axiosInstance.delete(ApiEndpoints.user);
};
