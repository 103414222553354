import React from 'react';
import { Box } from '@mui/material';
import { IProficiencyTheme } from '../../interfaces/Theme.interface';

interface ProficiencyProps {
    value: number;
    theme: IProficiencyTheme;
    labelKey: string;
}

const DottedProgress = (props: ProficiencyProps) => {
    const levels = [0, 20, 40, 60, 80, 100];
    const dotCount = 5;

    const activeDots = levels.indexOf(props.value);

    return (
        <Box display={'flex'} gap={props.theme.gap}>
            {[...Array(dotCount)].map((_, index: number) => (
                <Box
                    key={`${props.labelKey}-dotted-${index}`}
                    sx={{
                        width: props.theme.thickness,
                        height: props.theme.thickness,
                        backgroundColor: index < activeDots ? props.theme.activeColor : props.theme.inactiveColor,
                        borderRadius: props.theme.borderRadius,
                    }}
                />
            ))}
        </Box>
    );
};

export default DottedProgress;
