import { ICertification, ICertificationCreationDto } from '../../../../interfaces/Certification.interface';
import { IFontTheme } from '../../../../interfaces/Theme.interface';
import React from 'react';
import { Box } from '@mui/material';
import { EditableTextField } from '../../../GeneralTextField';
import { TranslationKey } from '../../../../interfaces/TranslationKey';

interface CertificationProps {
    certification: ICertification;
    setCertification: (cert: ICertification) => void;
    theme: IFontTheme;
    onBlur: (dto: Pick<ICertificationCreationDto, 'name' | 'issuer'>) => void;
}

const CertificationHeaderForm = (props: CertificationProps): React.ReactElement => {
    const [name, setName] = React.useState<string>(props.certification.name);
    const [issuer, setIssuer] = React.useState<string | null>(props.certification.issuer);

    const onBlurName = (value: string) => {
        if (!value || value === props.certification.name) return;

        props.onBlur({ issuer: issuer || null, name: value });
        setName(value);
    };

    const onBlurIssuer = (value: string) => {
        const cleanValue = value || null;
        if (cleanValue === props.certification.issuer) return;

        props.onBlur({ issuer: cleanValue, name: name });
        setIssuer(cleanValue);
    };

    return (
        <Box sx={{ marginBottom: '20px' }}>
            <EditableTextField
                value={name}
                setValue={setName}
                label={TranslationKey.certification.name}
                minLength={2}
                required={true}
                fontTheme={props.theme}
                onDoneClicked={onBlurName}
            />
            {!!props.certification.id && (
                <EditableTextField
                    value={issuer ?? ''}
                    setValue={setIssuer}
                    label={TranslationKey.certification.issuer}
                    minLength={2}
                    fontTheme={props.theme}
                    onDoneClicked={onBlurIssuer}
                    required={false}
                />
            )}
        </Box>
    );
};

export default CertificationHeaderForm;
