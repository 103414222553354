import { useAuth } from '../context/Auth.context';
import { ITokenResponse } from '../interfaces/Auth.interface';
import { ApiEndpoints } from '../services/endpoints';
import { api } from '../services/api.service';

export const useRefresh = () => {
    const { setAccessToken } = useAuth();
    let isRefreshing = false;
    let refreshPromise: Promise<string | null> | null = null;

    return async (): Promise<string | null> => {
        if (isRefreshing) {
            return refreshPromise;
        }

        isRefreshing = true;
        refreshPromise = new Promise(async (resolve, reject) => {
            try {
                const token: ITokenResponse | null = await api.get(ApiEndpoints.refresh, {
                    withCredentials: true,
                });

                setAccessToken(token?.accessToken ?? null);
                resolve(token?.accessToken ?? null);
            } catch (error) {
                reject(error);
            } finally {
                isRefreshing = false;
                refreshPromise = null;
            }
        });

        return refreshPromise;
    };
};
