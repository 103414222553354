import React from 'react';
import { Button, Card, CardContent, CardMedia, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/zero-styled';
import { TranslationKey } from '../interfaces/TranslationKey';

interface TemplateCardProps {
    title: string;
    description: string;
    imageUrl: string;
    onClick: () => void;
}

const StyledCard = styled(Card)(() => ({
    position: 'relative',
    cursor: 'pointer',
    '&:hover .hover-button': {
        opacity: 1,
        transform: 'translate(-50%, -50%)',
    },
    width: '100%', // Ensure card takes up full width
    maxWidth: '600px', // Limit maximum width
    margin: '0 auto 50px', // Center align cards
}));

const StyledButton = styled(Button)(() => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) scale(0)',
    opacity: 0,
    transition: 'all 0.3s ease',
    zIndex: 10,
}));

const ResponsiveImage = styled(CardMedia)(() => ({
    width: '100%',
    aspectRatio: '0.7',
    objectFit: 'cover',
}));

const TemplateCard = (props: TemplateCardProps): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <StyledCard onClick={props.onClick}>
            <CardContent sx={{ minHeight: '80px' }}>
                <Typography gutterBottom variant="h5" component="div">
                    {t(props.title)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {t(props.description)}
                </Typography>
            </CardContent>
            <Divider />
            <ResponsiveImage image={props.imageUrl} />
            <StyledButton className="hover-button" variant="contained" color="primary">
                {t(TranslationKey.buttons.useThisTemplate)}
            </StyledButton>
        </StyledCard>
    );
};

export default TemplateCard;
