import React, { useEffect, useState } from 'react';
import { LinearProgress } from '@mui/material';
import {
    ILanguage,
    ILanguageFilter,
    ILanguageProficiency,
    ILanguageProficiencyCreationDto,
    ILanguageProficiencyUpdateDto,
    LanguageProficiencyLevel,
    LanguageProficiencyToValue,
    ValueToLanguageProficiency,
} from '../../../../interfaces/LanguageProficiency.interface';
import { EditableSearchField, ISearchFieldError } from '../../../SearchField';
import { EditableProficiency } from '../../../proficiency/Proficiency';
import { TranslationKey } from '../../../../interfaces/TranslationKey';
import { IPaginatedListResponse } from '../../../../interfaces/Pagination.interface';
import { createLanguageProficiency, getAllLanguages, updateLanguageProficiency } from '../../../../services/language.service';
import { ILanguageTheme } from '../../../../interfaces/Theme.interface';
import { IApi } from '../../../../services/api.service';
import { useAxiosApi } from '../../../../hooks/useAxiosApi';
import { useCV } from '../../../../context/CV.context';

interface LanguageProficiencyProps {
    cvId: number;
    languageProficiency: ILanguageProficiency;
    languageProficiencies: ILanguageProficiency[];
    setLanguageProficiencies: (languageProficiency: ILanguageProficiency[]) => void;
    theme: ILanguageTheme;
}

const LanguageProficiencyForm = (props: LanguageProficiencyProps): React.ReactElement => {
    const api: IApi = useAxiosApi();
    const { cv } = useCV();
    const [languageProficiency, setLanguageProficiency] = useState<ILanguageProficiency>(props.languageProficiency);
    const [language, setLanguage] = useState<ILanguage>(languageProficiency.language);
    const [proficiency, setProficiency] = useState<LanguageProficiencyLevel>(languageProficiency.proficiency);
    const [searchFieldError, setSearchFieldError] = useState<ISearchFieldError>({ type: null, message: '' });
    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {
        setLanguageProficiency({
            ...languageProficiency,
            language: language,
            proficiency: proficiency,
        });
    }, [language, proficiency]);

    const fetchAllLanguages = async (query?: string): Promise<ILanguage[]> => {
        const filter: ILanguageFilter = {
            page: 1,
            size: 50,
        } as ILanguageFilter;

        if (query) {
            filter.name = query;
        }
        const paginatedLanguages: IPaginatedListResponse<ILanguage> = await getAllLanguages(api, filter);
        return paginatedLanguages.items;
    };

    const displayLanguage = (language: ILanguage): string => language.name;

    const _createLanguageProficiency = (dto: ILanguageProficiencyCreationDto) => {
        setIsLoading(true);
        const oldId: number = languageProficiency.id;
        createLanguageProficiency(api, dto)
            .then(async (newLanguageProficiency: ILanguageProficiency | null) => {
                if (newLanguageProficiency) {
                    setLanguageProficiency(newLanguageProficiency);
                    const updatedList = props.languageProficiencies.map((lp: ILanguageProficiency) =>
                        oldId === lp.id ? newLanguageProficiency : lp,
                    );
                    props.setLanguageProficiencies(updatedList);
                } else {
                    setLanguageProficiency(props.languageProficiency);
                    setLanguage(props.languageProficiency.language);
                }
            })
            .catch(() => {
                setLanguageProficiency(props.languageProficiency);
                setLanguage(props.languageProficiency.language);
            })
            .finally(() => setIsLoading(false));
    };

    const _updateLanguageProficiency = (dto: ILanguageProficiencyUpdateDto) => {
        updateLanguageProficiency(api, languageProficiency.id, dto).then(
            async (updatedLanguageProficiency: ILanguageProficiency | null) => {
                if (updatedLanguageProficiency) {
                    setLanguageProficiency(updatedLanguageProficiency);
                    const updatedList = props.languageProficiencies.map((s: ILanguageProficiency) =>
                        languageProficiency.id === s.id ? updatedLanguageProficiency : s,
                    );
                    props.setLanguageProficiencies(updatedList);
                }
            },
        );
    };

    const onBlurLanguage = async (): Promise<ILanguage | null> => {
        setSearchFieldError({ type: 'INVALID', message: TranslationKey.error.invalidLanguage });
        return null;
    };

    const onSelectLanguage = (_language: ILanguage): ILanguage | null => {
        // No changes
        if (_language.id === cv?.languageProficiencies.find((lp) => lp.id === languageProficiency.id)?.language.id) {
            return _language;
        }

        if (props.languageProficiencies.some((lp: ILanguageProficiency) => lp.language.id === _language.id)) {
            setSearchFieldError({ type: 'DUPLICATE', message: TranslationKey.error.duplicatedValue });
            return null;
        }

        if (languageProficiency.id) {
            _updateLanguageProficiency({ language: { id: _language.id }, proficiency: proficiency });
        } else {
            _createLanguageProficiency({ language: { id: _language.id }, proficiency: proficiency, cv: { id: props.cvId } });
        }

        return _language;
    };

    const onChangeProficiency = (newProficiency: LanguageProficiencyLevel): void => {
        if (newProficiency === languageProficiency.proficiency || !language.id) return;
        if (!languageProficiency.id) {
            _createLanguageProficiency({ language: { id: language.id }, proficiency: proficiency, cv: { id: props.cvId } });
        } else {
            _updateLanguageProficiency({ language: { id: language.id }, proficiency: newProficiency });
        }
    };

    return (
        <>
            <EditableSearchField<ILanguage>
                value={language}
                setValue={setLanguage}
                fetchResults={fetchAllLanguages}
                label={TranslationKey.language._name}
                getDisplayValue={displayLanguage}
                onDoneClicked={onBlurLanguage}
                onSelect={onSelectLanguage}
                error={searchFieldError}
                setError={setSearchFieldError}
                fontTheme={props.theme.font}
                typographyComponent={'p'}
            />

            {isLoading ? (
                <LinearProgress />
            ) : (
                !!props.languageProficiency.language.id && (
                    <EditableProficiency<LanguageProficiencyLevel>
                        theme={props.theme.proficiency}
                        value={proficiency}
                        setValue={setProficiency}
                        proficiencyToValue={LanguageProficiencyToValue}
                        valueToProficiency={ValueToLanguageProficiency}
                        onChange={onChangeProficiency}
                        labelKey={'Languages'}
                    />
                )
            )}
        </>
    );
};

export default LanguageProficiencyForm;
