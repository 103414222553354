import React, { Suspense, useEffect } from 'react';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../context/Auth.context';
import BaseTemplate from '../templates/BaseTemplate';
import Loading from '../components/Loading';
import { Helmet } from 'react-helmet-async';
import { TranslationKey } from '../interfaces/TranslationKey';
import { useTranslation } from 'react-i18next';
import { getCV } from '../services/cv.service';
import { ICV } from '../interfaces/CV.interface';
import { IApi } from '../services/api.service';
import { useAxiosApi } from '../hooks/useAxiosApi';
import { CVProvider, useCV } from '../context/CV.context';
import { useMode } from '../context/Mode.context';
import { Mode } from '../interfaces/Mode.interface';
import CVBuilder from '../components/builder/cv.builder';
import { getLocalizedPath } from '../utils/common.util';

function CVPage(): React.ReactElement {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { mode } = useMode();
    const { accessToken } = useAuth();
    const { uuid, templateName } = useParams();
    const api: IApi = useAxiosApi();
    const { cv, dispatch } = useCV();

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (accessToken) {
            if (uuid) {
                getCV(api, uuid, { signal }).then(async (_cv: ICV | null) => {
                    if (_cv) {
                        dispatch({ type: 'SET_CV', cv: _cv });
                    }
                });
            } else {
                navigate(getLocalizedPath(`/cv/builder/template/${templateName}`));
            }
        }

        return () => {
            controller.abort();
        };
    }, [accessToken, uuid]);

    const isEditing = mode === Mode.EDIT;

    return (
        <>
            <Helmet>
                <title>{t(TranslationKey.helmet.title.cvPage)}</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <Suspense fallback="loading">
                <Box>
                    {!!cv ? (
                        isEditing ? (
                            <CVBuilder />
                        ) : (
                            <Suspense fallback={<Loading />}>
                                <BaseTemplate />
                            </Suspense>
                        )
                    ) : (
                        <Loading />
                    )}
                </Box>
            </Suspense>
        </>
    );
}

export default function CVPageWithProvider() {
    return (
        <CVProvider>
            <CVPage />
        </CVProvider>
    );
}
