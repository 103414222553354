import React, { Dispatch, SetStateAction } from 'react';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { List, ListItem } from '@mui/material';
import { IOrderableEntity } from '../interfaces/Common.interface';

interface DragAndDropListProps {
    items: IOrderableEntity[];
    setItems: Dispatch<SetStateAction<IOrderableEntity[]>>;
    onDragEnd: (movedItemId: number, newIndex: number) => void;
    child: (item: IOrderableEntity) => React.ReactNode;
}

const DragAndDropList = (props: DragAndDropListProps): React.ReactElement => {
    const onDragEnd = (result: any) => {
        const { destination, source } = result;

        if (!destination) return;

        const reorderedItems = Array.from(props.items);
        const [removed] = reorderedItems.splice(source.index, 1);
        reorderedItems.splice(destination.index, 0, removed);

        const updatedItems = reorderedItems.map((item, index) => ({
            ...item,
            weight: (index + 1) * 10,
        }));

        props.setItems(updatedItems);

        const newIndex: number = updatedItems.findIndex((i: IOrderableEntity) => i.id === removed.id);
        updateItemsOnServer(removed.id, newIndex).then();
    };

    const updateItemsOnServer = async (itemId: number, newIndex: number) => {
        props.onDragEnd(itemId, newIndex);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided) => (
                    <List ref={provided.innerRef} {...provided.droppableProps} style={{ padding: 0 }}>
                        {props.items.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                {(provided) => (
                                    <ListItem
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                            ...provided.draggableProps.style,
                                            display: 'inline-block',
                                            marginBottom: '8px',
                                            backgroundColor: '#edf6f9',
                                            borderRadius: '4px',
                                            padding: '10px',
                                            width: '100%',
                                        }}
                                    >
                                        {props.child(item)}
                                    </ListItem>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </List>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default DragAndDropList;
