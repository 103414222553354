import React from 'react';
import { Box, Divider, Grid2, MenuItem, Typography } from '@mui/material';
import { IFontTheme, IMarginTheme, IProfessionTheme, ThemeValue } from '../../interfaces/Theme.interface';
import FontThemeCustomizer from './FontTheme';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../interfaces/TranslationKey';
import MarginThemeCustomizer from './MarginTheme';

interface ProfessionThemeCustomizerProps {
    theme: IProfessionTheme;
    setTheme: (nameTheme: (prev: IProfessionTheme) => IProfessionTheme) => void;
    setShouldUpdateThemed: (value: boolean) => void;
}

const ProfessionThemeCustomizer = (props: ProfessionThemeCustomizerProps): React.ReactElement => {
    const { t } = useTranslation();

    const handleFontThemeChange = (key: keyof IFontTheme, value: string) => {
        handleThemeChange('font', key, value);
    };

    const handleMarginThemeChange = (key: keyof IMarginTheme, value: number) => {
        handleThemeChange('margin', key, value);
    };

    const handleThemeChange = <T extends keyof IProfessionTheme, K extends keyof IProfessionTheme[T]>(
        section: T,
        key: K,
        value: ThemeValue,
    ) => {
        props.setTheme((prev: IProfessionTheme) => ({
            ...prev,
            [section]: {
                ...prev[section],
                [key]: value,
            },
        }));
        props.setShouldUpdateThemed(true);
    };

    return (
        <Box>
            <Typography variant="h6">{t(TranslationKey.profession)}</Typography>
            <Grid2 container spacing={2}>
                <Grid2 size={12}>
                    <FontThemeCustomizer
                        labelKey={'profession'}
                        theme={props.theme.font}
                        handleThemeChange={handleFontThemeChange}
                        variantItems={[
                            <MenuItem key={'profession-variant-h3'} value="h3">
                                {t(TranslationKey.theme.fontSize.L)}
                            </MenuItem>,
                            <MenuItem key={'profession-variant-h4'} value="h4">
                                {t(TranslationKey.theme.fontSize.M)}
                            </MenuItem>,
                            <MenuItem key={'profession-variant-h5'} value="h5">
                                {t(TranslationKey.theme.fontSize.S)}
                            </MenuItem>,
                            <MenuItem key={'profession-variant-h6'} value="h6">
                                {t(TranslationKey.theme.fontSize.XS)}
                            </MenuItem>,
                        ]}
                    />
                </Grid2>
            </Grid2>
            <Divider sx={{ my: 2 }} />

            <Grid2 container spacing={2}>
                <Grid2 size={12}>
                    <MarginThemeCustomizer theme={props.theme.margin} handleThemeChange={handleMarginThemeChange} />
                </Grid2>
            </Grid2>
        </Box>
    );
};

export default ProfessionThemeCustomizer;
