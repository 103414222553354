import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, CardContent, Divider, Grid2, LinearProgress, Tooltip, Typography } from '@mui/material';
import { ICV } from '../interfaces/CV.interface';
import { getAllCVs, removeCV } from '../services/cv.service';
import { IApi } from '../services/api.service';
import { useAxiosApi } from '../hooks/useAxiosApi';
import { IBaseTemplate } from '../interfaces/Template.interface';
import { TemplateName } from '../common/enum';
import { Mode } from '../interfaces/Mode.interface';
import { useMode } from '../context/Mode.context';
import { removeUser } from '../services/auth.service';
import { useAuth } from '../context/Auth.context';
import { TranslationKey } from '../interfaces/TranslationKey';
import ConfirmationDialog from '../components/ConfirmationDialog';
import { useTranslation } from 'react-i18next';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';
import PasswordIcon from '@mui/icons-material/Password';
import { Helmet } from 'react-helmet-async';
import LogoutButton from '../components/auth/LogoutButton';
import CVCreationButton from '../components/CVCreationButton';
import ActivationRequestButton from '../components/auth/ActivationRequestButton';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getLocalizedPath } from '../utils/common.util';

const ProfilePage: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const api: IApi = useAxiosApi();
    const { setMode } = useMode();
    const { user, setAccessToken } = useAuth();
    const [openDeleteUserConfirmModal, setOpenDeleteUserConfirmModal] = useState(false);
    const [openDeleteCVConfirmModal, setOpenDeleteCVConfirmModal] = useState(false);
    const [selectedCV, setSelectedCV] = useState<ICV | null>(null);
    const queryClient = useQueryClient();

    const { data: cvs = [], isLoading } = useQuery({
        queryKey: ['cvs'],
        queryFn: () => getAllCVs(api),
        staleTime: 30 * 60 * 1000, // Cache data for 30 minutes
    });

    const navigateToCV = (cv: ICV) => {
        const currentTemplateName: string = cv.templates.find((t: IBaseTemplate) => t.isCurrent)?.templateName ?? TemplateName.DAMASCUS;
        setMode(Mode.PRINT);
        navigate(getLocalizedPath(`/cv/${cv.uuid}/template/${currentTemplateName}`));
    };

    const handleRemoveUserClick = () => {
        setOpenDeleteUserConfirmModal(true);
    };

    const confirmRemoveUser = () => {
        removeUser(api)
            .then(() => {
                setAccessToken(null);
                navigate(getLocalizedPath('/login'));
            })
            .catch((e) => console.error(e));
        setOpenDeleteUserConfirmModal(false);
    };

    const handleRemoveClick = (cv: ICV) => {
        setSelectedCV(cv);
        setOpenDeleteCVConfirmModal(true);
    };

    const deleteCVMutation = useMutation({
        mutationFn: (cvId: string) => removeCV(api, cvId),
        onMutate: async (cvId: string) => {
            await queryClient.cancelQueries({ queryKey: ['cvs'] });

            const previousCVs = queryClient.getQueryData<ICV[]>(['cvs']);

            queryClient.setQueryData(['cvs'], (oldCVs: ICV[] | undefined) => {
                return oldCVs ? oldCVs.filter((cv) => cv.uuid !== cvId) : [];
            });

            return { previousCVs };
        },
        onError: (err, cvId, context) => {
            if (context?.previousCVs) {
                queryClient.setQueryData(['cvs'], context.previousCVs);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['cvs'] });
        },
    });

    const confirmRemoveCV = () => {
        if (selectedCV) {
            deleteCVMutation.mutate(selectedCV.uuid);
        }
        setSelectedCV(null);
        setOpenDeleteCVConfirmModal(false);
    };

    const handleChangePasswordClick = () => {};

    return (
        <>
            <Helmet>
                <title>{t(TranslationKey.helmet.title.profilePage)}</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <Card sx={{ maxWidth: 600, margin: 'auto', mt: 5, p: 3 }}>
                {!user ? (
                    <Box sx={{ my: 2 }}>
                        <LinearProgress sx={{ width: '100%', height: '10px' }} />
                    </Box>
                ) : (
                    <>
                        <CardContent>
                            <Typography variant="h5" fontWeight={'bold'} gutterBottom>
                                {t(TranslationKey.profile)}
                            </Typography>
                            <Box display={'flex'} alignContent="center">
                                <PersonIcon color={'disabled'} sx={{ mr: 2 }} /> {user.uuid}
                            </Box>
                            <Box display={'flex'} alignContent="center">
                                <EmailIcon color={'disabled'} sx={{ mr: 2 }} /> {user.email}
                            </Box>

                            <Divider sx={{ my: 2 }} />

                            <Typography variant="h6" sx={{ mt: 3 }}>
                                {t(TranslationKey.buttons.myResume)}
                            </Typography>

                            {!!user && !user.isActive ? (
                                <Box display={'flex'} height={'100px'} justifyContent="center" alignItems={'center'}>
                                    <ActivationRequestButton variant={'contained'} color={'warning'} fullWidth={true} />
                                </Box>
                            ) : isLoading ? (
                                <Box sx={{ my: 2 }}>
                                    <LinearProgress sx={{ width: '100%', height: '10px' }} />
                                </Box>
                            ) : (
                                <Box>
                                    {cvs.map((cv: ICV) => (
                                        <Grid2 container size={12} key={`cvs-card-${cv.uuid}`} alignItems={'stretch'}>
                                            <Grid2 size={10}>
                                                <Button
                                                    color="inherit"
                                                    size="large"
                                                    onClick={() => navigateToCV(cv)}
                                                    fullWidth
                                                    sx={{ justifyContent: 'start', mb: '5px' }}
                                                >
                                                    <Box>
                                                        <Typography
                                                            variant={'body2'}
                                                            display={'flex'}
                                                            color={'primary'}
                                                            sx={{ justifyContent: 'flex-start' }}
                                                        >{`${cv.firstName} ${cv.lastName} | ${cv.profession.name}`}</Typography>
                                                        <Typography
                                                            variant={'caption'}
                                                            color={'textSecondary'}
                                                            display={'flex'}
                                                            sx={{ justifyContent: 'flex-start' }}
                                                        >
                                                            {cv.uuid}
                                                        </Typography>
                                                    </Box>
                                                </Button>
                                            </Grid2>
                                            <Grid2 size={2} alignContent={'center'}>
                                                <Tooltip title={t(TranslationKey.tooltip.deleteCv)}>
                                                    <Button color="error" size="small" onClick={() => handleRemoveClick(cv)} fullWidth>
                                                        <DeleteIcon />
                                                    </Button>
                                                </Tooltip>
                                            </Grid2>
                                        </Grid2>
                                    ))}
                                </Box>
                            )}

                            {user.isActive && (
                                <Box display={'flex'} height={'100px'} justifyContent="center" alignItems={'center'}>
                                    <CVCreationButton fullWidth={true} color={'primary'} variant={'contained'} />
                                </Box>
                            )}

                            <Divider sx={{ my: 2 }} />

                            <Grid2 container spacing={2}>
                                <Button variant="outlined" color="primary" endIcon={<PasswordIcon />} onClick={handleChangePasswordClick}>
                                    {t(TranslationKey.buttons.changePassword)}
                                </Button>
                                <LogoutButton />
                                <Button variant="outlined" color="error" onClick={handleRemoveUserClick} endIcon={<HeartBrokenIcon />}>
                                    {t(TranslationKey.buttons.deleteAccount)}
                                </Button>
                            </Grid2>
                        </CardContent>
                    </>
                )}
            </Card>

            <ConfirmationDialog
                title={t(TranslationKey.confirmationModal.deleteUser.title)}
                message={t(TranslationKey.confirmationModal.deleteUser.message)}
                onConfirm={confirmRemoveUser}
                openConfirmModal={openDeleteUserConfirmModal}
                setOpenConfirmModal={setOpenDeleteUserConfirmModal}
            />

            <ConfirmationDialog
                title={t(TranslationKey.confirmationModal.deleteCV.title)}
                message={t(TranslationKey.confirmationModal.deleteCV.message)}
                onConfirm={confirmRemoveCV}
                openConfirmModal={openDeleteCVConfirmModal}
                setOpenConfirmModal={setOpenDeleteCVConfirmModal}
            />
        </>
    );
};

export default ProfilePage;
