import React from 'react';
import { Box, Container, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../interfaces/TranslationKey';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Helmet } from 'react-helmet-async';

const TermsOfServicePage: React.FC = () => {
    const { t } = useTranslation();
    return (
        <>
            <Helmet>
                <title>{t(TranslationKey.helmet.title.termsOfServicePage)}</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <Container>
                <Paper elevation={3} sx={{ padding: 3, marginY: 3 }}>
                    <Typography variant="h4" gutterBottom>
                        {t(TranslationKey.termsOfService.title)}
                    </Typography>
                    <Typography variant="h6">{t(TranslationKey.termsOfService.lastUpdated)}</Typography>
                    <Typography variant="body1">{t(TranslationKey.termsOfService.introduction)}</Typography>

                    <Box sx={{ mt: 3 }}>
                        <Typography variant="h6">{t(TranslationKey.termsOfService.eligibility.title)}</Typography>
                        <Box sx={{ mt: 2 }}>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">{t(TranslationKey.termsOfService.eligibility.item1)}</Typography>
                            </Box>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">{t(TranslationKey.termsOfService.eligibility.item2)}</Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <Typography variant="h6">{t(TranslationKey.termsOfService.accountResponsibility.title)}</Typography>
                        <Box sx={{ mt: 1 }}>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">{t(TranslationKey.termsOfService.accountResponsibility.item1)}</Typography>
                            </Box>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">{t(TranslationKey.termsOfService.accountResponsibility.item2)}</Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <Typography variant="h6">{t(TranslationKey.termsOfService.useOfServices.title)}</Typography>
                        <Box sx={{ mt: 1 }}>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">{t(TranslationKey.termsOfService.useOfServices.item1)}</Typography>
                            </Box>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">{t(TranslationKey.termsOfService.useOfServices.item2)}</Typography>
                            </Box>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">{t(TranslationKey.termsOfService.useOfServices.item3)}</Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <Typography variant="h6">{t(TranslationKey.termsOfService.donations.title)}</Typography>
                        <Box sx={{ mt: 1 }}>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">{t(TranslationKey.termsOfService.donations.item1)}</Typography>
                            </Box>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">{t(TranslationKey.termsOfService.donations.item2)}</Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <Typography variant="h6">{t(TranslationKey.termsOfService.intellectualProperty.title)}</Typography>
                        <Box sx={{ mt: 1 }}>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">{t(TranslationKey.termsOfService.intellectualProperty.item1)}</Typography>
                            </Box>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">{t(TranslationKey.termsOfService.intellectualProperty.item2)}</Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <Typography variant="h6">{t(TranslationKey.termsOfService.limitationOfLiability.title)}</Typography>
                        <Box sx={{ mt: 1 }}>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">{t(TranslationKey.termsOfService.limitationOfLiability.item1)}</Typography>
                            </Box>
                            <Box display={'flex'}>
                                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#888', margin: '4px 8px' }} />
                                <Typography variant="body1">{t(TranslationKey.termsOfService.limitationOfLiability.item2)}</Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <Typography variant="h6">{t(TranslationKey.termsOfService.termination.title)}</Typography>
                        <Typography variant="body1">{t(TranslationKey.termsOfService.termination.description)}</Typography>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <Typography variant="h6">{t(TranslationKey.termsOfService.changesToTheseTerms.title)}</Typography>
                        <Typography variant="body1">{t(TranslationKey.termsOfService.changesToTheseTerms.description)}</Typography>
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <Typography variant="h6">{t(TranslationKey.termsOfService.contactUs.title)}</Typography>
                        <Typography variant="body1">{t(TranslationKey.termsOfService.contactUs.description)}</Typography>
                    </Box>
                </Paper>
            </Container>
        </>
    );
};

export default TermsOfServicePage;
