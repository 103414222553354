import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

interface ButtonProps {
    label: string;
    onClick: MouseEventHandler | undefined;
}

const EditButton = (props: ButtonProps): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <Button
            onClick={props.onClick}
            endIcon={<EditIcon />}
            sx={{
                fontSize: '12px',
                '&:hover': {
                    bgcolor: (theme) => theme.palette.primary.light,
                    color: (theme) => theme.palette.primary.contrastText,
                },
            }}
        >
            {t(props.label)}
        </Button>
    );
};

export default EditButton;
