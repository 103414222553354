import { IEducation, IEducationCreationDto, IEducationUpdateDto } from '../../../../interfaces/Education.interface';
import { IEducationTheme } from '../../../../interfaces/Theme.interface';
import React, { useEffect } from 'react';
import { IApi } from '../../../../services/api.service';
import { useAxiosApi } from '../../../../hooks/useAxiosApi';
import dayjs, { Dayjs } from 'dayjs';
import { createEducation, updateEducation } from '../../../../services/education.service';
import { IDateRange } from '../../../../interfaces/Date.interface';
import DatePickerRange from '../../../DatePickerRange';
import EducationHeaderForm from './EducationHeaderForm';
import { LinearProgress } from '@mui/material';

interface EducationProps {
    cvId: number;
    education: IEducation;
    educations: IEducation[];
    setEducations: (educations: IEducation[]) => void;
    theme: IEducationTheme;
}

const EducationForm = (props: EducationProps): React.ReactElement => {
    const api: IApi = useAxiosApi();
    const [education, setEducation] = React.useState<IEducation>(props.education);
    const [startDate, setStartDate] = React.useState<Dayjs>(dayjs(dayjs(education.startDate)));
    const [endDate, setEndDate] = React.useState<Dayjs | null>(education.endDate ? dayjs(education.endDate) : null);
    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {
        updateState(education);
    }, [education]);

    const updateState = (_education: IEducation) => {
        setEducation(_education);
        const updatedList = props.educations.map((e: IEducation) => (e.id === education.id ? _education : e));
        props.setEducations(updatedList);
    };

    const _updateEducation = (dto: IEducationUpdateDto) => {
        updateEducation(api, education.id, dto).then((updatedEducation: IEducation | null) => {
            if (updatedEducation) {
                updateState(updatedEducation);
            }
        });
    };

    const onDoneClickedHeader = (educationHeaderDto: Pick<IEducationCreationDto, 'degree' | 'institution'>) => {
        const dto: IEducationUpdateDto = {
            ...educationHeaderDto,
            startDate: startDate.toDate(),
            endDate: endDate ? endDate.toDate() : null,
        };

        if (!education.id) {
            const readyToCreate: boolean = !!educationHeaderDto.degree && !!startDate;
            if (readyToCreate) {
                setIsLoading(true);
                const creationDto: IEducationCreationDto = {
                    ...dto,
                    cv: { id: props.cvId },
                } as IEducationCreationDto;

                createEducation(api, creationDto)
                    .then((newEducation: IEducation | null) => {
                        if (newEducation) {
                            updateState(newEducation);
                        }
                    })
                    .finally(() => setIsLoading(false));
            }
        } else {
            _updateEducation(dto);
        }
    };

    const onAcceptRange = (dateRange: IDateRange) => {
        if (!education.id) return;
        const dto: IEducationUpdateDto = {
            degree: education.degree,
            institution: education.institution,
            startDate: dateRange.start.toDate(),
            endDate: dateRange.end?.toDate() ?? null,
        };
        _updateEducation(dto);
    };

    return (
        <>
            <EducationHeaderForm
                education={education}
                setEducation={setEducation}
                theme={props.theme.header}
                onDoneClicked={onDoneClickedHeader}
            />
            {isLoading && <LinearProgress sx={{ my: 3 }} />}
            <DatePickerRange
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                theme={props.theme.date}
                onAcceptRange={onAcceptRange}
            />
        </>
    );
};

export default EducationForm;
