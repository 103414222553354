import React, { useEffect, useState } from 'react';
import { requestActivation } from '../../services/auth.service';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../interfaces/TranslationKey';
import { AxiosError } from 'axios';
import FeedbackModal from '../FeedbackModal';
import { IApi } from '../../services/api.service';
import { useAxiosApi } from '../../hooks/useAxiosApi';

interface ActivationRequestProps {
    anchorEl: null | SVGSVGElement | HTMLElement;
    setAnchorEl: (v: null | HTMLElement | SVGSVGElement) => void;
    email: string;
}

const ActivationRequestModal = (props: ActivationRequestProps): React.ReactElement => {
    const { t } = useTranslation();
    const api: IApi = useAxiosApi();
    const [error, setError] = useState<boolean>(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(false);
        setError(false);
        setMessage('');

        if (Boolean(props.anchorEl)) {
            requestActivation(api, { email: props.email })
                .then(() => {
                    setMessage(t(TranslationKey.accountActivation.requestActivationSuccessMsg) + props.email);
                })
                .catch((error: AxiosError) => {
                    if (error.response?.status === 404) {
                        setMessage(t(TranslationKey.accountActivation.requestActivationErrorNoAccountMsg) + props.email);
                        setError(true);
                    } else if (error.response?.status === 409) {
                        setMessage(t(TranslationKey.accountActivation.requestActivationErrorActiveMsg));
                    }
                })
                .finally(() => setLoading(false));
        }
    }, [props.anchorEl]);

    return (
        <FeedbackModal
            anchorEl={props.anchorEl}
            setAnchorEl={props.setAnchorEl}
            title={t(TranslationKey.accountActivation._name)}
            message={message}
            error={error}
            loading={loading}
        />
    );
};

export default ActivationRequestModal;
