import { IExperience } from '../../../../interfaces/Experience.interface';
import { IFontTheme } from '../../../../interfaces/Theme.interface';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IApi } from '../../../../services/api.service';
import { useAxiosApi } from '../../../../hooks/useAxiosApi';
import { useCV } from '../../../../context/CV.context';
import { ICompanyDto } from '../../../../interfaces/Company.interface';
import { createCompany, removeCompany, updateCompany } from '../../../../services/experience.service';
import { Grid2, TextField, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { TranslationKey } from '../../../../interfaces/TranslationKey';
import EditButton from '../../../EditButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface CompanyProps {
    experience: IExperience;
    setExperience: (experiences: IExperience) => void;
    theme: IFontTheme;
}

const CompanyForm = (props: CompanyProps): React.ReactElement => {
    const { t } = useTranslation();
    const api: IApi = useAxiosApi();
    const { cv } = useCV();
    const [name, setName] = React.useState<string | null>(props.experience.company?.name ?? null);
    const [location, setLocation] = React.useState<string | null>(props.experience.company?.location ?? null);
    const [isEditing, setIsEditing] = useState(!props.experience.company?.id);
    const [error, setError] = useState(false);

    const handleClick = () => {
        setIsEditing(true);
    };

    const handleNameChange = (e: { target: { value: any } }) => {
        const value = e.target.value;
        setName(value || null);

        setError(!value && !!location);
    };

    const handleLocationChange = (e: { target: { value: any } }) => {
        const value = e.target.value;
        setLocation(value || null);

        setError(!name && !!value);
    };

    const handleDoneClick = () => {
        if (location && !name) {
            setError(true);
        }

        if (!name) {
            if (!!props.experience.company?.id) {
                _removeCompany();
            }

            return;
        }

        setIsEditing(false);

        if (name === props.experience.company?.name && location === props.experience.company?.location) return;

        const dto: ICompanyDto = {
            name: name,
            location: location || null,
        };

        if (!props.experience.company?.id) {
            _createCompany(dto);
        } else {
            _updateCompany(dto);
        }
    };

    const _createCompany = (dto: ICompanyDto): void => {
        if (!props.experience.id) return;
        createCompany(api, props.experience.id, dto).then((updatedExperience: IExperience | null) => {
            if (updatedExperience) {
                props.setExperience(updatedExperience);
            }
        });
    };

    const _updateCompany = (dto: ICompanyDto): void => {
        if (!props.experience.id) return;
        updateCompany(api, props.experience.id, dto).then((updatedExperience: IExperience | null) => {
            if (updatedExperience) {
                props.setExperience(updatedExperience);
            }
        });
    };

    const _removeCompany = (): void => {
        if (!props.experience.id) return;
        removeCompany(api, props.experience.id).then(() => {
            const updatedExperience = cv?.experiences.find((e) => e.id === props.experience.id);
            if (updatedExperience) {
                props.setExperience({ ...updatedExperience, company: null });
            }
        });
    };

    const handleCancel = () => {
        setName(props.experience.company?.name ?? null);
        setLocation(props.experience.company?.location ?? null);
        setIsEditing(false);
        setError(false);
    };

    return (
        <Grid2>
            {isEditing ? (
                <Grid2 container spacing={2} alignItems={'center'}>
                    <Grid2 size={'grow'}>
                        <TextField
                            value={name ?? ''}
                            label={t(TranslationKey.experience.company.name)}
                            name={'name'}
                            onChange={handleNameChange}
                            fullWidth
                            variant="outlined"
                            error={error}
                            helperText={error ? t(TranslationKey.error.requiredField) : ' '}
                            slotProps={{
                                htmlInput: { autoComplete: 'new-password' },
                            }}
                        />
                    </Grid2>
                    <Grid2 size={'grow'}>
                        <TextField
                            value={location ?? ''}
                            label={t(TranslationKey.experience.company.location)}
                            name={'location'}
                            onChange={handleLocationChange}
                            fullWidth
                            variant="outlined"
                            helperText={' '}
                            slotProps={{
                                htmlInput: { autoComplete: 'new-password' },
                            }}
                        />
                    </Grid2>
                    <Grid2 size={'auto'}>
                        <IconButton onClick={handleDoneClick} color="primary">
                            <DoneIcon />
                        </IconButton>
                        <IconButton onClick={handleCancel} color="secondary">
                            <CloseIcon />
                        </IconButton>
                    </Grid2>
                </Grid2>
            ) : (
                <Grid2 container alignItems={'center'}>
                    <Grid2 size={'grow'} alignContent={'center'}>
                        <Typography
                            variant={props.theme.variant ?? 'body1'}
                            component={'p'}
                            fontWeight={props.theme.weight}
                            fontStyle={props.theme.style}
                            sx={{
                                cursor: 'pointer',
                                flex: 1,
                                padding: '6px 8px',
                                borderRadius: '4px',
                                '&:hover': { backgroundColor: '#f5f5f5' },
                            }}
                            onClick={handleClick}
                        >
                            {name || <span style={{ color: 'gray' }}>{t(TranslationKey.info.clickToEdit)}</span>}
                        </Typography>
                    </Grid2>
                    <Grid2 size={'grow'} alignContent={'center'}>
                        <Typography
                            variant={props.theme.variant ?? 'body1'}
                            component={'p'}
                            fontWeight={props.theme.weight}
                            fontStyle={props.theme.style}
                            sx={{
                                cursor: 'pointer',
                                flex: 1,
                                padding: '6px 8px',
                                borderRadius: '4px',
                                '&:hover': { backgroundColor: '#f5f5f5' },
                            }}
                            onClick={handleClick}
                        >
                            {location || <span style={{ color: 'gray' }}>{t(TranslationKey.info.clickToEdit)}</span>}
                        </Typography>
                    </Grid2>
                    <Grid2 size={'auto'} sx={{ marginRight: '4px' }}>
                        <EditButton onClick={handleClick} label={t(TranslationKey.experience.company._name)} />
                    </Grid2>
                </Grid2>
            )}
        </Grid2>
    );
};

export default CompanyForm;
