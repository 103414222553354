import React from 'react';
import { Button } from '@mui/material';
import { ICV } from '../interfaces/CV.interface';
import { TemplateName } from '../common/enum';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../interfaces/TranslationKey';
import DownloadIcon from '@mui/icons-material/Download';
import { ITheme } from '../interfaces/Theme.interface';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import DamascusTemplatePDF from '../templates/damascus/DamascusPDF';
import HomsTemplatePDF from '../templates/homs/HomsPDF';
import MeccaTemplatePDF from '../templates/mecca/MeccaPDF';
import MadinaTemplatePDF from '../templates/madina/MadinaPDF';
import DohaTemplatePDF from '../templates/doha/DohaPDF';
import AlqudsTemplatePDF from '../templates/alquds/AlqudsPDF';
import HamaTemplatePDF from '../templates/hama/HamaPDF';
import CairoTemplatePDF from '../templates/cairo/CairoPDF';
import IdlibTemplatePDF from '../templates/idlib/IdlibPDF';

interface PDFDownloadableButtonProps {
    templateName: TemplateName;
    theme: ITheme;
    cv: ICV;
    imageSrc: string | null;
}

export const getPDFTemplate = (props: PDFDownloadableButtonProps) => {
    switch (props.templateName) {
        case TemplateName.DAMASCUS:
            return <DamascusTemplatePDF cv={props.cv} imageSrc={props.imageSrc} theme={props.theme} />;
        case TemplateName.HOMS:
            return <HomsTemplatePDF cv={props.cv} imageSrc={props.imageSrc} theme={props.theme} />;
        case TemplateName.MECCA:
            return <MeccaTemplatePDF cv={props.cv} imageSrc={props.imageSrc} theme={props.theme} />;
        case TemplateName.MADINA:
            return <MadinaTemplatePDF cv={props.cv} imageSrc={props.imageSrc} theme={props.theme} />;
        case TemplateName.DOHA:
            return <DohaTemplatePDF cv={props.cv} imageSrc={props.imageSrc} theme={props.theme} />;
        case TemplateName.ALQUDS:
            return <AlqudsTemplatePDF cv={props.cv} imageSrc={props.imageSrc} theme={props.theme} />;
        case TemplateName.HAMA:
            return <HamaTemplatePDF cv={props.cv} imageSrc={props.imageSrc} theme={props.theme} />;
        case TemplateName.CAIRO:
            return <CairoTemplatePDF cv={props.cv} imageSrc={props.imageSrc} theme={props.theme} />;
        case TemplateName.IDLIB:
            return <IdlibTemplatePDF cv={props.cv} imageSrc={props.imageSrc} theme={props.theme} />;
        default:
            return <DamascusTemplatePDF cv={props.cv} imageSrc={props.imageSrc} theme={props.theme} />;
    }
};

const PDFDownloadableButton = (props: PDFDownloadableButtonProps) => {
    const { t } = useTranslation();

    const fileName: string = `${props.cv.firstName}_${props.cv.lastName}.pdf`;

    const handleDownload = async () => {
        try {
            const blob = await pdf(getPDFTemplate(props)).toBlob();
            saveAs(blob, fileName);
        } catch (error) {
            console.error('PDF generation error:', error);
        }
    };

    return (
        <Button variant={'outlined'} endIcon={<DownloadIcon />} onClick={handleDownload} sx={{ width: { xs: '100%', md: '150px' } }}>
            {t(TranslationKey.buttons.download)}
        </Button>
    );
};

export default PDFDownloadableButton;
