import React from 'react';
import { MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { setDayjsLocale } from '../dayjs';
import { PATH_LANGUAGES_REGEX, STORAGE_LANGUAGE_KEY } from '../common/constants';

interface LanguageSelectorProps {
    setDirection: (dir: 'rtl' | 'ltr') => void;
}

const LanguageSelector = (props: LanguageSelectorProps) => {
    const { i18n } = useTranslation();

    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang).then(() => {
            document.body.dir = i18n.dir();
            setDayjsLocale(lang);
            props.setDirection(i18n.dir());
            localStorage.setItem(STORAGE_LANGUAGE_KEY, lang);
            const currentPath = window.location.pathname.replace(PATH_LANGUAGES_REGEX, '');
            window.location.pathname = `/${lang}${currentPath}`;
        });
    };

    return (
        <Select
            value={i18n.language}
            defaultValue="en"
            onChange={(e) => changeLanguage(e.target.value)}
            label="Language"
            variant="standard"
        >
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="ar">العربية</MenuItem>
            <MenuItem value="de">Deutsch</MenuItem>
        </Select>
    );
};

export default LanguageSelector;
