import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useMode } from '../context/Mode.context';
import { ITitleTheme } from '../interfaces/Theme.interface';
import { useTranslation } from 'react-i18next';
import { Mode } from '../interfaces/Mode.interface';
import { GeneralTextField } from './GeneralTextField';
import { TranslationKey } from '../interfaces/TranslationKey';
import { IApi } from '../services/api.service';
import { useAxiosApi } from '../hooks/useAxiosApi';
import { CVMetadata, ICV } from '../interfaces/CV.interface';
import { updateCV } from '../services/cv.service';

interface SectionHeaderProps {
    metadataKey: keyof CVMetadata;
    cv: ICV;
    theme: ITitleTheme;
}

interface PrintableSectionHeaderProps {
    title: string;
    theme: ITitleTheme;
}

const EditableSectionHeader = (props: SectionHeaderProps): React.ReactElement => {
    const api: IApi = useAxiosApi();
    const [oldTitle, setOldTitle] = useState<string>(props.cv.metadata[props.metadataKey].title);
    const [title, setTitle] = useState<string>(props.cv.metadata[props.metadataKey].title);

    const onBlur = (value: string) => {
        if (value === oldTitle) return;

        props.cv.metadata[props.metadataKey] = { title: value };

        updateCV(api, props.cv.id, { metadata: props.cv.metadata }).then();
        setOldTitle(value);
        setTitle(value);
    };

    return (
        <Box sx={{ marginBottom: '10px', padding: '5px' }}>
            <GeneralTextField
                value={title}
                setValue={setTitle}
                label={TranslationKey.theme.title}
                required={true}
                fontTheme={props.theme}
                onDoneClicked={onBlur}
            />
        </Box>
    );
};

export const PrintableSectionHeader = (props: PrintableSectionHeaderProps): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <Box>
            <Typography
                color={props.theme.color}
                variant={props.theme.variant}
                fontWeight={props.theme.weight}
                fontStyle={props.theme.style}
                sx={{ color: `${props.theme.color} !important` }}
            >
                {t(props.title)}
            </Typography>
        </Box>
    );
};

const SectionHeader = (props: SectionHeaderProps): React.ReactElement => {
    const { mode } = useMode();

    switch (mode) {
        case Mode.EDIT:
            return <EditableSectionHeader {...props} />;
        case Mode.PRINT:
            return <PrintableSectionHeader theme={props.theme} title={props.cv.metadata[props.metadataKey]?.title} />;
    }
};

export default SectionHeader;
