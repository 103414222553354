import React from 'react';
import { Box, Divider, Grid2, InputLabel, MenuItem, Slider, Typography } from '@mui/material';
import { IFontTheme, INameTheme, ThemeValue } from '../../interfaces/Theme.interface';
import { LABEL_SIZE } from './ThemeDesigner';
import FontThemeCustomizer from './FontTheme';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../interfaces/TranslationKey';

interface NameThemeCustomizerProps {
    theme: INameTheme;
    setTheme: (nameTheme: (prev: INameTheme) => INameTheme) => void;
    setShouldUpdateThemed: (value: boolean) => void;
}

const NameThemeCustomizer = (props: NameThemeCustomizerProps): React.ReactElement => {
    const { t } = useTranslation();

    const handleFirstNameFontThemeChange = (key: keyof IFontTheme, value: string) => {
        handleThemeChange('first', key, value);
    };

    const handleLastNameFontThemeChange = (key: keyof IFontTheme, value: string) => {
        handleThemeChange('last', key, value);
    };

    const handleMarginThemeChange = (key: keyof { gap: number }, value: number) => {
        handleThemeChange('margin', key, value);
    };

    const handleThemeChange = <T extends keyof INameTheme, K extends keyof INameTheme[T]>(section: T, key: K, value: ThemeValue) => {
        props.setTheme((prev: INameTheme) => ({
            ...prev,
            [section]: {
                ...prev[section],
                [key]: value,
            },
        }));
        props.setShouldUpdateThemed(true);
    };

    return (
        <Box>
            <Typography variant="h6">{t(TranslationKey.firstName)}</Typography>
            <Grid2 container spacing={2}>
                <Grid2 size={12}>
                    <FontThemeCustomizer
                        labelKey={'firstName'}
                        theme={props.theme.first}
                        handleThemeChange={handleFirstNameFontThemeChange}
                        variantItems={[
                            <MenuItem key={'name-variant-h1'} value="h1">
                                {t(TranslationKey.theme.fontSize.XXL)}
                            </MenuItem>,
                            <MenuItem key={'name-variant-h2'} value="h2">
                                {t(TranslationKey.theme.fontSize.XL)}
                            </MenuItem>,
                            <MenuItem key={'name-variant-h3'} value="h3">
                                {t(TranslationKey.theme.fontSize.L)}
                            </MenuItem>,
                            <MenuItem key={'name-variant-h4'} value="h4">
                                {t(TranslationKey.theme.fontSize.M)}
                            </MenuItem>,
                            <MenuItem key={'name-variant-h5'} value="h5">
                                {t(TranslationKey.theme.fontSize.S)}
                            </MenuItem>,
                            <MenuItem key={'name-variant-h6'} value="h6">
                                {t(TranslationKey.theme.fontSize.XS)}
                            </MenuItem>,
                        ]}
                    />
                </Grid2>
            </Grid2>
            <Divider sx={{ my: 2 }} />

            <Typography variant="h6">{t(TranslationKey.lastName)}</Typography>
            <Grid2 container spacing={2}>
                <Grid2 size={12}>
                    <FontThemeCustomizer
                        labelKey={'lastName'}
                        theme={props.theme.last}
                        handleThemeChange={handleLastNameFontThemeChange}
                        variantItems={[
                            <MenuItem key={'name-variant-h1'} value="h1">
                                {t(TranslationKey.theme.fontSize.XXL)}
                            </MenuItem>,
                            <MenuItem key={'name-variant-h2'} value="h2">
                                {t(TranslationKey.theme.fontSize.XL)}
                            </MenuItem>,
                            <MenuItem key={'name-variant-h3'} value="h3">
                                {t(TranslationKey.theme.fontSize.L)}
                            </MenuItem>,
                            <MenuItem key={'name-variant-h4'} value="h4">
                                {t(TranslationKey.theme.fontSize.M)}
                            </MenuItem>,
                            <MenuItem key={'name-variant-h5'} value="h5">
                                {t(TranslationKey.theme.fontSize.S)}
                            </MenuItem>,
                            <MenuItem key={'name-variant-h6'} value="h6">
                                {t(TranslationKey.theme.fontSize.XS)}
                            </MenuItem>,
                        ]}
                    />
                </Grid2>
            </Grid2>
            <Divider sx={{ my: 2 }} />

            <Grid2 container spacing={2}>
                <Grid2 size={LABEL_SIZE}>
                    <InputLabel>{t(TranslationKey.theme.nameSpace)}</InputLabel>
                </Grid2>
                <Grid2 size="grow">
                    <Slider
                        value={props.theme.margin.gap}
                        onChange={(_, newValue: number | number[]) => handleMarginThemeChange('gap', newValue as number)}
                        valueLabelDisplay={'auto'}
                        valueLabelFormat={`${props.theme.margin.gap}px`}
                        step={1}
                        min={5}
                        max={50}
                        marks={[
                            { value: 5, label: 5 },
                            { value: 50, label: 50 },
                        ]}
                    />
                </Grid2>
            </Grid2>
        </Box>
    );
};

export default NameThemeCustomizer;
