import React from 'react';
import { IFontTheme } from '../../interfaces/Theme.interface';
import { getFontSize } from '../../utils/common.util';
import { IExperience } from '../../interfaces/Experience.interface';

interface CompanyProps {
    experience: IExperience;
    theme: IFontTheme;
}

const PrintableCompany = (props: Pick<CompanyProps, 'experience' | 'theme'>): React.ReactElement => {
    return props.experience.company ? (
        <>
            <span
                style={{
                    color: props.theme.color,
                    fontSize: getFontSize(props.theme.variant),
                    fontWeight: props.theme.weight === 'light' ? 'lighter' : props.theme.weight,
                    fontStyle: props.theme.style,
                }}
            >
                {props.experience.company.name}
            </span>
            {props.experience.company.location && (
                <>
                    <span
                        style={{
                            color: props.theme.color,
                            fontSize: getFontSize(props.theme.variant),
                            fontWeight: props.theme.weight === 'light' ? 'lighter' : props.theme.weight,
                            fontStyle: props.theme.style,
                        }}
                    >
                        {`\u200C `} &mdash; {` \u200C`}
                    </span>
                    <span
                        style={{
                            color: props.theme.color,
                            fontSize: getFontSize(props.theme.variant),
                            fontWeight: props.theme.weight === 'light' ? 'lighter' : props.theme.weight,
                            fontStyle: props.theme.style,
                        }}
                    >
                        {props.experience.company.location}
                    </span>
                </>
            )}
        </>
    ) : (
        <></>
    );
};

export default PrintableCompany;
