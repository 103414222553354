import { ApiEndpoints } from './endpoints';
import { ICertification, ICertificationCreationDto, ICertificationUpdateDto } from '../interfaces/Certification.interface';
import { IApi } from './api.service';

export const createCertification = async (api: IApi, dto: ICertificationCreationDto): Promise<ICertification | null> => {
    return api.post<ICertification, ICertificationCreationDto>(ApiEndpoints.certification, dto);
};

export const updateCertification = async (api: IApi, id: number, dto: ICertificationUpdateDto): Promise<ICertification | null> => {
    return api.put<ICertification, ICertificationUpdateDto>(ApiEndpoints.certificationId.replace(':id', String(id)), dto);
};

export const removeCertification = async (api: IApi, id: number): Promise<void> => {
    return api.delete(ApiEndpoints.certificationId.replace(':id', String(id)));
};
