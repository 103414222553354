import React, { useState } from 'react';
import { Box, Grid2, Typography } from '@mui/material';
import { ILayout, ILayoutTheme, ISectionWidth, Position, SectionName } from '../../interfaces/Theme.interface';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { useCV } from '../../context/CV.context';
import { ICV } from '../../interfaces/CV.interface';
import { TranslationKey } from '../../interfaces/TranslationKey';
import { getSections } from '../../templates/Template.util';
import { TemplateName } from '../../common/enum';

interface BaseThemeCustomizerProps {
    templateName: TemplateName;
    theme: ILayoutTheme;
    setTheme: (layoutTheme: (prev: ILayoutTheme) => ILayoutTheme) => void;
    setShouldUpdateThemed: (value: boolean) => void;
}

const sectionExistOnCV = (cv: ICV | null, section: SectionName): boolean => {
    if (!cv) return false;

    switch (section) {
        case 'name':
            return !!cv.firstName || !!cv.lastName;
        case 'profession':
            return !!cv.profession;
        case 'image':
            return !!cv.profileImage;
        case 'contact':
            return !!cv.address || !!cv.phoneNumber || !!cv.email;
        case 'profile':
            return !!cv.profile;
        case 'experiences':
            return !!cv.experiences.length;
        case 'educations':
            return !!cv.educations.length;
        case 'skills':
            return !!cv.skillProficiencies.length;
        case 'languages':
            return !!cv.languageProficiencies.length;
        case 'references':
            return !!cv.references.length;
        case 'interests':
            return !!cv.interests;
        case 'links':
            return !!cv.socialLinks.length;
        default:
            return false;
    }
};

const sectionToTranslationKey = (section: SectionName): string => {
    switch (section) {
        case 'name':
            return TranslationKey.name;
        case 'profession':
            return TranslationKey.profession;
        case 'image':
            return TranslationKey.image._name;
        case 'contact':
            return TranslationKey.contact;
        case 'profile':
            return TranslationKey.profile;
        case 'experiences':
            return TranslationKey.experience._name;
        case 'educations':
            return TranslationKey.educationAndCertification;
        case 'skills':
            return TranslationKey.skill.plural;
        case 'languages':
            return TranslationKey.language.plural;
        case 'references':
            return TranslationKey.reference.plural;
        case 'interests':
            return TranslationKey.interests;
        case 'links':
            return TranslationKey.socialLink.plural;
        default:
            return 'UNKNOWN';
    }
};

const positionToWidth = (sectionWidth: ISectionWidth, position: Position): number => {
    switch (position) {
        case 'topLeft':
            return sectionWidth.topLeftWidth;
        case 'topRight':
            return 12 - sectionWidth.topLeftWidth;
        case 'middleLeft':
            return sectionWidth.middleLeftWidth;
        case 'middleRight':
            return 12 - sectionWidth.middleLeftWidth;
        case 'bottomLeft':
            return sectionWidth.bottomLeftWidth;
        case 'bottomRight':
            return 12 - sectionWidth.bottomLeftWidth;
        default:
            return 12;
    }
};

const SectionPositionCustomizer = (props: BaseThemeCustomizerProps): React.ReactElement => {
    const { t } = useTranslation();
    const { cv } = useCV();
    const [hoveredDroppable, setHoveredDroppable] = useState<string | null>(null);

    const handleThemeChange = (key: keyof ILayoutTheme, value: ILayout) => {
        props.setTheme((prev: ILayoutTheme) => ({
            ...prev,
            [key]: value,
        }));
        props.setShouldUpdateThemed(true);
    };

    const sectionStyle: SxProps<Theme> = {
        borderRadius: '10px',
        border: '1px solid',
        borderColor: (theme) => theme.palette.primary.main,
        padding: '12px',
        margin: '5px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.2s ease-in-out',
        '&:hover': {
            transform: 'scale(1.03)',
        },
    };

    const missingSectionStyle: SxProps<Theme> = {
        borderColor: (theme) => theme.palette.error.main,
        backgroundColor: '#FFEBEE',
    };

    const layout: ILayout = getSections(props.theme, props.templateName);

    const onDragStart = () => {
        // Add slight transparency when dragging starts
        document.body.style.opacity = '0.9';
    };

    const onDragEnd = (result: any) => {
        document.body.style.opacity = '1'; // Reset opacity

        const { source, destination } = result;
        setHoveredDroppable(null); // Reset highlighted droppable

        if (!destination) return;
        if (source.droppableId === destination.droppableId && source.index === destination.index) return;

        const sourceList: SectionName[] = [...layout[source.droppableId as keyof ILayout].sections];

        let newSectionPosition: ILayout;
        if (source.droppableId === destination.droppableId) {
            const reorder = (list: SectionName[], startIndex: number, endIndex: number): SectionName[] => {
                const result = [...list];
                const [removed] = result.splice(startIndex, 1);
                result.splice(endIndex, 0, removed);
                return result;
            };

            newSectionPosition = {
                ...layout,
                [source.droppableId]: {
                    immutable: layout[source.droppableId as keyof ILayout].immutable,
                    sections: reorder(sourceList, source.index, destination.index),
                },
            };
        } else {
            const destinationList = [...layout[destination.droppableId as keyof ILayout].sections];
            const [movedItem] = sourceList.splice(source.index, 1);
            destinationList.splice(destination.index, 0, movedItem);

            newSectionPosition = {
                ...layout,
                [source.droppableId]: {
                    immutable: layout[source.droppableId as keyof ILayout].immutable,
                    sections: sourceList,
                },
                [destination.droppableId]: {
                    immutable: layout[destination.droppableId as keyof ILayout].immutable,
                    sections: destinationList,
                },
            };
        }

        handleThemeChange('layout', newSectionPosition);
    };

    const buildDroppable = (sections: SectionName[], droppableId: Position, key: string): React.ReactElement => (
        <Droppable droppableId={droppableId} key={`${props.templateName}-${key}`}>
            {(provided, snapshot) => (
                <Grid2
                    size={positionToWidth(props.theme.sectionWidth, droppableId)}
                    ref={provided.innerRef}
                    onDragOver={() => setHoveredDroppable(droppableId)} // Set highlight on drag over
                    onDragLeave={() => setHoveredDroppable(null)} // Remove highlight on leave
                    sx={{
                        minHeight: '100px',
                        border: '2px dashed #ccc',
                        padding: '15px',
                        borderRadius: '8px',
                        backgroundColor:
                            snapshot.isDraggingOver || hoveredDroppable === droppableId
                                ? '#D1ECF1'
                                : droppableId === 'excludedSections'
                                  ? 'rgba(255,227,227,0.44)'
                                  : '#F8F9FA',
                        borderColor: snapshot.isDraggingOver || hoveredDroppable === droppableId ? '#17A2B8' : '#ccc',
                        transition: 'background-color 0.3s ease, border-color 0.3s ease',
                    }}
                >
                    {sections.map((section: SectionName, index: number) => (
                        <Draggable key={props.templateName + section} draggableId={String(section)} index={index}>
                            {(provided, snapshot) => (
                                <Box
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    sx={{
                                        ...sectionStyle,
                                        ...(sectionExistOnCV(cv, section) ? {} : missingSectionStyle),
                                        backgroundColor: snapshot.isDragging ? '#B3E5FC' : 'white',
                                        boxShadow: snapshot.isDragging
                                            ? '0px 4px 10px rgba(0, 0, 0, 0.2)'
                                            : '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                        opacity: snapshot.isDragging ? 0.8 : 1,
                                        transform: snapshot.isDragging ? 'scale(1.05)' : 'scale(1)',
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        fontWeight="bold"
                                        sx={{
                                            textAlign: 'center',
                                            whiteSpace: 'normal', // Allows text wrapping
                                            wordWrap: 'break-word', // Centers text
                                        }}
                                    >
                                        {t(sectionToTranslationKey(section))}
                                    </Typography>
                                </Box>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </Grid2>
            )}
        </Droppable>
    );

    return (
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
            <Grid2 container spacing={2}>
                <Grid2 size={12} container spacing={2}>
                    {buildDroppable(layout.top.sections, 'top', 'droppable-area-top')}
                    {buildDroppable(layout.topLeft.sections, 'topLeft', 'droppable-area-top-left')}
                    {buildDroppable(layout.topRight.sections, 'topRight', 'droppable-area-top-right')}
                    {buildDroppable(layout.middle.sections, 'middle', 'droppable-area-middle')}
                    {buildDroppable(layout.middleLeft.sections, 'middleLeft', 'droppable-area-middle-left')}
                    {buildDroppable(layout.middleRight.sections, 'middleRight', 'droppable-area-middle-right')}
                    {buildDroppable(layout.bottom.sections, 'bottom', 'droppable-area-bottom')}
                    {buildDroppable(layout.bottomLeft.sections, 'bottomLeft', 'droppable-area-bottom-left')}
                    {buildDroppable(layout.bottomRight.sections, 'bottomRight', 'droppable-area-bottom-right')}
                </Grid2>
                <Grid2 size={12}>
                    {buildDroppable(layout.excludedSections.sections, 'excludedSections', 'droppable-area-exclude-sections')}
                </Grid2>
            </Grid2>
        </DragDropContext>
    );
};

export default SectionPositionCustomizer;
