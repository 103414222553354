import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export enum IconType {
    DANGER = 'DANGER',
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
}

export const iconClickable = (iconType: IconType): SxProps<Theme> => {
    return {
        adding: '0px',
        cursor: 'pointer',
        color: (theme) =>
            iconType === IconType.DANGER
                ? theme.palette.error.main
                : IconType.SECONDARY
                  ? theme.palette.secondary.main
                  : theme.palette.primary.main,
    };
};

export const iconClickableButton = (iconType: IconType): SxProps<Theme> => {
    return {
        adding: '0px',
        cursor: 'pointer',
        color: (theme) =>
            iconType === IconType.DANGER
                ? theme.palette.error.main
                : iconType === IconType.SECONDARY
                  ? theme.palette.secondary.main
                  : theme.palette.primary.main,
        padding: '3px 10px',
        transition: 'background 0.3s, color 0.3s',
        borderRadius: '5px',
        '&:hover': {
            bgcolor: (theme) => theme.palette.primary.light,
            color: (theme) => theme.palette.primary.contrastText,
        },
    };
};
