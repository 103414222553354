import { ApiEndpoints } from './endpoints';
import { IApi } from './api.service';
import { ISocialLink, ISocialLinkCreationDto, ISocialLinkUpdateDto } from '../interfaces/SocialLink.interface';

export const createSocialLink = async (api: IApi, dto: ISocialLinkCreationDto): Promise<ISocialLink | null> => {
    return api.post<ISocialLink, ISocialLinkCreationDto>(ApiEndpoints.socialLink, dto);
};

export const updateSocialLink = async (api: IApi, id: number, dto: ISocialLinkUpdateDto): Promise<ISocialLink | null> => {
    return api.put<ISocialLink, ISocialLinkUpdateDto>(ApiEndpoints.socialLinkId.replace(':id', String(id)), dto);
};

export const removeSocialLink = async (api: IApi, id: number): Promise<void> => {
    return api.delete(ApiEndpoints.socialLinkId.replace(':id', String(id)));
};
