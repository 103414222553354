import React from 'react';
import { ILayout, SectionName } from '../../interfaces/Theme.interface';
import { getPDFStyles } from '../../utils/theme.util';
import { Document, Page, View } from '@react-pdf/renderer/lib/react-pdf';
import { IPDFStyle } from '../../interfaces/pdfStyle.interface';
import { useTranslation } from 'react-i18next';
import { TemplateName } from '../../common/enum';
import { IPDFProps } from '../../interfaces/Template.interface';
import { getSections } from '../Template.util';
import { getPDFSectionElements, IPDFSectionElementsProps } from '../PDFTemplate.util';

const HomsTemplatePDF = (props: IPDFProps): React.ReactElement => {
    const { i18n } = useTranslation();

    const isRTL: boolean = i18n.dir() === 'rtl';

    const styles: IPDFStyle = getPDFStyles(props.theme, isRTL);

    const templateName: TemplateName = TemplateName.HOMS;

    const layout: ILayout = getSections(props.theme.layoutTheme, templateName);

    const showTopLeftSection: boolean = layout.topLeft.sections.some((i) => !!i);
    const showTopSection: boolean = layout.top.sections.some((i) => !!i);
    const showMiddleSection: boolean = layout.middle.sections.some((i) => !!i);
    const showMiddleLeftRightSection: boolean = layout.middleLeft.sections.some((i) => !!i) || layout.middleRight.sections.some((i) => !!i);
    const showBottomSection: boolean = layout.bottom.sections.some((i) => !!i);
    const showBottomLeftRightSection: boolean = layout.bottomLeft.sections.some((i) => !!i) || layout.bottomRight.sections.some((i) => !!i);

    const leftSectionPadding: string = isRTL ? '0 0 0 14px' : '0 14px 0 0';
    const rightSectionPadding: string = isRTL ? '0 14px 0 0' : '0 0 0 14px';

    const getProps = (sections: SectionName[]): IPDFSectionElementsProps => ({
        templateName: templateName,
        sections: sections,
        styles: styles,
        pdfProps: props,
    });

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* top  */}
                {showTopSection && (
                    <View style={{ padding: leftSectionPadding }}>{getPDFSectionElements(getProps(layout.top.sections))}</View>
                )}

                {/* top left/right */}
                <View style={{ ...styles.rowStyle, marginBottom: '10px' }}>
                    {showTopLeftSection && (
                        <View style={styles.leftTopView}>{getPDFSectionElements(getProps(layout.topLeft.sections))}</View>
                    )}
                    <View style={{ width: showTopLeftSection ? styles.rightTopView.width : '100%' }}>
                        {getPDFSectionElements(getProps(layout.topRight.sections))}
                    </View>
                </View>

                {/* middle  */}
                {showMiddleSection && (
                    <View style={{ padding: leftSectionPadding }}>{getPDFSectionElements(getProps(layout.middle.sections))}</View>
                )}

                {/* middle left/right */}
                {showMiddleLeftRightSection && (
                    <View style={{ ...styles.rowStyle }}>
                        <View style={{ ...styles.leftMiddleView, padding: leftSectionPadding }}>
                            {getPDFSectionElements(getProps(layout.middleLeft.sections))}
                        </View>
                        <View style={{ ...styles.rightMiddleView, padding: rightSectionPadding }}>
                            {getPDFSectionElements(getProps(layout.middleRight.sections))}
                        </View>
                    </View>
                )}

                {/* bottom  */}
                {showBottomSection && (
                    <View style={{ padding: leftSectionPadding }}>{getPDFSectionElements(getProps(layout.bottom.sections))}</View>
                )}

                {/* bottom left/right */}
                {showBottomLeftRightSection && (
                    <View style={{ ...styles.rowStyle }}>
                        <View style={{ ...styles.leftBottomView, padding: leftSectionPadding }}>
                            {getPDFSectionElements(getProps(layout.bottomLeft.sections))}
                        </View>
                        <View style={{ ...styles.rightBottomView, padding: rightSectionPadding }}>
                            {getPDFSectionElements(getProps(layout.bottomRight.sections))}
                        </View>
                    </View>
                )}
            </Page>
        </Document>
    );
};

export default HomsTemplatePDF;
