import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

// Fuck axios: it took me 3 days to fix the cookie issue
axios.defaults.withCredentials = true;

export interface IApi {
    axiosInstance: AxiosInstance;

    get<T, B = unknown>(urlPath: string, config?: AxiosRequestConfig<B>): Promise<T | null>;
    post<T, B>(urlPath: string, data: B, config?: AxiosRequestConfig): Promise<T | null>;
    put<T, B>(urlPath: string, data: B, config?: AxiosRequestConfig): Promise<T | null>;
    delete(urlPath: string, config?: AxiosRequestConfig): Promise<void>;
    getBlob(urlPath: string, config?: AxiosRequestConfig): Promise<Blob | null>;
}

export class Api implements IApi {
    private static instance: Api;
    readonly axiosInstance: AxiosInstance;
    static baseURL = process.env.REACT_APP_API_BASE_URL;

    private constructor() {
        const baseURL = Api.baseURL;
        if (!baseURL) {
            throw new Error('REACT_APP_API_BASE_URL is not defined in the environment variables.');
        }

        this.axiosInstance = axios.create({
            baseURL,
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    static getInstance(): IApi {
        if (!Api.instance) {
            Api.instance = new Api();
        }
        return Api.instance;
    }

    async get<T, B = unknown>(urlPath: string, config?: AxiosRequestConfig<B>): Promise<T | null> {
        try {
            const response: AxiosResponse<T> = await this.axiosInstance.get(urlPath, config);
            return response.data;
        } catch (error) {
            console.error(`GET ${urlPath} failed:`, error);
            return null;
        }
    }

    async post<T, B>(urlPath: string, data: B, config?: AxiosRequestConfig): Promise<T | null> {
        try {
            const response: AxiosResponse<T> = await this.axiosInstance.post(urlPath, data, config);
            return response.data;
        } catch (error) {
            console.error(`POST ${urlPath} failed:`, error);
            return null;
        }
    }

    async put<T, B>(urlPath: string, data: B, config?: AxiosRequestConfig): Promise<T | null> {
        try {
            const response: AxiosResponse<T> = await this.axiosInstance.put(urlPath, data, config);
            return response.data;
        } catch (error) {
            console.error(`PUT ${urlPath} failed:`, error);
            return null;
        }
    }

    async delete(urlPath: string, config?: AxiosRequestConfig): Promise<void> {
        try {
            await this.axiosInstance.delete(urlPath, config);
        } catch (error) {
            console.error(`DELETE ${urlPath} failed:`, error);
        }
    }

    async getBlob(urlPath: string, config?: AxiosRequestConfig): Promise<Blob | null> {
        try {
            const response: AxiosResponse<Blob> = await this.axiosInstance.get(urlPath, { ...config, responseType: 'blob' });
            return response.data;
        } catch (error) {
            console.error(`GET ${urlPath} failed:`, error);
            return null;
        }
    }
}

export const api = Api.getInstance();
