import { Canvas, View } from '@react-pdf/renderer';
import React from 'react';
import { IProficiencyTheme } from '../../interfaces/Theme.interface';
import { Style } from '@react-pdf/types';
import { useTranslation } from 'react-i18next';

interface ProficiencyPDFProps {
    value: number;
    theme: IProficiencyTheme;
    rowStyle: Style;
    labelKey: string;
}

const DashedProgress = (props: ProficiencyPDFProps): React.ReactElement => {
    const levels = [0, 20, 40, 60, 80, 100];
    const dashCount = 5;
    const activeDashes = levels.indexOf(props.value);

    return (
        <View style={{ ...props.rowStyle, gap: props.theme.gap * 5 }}>
            {[...Array(dashCount)].map((_, index: number) => (
                <View
                    key={`PDF-${props.labelKey}-dashed-${index}`}
                    style={{
                        width: '18%',
                        height: props.theme.thickness * 0.7,
                        backgroundColor: index < activeDashes ? props.theme.activeColor : props.theme.inactiveColor,
                        borderRadius: props.theme.borderRadius,
                    }}
                />
            ))}
        </View>
    );
};

const DottedProgress = (props: ProficiencyPDFProps): React.ReactElement => {
    const levels = [0, 20, 40, 60, 80, 100];
    const dotCount = 5;
    const activeDots = levels.indexOf(props.value);

    return (
        <View style={{ ...props.rowStyle, gap: props.theme.gap * 5 }}>
            {[...Array(dotCount)].map((_, index: number) => (
                <View
                    key={`PDF-${props.labelKey}-dotted-${index}`}
                    style={{
                        width: props.theme.thickness * 0.7,
                        height: props.theme.thickness * 0.7,
                        backgroundColor: index < activeDots ? props.theme.activeColor : props.theme.inactiveColor,
                        borderRadius: props.theme.borderRadius,
                    }}
                />
            ))}
        </View>
    );
};

const LinearProgress = (props: ProficiencyPDFProps): React.ReactElement => {
    const { i18n } = useTranslation();

    const isRTL: boolean = i18n.dir() === 'rtl';

    return (
        <View style={props.rowStyle}>
            <Canvas
                style={{ height: props.theme.thickness * 0.7, width: '100%' }}
                paint={(painter: any, availableWidth: number) => {
                    const margin: number = 20;
                    const cleanWidth = availableWidth - margin;

                    const filledWidth = (props.value / 100) * cleanWidth;

                    const startX = isRTL ? margin : 0;
                    painter
                        .roundedRect(startX, 0, cleanWidth, props.theme.thickness * 0.7, props.theme.borderRadius)
                        .fill(props.theme.inactiveColor);

                    const startFilledX = isRTL ? cleanWidth - filledWidth + startX : 0;
                    painter
                        .roundedRect(startFilledX, 0, filledWidth, props.theme.thickness * 0.7, props.theme.borderRadius)
                        .fill(props.theme.activeColor);
                    return null;
                }}
            />
        </View>
    );
};

const PDFProficiency = (props: ProficiencyPDFProps): React.ReactElement => {
    switch (props.theme.type) {
        case 'none':
            return <View></View>;
        case 'dashed':
            return <DashedProgress {...props} />;
        case 'dotted':
            return <DottedProgress {...props} />;
        default:
            return <LinearProgress {...props} />;
    }
};

export default PDFProficiency;
