import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PasswordField from './PasswordField';
import PasswordStrengthHelpText from './PasswordStrengthHelpText';
import { TranslationKey } from '../../interfaces/TranslationKey';

interface PasswordFieldsProps {
    password: string;
    confirmPassword: string;
    setPassword: (value: string) => void;
    setConfirmPassword: (value: string) => void;
    setError: (value: string) => void;
}

const PasswordFields: React.FC<PasswordFieldsProps> = ({ password, confirmPassword, setPassword, setConfirmPassword, setError }) => {
    const { t } = useTranslation();
    const [passwordError, setPasswordError] = useState(false);

    const handlePasswordChange = (value: string) => {
        setPassword(value);

        const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&,;^])[A-Za-z\d@$#!%*?&,;^]{8,20}$/;

        setPasswordError(!strongPasswordRegex.test(value));

        if (value && confirmPassword) {
            setError(value === confirmPassword ? '' : t(TranslationKey.error.passwordsMismatch));
        } else {
            setError('');
        }
    };

    const handleConfirmPasswordChange = (value: string) => {
        setConfirmPassword(value);

        if (value && password) {
            setError(value === password ? '' : t(TranslationKey.error.passwordsMismatch));
        } else {
            setError('');
        }
    };

    return (
        <Box>
            <PasswordField
                value={password}
                error={passwordError}
                label={t(TranslationKey.user.password)}
                onChange={(e) => handlePasswordChange(e.target.value)}
            />
            <PasswordStrengthHelpText password={password} />
            <PasswordField
                value={confirmPassword}
                error={false}
                label={t(TranslationKey.user.confirmPassword)}
                onChange={(e) => handleConfirmPasswordChange(e.target.value)}
            />
        </Box>
    );
};

export default PasswordFields;
