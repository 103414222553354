import { ApiEndpoints } from './endpoints';
import { IPaginatedListResponse } from '../interfaces/Pagination.interface';
import { IProfession, IProfessionDto, IProfessionFilter } from '../interfaces/Profession.interface';
import { IApi } from './api.service';

export const getAllProfession = async (api: IApi, filter: IProfessionFilter): Promise<IPaginatedListResponse<IProfession>> => {
    const paginatedProfessions: IPaginatedListResponse<IProfession> | null = await api.get<
        IPaginatedListResponse<IProfession>,
        IProfessionFilter
    >(ApiEndpoints.profession, { params: filter });
    if (!paginatedProfessions) {
        return {
            count: 0,
            items: [],
            page: 0,
            pageSize: 0,
        };
    }
    return paginatedProfessions;
};

export const getOrCreateProfession = async (api: IApi, dto: IProfessionDto): Promise<IProfession | null> => {
    return await api.post<IProfession, IProfessionDto>(ApiEndpoints.profession, dto);
};
