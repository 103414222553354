import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import LoginPage from '../pages/auth/Login.page';
import HomePage from '../pages/HomePage';
import CVPage from '../pages/CV.page';
import CVCreationPage from '../pages/CVCreation.page';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import { Box } from '@mui/material';
import SignupPage from '../pages/auth/Signup.page';
import PrivateRoute from './PrivateRoute';
import ContactFormModal from '../components/ContactFormModal';
import ActivatePage from '../pages/auth/Activate.page';
import PrivacyPolicyPage from '../pages/PrivacyPolicy.page';
import TermsOfServicePage from '../pages/TermsOfService.page';
import RequestPasswordResetPage from '../pages/auth/RequestPasswordReset.page';
import ResetPasswordPage from '../pages/auth/ResetPassword.page';
import NotFoundPage from '../pages/NotFound.page';
import ProfilePage from '../pages/Profile.page';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { STORAGE_LANGUAGE_KEY, SUPPORTED_LANGUAGES } from '../common/constants';
import Loading from '../components/Loading';

interface AppRoutesProps {
    setDirection: (direction: 'rtl' | 'ltr') => void;
}

const AppRoutes = (props: AppRoutesProps): React.ReactElement => {
    const { i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [contactFormAnchor, setContactFormAnchor] = useState<null | SVGSVGElement | HTMLElement>(null);
    const [isLanguageChanging, setIsLanguageChanging] = useState(false);

    const handleContactUsClick = (event: React.MouseEvent<HTMLElement>) => {
        setContactFormAnchor(event.currentTarget);
    };

    const queryClient = new QueryClient();

    useEffect(() => {
        const urlLang = location.pathname.split('/')[1];

        const defaultLang = localStorage.getItem(STORAGE_LANGUAGE_KEY) || 'en';

        if (!urlLang) {
            setIsLanguageChanging(true);
            i18n.changeLanguage(defaultLang).then(() => {
                document.body.dir = i18n.dir(defaultLang);
                props.setDirection(i18n.dir(defaultLang));
                setIsLanguageChanging(false);
            });
            return;
        }

        if (!SUPPORTED_LANGUAGES.includes(urlLang)) {
            navigate(`/${defaultLang}/`, { replace: true });
            return;
        }

        if (i18n.language !== urlLang) {
            setIsLanguageChanging(true);
            i18n.changeLanguage(urlLang).then(() => {
                document.body.dir = i18n.dir(urlLang);
                props.setDirection(i18n.dir(urlLang));
                setIsLanguageChanging(false);
            });
        }
    }, [location.pathname]);

    return (
        <QueryClientProvider client={queryClient}>
            <Navbar setDirection={props.setDirection} onContactUsClicked={handleContactUsClick} />
            <Box sx={{ minHeight: '80vh' }}>
                {isLanguageChanging && <Loading />}
                {!isLanguageChanging && (
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/:lang">
                            <Route index element={<HomePage />} />
                            <Route path="home" element={<HomePage />} />
                            <Route path={'login'} element={<LoginPage />} />
                            <Route path={'signup'} element={<SignupPage />} />
                            <Route path={'activate'} element={<ActivatePage />} />
                            <Route path={'password-reset-request'} element={<RequestPasswordResetPage />} />
                            <Route path={'password-reset'} element={<ResetPasswordPage />} />
                            <Route path={'privacy-policy'} element={<PrivacyPolicyPage />} />
                            <Route path={'terms-of-service'} element={<TermsOfServicePage />} />

                            {/* Protected Routes */}
                            <Route element={<PrivateRoute />}>
                                <Route path={'profile'} element={<ProfilePage />} />
                                <Route path={'cv/:uuid/template/:templateName'} element={<CVPage />} />
                                <Route path={'cv/builder/template/:templateName'} element={<CVCreationPage />} />
                            </Route>
                        </Route>

                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                )}
            </Box>
            <Footer onContactUsClicked={handleContactUsClick} />
            <ContactFormModal anchorEl={contactFormAnchor} setAnchorEl={setContactFormAnchor} />
        </QueryClientProvider>
    );
};

export default AppRoutes;
