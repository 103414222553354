import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
import { TranslationKey } from '../interfaces/TranslationKey';
import { useTranslation } from 'react-i18next';

interface DialogProps {
    title: string;
    message: string;
    openConfirmModal: boolean;
    setOpenConfirmModal: (v: boolean) => void;
    onConfirm: () => void;
}

const ConfirmationDialog = (props: DialogProps): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <Dialog open={props.openConfirmModal} onClose={() => props.setOpenConfirmModal(false)}>
            <DialogTitle>{props.title}</DialogTitle>
            <Divider />
            <DialogContent>
                <Typography>{props.message}</Typography>
            </DialogContent>
            <DialogActions sx={{ px: 2 }}>
                <Button onClick={() => props.setOpenConfirmModal(false)} variant={'contained'} color="primary">
                    {t(TranslationKey.buttons.cancel)}
                </Button>
                <Button onClick={props.onConfirm} color="error">
                    {t(TranslationKey.buttons.confirm)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
