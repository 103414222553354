import React, { useState } from 'react';
import { Box } from '@mui/material';
import { PrintableTextField } from '../GeneralTextField';
import { IProfileTheme } from '../../interfaces/Theme.interface';
import { useMode } from '../../context/Mode.context';
import { Mode } from '../../interfaces/Mode.interface';
import ProfileForm from '../builder/forms/ProfileForm';

interface ProfileProps {
    cvId: number;
    profile: string | null;
    theme: IProfileTheme;
}

const Profile: React.FC<ProfileProps> = (props: ProfileProps) => {
    const { mode } = useMode();
    const [profile] = useState<string | null>(props.profile);

    const isEditing: boolean = mode === Mode.EDIT;

    return (
        <Box id="profile" sx={{ mb: `${props.theme.margin.bottom}px` }}>
            {isEditing ? (
                <ProfileForm {...props} />
            ) : (
                <PrintableTextField value={profile ?? ''} typographyComponent={'p'} fontTheme={props.theme.font} />
            )}
        </Box>
    );
};

export default Profile;
