import { jwtDecode } from 'jwt-decode';
import { IUser } from '../interfaces/User.interface';

interface JwtPayload extends IUser {
    sub: string;
    exp: number;
}

export const decodeAccessToken = (token: string): JwtPayload | null => {
    try {
        return jwtDecode<JwtPayload>(token);
    } catch {
        return null;
    }
};

export const getUserFromToken = (accessToken: string | null): IUser | null => {
    if (!accessToken) return null;

    const decoded: JwtPayload | null = decodeAccessToken(accessToken);
    if (!decoded) return null;

    return {
        email: decoded.email,
        uuid: decoded.uuid,
        isActive: decoded.isActive,
    };
};
