import React, { useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { useMode } from '../context/Mode.context';
import { Mode } from '../interfaces/Mode.interface';
import { PrintableTextField } from './GeneralTextField';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../interfaces/TranslationKey';
import { IFontTheme } from '../interfaces/Theme.interface';
import DoneIcon from '@mui/icons-material/Done';
import EditButton from './EditButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface EditableEmailProps {
    email: string;
    setEmail: (value: string) => void;
    fontTheme: IFontTheme;
    alwaysEditable?: boolean;
    onDoneClicked?: (value: string) => void;
}

const EditableEmail: React.FC<EditableEmailProps> = (props: EditableEmailProps) => {
    const { t } = useTranslation();
    const [isEditing, setIsEditing] = useState(false);
    const [error, setError] = useState(false);
    const [tempValue, setTempValue] = useState(props.email);

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]{3,}\.[a-zA-Z]{2,}$/;

    const handleClick = () => {
        setIsEditing(true);
    };

    const handleChange = (e: { target: { value: string } }) => {
        setIsEditing(true);
        setTempValue(e.target.value);
        if (!emailRegex.test(e.target.value)) {
            setError(true);
        } else {
            setError(false);
        }
    };

    const handleDoneClick = () => {
        if (!tempValue || !emailRegex.test(tempValue)) {
            setError(true);
            return;
        }

        props.setEmail(tempValue);

        setError(false);
        setIsEditing(false);
        if (props.onDoneClicked) {
            props.onDoneClicked(tempValue);
        }
    };

    const handleCancel = () => {
        setTempValue(props.email);
        setIsEditing(false);
        setError(false);
    };

    return (
        <Box display="flex" alignItems="center" gap={1}>
            {isEditing || props.alwaysEditable ? (
                <>
                    <TextField
                        value={tempValue}
                        label={t(TranslationKey.email)}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                        required={true}
                        error={error}
                        helperText={error ? t(TranslationKey.error.invalidEmail) : ''}
                        sx={{ flex: 1, margin: '8px 0' }}
                    />
                    {!props.alwaysEditable && (
                        <>
                            <IconButton onClick={handleDoneClick} color="primary">
                                <DoneIcon />
                            </IconButton>
                            <IconButton onClick={handleCancel} color="secondary">
                                <CloseIcon />
                            </IconButton>
                        </>
                    )}
                </>
            ) : (
                <>
                    <Typography
                        variant={'body1'}
                        component={'p'}
                        fontStyle={props.email ? undefined : 'italic'}
                        sx={{
                            cursor: 'pointer',
                            flex: 1,
                            padding: '6px 8px',
                            borderRadius: '4px',
                            '&:hover': { backgroundColor: '#f5f5f5' },
                        }}
                        onClick={handleClick}
                    >
                        {props.email || <span style={{ color: 'gray' }}>{t(TranslationKey.info.clickToEdit)}</span>}
                    </Typography>
                    <EditButton label={t(TranslationKey.email)} onClick={handleClick} />
                </>
            )}
        </Box>
    );
};

const EmailField: React.FC<EditableEmailProps> = (props: EditableEmailProps) => {
    const { mode } = useMode();

    switch (mode) {
        case Mode.EDIT:
            return <EditableEmail {...props} />;
        case Mode.PRINT:
            return <PrintableTextField value={props.email} fontTheme={props.fontTheme} />;
    }
};

export { EmailField, EditableEmail };
