import React from 'react';
import { Box, Button, Grid2, IconButton, Typography } from '@mui/material';
import { TranslationKey } from '../interfaces/TranslationKey';
import { Facebook, LinkedIn, Twitter } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface FooterProps {
    onContactUsClicked: (event: React.MouseEvent<HTMLElement>) => void;
}

const Footer = (props: FooterProps): React.ReactElement => {
    const { t, i18n } = useTranslation();

    return (
        <Box component="footer" sx={{ backgroundColor: '#444', color: '#fff', py: 4, textAlign: 'center', mt: 'auto' }}>
            <Grid2 container spacing={3} mx={{ xs: '10px', md: '40px' }}>
                <Grid2 size={{ xs: 12, md: 4 }}>
                    <Typography variant="h6" gutterBottom>
                        {t(TranslationKey.footer.aboutUs)}
                    </Typography>
                    <Typography variant="body2">{t(TranslationKey.footer.aboutUsDesc)}</Typography>
                </Grid2>
                <Grid2 size={{ xs: 12, md: 4 }}>
                    <Typography variant="h6" gutterBottom>
                        {t(TranslationKey.footer.quickLinks)}
                    </Typography>
                    <Box>
                        <Button color="inherit" size="small" onClick={props.onContactUsClicked}>
                            {t(TranslationKey.contactUs._name)}
                        </Button>
                        <Button
                            color="inherit"
                            size="small"
                            onClick={() => window.open(`/${i18n.language}/privacy-policy`, '_blank', 'noopener,noreferrer')}
                        >
                            {t(TranslationKey.terms.privacyPolicy)}
                        </Button>
                        <Button
                            color="inherit"
                            size="small"
                            onClick={() => window.open(`/${i18n.language}/terms-of-service`, '_blank', 'noopener,noreferrer')}
                        >
                            {t(TranslationKey.terms.termsOfService)}
                        </Button>
                    </Box>
                </Grid2>
                <Grid2 size={{ xs: 12, md: 4 }}>
                    <Typography variant="h6" gutterBottom>
                        {t(TranslationKey.footer.followUs)}
                    </Typography>
                    <Box>
                        <IconButton
                            color="inherit"
                            component="a"
                            href="https://www.facebook.com/people/Smart-CV/61574637844211/"
                            target="_blank"
                        >
                            <Facebook />
                        </IconButton>
                        <IconButton color="inherit" component="a" href="https://twitter.com" target="_blank">
                            <Twitter />
                        </IconButton>
                        <IconButton color="inherit" component="a" href="https://linkedin.com" target="_blank">
                            <LinkedIn />
                        </IconButton>
                    </Box>
                </Grid2>
            </Grid2>
            <Typography variant="body2" sx={{ mt: 3 }}>
                <span dir="ltr">&copy; {new Date().getFullYear()} Smart-CV.</span> {t(TranslationKey.footer.allRightsReserved)}
            </Typography>
        </Box>
    );
};

export default Footer;
