import { INumericId } from './Id.interface';
import { IOrderableEntity } from './Common.interface';

export enum SocialPlatform {
    LINKEDIN = 'LinkedIn',
    GITHUB = 'GitHub',
    PERSONAL_WEBSITE = 'Personal Website',
    X = 'X',
    FACEBOOK = 'Facebook',
    OTHER = 'Other',
}

export interface ISocialLink extends IOrderableEntity {
    url: string;
    platform: SocialPlatform;
}

export type ISocialLinkUpdateDto = Omit<ISocialLink, 'id' | 'weight'>;

export type ISocialLinkCreationDto = ISocialLinkUpdateDto & {
    cv: INumericId;
};
