import React, { Dispatch, SetStateAction } from 'react';
import { Box, Divider, Grid2, Tooltip } from '@mui/material';
import { IOrderableEntity } from '../interfaces/Common.interface';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Mode } from '../interfaces/Mode.interface';
import { useMode } from '../context/Mode.context';
import { TranslationKey } from '../interfaces/TranslationKey';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';

interface DragAndDropListProps {
    labelKey: string;
    items: IOrderableEntity[];
    setItems: Dispatch<SetStateAction<IOrderableEntity[]>>;
    onMoveEnd: (movedItemId: number, newIndex: number) => void;
    onRemoveCallback: (itemId: number) => void;
    child: (item: IOrderableEntity) => React.ReactNode;
    itemsGap?: number;
}

const OrderableList = (props: DragAndDropListProps): React.ReactElement => {
    const { mode } = useMode();
    const { t } = useTranslation();

    const onMoveEnd = (currentIndex: number, nextIndex: number) => {
        const reorderedItems = Array.from(props.items);
        const [removed] = reorderedItems.splice(currentIndex, 1);
        reorderedItems.splice(nextIndex, 0, removed);

        const updatedItems = reorderedItems.map((item, index) => ({
            ...item,
            weight: (index + 1) * 10,
        }));

        props.setItems(updatedItems);

        const newIndex: number = updatedItems.findIndex((i: IOrderableEntity) => i.id === removed.id);
        updateItemsOnServer(removed.id, newIndex).then();
    };

    const updateItemsOnServer = async (itemId: number, newIndex: number) => {
        props.onMoveEnd(itemId, newIndex);
    };

    const handleRemoveItem = (id: number) => {
        props.onRemoveCallback(id);
    };

    const isEditing: boolean = mode === Mode.EDIT;

    return (
        <Box>
            {props.items.map((item: IOrderableEntity, index: number) => (
                <Grid2 key={props.labelKey + item.id} container size={12}>
                    <Grid2 size={'grow'} sx={{ marginBottom: props.items.length - 1 !== index ? `${props.itemsGap ?? 0}px` : 0 }}>
                        {props.child(item)}
                    </Grid2>
                    {isEditing && (
                        <Grid2 size={1} sx={{ margin: '10px 0 10px 10px', padding: '10px', borderLeft: 'solid 1px #eee' }}>
                            <IconButton onClick={() => handleRemoveItem(item.id)} color={'error'}>
                                <DeleteIcon />
                            </IconButton>

                            {index > 0 && !!item.id && (
                                <Tooltip title={t(TranslationKey.tooltip.moveUp)}>
                                    <IconButton onClick={() => onMoveEnd(index, index - 1)} color={'primary'}>
                                        <ExpandLessIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {!!props.items[index + 1]?.id && (
                                <Tooltip title={t(TranslationKey.tooltip.moveDown)}>
                                    <IconButton onClick={() => onMoveEnd(index, index + 1)} color={'primary'}>
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Grid2>
                    )}
                    {isEditing && props.items.length - 1 !== index && (
                        <Grid2 size={12}>
                            <Divider color={'#eee'} sx={{ margin: '0 0 20px' }} />
                        </Grid2>
                    )}
                </Grid2>
            ))}
        </Box>
    );
};

export default OrderableList;
