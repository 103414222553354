import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { ICV } from '../../interfaces/CV.interface';
import { IPDFStyle } from '../../interfaces/pdfStyle.interface';

interface SectionPDFProps {
    cv: ICV;
    styles: IPDFStyle;
}

const ProfessionPDF = (props: SectionPDFProps): React.ReactElement => {
    return (
        <View style={{ ...props.styles.rowStyle, ...props.styles.professionView }}>
            <Text style={props.styles.professionFont}>{props.cv.profession.name}</Text>
        </View>
    );
};

export default ProfessionPDF;
