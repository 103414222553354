import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { getLocalizedPath } from '../utils/common.util';

const Logo = () => {
    const navigate = useNavigate();

    return (
        <Box display={'flex'} alignItems={'center'} sx={{ cursor: 'pointer' }} onClick={() => navigate(getLocalizedPath(`/`))}>
            <Typography variant="h6" color={'primary'} fontFamily={'roboto'} fontStyle={'italic'} sx={{ mx: 1 }}>
                SMART
            </Typography>
            <img src="/logo192.png" alt="Smart CV" width="50" height="50" />
        </Box>
    );
};

export default Logo;
