import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { ITokenDto, ITokenResponse } from '../../interfaces/Auth.interface';
import { useAuth } from '../../context/Auth.context';
import { TranslationKey } from '../../interfaces/TranslationKey';
import { useTranslation } from 'react-i18next';
import { activateUser } from '../../services/auth.service';
import { AxiosError } from 'axios';
import { IApi } from '../../services/api.service';
import { useAxiosApi } from '../../hooks/useAxiosApi';
import { Helmet } from 'react-helmet-async';
import { getLocalizedPath } from '../../utils/common.util';

const ActivatePage = () => {
    const { t } = useTranslation();
    const api: IApi = useAxiosApi();
    const [searchParams] = useSearchParams();
    const activationToken = searchParams.get('token');
    const { setAccessToken } = useAuth();
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const isMounted = useRef(true);

    useEffect(() => {
        let timeoutId: string | number | NodeJS.Timeout | undefined;

        isMounted.current = true;
        const controller = new AbortController();
        const signal = controller.signal;

        const activateAccount = async () => {
            setMessage('');
            setError(false);
            if (!activationToken) return;

            const dto: ITokenDto = {
                token: activationToken,
            };

            activateUser(api, dto, signal)
                .then((token: ITokenResponse) => {
                    setAccessToken(token.accessToken);
                    setMessage(TranslationKey.accountActivation.activationSuccessMsg);
                    if (token.accessToken) {
                        timeoutId = setTimeout(() => navigate(getLocalizedPath('/profile')), 2000);
                    }
                })
                .catch((error: AxiosError) => {
                    if (error.response?.status === 409) {
                        setMessage(TranslationKey.accountActivation.activationErrorActiveMsg);
                    } else {
                        setMessage(TranslationKey.accountActivation.activationErrorExpiredMsg);
                    }
                    timeoutId = setTimeout(() => navigate(getLocalizedPath('/')), 3000);
                });
        };

        activateAccount();

        return () => {
            clearTimeout(timeoutId);
            isMounted.current = false;
            controller.abort();
        };
    }, [activationToken, navigate]);

    return (
        <>
            <Helmet>
                <title>{t(TranslationKey.helmet.title.activationPage)}</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'} height={'40vh'}>
                <Box>
                    <Typography variant="h4" align={'center'}>
                        {t(TranslationKey.accountActivation._name)}
                    </Typography>
                    {message ? (
                        <Box>
                            <Typography color={error ? 'error.main' : ''}>{t(message)}</Typography>
                            <Button variant={'text'} fullWidth onClick={() => navigate(getLocalizedPath('/'))}>
                                {t(TranslationKey.landingPage._name)}
                            </Button>
                        </Box>
                    ) : (
                        <Box display={'flex'} justifyContent={'center'} mt={5}>
                            <CircularProgress />
                        </Box>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default ActivatePage;
