import React from 'react';
import { Button, Grid2, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TranslationKey } from '../interfaces/TranslationKey';
import { getLocalizedPath } from '../utils/common.util';

const NotFoundPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Grid2 container height="60vh" alignItems="center" justifyContent="center">
            <Paper elevation={3} sx={{ padding: '2rem', marginTop: '4rem', width: '350px' }}>
                <Typography variant="h4">{t(TranslationKey.error.error404Title)}</Typography>
                <Typography variant="body1">{t(TranslationKey.error.error404Desc)}</Typography>
                <Button variant="text" sx={{ mt: 2 }} onClick={() => navigate(getLocalizedPath('/'))}>
                    {t(TranslationKey.landingPage._name)}
                </Button>
            </Paper>
        </Grid2>
    );
};

export default NotFoundPage;
