import React from 'react';
import { useTranslation } from 'react-i18next';
import { Canvas, Text, View } from '@react-pdf/renderer';
import { ICV } from '../../interfaces/CV.interface';
import { IPDFStyle } from '../../interfaces/pdfStyle.interface';
import { TranslationKey } from '../../interfaces/TranslationKey';
import dayjs from 'dayjs';
import { IExperience, IExperienceItem } from '../../interfaces/Experience.interface';
import { Style } from '@react-pdf/types';

interface SectionPDFProps {
    cv: ICV;
    styles: IPDFStyle;
}

const ExperiencePDF = (props: SectionPDFProps): React.ReactElement => {
    const { t, i18n } = useTranslation();

    const isRTL: boolean = i18n.dir() === 'rtl';

    const rtlTermination: string = isRTL ? ' \u200F ' : '';

    return (
        <View>
            <View style={props.styles.experienceView}>
                {props.cv.experiences.map((experience: IExperience, index: number) => {
                    const startDateStr: string = dayjs(experience.startDate).format(props.styles.experienceDateFormat.format);
                    const endDateStr: string = experience.endDate
                        ? dayjs(experience.endDate).format(props.styles.experienceDateFormat.format)
                        : t(TranslationKey.date.current);
                    return (
                        <View
                            key={`PDF-experience-${experience.id}`}
                            style={{ marginBottom: index === props.cv.experiences.length - 1 ? 0 : 10 }}
                        >
                            <View style={{ ...props.styles.rowStyle, flexWrap: 'wrap' }}>
                                <Text style={props.styles.experienceHeaderFont}>{experience.title}</Text>
                                {experience.company && (
                                    <View style={{ ...props.styles.rowStyle, flexWrap: 'wrap' }}>
                                        <Text style={{ ...props.styles.experienceHeaderFont, margin: '0 5px' }}>|</Text>
                                        <Text style={props.styles.experienceHeaderFont}>{experience.company.name}</Text>
                                        {experience.company.location && (
                                            <Text style={{ ...props.styles.experienceHeaderFont, margin: '0 5px' }}>&mdash;</Text>
                                        )}
                                        {experience.company.location && (
                                            <Text style={props.styles.experienceHeaderFont}>{experience.company.location}</Text>
                                        )}
                                    </View>
                                )}
                            </View>
                            <View style={{ ...props.styles.rowStyle, margin: '5px 0' }}>
                                <Text style={props.styles.experienceDateFont}>{startDateStr}</Text>
                                <Text style={{ ...props.styles.experienceDateFont, margin: '0 5px' }}>&mdash;</Text>
                                <Text style={props.styles.experienceDateFont}>{endDateStr}</Text>
                            </View>
                            {experience.items.map((item: IExperienceItem) => (
                                <View key={`PDF-item-${item.id}`} style={{ ...props.styles.rowStyle, marginBottom: '3px' }}>
                                    <Canvas
                                        style={{ height: 8, width: 10 }}
                                        paint={(painter: any) => {
                                            painter.circle(5, 6, 3).fill(props.styles.experienceItemFont.color);
                                            return null;
                                        }}
                                    />
                                    <Text
                                        style={
                                            {
                                                ...props.styles.experienceItemFont,
                                                flex: 1,
                                                margin: '0 8px',
                                                direction: i18n.dir(),
                                                textAlign: isRTL ? 'right' : 'left',
                                            } as Style
                                        }
                                    >
                                        {rtlTermination + item.description + rtlTermination}
                                    </Text>
                                </View>
                            ))}
                        </View>
                    );
                })}
            </View>
        </View>
    );
};

export default ExperiencePDF;
