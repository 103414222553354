import { ApiEndpoints } from './endpoints';
import { IAddress, IAddressCreationDto, IAddressUpdateDto } from '../interfaces/Address.interface';
import { IApi } from './api.service';

export const createAddress = async (api: IApi, addressDto: IAddressCreationDto): Promise<IAddress | null> => {
    return api.post<IAddress, IAddressCreationDto>(ApiEndpoints.address, addressDto);
};

export const updateAddress = async (api: IApi, id: number, addressDto: IAddressUpdateDto): Promise<IAddress | null> => {
    return api.put<IAddress, IAddressUpdateDto>(ApiEndpoints.addressId.replace(':id', String(id)), addressDto);
};
