import React from 'react';
import { Typography } from '@mui/material';
import { useMode } from '../context/Mode.context';
import { Mode } from '../interfaces/Mode.interface';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { IDateTheme, IFontTheme } from '../interfaces/Theme.interface';

interface DateProps {
    label: string;
    value: Dayjs;
    setValue: (value: Dayjs) => void;
    theme: IFontTheme & IDateTheme;
    onAccept?: (value: Dayjs | null) => void;
}

const EditableDate: React.FC<Omit<DateProps, 'date' | 'format'>> = (props: Omit<DateProps, 'date' | 'format'>) => {
    const { t } = useTranslation();

    const onAccept = (value: Dayjs | null) => {
        if (props.onAccept) {
            props.onAccept(value);
        }
        props.setValue(value ?? dayjs());
    };

    return <DatePicker label={t(props.label)} views={['month', 'year']} value={props.value} onAccept={onAccept} sx={{ width: '100%' }} />;
};

const PrintableDate = (props: Pick<DateProps, 'value' | 'theme'>): React.ReactElement => {
    const { i18n } = useTranslation();

    return (
        <Typography
            component={'p'}
            color={props.theme.color}
            variant={props.theme.variant}
            fontStyle={props.theme.style}
            fontWeight={props.theme.weight}
            sx={{ color: `${props.theme.color} !important` }}
        >
            {props.value.locale(i18n.language).format(props.theme.format)}
        </Typography>
    );
};

const DatePickerMY: React.FC<DateProps> = (props: DateProps) => {
    const { mode } = useMode();

    switch (mode) {
        case Mode.EDIT:
            return <EditableDate {...props} />;
        case Mode.PRINT:
            return <PrintableDate value={props.value} theme={props.theme} />;
    }
};

export { DatePickerMY, PrintableDate, EditableDate };
