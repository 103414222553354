import React from 'react';
import { Box, Grid2, InputLabel, MenuItem, Switch } from '@mui/material';
import { ITitleTheme, ThemeValue } from '../../interfaces/Theme.interface';
import { LABEL_SIZE } from './ThemeDesigner';
import { TranslationKey } from '../../interfaces/TranslationKey';
import { useTranslation } from 'react-i18next';
import FontThemeCustomizer from './FontTheme';

interface TitleThemeCustomizerProps {
    labelKey: string;
    theme: ITitleTheme;
    handleThemeChange: (key: keyof ITitleTheme, value: ThemeValue) => void;
}

const TitleThemeCustomizer = (props: TitleThemeCustomizerProps): React.ReactElement => {
    const { t } = useTranslation();
    const [show, setShow] = React.useState(props.theme.show);

    const handleOnToggle = () => {
        props.handleThemeChange('show', !show);
        setShow(!show);
    };

    const handleFontThemeChange = (key: keyof ITitleTheme, value: string) => {
        props.handleThemeChange(key, value);
    };

    return (
        <Box>
            <Grid2 container spacing={2}>
                <Grid2 size={LABEL_SIZE}>
                    <InputLabel>{t(TranslationKey.theme.showTitle)}</InputLabel>
                </Grid2>
                <Grid2 size="grow">
                    <Switch value={show} checked={show} onChange={handleOnToggle} />
                </Grid2>
                <Grid2 size={12}>
                    <FontThemeCustomizer
                        labelKey={props.labelKey}
                        theme={props.theme}
                        handleThemeChange={handleFontThemeChange}
                        variantItems={[
                            <MenuItem key={'section-font-variant-h4'} value="h4">
                                {t(TranslationKey.theme.fontSize.L)}
                            </MenuItem>,
                            <MenuItem key={'section-font-variant-h5'} value="h5">
                                {t(TranslationKey.theme.fontSize.M)}
                            </MenuItem>,
                            <MenuItem key={'section-font-variant-h6'} value="h6">
                                {t(TranslationKey.theme.fontSize.S)}
                            </MenuItem>,
                            <MenuItem key={'section-font-variant-body1'} value="body1">
                                {t(TranslationKey.theme.fontSize.XS)}
                            </MenuItem>,
                        ]}
                    />
                </Grid2>
            </Grid2>
        </Box>
    );
};

export default TitleThemeCustomizer;
