import React from 'react';
import { Box, Grid2 } from '@mui/material';
import { useMode } from '../../context/Mode.context';
import { Mode } from '../../interfaces/Mode.interface';
import { ITemplateProps } from '../../interfaces/Template.interface';
import { A4_HEIGHT } from '../BaseTemplate';
import { ILayout, SectionName } from '../../interfaces/Theme.interface';
import { TemplateName } from '../../common/enum';
import { getSectionElements, getSections, ISectionElementsProps } from '../Template.util';

const CairoTemplate = (props: ITemplateProps): React.ReactElement => {
    const { mode } = useMode();
    const isEditing: boolean = mode === Mode.EDIT;

    const topSectionHeight: number = A4_HEIGHT * 0.2;

    const templateName: TemplateName = TemplateName.CAIRO;
    const layout: ILayout = getSections(props.theme.layoutTheme, templateName);

    const showTopSection: boolean = layout.top.sections.some((i) => !!i);

    const showMiddleSection: boolean = layout.middle.sections.some((i) => !!i);
    const showMiddleLeftRightSection: boolean = layout.middleLeft.sections.some((i) => !!i) || layout.middleRight.sections.some((i) => !!i);

    const showBottomSection: boolean = layout.bottom.sections.some((i) => !!i);
    const showBottomLeftRightSection: boolean = layout.bottomLeft.sections.some((i) => !!i) || layout.bottomRight.sections.some((i) => !!i);

    const sectionPadding = `0 30px`;

    const getProps = (sections: SectionName[]): ISectionElementsProps => ({
        templateName: templateName,
        sections: sections,
        templateProps: props,
        isEditing: isEditing,
    });

    return (
        <Box>
            {/* top  */}
            {showTopSection && <Grid2 sx={{ padding: sectionPadding }}>{getSectionElements(getProps(layout.top.sections))}</Grid2>}

            {/* top left/right */}
            <Grid2 container size={12}>
                <Grid2
                    size={{
                        xs: isEditing ? 12 : props.theme.layoutTheme.sectionWidth.topLeftWidth,
                        lg: props.theme.layoutTheme.sectionWidth.topLeftWidth,
                    }}
                    sx={{
                        minHeight: topSectionHeight,
                        backgroundColor: isEditing ? undefined : props.theme.subSectionTheme.backgroundColor.leftSection,
                        border: isEditing ? 'solid 1px #444' : undefined,
                    }}
                >
                    <Box display={'flex'} minHeight={topSectionHeight} alignItems={'center'} justifyContent={'center'}>
                        {getSectionElements(getProps(layout.topLeft.sections))}
                    </Box>
                </Grid2>
                <Grid2
                    size={{
                        xs: isEditing ? 12 : 12 - props.theme.layoutTheme.sectionWidth.topLeftWidth,
                        lg: 12 - props.theme.layoutTheme.sectionWidth.topLeftWidth,
                    }}
                    sx={{ padding: sectionPadding }}
                >
                    <Box
                        alignItems="center"
                        justifyContent={'center'}
                        alignContent="center"
                        justifyItems={'flex-start'}
                        minHeight={topSectionHeight}
                    >
                        {getSectionElements(getProps(layout.topRight.sections))}
                    </Box>
                </Grid2>
            </Grid2>

            {/* top  */}
            {showMiddleSection && <Grid2 sx={{ padding: sectionPadding }}>{getSectionElements(getProps(layout.middle.sections))}</Grid2>}

            {/* middle left/right */}
            {showMiddleLeftRightSection && (
                <Grid2 container size={12}>
                    <Grid2
                        size={{
                            xs: isEditing ? 12 : props.theme.layoutTheme.sectionWidth.middleLeftWidth,
                            lg: props.theme.layoutTheme.sectionWidth.middleLeftWidth,
                        }}
                        sx={{
                            backgroundColor: isEditing ? undefined : props.theme.subSectionTheme.backgroundColor.leftSection,
                            border: isEditing ? 'solid 1px #444' : undefined,
                            padding: sectionPadding,
                        }}
                    >
                        {getSectionElements(getProps(layout.middleLeft.sections))}
                    </Grid2>
                    <Grid2
                        size={{
                            xs: isEditing ? 12 : 12 - props.theme.layoutTheme.sectionWidth.middleLeftWidth,
                            lg: 12 - props.theme.layoutTheme.sectionWidth.middleLeftWidth,
                        }}
                        sx={{ padding: sectionPadding }}
                    >
                        {getSectionElements(getProps(layout.middleRight.sections))}
                    </Grid2>
                </Grid2>
            )}

            {/* bottom  */}
            {showBottomSection && <Grid2 sx={{ padding: sectionPadding }}>{getSectionElements(getProps(layout.bottom.sections))}</Grid2>}

            {/* bottom left/right */}
            {showBottomLeftRightSection && (
                <Grid2 container size={12}>
                    <Grid2
                        size={{
                            xs: isEditing ? 12 : props.theme.layoutTheme.sectionWidth.bottomLeftWidth,
                            lg: props.theme.layoutTheme.sectionWidth.bottomLeftWidth,
                        }}
                        sx={{
                            backgroundColor: isEditing ? undefined : props.theme.subSectionTheme.backgroundColor.leftSection,
                            padding: sectionPadding,
                        }}
                    >
                        {getSectionElements(getProps(layout.bottomLeft.sections))}
                    </Grid2>
                    <Grid2
                        size={{
                            xs: isEditing ? 12 : 12 - props.theme.layoutTheme.sectionWidth.bottomLeftWidth,
                            lg: 12 - props.theme.layoutTheme.sectionWidth.bottomLeftWidth,
                        }}
                        sx={{ padding: sectionPadding }}
                    >
                        {getSectionElements(getProps(layout.bottomRight.sections))}
                    </Grid2>
                </Grid2>
            )}
        </Box>
    );
};

export default CairoTemplate;
