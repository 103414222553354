import React from 'react';
import { Box, Container, Grid2, Typography } from '@mui/material';
import { TranslationKey } from '../interfaces/TranslationKey';
import { useTranslation } from 'react-i18next';
import damascusImage from './../assets/images/damascus.png';
import cairoImage from './../assets/images/cairo.png';
import dohaImage from './../assets/images/doha.png';
import hamaImage from './../assets/images/hama.png';
import homsImage from './../assets/images/homs.png';
import idlibImage from './../assets/images/idlib.png';
import madinaImage from './../assets/images/madina.png';
import meccaImage from './../assets/images/mecca.png';
import alqudsImage from './../assets/images/alquds.png';
import TemplateCard from '../components/TemplateCard';
import { AutoAwesome, Description } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { TemplateName } from '../common/enum';
import { useAuth } from '../context/Auth.context';
import { Helmet } from 'react-helmet-async';
import CVCreationButton from '../components/CVCreationButton';
import { getLocalizedPath } from '../utils/common.util';

interface TemplateDate {
    humanName: string;
    name: TemplateName;
    description: string;
    imageUrl: string | null;
}

const templates: TemplateDate[] = [
    {
        humanName: TranslationKey.template.damascus,
        name: TemplateName.DAMASCUS,
        description: TranslationKey.template.damascusDesc,
        imageUrl: damascusImage,
    },
    {
        humanName: TranslationKey.template.cairo,
        name: TemplateName.CAIRO,
        description: TranslationKey.template.cairoDesc,
        imageUrl: cairoImage,
    },
    {
        humanName: TranslationKey.template.doha,
        name: TemplateName.DOHA,
        description: TranslationKey.template.dohaDesc,
        imageUrl: dohaImage,
    },
    {
        humanName: TranslationKey.template.mecca,
        name: TemplateName.MECCA,
        description: TranslationKey.template.meccaDesc,
        imageUrl: meccaImage,
    },
    {
        humanName: TranslationKey.template.madina,
        name: TemplateName.MADINA,
        description: TranslationKey.template.madinaDesc,
        imageUrl: madinaImage,
    },
    {
        humanName: TranslationKey.template.alQuds,
        name: TemplateName.ALQUDS,
        description: TranslationKey.template.alQudsDesc,
        imageUrl: alqudsImage,
    },
    {
        humanName: TranslationKey.template.idlib,
        name: TemplateName.IDLIB,
        description: TranslationKey.template.idlibDesc,
        imageUrl: idlibImage,
    },
    {
        humanName: TranslationKey.template.homs,
        name: TemplateName.HOMS,
        description: TranslationKey.template.homsDesc,
        imageUrl: homsImage,
    },
    {
        humanName: TranslationKey.template.hama,
        name: TemplateName.HAMA,
        description: TranslationKey.template.hamaDesc,
        imageUrl: hamaImage,
    },

    {
        humanName: TranslationKey.template.aleppo,
        name: TemplateName.ALEPPO,
        description: TranslationKey.template.aleppoDesc,
        imageUrl: null,
    },
    {
        humanName: TranslationKey.template.golan,
        name: TemplateName.GOLAN,
        description: TranslationKey.template.golanDesc,
        imageUrl: null,
    },
    {
        humanName: TranslationKey.template.gaza,
        name: TemplateName.GAZA,
        description: TranslationKey.template.gazaDesc,
        imageUrl: null,
    },
    {
        humanName: TranslationKey.template.riadh,
        name: TemplateName.RIADH,
        description: TranslationKey.template.riadhDesc,
        imageUrl: null,
    },
    {
        humanName: TranslationKey.template.mosul,
        name: TemplateName.MOSUL,
        description: TranslationKey.template.mosulDesc,
        imageUrl: null,
    },
    {
        humanName: TranslationKey.template.tripoli,
        name: TemplateName.TRIPOLI,
        description: TranslationKey.template.tripoliDesc,
        imageUrl: null,
    },
];

const HomePage: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user } = useAuth();

    const handleOnTemplateClick = (templateName: string) => {
        navigate(getLocalizedPath(`/cv/builder/template/${templateName}`));
    };

    return (
        <>
            <Helmet>
                <title>{t(TranslationKey.helmet.title.homePage)}</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="description" content={t(TranslationKey.helmet.meta.description)} />
            </Helmet>
            <Container>
                {/* Hero Section */}
                <Box sx={{ textAlign: 'center', py: 5 }}>
                    <Typography variant="h3" component="h1" gutterBottom>
                        {t(TranslationKey.landingPage.header)}
                    </Typography>
                    <Typography variant="h6" component="h2" color="text.secondary" gutterBottom>
                        {t(TranslationKey.landingPage.headerDec1)}
                    </Typography>
                    <Typography variant="h6" component="h2" color="text.secondary" gutterBottom>
                        {t(TranslationKey.landingPage.headerDec2)}
                    </Typography>
                </Box>

                {(!user || user?.isActive) && (
                    <Box sx={{ textAlign: 'center', pb: 5 }}>
                        <CVCreationButton variant={'contained'} color={'primary'} />
                    </Box>
                )}

                {/* Why Choose Us Section */}
                <Box sx={{ textAlign: 'center', py: 5, px: 3, backgroundColor: '#f9f9f9' }}>
                    <Typography variant="h4" component="h3" gutterBottom>
                        {t(TranslationKey.landingPage.whyChooseUs)}
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: '2rem' }}>
                        {t(TranslationKey.landingPage.whyChooseUsDesc)}
                    </Typography>
                    <Grid2 container spacing={3}>
                        <Grid2 size={{ xs: 12, md: 4 }}>
                            <Typography variant="h6" component="h4">
                                {t(TranslationKey.landingPage.easyToUse)}
                            </Typography>
                            <Typography variant="body2">{t(TranslationKey.landingPage.easyToUsDesc)}</Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 4 }}>
                            <Typography variant="h6" component="h4">
                                {t(TranslationKey.landingPage.professionalDesigns)}
                            </Typography>
                            <Typography variant="body2">{t(TranslationKey.landingPage.professionalDesignsDesc)}</Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 4 }}>
                            <Typography variant="h6" component="h4">
                                {t(TranslationKey.landingPage.astFriendly)}
                            </Typography>
                            <Typography variant="body2">{t(TranslationKey.landingPage.astFriendlyDesc)}</Typography>
                        </Grid2>
                    </Grid2>
                </Box>

                <Box sx={{ my: 5, p: 5, backgroundColor: '' }}>
                    <Typography
                        variant="h4"
                        component="h3"
                        gutterBottom
                        sx={{
                            textAlign: 'center',
                            fontWeight: 'bold',
                            color: '#333',
                            mb: 4,
                        }}
                    >
                        {t(TranslationKey.landingPage.howItWorks)}
                    </Typography>
                    <Grid2 container spacing={4} sx={{ textAlign: 'center' }}>
                        {[
                            {
                                step: t(TranslationKey.landingPage.step1),
                                description: t(TranslationKey.landingPage.step1Desc),
                                icon: <Description sx={{ fontSize: '32px' }} />,
                            },
                            {
                                step: t(TranslationKey.landingPage.step2),
                                description: t(TranslationKey.landingPage.step2Desc),
                                icon: <AutoAwesome sx={{ fontSize: '32px' }} />,
                            },
                            {
                                step: t(TranslationKey.landingPage.step3),
                                description: t(TranslationKey.landingPage.step3Desc),
                                icon: '✏️',
                            },
                            {
                                step: t(TranslationKey.landingPage.step4),
                                description: t(TranslationKey.landingPage.step4Desc),
                                icon: '📤',
                            },
                        ].map(({ step, description, icon }, index) => (
                            <Grid2 container size={{ xs: 12, md: 3 }} key={index} alignItems="stretch" justifyContent="center">
                                <Box
                                    sx={{
                                        width: '100%',
                                        p: 3,
                                        border: '1px solid #ddd',
                                        borderRadius: '10px',
                                        backgroundColor: '#fff',
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                        transition: 'transform 0.3s, box-shadow 0.3s',
                                        '&:hover': {
                                            transform: 'translateY(-5px)',
                                            boxShadow: '0 6px 10px rgba(0, 0, 0, 0.2)',
                                        },
                                    }}
                                >
                                    <Grid2 container justifyContent="center">
                                        <Grid2>
                                            <Typography
                                                variant="h5"
                                                component="h4"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    color: '#333',
                                                    m: 1,
                                                }}
                                            >
                                                {icon}
                                            </Typography>
                                        </Grid2>
                                        <Grid2>
                                            <Typography
                                                variant="h5"
                                                component="h4"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    color: '#333',
                                                    m: 1,
                                                }}
                                            >
                                                {step}
                                            </Typography>
                                        </Grid2>
                                    </Grid2>
                                    <Typography variant="body2" sx={{ color: '#666', lineHeight: 1.6 }}>
                                        {description}
                                    </Typography>
                                </Box>
                            </Grid2>
                        ))}
                    </Grid2>
                </Box>

                {/* Templates Section */}
                <Box>
                    <Typography variant="h4" component="h3" gutterBottom sx={{ margin: '2rem 0' }}>
                        {t(TranslationKey.landingPage.chooseTemplate)}
                    </Typography>
                    <Grid2 container={true} spacing={4}>
                        {templates.map((template: TemplateDate, index: number) => (
                            <Grid2 key={`template-blueprint-${index}`} size={{ xs: 12, md: 4 }}>
                                {template.imageUrl && (
                                    <TemplateCard
                                        title={template.humanName}
                                        description={template.description}
                                        imageUrl={template.imageUrl}
                                        onClick={() => handleOnTemplateClick(template.name)}
                                    />
                                )}
                            </Grid2>
                        ))}
                    </Grid2>
                </Box>
            </Container>
        </>
    );
};

export default HomePage;
