import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useMode } from '../../context/Mode.context';
import { Mode } from '../../interfaces/Mode.interface';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../interfaces/TranslationKey';
import ImageForm, { ImageProps } from '../builder/forms/ImageForm';
import { getImage } from '../../services/cv.service';
import { useCV } from '../../context/CV.context';
import { IApi } from '../../services/api.service';
import { useAxiosApi } from '../../hooks/useAxiosApi';

export const PrintableProfileImage = (props: ImageProps): React.ReactElement => {
    const { t } = useTranslation();
    const api: IApi = useAxiosApi();
    const { cv } = useCV();
    const [imageSrc, setImageSrc] = useState<string | null>(null);

    useEffect(() => {
        if (!!cv?.profileImage) {
            getImage(api, cv.id).then((image) => {
                if (image) {
                    setImageSrc(image);
                }
            });
        } else {
            setImageSrc(null);
        }
    }, [cv?.profileImage, cv?.id]);

    return !!imageSrc ? (
        <Box
            component="img"
            src={imageSrc}
            alt={t(TranslationKey.image.profileImage)}
            sx={{
                width: props.theme.width,
                height: props.theme.height,
                objectFit: 'cover',
                borderRadius: props.theme.circular ? '50%' : null,
                border: props.theme.hasBorder ? `solid ${props.theme.borderColor} ${props.theme.borderWidth}px` : undefined,
            }}
        />
    ) : (
        <Box></Box>
    );
};

const ProfileImage = (props: ImageProps): React.ReactElement => {
    const { mode } = useMode();

    switch (mode) {
        case Mode.EDIT:
            return <ImageForm {...props} />;
        case Mode.PRINT:
            return <PrintableProfileImage {...props} />;
    }
};

export default ProfileImage;
