import { ApiEndpoints } from './endpoints';
import { IPaginatedListResponse } from '../interfaces/Pagination.interface';
import {
    ILanguage,
    ILanguageFilter,
    ILanguageProficiency,
    ILanguageProficiencyCreationDto,
    ILanguageProficiencyUpdateDto,
} from '../interfaces/LanguageProficiency.interface';
import { IApi } from './api.service';

export const getAllLanguages = async (api: IApi, filter: ILanguageFilter): Promise<IPaginatedListResponse<ILanguage>> => {
    const paginatedLanguages: IPaginatedListResponse<ILanguage> | null = await api.get<IPaginatedListResponse<ILanguage>, ILanguageFilter>(
        ApiEndpoints.language,
        { params: filter },
    );
    if (!paginatedLanguages) {
        return {
            count: 0,
            items: [],
            page: 0,
            pageSize: 0,
        };
    }
    return paginatedLanguages;
};

export const createLanguageProficiency = async (api: IApi, dto: ILanguageProficiencyCreationDto): Promise<ILanguageProficiency | null> => {
    return api.post<ILanguageProficiency, ILanguageProficiencyCreationDto>(ApiEndpoints.languageProficiency, dto);
};

export const updateLanguageProficiency = async (
    api: IApi,
    id: number,
    dto: ILanguageProficiencyUpdateDto,
): Promise<ILanguageProficiency | null> => {
    return api.put<ILanguageProficiency, ILanguageProficiencyUpdateDto>(ApiEndpoints.languageProficiencyId.replace(':id', String(id)), dto);
};

export const removeLanguageProficiency = async (api: IApi, id: number): Promise<void> => {
    return api.delete(ApiEndpoints.languageProficiencyId.replace(':id', String(id)));
};
