import React, { useEffect, useState } from 'react';
import {
    IContactTheme,
    IEducationTheme,
    IExperienceTheme,
    IImageTheme,
    ILanguageTheme,
    ILayoutTheme,
    INameTheme,
    IProfessionTheme,
    IReferenceTheme,
    ISkillTheme,
    ISocialLinkTheme,
    ISubSectionTheme,
    ITheme,
} from '../../interfaces/Theme.interface';
import NameThemeCustomizer from './NameTheme';
import ImageThemeCustomizer from './ImageTheme';
import LayoutThemeCustomizer from './LayoutTheme';
import ProfessionThemeCustomizer from './ProfessionTheme';
import ExperienceThemeCustomizer from './ExperienceTheme';
import EducationThemeCustomizer from './EducationTheme';
import SkillLanguageThemeCustomizer from './SkillLanguageTheme';
import ReferenceThemeCustomizer from './ReferenceTheme';
import { TranslationKey } from '../../interfaces/TranslationKey';
import { useTranslation } from 'react-i18next';
import { updateTemplate } from '../../services/template.service';
import { IApi } from '../../services/api.service';
import { useAxiosApi } from '../../hooks/useAxiosApi';
import FontMarginTitleThemeCustomizer from './FontMarginTitleTheme';
import { Box, Button, Divider, Grid2, Tab, Tabs, Typography } from '@mui/material';
import SocialLinkThemeCustomizer from './SocialLinkTheme';
import { TemplateName } from '../../common/enum';
import {
    AccountBox as ProfileIcon,
    BusinessCenter as ExperienceIcon,
    Contacts as ContactIcon,
    Dashboard as LayoutIcon,
    Favorite as InterestIcon,
    InsertPhoto as ImageIcon,
    Link as SocialLinkIcon,
    People as ReferenceIcon,
    Person as NameIcon,
    School as EducationIcon,
    Star as SkillIcon,
    Translate as LanguageIcon,
    Work as ProfessionIcon,
} from '@mui/icons-material';

export const LABEL_SIZE = 3;

interface ThemeCustomizerProps {
    templateId: number | null;
    templateName: TemplateName;
    theme: ITheme;
    setTheme: (theme: (prev: ITheme) => ITheme) => void;
}

const ThemeDesigner = (props: ThemeCustomizerProps): React.ReactElement => {
    const { t } = useTranslation();
    const api: IApi = useAxiosApi();
    const [activeTab, setActiveTab] = useState(0);
    const [imageTheme, setImageTheme] = useState<IImageTheme>(props.theme.imageTheme);
    const [nameTheme, setNameTheme] = useState<INameTheme>(props.theme.nameTheme);
    const [professionTheme, setProfessionTheme] = useState<IProfessionTheme>(props.theme.professionTheme);
    const [layoutTheme, setLayoutTheme] = useState<ILayoutTheme>(props.theme.layoutTheme);
    const [subSectionTheme, setSubSectionTheme] = useState<ISubSectionTheme>(props.theme.subSectionTheme);
    const [contactTheme, setContactTheme] = useState<IContactTheme>(props.theme.contactTheme);
    const [profileTheme, setProfileTheme] = useState<IContactTheme>(props.theme.profileTheme);
    const [interestsTheme, setInterestsTheme] = useState<IContactTheme>(props.theme.interestsTheme);
    const [experienceTheme, setExperienceTheme] = useState<IExperienceTheme>(props.theme.experienceTheme);
    const [educationTheme, setEducationTheme] = useState<IEducationTheme>(props.theme.educationTheme);
    const [referenceTheme, setReferenceTheme] = useState<IReferenceTheme>(props.theme.referenceTheme);
    const [socialLinkTheme, setSocialLinkTheme] = useState<ISocialLinkTheme>(props.theme.socialLinkTheme);
    const [skillTheme, setSkillTheme] = useState<ISkillTheme>(props.theme.skillTheme);
    const [languageTheme, setLanguageTheme] = useState<ILanguageTheme>(props.theme.languageTheme);
    const [shouldUpdateTheme, setShouldUpdateThemed] = useState<boolean>(false);

    useEffect(() => {
        handleThemeChange('layoutTheme', layoutTheme);
    }, [layoutTheme]);

    useEffect(() => {
        handleThemeChange('subSectionTheme', subSectionTheme);
    }, [subSectionTheme]);

    useEffect(() => {
        handleThemeChange('nameTheme', nameTheme);
    }, [nameTheme]);

    useEffect(() => {
        handleThemeChange('imageTheme', imageTheme);
    }, [imageTheme]);

    useEffect(() => {
        handleThemeChange('professionTheme', professionTheme);
    }, [professionTheme]);

    useEffect(() => {
        handleThemeChange('contactTheme', contactTheme);
    }, [contactTheme]);

    useEffect(() => {
        handleThemeChange('profileTheme', profileTheme);
    }, [profileTheme]);

    useEffect(() => {
        handleThemeChange('interestsTheme', interestsTheme);
    }, [interestsTheme]);

    useEffect(() => {
        handleThemeChange('experienceTheme', experienceTheme);
    }, [experienceTheme]);

    useEffect(() => {
        handleThemeChange('educationTheme', educationTheme);
    }, [educationTheme]);

    useEffect(() => {
        handleThemeChange('referenceTheme', referenceTheme);
    }, [referenceTheme]);

    useEffect(() => {
        handleThemeChange('socialLinkTheme', socialLinkTheme);
    }, [socialLinkTheme]);

    useEffect(() => {
        handleThemeChange('skillTheme', skillTheme);
    }, [skillTheme]);

    useEffect(() => {
        handleThemeChange('languageTheme', languageTheme);
    }, [languageTheme]);

    const handleThemeChange = <T extends keyof ITheme>(section: T, value: Partial<ITheme[T]>) => {
        props.setTheme((prev: ITheme) => {
            return {
                ...prev,
                [section]: { ...prev[section], ...value },
            };
        });
    };

    const saveThemeChanges = () => {
        if (props.templateId) {
            updateTemplate(api, props.templateId, props.theme).then();
        }

        setShouldUpdateThemed(false);
    };

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const tabSections = [
        {
            label: t(TranslationKey.theme.layout),
            icon: <LayoutIcon />,
            component: (
                <LayoutThemeCustomizer
                    key={'LayoutThemeCustomizer'}
                    templateName={props.templateName}
                    theme={layoutTheme}
                    setTheme={setLayoutTheme}
                    sectionTheme={props.theme.subSectionTheme}
                    setSectionTheme={setSubSectionTheme}
                    setShouldUpdateThemed={setShouldUpdateThemed}
                />
            ),
        },
        {
            label: t(TranslationKey.name),
            icon: <NameIcon />,
            component: (
                <NameThemeCustomizer theme={props.theme.nameTheme} setTheme={setNameTheme} setShouldUpdateThemed={setShouldUpdateThemed} />
            ),
        },
        {
            label: t(TranslationKey.profession),
            icon: <ProfessionIcon />,
            component: (
                <ProfessionThemeCustomizer
                    key={'ProfessionThemeCustomizer'}
                    theme={props.theme.professionTheme}
                    setTheme={setProfessionTheme}
                    setShouldUpdateThemed={setShouldUpdateThemed}
                />
            ),
        },
        {
            label: t(TranslationKey.image._name),
            icon: <ImageIcon />,
            component: (
                <ImageThemeCustomizer
                    key={'ImageThemeCustomizer'}
                    theme={props.theme.imageTheme}
                    setTheme={setImageTheme}
                    setShouldUpdateThemed={setShouldUpdateThemed}
                />
            ),
        },
        {
            label: t(TranslationKey.cvProfile),
            icon: <ProfileIcon />,
            component: (
                <FontMarginTitleThemeCustomizer
                    key={'ProfileThemeCustomizer'}
                    themeLabelKey={TranslationKey.cvProfile}
                    theme={props.theme.profileTheme}
                    setTheme={setProfileTheme}
                    setShouldUpdateThemed={setShouldUpdateThemed}
                />
            ),
        },
        {
            label: t(TranslationKey.contact),
            icon: <ContactIcon />,
            component: (
                <FontMarginTitleThemeCustomizer
                    key={'ContactThemeCustomizer'}
                    themeLabelKey={TranslationKey.contact}
                    theme={props.theme.contactTheme}
                    setTheme={setContactTheme}
                    setShouldUpdateThemed={setShouldUpdateThemed}
                />
            ),
        },
        {
            label: t(TranslationKey.skill.plural),
            icon: <SkillIcon />,
            component: (
                <SkillLanguageThemeCustomizer
                    key={'SkillThemeCustomizer'}
                    themeLabelKey={TranslationKey.skill._name}
                    theme={props.theme.skillTheme}
                    setTheme={setSkillTheme}
                    setShouldUpdateThemed={setShouldUpdateThemed}
                />
            ),
        },
        {
            label: t(TranslationKey.language.plural),
            icon: <LanguageIcon />,
            component: (
                <SkillLanguageThemeCustomizer
                    key={'LanguageThemeCustomizer'}
                    themeLabelKey={TranslationKey.language._name}
                    theme={props.theme.languageTheme}
                    setTheme={setLanguageTheme}
                    setShouldUpdateThemed={setShouldUpdateThemed}
                />
            ),
        },
        {
            label: t(TranslationKey.experience._name),
            icon: <ExperienceIcon />,
            component: (
                <ExperienceThemeCustomizer
                    key={'ExperienceThemeCustomizer'}
                    theme={props.theme.experienceTheme}
                    setTheme={setExperienceTheme}
                    setShouldUpdateThemed={setShouldUpdateThemed}
                />
            ),
        },
        {
            label: t(TranslationKey.education._name),
            icon: <EducationIcon />,
            component: (
                <EducationThemeCustomizer
                    key={'EducationThemeCustomizer'}
                    theme={props.theme.educationTheme}
                    setTheme={setEducationTheme}
                    setShouldUpdateThemed={setShouldUpdateThemed}
                />
            ),
        },
        {
            label: t(TranslationKey.reference.plural),
            icon: <ReferenceIcon />,
            component: (
                <ReferenceThemeCustomizer
                    key={'ReferenceThemeCustomizer'}
                    theme={props.theme.referenceTheme}
                    setTheme={setReferenceTheme}
                    setShouldUpdateThemed={setShouldUpdateThemed}
                />
            ),
        },
        {
            label: t(TranslationKey.interests),
            icon: <InterestIcon />,
            component: (
                <FontMarginTitleThemeCustomizer
                    key={'InterestThemeCustomizer'}
                    themeLabelKey={TranslationKey.interests}
                    theme={props.theme.interestsTheme}
                    setTheme={setInterestsTheme}
                    setShouldUpdateThemed={setShouldUpdateThemed}
                />
            ),
        },
        {
            label: t(TranslationKey.socialLink.plural),
            icon: <SocialLinkIcon />,
            component: (
                <SocialLinkThemeCustomizer
                    key={'SocialLinkThemeCustomizer'}
                    theme={props.theme.socialLinkTheme}
                    setTheme={setSocialLinkTheme}
                    setShouldUpdateThemed={setShouldUpdateThemed}
                />
            ),
        },
    ];

    return (
        <Box>
            <Grid2 container size={12} spacing={2}>
                <Grid2 size={'grow'}>
                    <Typography variant="h4" gutterBottom>
                        {t(TranslationKey.theme.designer)}
                    </Typography>
                </Grid2>
                <Grid2 size={'auto'}>
                    <Button variant={'contained'} color={'primary'} onClick={saveThemeChanges} disabled={!shouldUpdateTheme}>
                        {t(TranslationKey.buttons.save)}
                    </Button>
                </Grid2>
            </Grid2>

            <Tabs value={activeTab} onChange={handleChange} variant="scrollable" scrollButtons="auto">
                {tabSections.map((section, index) => (
                    <Tab key={`${index}_+${section.component.key ?? section.label}`} label={section.label} icon={section.icon} />
                ))}
            </Tabs>

            <Divider sx={{ my: 2 }} />

            <Box
                sx={{
                    p: '30px 20px 20px',
                    height: '80vh',
                    maxHeight: '80vh',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    boxSizing: 'border-box',
                }}
            >
                {tabSections[activeTab].component}
            </Box>
        </Box>
    );
};

export default ThemeDesigner;
