import { IExperience } from '../../../interfaces/Experience.interface';
import { IExperienceTheme } from '../../../interfaces/Theme.interface';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { IApi } from '../../../services/api.service';
import { useAxiosApi } from '../../../hooks/useAxiosApi';
import { useCV } from '../../../context/CV.context';
import { reorderItems } from '../../../services/common.services';
import { ApiEndpoints } from '../../../services/endpoints';
import { removeExperience } from '../../../services/experience.service';
import OrderableList from '../../OrderableList';
import { IOrderableEntity } from '../../../interfaces/Common.interface';
import AddSectionButton from '../../AddSectionButton';
import ExperienceForm from './experience/ExperienceForm';

interface ExperiencesProps {
    cvId: number;
    experiences: IExperience[];
    theme: IExperienceTheme;
}

const ExperiencesForm: React.FC<ExperiencesProps> = (props: ExperiencesProps) => {
    const api: IApi = useAxiosApi();
    const { dispatch } = useCV();
    const [experiences, setExperiences] = React.useState<IExperience[]>(props.experiences);

    useEffect(() => {
        dispatch({ type: 'UPDATE_FIELD', field: 'experiences', value: experiences });
    }, [experiences]);

    const experienceAddCallback = () => {
        if (experiences.map((experience: IExperience) => experience.id).includes(0)) {
            return;
        }
        const newExperience: IExperience = {
            company: null,
            endDate: new Date(),
            items: [],
            startDate: new Date(),
            title: '',
            id: 0,
            weight: 100,
        };
        setExperiences([...experiences, newExperience]);
    };

    const _updateItems = async (movedItemId: number, newIndex: number) => {
        if (movedItemId) {
            reorderItems(ApiEndpoints.experienceIdReorder, api, movedItemId, newIndex).then();
        }
    };

    const handleRemoveExperience = (id: number): void => {
        if (id) {
            removeExperience(api, id).then();
        }
        const filteredList: IExperience[] = props.experiences.filter((experience: IExperience) => experience.id !== id);
        setExperiences(filteredList);
    };

    return (
        <>
            <OrderableList
                labelKey={'experience-'}
                items={experiences}
                setItems={setExperiences as Dispatch<SetStateAction<IOrderableEntity[]>>}
                onMoveEnd={_updateItems}
                onRemoveCallback={handleRemoveExperience}
                itemsGap={12}
                child={(item) => (
                    <ExperienceForm
                        cvId={props.cvId}
                        experience={item as IExperience}
                        experiences={experiences}
                        setExperiences={setExperiences}
                        them={props.theme}
                    />
                )}
            ></OrderableList>
            <AddSectionButton
                disabled={experiences.map((experience: IExperience) => experience.id).includes(0)}
                onClick={experienceAddCallback}
            />
        </>
    );
};

export default ExperiencesForm;
