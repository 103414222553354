import React from 'react';
import { Box, Grid2 } from '@mui/material';
import { useMode } from '../../context/Mode.context';
import { Mode } from '../../interfaces/Mode.interface';
import { ITemplateProps } from '../../interfaces/Template.interface';
import { ILayout, SectionName } from '../../interfaces/Theme.interface';
import { TemplateName } from '../../common/enum';
import { getSectionElements, getSections, ISectionElementsProps } from '../Template.util';

const MeccaTemplate = (props: ITemplateProps): React.ReactElement => {
    const { mode } = useMode();
    const isEditing: boolean = mode === Mode.EDIT;

    const templateName: TemplateName = TemplateName.MECCA;

    const layout: ILayout = getSections(props.theme.layoutTheme, templateName);

    const showTopSection: boolean = layout.top.sections.some((i) => !!i);
    const showTopLeftSection: boolean = layout.topLeft.sections.some((i) => !!i);
    const showTopLeftRightSection: boolean = showTopLeftSection || layout.topRight.sections.some((i) => !!i);

    const showMiddleSection: boolean = layout.middle.sections.some((i) => !!i);
    const showMiddleLeftRightSection: boolean = layout.middleLeft.sections.some((i) => !!i) || layout.middleRight.sections.some((i) => !!i);

    const showBottomSection: boolean = layout.bottom.sections.some((i) => !!i);
    const showBottomLeftRightSection: boolean = layout.bottomLeft.sections.some((i) => !!i) || layout.bottomRight.sections.some((i) => !!i);

    const getProps = (sections: SectionName[]): ISectionElementsProps => ({
        templateName: templateName,
        sections: sections,
        templateProps: props,
        isEditing: isEditing,
    });

    return (
        <>
            {/* top */}
            {showTopSection && <Box sx={{ padding: '20px 20px 20px 0' }}>{getSectionElements(getProps(layout.top.sections))}</Box>}

            {/* top left/right */}
            {showTopLeftRightSection && (
                <Grid2 container size={12} spacing={1} mb={'20px'}>
                    {showTopLeftSection && (
                        <Grid2
                            size={isEditing ? 12 : props.theme.layoutTheme.sectionWidth.topLeftWidth}
                            alignContent={'center'}
                            sx={isEditing ? { border: 'solid 1px #444', padding: '10px' } : {}}
                        >
                            {getSectionElements(getProps(layout.topLeft.sections))}
                        </Grid2>
                    )}
                    <Grid2
                        size={isEditing ? 12 : showTopLeftSection ? 12 - props.theme.layoutTheme.sectionWidth.topLeftWidth : 12}
                        alignItems="flex-start"
                        alignContent="flex-start"
                    >
                        {getSectionElements(getProps(layout.topRight.sections))}
                    </Grid2>
                </Grid2>
            )}

            {/* middle */}
            {showMiddleSection && <Box sx={{ padding: '20px 20px 20px 0' }}>{getSectionElements(getProps(layout.middle.sections))}</Box>}

            {/* middle left/right */}
            {showMiddleLeftRightSection && (
                <Grid2 container size={12}>
                    <Grid2 size={isEditing ? 12 : props.theme.layoutTheme.sectionWidth.middleLeftWidth}>
                        <Box sx={{ padding: '20px 20px 0 0' }}>{getSectionElements(getProps(layout.middleLeft.sections))}</Box>
                    </Grid2>
                    <Grid2 size={isEditing ? 12 : 12 - props.theme.layoutTheme.sectionWidth.middleLeftWidth}>
                        <Box sx={isEditing ? { border: 'solid 1px #444' } : {}}>
                            {getSectionElements(getProps(layout.middleRight.sections))}
                        </Box>
                    </Grid2>
                </Grid2>
            )}

            {/* bottom */}
            {showBottomSection && <Box sx={{ padding: '20px 20px 20px 0' }}>{getSectionElements(getProps(layout.bottom.sections))}</Box>}

            {/* bottom left/right */}
            {showBottomLeftRightSection && (
                <Grid2 container size={12}>
                    <Grid2 size={isEditing ? 12 : props.theme.layoutTheme.sectionWidth.bottomLeftWidth}>
                        <Box sx={{ padding: '20px 20px 0 0' }}>{getSectionElements(getProps(layout.bottomLeft.sections))}</Box>
                    </Grid2>
                    <Grid2 size={isEditing ? 12 : 12 - props.theme.layoutTheme.sectionWidth.bottomLeftWidth}>
                        <Box sx={isEditing ? { border: 'solid 1px #444' } : {}}>
                            {getSectionElements(getProps(layout.bottomRight.sections))}
                        </Box>
                    </Grid2>
                </Grid2>
            )}
        </>
    );
};

export default MeccaTemplate;
